import React, { Component } from "react";
import classNames from "classnames";
import "./index.css";

class Modal extends Component {

  modalContentRef = null;

  componentDidMount() {    
    if(this.props.isOpen){
      document.body.style.overflow = 'hidden';
    }    
  }
  
  componentWillUnmount() {
      document.body.style.overflow = 'auto';
  }

  backDropHandler = (e) => {
    if (this.props.backdrop) {
      if (this.modalContentRef && this.modalContentRef.contains && this.modalContentRef.contains(e.target)) {
          return;
      } else {
          this.props.close();
      }
    }
  }

  render() {
    let modalClose = classNames(
      "modal",
      this.props.isOpen === true ? "modal-open" : "modal-close"
    );
    let modlaDialogStyles = classNames('modal-dialog', this.props.className )
    
    return (
      <div className={modalClose} style={{zIndex:999999}} onClick={this.backDropHandler}>
        <div className={modlaDialogStyles}>
          <div className="modal-content" ref={node => {this.modalContentRef = node;}}>
              {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
Modal.defaultProps = {
  isOpen: false,
  backdrop: false,
  close: ()=>{},
};
export { Modal };
