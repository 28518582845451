import React, { Component } from "react";
import IsAuth from "./isAuth";
import NonAuth from './nonAuth'
import LogoNav from './logoNav'

class TopNavigation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showTopNavBar: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.route.isAuthenticated) {
      this.setState({ showTopNavBar: true });
    } else if(!nextProps.route.isAuthenticated && nextProps.location.pathname === '/'){
          this.setState({ showTopNavBar: true });
    } else {
      this.setState({ showTopNavBar: false });
    }

  }


  componentWillUnmount() {
    this.setState({ showTopNavBar: false });
  }

  render() {
    const { user } = this.props.auth;
    return (
      <React.Fragment>
        {
          (((this.props.isAuthenticated && localStorage.getItem("token")) || (!this.props.isAuthenticated && this.props.location.pathname === '/'))  && this.state.showTopNavBar) &&
          <IsAuth
            {...this.props}
            onSearch={this.props.onSearch}
            resetTopNavNotificationCounters={this.props.resetTopNavNotificationCounters}
          />
        }

        {/* {!this.props.isAuthenticated && !localStorage.getItem("token") && <NonAuth {...this.props} getLoginData={this.props.getLoginData}/>} */}

        {
          !localStorage.getItem("token") && (this.props.location.pathname !== '/mjbiz') && this.props.location.pathname !== '/' && // added to remove topnav from co branded login page
          <NonAuth
            {...this.props}
            isDesktop={this.props.isDesktop}
            getLoginData={this.props.getLoginData}
          />
        }

        {
          (user._id && !this.state.showTopNavBar) && <LogoNav />
        }
      </React.Fragment>
    );
  }
}

export default TopNavigation;
