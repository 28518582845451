import React, {PureComponent, lazy, Suspense} from 'react';
import Routes from "./Routes";
import {withRouter} from "react-router-dom";
import {authUser} from "./libs/auth";
import {isBrowserNotCompatible} from "./libs/validation";
import {connect} from "react-redux";
import TopNavigation from "./components/topNavigation";
import {NotifyAlert, CookiePolicy} from "./components/common";
import NonFadeOutAlert from "./components/common/alert/NonFadeOutAlert";
import * as AuthActions from "./actions/auth";
import {endEmulateSession} from './actions/admin';
import * as SearchActions from "./actions/search";
import {pullNotifications, markAsRead, openMessage} from './actions/message-v2';
import {switchCompany} from "./actions/company";
import {refreshNewsFeed} from './actions/newsFeed';
import {
    REDIRECT_BLOCK,
    CREATE_COMPANY_POPUP,
    SYSTEM_USER_BLOCKED_STATUS,
    VIEW_COUNT_TYPE,
    VIEW_COUNT_LOCATION,
    VIEW_MODULES,
    VIEW_ELEMENT_TYPE,
    MEMBER_TYPE,
    REGISTRATION_STEP,
    CONNECTIONS_SECTION,
} from './types/common';
import {ENCODED_DEFAULT_FILTER} from "./types/data";
import * as AuthTypes from './types/authentication'
import {CLIENT_BROWSER} from "./config";
import PopupAlert from './components/popupAlert/response';
import ToastAlert from './components/toast';
import {SOCKET_URL} from './config';
import SweetAlert from 'sweetalert-react';
import 'sweetalert/dist/sweetalert.css';
import {encodeUrlParams} from './libs/textFormat';
import {ADVANCED_SEARCH_DEFAULT, HEADER_TYPES, HEADER} from './types/data';
import NotFound from './containers/notFound';
import QueryString from 'query-string';
import {Loading} from './components/common';
import {postAJob} from './libs/commonMethods';
import {initSocket, joinEventRoom} from "./libs/socket";
import {getRandomOneZero, increaseView} from './libs/commonFunctions';
import {joinGroup, leaveGroup, closeMobileGroupsPopup} from "./actions/groups";
import {STATUS, JOIN_GROUP, LEAVE_GROUP} from './types/groups';
// import {JoinGroupModal, LeaveGroupModal} from "./components/groups/joinGroupModal";
import ReactGA from 'react-ga';
// import { datadogLogs } from '@datadog/browser-logs';

const Register = lazy(() => import('./containers/feed/public/register'));
const ReportModal = lazy(() => import("./containers/common/reportPopup"));
const CommonFriends = lazy(() => import('./components/commonFriends'));
const MyFriends = lazy(() => import('./components/myFriends'));
const PostingGuideLines = lazy(() => import("./containers/common/postingGuideLines"));
const AdminCompanies = lazy(() => import('./components/AdminCompanies'));
const ChatPopup = lazy(() => import("./containers/chatPopup"));
const AttendeesPopup = lazy(() => import('./containers/events/attendeesPopup'));
const MessengerPopUp = lazy(() => import("./containers/dataRoom/messengerPopup.modal"));
const ProInvitesPopup = lazy(() => import('./containers/userProfile/personalDashboard/proInvitesPopup'));
const CreateCompanyModel = lazy(() => import('./containers/profile/company/createCompanyPopup'));
const LeafwireGetProPopup = lazy(() => import('./containers/userProfile/personalDashboard/leafwireGetProPopup'));
const Footer = lazy(() => import('./components/footer'));
const {JoinGroupModal, LeaveGroupModal} = lazy(() => import("./components/groups/joinGroupModal"))

class App extends PureComponent {

    constructor(props) {
        super(props);

        this.alertInitSettings = {
            show: false,
            title: '',
            text: '',
            showCancelButton: false,
            onConfirm: null,
            onCancel: null,
            onEscapeKey: null,
            onOutsideClick: null,
            confirmButtonText: 'Ok!',
            cancelButtonText: 'Cancel',
        };

        this.defaultJoinPopup = {
            open: false,
            onClickClose: null,
            onClickSuccess: null,
            isButtonClicked: false,
            groupDetails: ''
        };

        this.state = {
            socket: null,
            isAuthenticated: false,
            hideActivation: true,
            isAdmin: true,
            pageNotFound: false,
            topSearch: "",
            isCookiePolicyAccepted: false,
            isBrowserCompatible: true,
            message: null,
            sweetAlert: this.alertInitSettings,
            location: '',
            history: [],
            isDesktop: false,
            isMyMarketPlace: false,
            isCreateCompanyPopupOpen: false,
            RedirectMarketplaceAfterCreateCompany: false,
            createListing: null,
            isOpenReportModal: false,
            report: null,
            isOpenPostingGuideLinesModal: false,
            showLoading: false,
            creatingCompanyFor: null,
            commonFriends: {
                isOpen: false,
                count: 0,
                friendId: null,
                user: null,
                dispatch: null,
                page: null,
                section: null
            },
            showMobileNav: true,
            blocked: false,
            marketplaceProPlusAdsCache: getRandomOneZero(), // this is added to switch marketplace caches
            joinPopUp: this.defaultJoinPopup,
            leaveGroupPopUp: this.defaultJoinPopup,
            onGroupJoin: '',
            attendeesPopup: false,
            isOpenMessagePopupModel: false,
            userToMessage: null,
            showLoginPopup: false,
            activeLogin: false,
            isNewSignUp: false,
            dataTrackingPage: null,
            dataTrackingSection: null,
            leafwireProPopup: false,
            myFriends: {
                isOpen: false,
                count: 0,
                user: null,
                dispatch: null,
                page: null,
                section: null
            },
        };

        this.showSweetAlert = this._showSweetAlert.bind(this);
        this.performSweetAlertEvent = this._performSweetAlertEvent.bind(this);
        this.updatePredicate = this.updatePredicate.bind(this);
        this.closeCreateCompanyModel = this._closeCreateCompanyModel.bind(this);
        this.openCreateCompanyModel = this._openCreateCompanyModel.bind(this);
        this.openSelectCompanyModel = this._openSelectCompanyModel.bind(this);
        this.closeSelectCompanyModel = this._closeSelectCompanyModel.bind(this);
        this.toggleLoading = this._toggleLoading.bind(this);
        this.switchCompany = this._switchCompany.bind(this);
        this.resetTopNavNotificationCounters = this._resetTopNavNotificationCounters.bind(this);
        this.toggleMobileNav = this._toggleMobileNav.bind(this);
        this.increaseClicks = this._increaseClicks.bind(this);
        this.joinGroup = this._joinGroup.bind(this);
        this.leaveGroup = this._leaveGroup.bind(this);
        this.openMsgPopup = this._openMsgPopup.bind(this);
        this.closeMsgPopup = this._closeMsgPopup.bind(this);
        this.setAttendeesPopup = this._setAttendeesPopup.bind(this);
        this.forceNewsfeedRefresh = this._forceNewsfeedRefresh.bind(this);
        this.showLoginPopup = this._showLoginPopup.bind(this);
        this.closeLoginPopup = this._closeLoginPopup.bind(this);
        this.userAccountDeleted = this._userAccountDeleted.bind(this);
        this.toggleLeafwireProPopup = this._toggleLeafwireProPopup.bind(this);
        this.showConnectionDalyLimitMessage = this._showConnectionDalyLimitMessage.bind(this);
        window.scrollTo(0, 0);
    }

    _isNeedToRedirect = (includes = [], excludes = []) => {
        let check = true;
        includes.forEach(item => {
            if (window.location.pathname.includes(item)) {
                check = false;
            }
        });
        if (check) {

            const {postId} = QueryString.parse(this.props.location.search) || null;

            excludes.forEach(item => {
                if (window.location.pathname === item) {
                    // added to redirect external post links to new newsfeed single post view (after user authenticated)
                    if (!(item === '/' && postId)) {
                        check = false;
                    }
                } else if (window.location.pathname.includes('/groups')) { // added to stop redirecting to groups related routes
                    check = false;
                } else if (window.location.pathname.includes('/landing')) { // added to stop redirecting to custom landing routes
                    check = false;
                }
            });
        }
        return check;
    };

    _isNeedToRedirectPost = () => {
        if (this.props.location) {
            const params = QueryString.parse(this.props.location.search);
            if (this.props.location.pathname === '/' && params && params.postId) {
                localStorage.setItem('postToShow', this.props.location.search);
            }
        }

    };

    createSocketConnection = (url) => {
        const socket = initSocket(this.props.dispatch);
        if (this.state.socket) {
            this.state.socket.disconnect();
        }
        this.setState({socket});
    };

    async componentWillMount() {
        if (!sessionStorage.length) {
            localStorage.setItem('getSessionStorage', 'test');
            localStorage.removeItem('getSessionStorage', 'test');
        }

        if (!localStorage.getItem('userId')) { //guest user socket connection
            this.createSocketConnection(SOCKET_URL);
        }
        const response = await isBrowserNotCompatible(CLIENT_BROWSER);
        if (!response.status) {
            this.setState({isBrowserCompatible: false, message: response.message});
        }

        const params = QueryString.parse(this.props.location ? this.props.location.search : '');
        if (params && params.returnurl) localStorage.setItem('JBHQ_JOB_ID', params.returnurl.split('/')[2]);
    }

    async componentDidMount() {
        this.updatePredicate();
        try {
            if (await authUser()) {
                this.userHasAuthenticated(true);
            } else {
                this.userHasAuthenticated(false);
                if (this._isNeedToRedirect(REDIRECT_BLOCK.INCLUDES, REDIRECT_BLOCK.EXCLUDES)) {
                    localStorage.setItem("goto", (window.location.pathname + window.location.search));
                    if (!this.props.route.isAuthenticated && this.props.route.loading) {
                        this.props.dispatch({type: AuthTypes.CLEAR_ROUTE_ACCESS})
                    }
                }
                this._isNeedToRedirectPost();
            }
        } catch (e) {
            alert(e);
        }

        if (window.innerWidth < 992) {
            const delegate = (selector1, selector2) => (cb) => (e) => (e.target.matches(selector1) || e.target.matches(selector2)) && cb(e);
            const inputDelegate = (delegate('input[type=text]', 'textarea'));
            document.addEventListener('focusin', inputDelegate((el) => this.toggleMobileNav(false)));
            document.addEventListener('focusout', inputDelegate((el) => this.toggleMobileNav(true)));
        }

        window.addEventListener('beforeunload', (e) => {
            if (localStorage.getItem('isVideoUploading')) {
                // Cancel the event
                e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
                // Chrome requires returnValue to be set
                e.returnValue = '';
            }
        });
        window.addEventListener('unload', (e) => {
            if (localStorage.getItem('isVideoUploading')) {
                localStorage.removeItem('isVideoUploading')
            }
        })
        document.addEventListener("connectUserDeleted", this.userAccountDeleted);

        document.addEventListener("maxDailyConnectionLimit", this.showConnectionDalyLimitMessage);

        /*datadog init*/
        /*datadogLogs.init({
            clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
            site: process.env.REACT_APP_DATADOG_SITE,
            forwardErrorsToLogs: true,
            sampleRate: 100,
            env: process.env.REACT_APP_DATADOG_ENVIRONMENT,
        })*/

        if(localStorage.getItem('personalNewsfeedAvailable') === 'false' && localStorage.getItem('userId')){
            this.props.dispatch(AuthActions.checkPersonalNewsfeedStatus())
        } else if(localStorage.getItem('personalNewsfeedAvailable') === 'true' && localStorage.getItem('userId')){
            this.props.dispatch({type: AuthTypes.UPDATE_USER_NEWSFEED_STATUS, payload: true})
        }
    }

    updatePredicate() {
        this.setState({isDesktop: window.innerWidth > 991});
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.location.search === '?action=logout') {
            this.props.history.push('/');
            this.props.dispatch(AuthActions.logout());
            window.location.reload();
        } else if (nextProps.location.search === '?action=post-a-job' && nextProps.auth.action === AuthTypes.FETCHING_ADMIN_COMPANY_LIST_SUCCESS) {
            let companyList = (nextProps.auth && nextProps.auth.myCompanies) ? nextProps.auth.myCompanies : [];
            postAJob(companyList, this.props.history.push, this.openCreateCompanyModel, this.openSelectCompanyModel);
        }

        if (nextProps.auth.action === 'SIGNIN_SUCCESS' || nextProps.auth.action === 'SIGNUP_SUCCESS' || (nextProps.location.pathname !== '/' && nextProps.route.isAuthenticated && !this.props.route.isAuthenticated)) {
            let isNewRegistration = nextProps.auth.action === 'SIGNUP_SUCCESS' ? true : this.state.isNewSignUp;
            if (this.state.socket && localStorage.getItem('userId')) {
                this.state.socket.disconnect()
                this.setState({isNewSignUp: isNewRegistration, socket: null}, () => { // login popup close removed LEAF_4473
                    this.createSocketConnection(SOCKET_URL); // connect to socket with private notifications listener
                });
            } else if (!this.state.socket) {
                this.createSocketConnection(SOCKET_URL); // connect to socket without private notifications listener
            }

            if (nextProps.auth.user.events) {
                let userEvents = [];
                nextProps.auth.user.events.forEach(event => {
                    if (event.status === 1) {
                        userEvents = [...userEvents, event._id];
                    }
                });
                if (userEvents.length > 0 && this.state.socket !== null) {
                    if (this.state.socket) {
                        joinEventRoom(this.state.socket, userEvents);
                    } else {
                        console.error('socket error join event room failed', this.state.socket)
                    }
                } else {
                    setTimeout(() => {
                        if (this.state.socket) {
                            joinEventRoom(this.state.socket, userEvents);
                        } else {
                            console.error('socket error join event room failed', this.state.socket)
                        }
                    }, 1000);
                }
            }
        }

        if (nextProps.auth.action === AuthTypes.SIGNUP_STEP_TWO_SUCCESS || nextProps.auth.action === AuthTypes.SIGNIN_SUCCESS || nextProps.auth.action === AuthTypes.INSERTING_USER_DATA_SUCCESS || nextProps.auth.action === AuthTypes.SIGNUP_AS_PROFESSIONAL) {
            if (nextProps.auth.action === AuthTypes.SIGNIN_SUCCESS && !this.state.isNewSignUp) {
                let shouldOpenRegistrationPopup = false;
                if (
                    !this.props.location.pathname.includes('/landing/')
                    && !this.props.location.pathname.includes('/mjbiz')
                    && nextProps.auth.action === AuthTypes.SIGNIN_SUCCESS
                    && nextProps.auth.user.MEMBER_TYPE === MEMBER_TYPE.INVESTOR
                    && nextProps.auth.user.REGISTRATION_STEP < REGISTRATION_STEP.INVESTOR_STEP_04) {
                    shouldOpenRegistrationPopup = true
                }
                this.setState({showLoginPopup: shouldOpenRegistrationPopup, isNewSignUp: shouldOpenRegistrationPopup});
            } else if ((nextProps.auth.action === AuthTypes.SIGNUP_STEP_TWO_SUCCESS || nextProps.auth.action === AuthTypes.SIGNUP_AS_PROFESSIONAL) && nextProps.auth.user.MEMBER_TYPE === MEMBER_TYPE.PROFESSIONAL) {
                this.setState({showLoginPopup: false, isNewSignUp: false});
            } else if (nextProps.auth.action === AuthTypes.INSERTING_USER_DATA_SUCCESS && nextProps.auth.user.MEMBER_TYPE === MEMBER_TYPE.INVESTOR) {
                this.setState({showLoginPopup: false, isNewSignUp: false});
            }
        }

        if (nextProps.auth.action === 'LOGOUT' && this.state.socket && this.props.auth.action !== 'LOGOUT') {
            window.scrollTo(0, 0);
            this.setState({socket: null}, () => { //remove existing socket with private notifications listener
                this.createSocketConnection(SOCKET_URL); // connect to socket without private notifications listener
            });
        }

        if (this.props.location && this.props.location.search) {
            let windowsLiveCode = this.props.location.search.split("code=") || [];
            windowsLiveCode = windowsLiveCode.length >= 1 ? windowsLiveCode[1] : false;
            localStorage.setItem('wlCode', windowsLiveCode);

        }
        if (nextProps.auth.action === AuthTypes.ACTIVATION_REQUIRED) {
            this.setState({hideActivation: false})
        }
        if (nextProps.auth.action === 0) {
            this.setState({hideActivation: true})
        }
        if (this.state.isAuthenticated && localStorage.getItem("goto") && !this.state.isNewSignUp) {
            let dest = localStorage.getItem("goto");
            localStorage.removeItem("goto");
            this.props.history.push(dest)
        }

        if (this.state.location !== nextProps.location.pathname) {

            // Initialize google analytics page view tracking
            ReactGA.set({page: nextProps.location.pathname}); // Update the user's current page
            ReactGA.pageview(nextProps.location.pathname); // Record a pageview for the given page

            let isMyMarketPlace = HEADER_TYPES.MARKETPLACE.some(url => nextProps.location.pathname.includes(url)) ? HEADER.MARKETPLACE : HEADER.DEFAULT;
            if (isMyMarketPlace === HEADER.DEFAULT) {
                isMyMarketPlace = HEADER_TYPES.MARKETPLACE_EDIT.some(url => nextProps.location.pathname.includes(url)) ? HEADER.MARKETPLACE_EDIT : HEADER.DEFAULT;
            }
            if (isMyMarketPlace === HEADER.DEFAULT) {
                isMyMarketPlace = HEADER_TYPES.MARKETPLACE_PUBLIC.some(url => nextProps.location.pathname.includes(url)) ? HEADER.MARKETPLACE_PUBLIC : HEADER.DEFAULT;
            }

            this.setState({
                location: nextProps.location.pathname,
                sweetAlert: this.alertInitSettings,
                history: [...this.state.history, nextProps.location.pathname].reverse().slice(0, 2).reverse(),
                isMyMarketPlace
            });
        }

        if (nextProps.auth.action === AuthTypes.DELETE_USER_FAIL) {
            setTimeout(() => {
                this.showSweetAlert(
                    'Warning',
                    nextProps.auth.deleteUserMessage.replace(/`/i, "'"),
                    false,
                    true,
                    true,
                    true,
                    true
                );
            }, 10)
        }

        if (nextProps.auth && nextProps.auth.user && nextProps.auth.user.BLOCKED_STATUS && nextProps.auth.user.BLOCKED_STATUS === SYSTEM_USER_BLOCKED_STATUS.BLOCKED && !this.state.blocked) {
            this.setState({blocked: true})
        }

        if ((nextProps.groupsAction === JOIN_GROUP + STATUS.SUCCESS) || (nextProps.groupsAction === JOIN_GROUP + STATUS.FAIL)) {
            let {onGroupJoin} = this.state;
            this.setState({joinPopUp: this.defaultPopup, onGroupJoin: ''}, () => {
                if (onGroupJoin && nextProps.groupsAction === JOIN_GROUP + STATUS.SUCCESS) {
                    this.props.history.push(onGroupJoin);
                }
            });
        }

        if ((nextProps.groupsAction === LEAVE_GROUP + STATUS.SUCCESS) || (nextProps.groupsAction === LEAVE_GROUP + STATUS.FAIL)) {
            this.setState({leaveGroupPopUp: this.defaultJoinPopup, onGroupJoin: ''}, () => {
                if (nextProps.groupsAction === LEAVE_GROUP + STATUS.SUCCESS && nextProps.location.pathname !== '/') {
                    this.props.history.push('/');
                }
            })
        }

    };

    // checkDays = (user) => (Math.floor(Date.now() / (1000 * 60 * 60 * 24) - Date.parse(user.CREATEDAT) / (1000 * 60 * 60 * 24))) < 7

    userHasAuthenticated = authenticated => {
        this.setState({isAuthenticated: authenticated});
        if (localStorage.getItem("token")) {
            // this.props.dispatch(getPendingConnectionCount());
            this.props.dispatch(AuthActions.getNotifiedConvIdSet());
            this.props.dispatch(AuthActions.getInitChatIdSet());
            this.props.dispatch(AuthActions.getUnseenTopNavConvIdSet());
            this.props.dispatch(AuthActions.getUnseenConnectionRequestersIdSet());
        }
    }

    adminHasAuthenticated = status => {
        this.setState({isAdmin: status, isAuthenticated: status});
    }

    setLoginData = credentials => {
        this.props.dispatch(AuthActions.signIn(credentials));
    };

    pageNotFound = status => {
        this.setState({pageNotFound: status});
    };

    onSearch = (event) => {
        if (event.target && event.which === 13 && event.target.value.trim()) {
            // let searchText = encodeUrlParams({"searchText" : this.state.topSearch});
            let searchHash = ADVANCED_SEARCH_DEFAULT.ALL;
            searchHash.searchTerm = this.state.topSearch;
            let searchText = encodeUrlParams(searchHash);
            this.props.history.push(`/advanced-search/all-users/q=${searchText}`);
        }

        if (event.target && event.target.value.trim() && event.which !== 13) {
            this.setState({
                topSearch: event.target.value
            });
            this.props.dispatch(SearchActions.topSearch(event));
            this.props.dispatch(SearchActions.topSearchCount(event));
        }
        if (event.target && !event.target.value.trim()) {
            this.props.dispatch(SearchActions.clearTopSearchText())
        }
    };

    routeHandler = () => {
        if (this.props.location.pathname.includes('/admin/') || this.props.location.pathname.includes('/faq')) {
            return false;
        } else {
            return true;
        }
    };

    agreeCookiePolicy = () => {
        let expiration_date = new Date();
        let cookie_string = '';
        expiration_date.setFullYear(expiration_date.getFullYear() + 1);
        cookie_string = "cookiePolicy=true; path=/; expires=" + expiration_date.toUTCString();
        document.cookie = cookie_string;
        this.setState({isCookiePolicyAccepted: true});
    };

    getCookie = (cname) => {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    };

    _showSweetAlert = (title, text, showCancelButton, onConfirm, onCancel, onEscapeKey = null, onOutsideClick = null, extraSettings = {}) => {
        this.setState({
            sweetAlert: {
                show: true,
                title,
                text,
                showCancelButton,
                onConfirm,
                onCancel,
                onEscapeKey,
                onOutsideClick, ...extraSettings
            }
        })
    };

    _performSweetAlertEvent = (eventToPerform) => { // onConfirm, onCancel, onEscapeKey, onOutsideClick
        let functionToPerform = null;
        switch (eventToPerform) {
            case 'onConfirm':
                functionToPerform = this.state.sweetAlert.onConfirm;
                break;
            case 'onCancel':
                functionToPerform = this.state.sweetAlert.onCancel;
                break;
            case 'onEscapeKey':
                functionToPerform = this.state.sweetAlert.onEscapeKey;
                break;
            case 'onOutsideClick':
                functionToPerform = this.state.sweetAlert.onOutsideClick;
                break;
            default:
                break;
        }

        if (functionToPerform && {}.toString.call(functionToPerform) === '[object Function]') {
            this.setState({sweetAlert: this.alertInitSettings});
            functionToPerform();
        } else if (functionToPerform) {
            this.setState({sweetAlert: this.alertInitSettings});
        }
    };

    toggleShowFooter = () => {
        this.setState((curSt) => {
            return {
                showFooter: !curSt.showFooter
            }
        })
    };

    _closeCreateCompanyModel = () => {
        this.setState({
            isCreateCompanyPopupOpen: false,
            RedirectMarketplaceAfterCreateCompany: false
        })
    };

    _openCreateCompanyModel = (isToCreateProduct = true, type = CREATE_COMPANY_POPUP.TO_CREATE_PRODUCT) => {
        this.setState({
            isCreateCompanyPopupOpen: true,
            RedirectMarketplaceAfterCreateCompany: isToCreateProduct,
            creatingCompanyFor: type
        })
    };

    _openSelectCompanyModel = (type, page = null, section = null) => {
        this.setState({
            createListing: type,
            dataTrackingPage: page,
            dataTrackingSection: section
        })
    };

    _closeSelectCompanyModel = () => {
        this.setState({
            createListing: null,
            dataTrackingPage: null,
            dataTrackingSection: null
        })
    };

    openCommonFriendsModal = (mutaulFriendsCount, friendId, user = null, dispatch = null, page = null, section = null) => {
        const commonFriends = {
            ...this.state.commonFriends,
            isOpen: true,
            count: mutaulFriendsCount,
            friendId: friendId,
            user: user,
            dispatch: dispatch,
            page: page,
            section: section
        };
        this.setState({commonFriends});
    }

    openMyFriendsModal = (myFriendsCount, user = null, dispatch = null, page = null, section = null,socket = null) => {
        const myFriends = {
            ...this.state.commonFriends,
            isOpen: true,
            count: myFriendsCount,
            user: user,
            dispatch: dispatch,
            page: page,
            section: section,
            socket: socket
        };
        this.setState({myFriends});
    }

    openReportModal = (report) => {
        this.setState({
            isOpenReportModal: !this.state.isOpenReportModal,
            report
        })
    };

    openPostingGuideLinesModal = () => {
        this.setState({
            isOpenPostingGuideLinesModal: !this.state.isOpenPostingGuideLinesModal,
        })
    };

    _toggleLoading = (status) => {
        this.setState({
            showLoading: status
        })
    };

    closeCommonFriendsModal = () => {
        const commonFriends = {
            ...this.state.commonFriends,
            isOpen: false
        };
        this.setState({commonFriends});
    }

    closeMyFriendsModal = () => {
        const myFriends = {
            ...this.state.myFriends,
            isOpen: false
        };
        this.setState({myFriends});
    }

    _switchCompany = () => {
        this.props.dispatch(switchCompany())
    };

    _resetTopNavNotificationCounters = (moduleNo) => {
        this.props.dispatch(AuthActions.resetTopNavCount(this.state.socket, moduleNo));
    };

    _toggleMobileNav = (status) => {
        this.setState({showMobileNav: status})
    };

    _increaseClicks = (item, ELEMENT_ID) => {
        const clickData = {
            ELEMENT_ID,
            MODULE_ID: VIEW_MODULES.GROUPS,
            ITEM_ID: item._id,
            TYPE: VIEW_COUNT_TYPE.VIEW_BY_CLICK,
            LOCATION: VIEW_COUNT_LOCATION.WIDGET,
            VIEWED_DATE: Date.now()
        };
        increaseView(clickData);
    };

    _joinGroup = (group, index, postId = '', isSinglePost = null) => {
        this.setState({
            joinPopUp: {
                open: true,
                onClickClose: async (closeGroupsPopup = false) => {
                    // handle no tracking
                    this.increaseClicks(group, VIEW_ELEMENT_TYPE.NO);
                    this.setState({joinPopUp: this.defaultPopup});
                    if (closeGroupsPopup && closeGroupsPopup.isUserName) {
                        this.props.dispatch(closeMobileGroupsPopup());
                    }
                },
                onClickSuccess: async () => {
                    // handle yes tracking
                    this.increaseClicks(group, VIEW_ELEMENT_TYPE.YES);

                    let successUrl = '/';
                    if (isSinglePost) { // handle redirecting to group post comment when user is not joined to group.
                        successUrl = (isSinglePost.tagCommentId)
                            ? `/groups/${group._id}/${postId}?tagCommentId=${isSinglePost.tagCommentId}&tagId=${isSinglePost.tagId}`
                            : (isSinglePost.tagId)
                                ? `/groups/${group._id}/${postId}?tagId=${isSinglePost.tagId}`
                                : (isSinglePost.commentId)
                                    ? `/groups/${group._id}/${postId}?commentId=${isSinglePost.commentId}`
                                    : `/groups/${group._id}/${postId}`;
                    } else if (postId) {
                        successUrl = `/groups/${group._id}/${postId}`
                    } else {
                        successUrl = `/groups/${group._id}`
                    }

                    this.setState({
                        joinPopUp: {...this.state.joinPopUp, isButtonClicked: true},
                        onGroupJoin: successUrl
                    });
                    this.props.dispatch(joinGroup(group._id, index));
                },
                groupDetails: group
            }
        })
    };

    _leaveGroup = (group) => {
        this.setState({
            leaveGroupPopUp: {
                open: true,
                onClickClose: () => {
                    this.setState({leaveGroupPopUp: this.defaultJoinPopup})
                },
                onClickSuccess: () => {
                    this.setState({leaveGroupPopUp: {...this.state.leaveGroupPopUp, isButtonClicked: true}});
                    this.props.dispatch(leaveGroup(group._id));
                },
                groupDetails: group
            }
        })
    };

    _openMsgPopup = user => {
        this.setState({
            isOpenMessagePopupModel: true,
            userToMessage: user
        })
    };

    _closeMsgPopup = () => {
        this.setState({
            isOpenMessagePopupModel: false,
            userToMessage: null
        })
    };

    _setAttendeesPopup = (val) => {
        this.setState({
            attendeesPopup: val
        })
    };

    _forceNewsfeedRefresh = () => {
        this.props.dispatch(refreshNewsFeed())
    };

    _showLoginPopup = (action = null, data = null, activeLogin = false) => {
        if (action) {
            this.props.dispatch(AuthActions.setPublicAction(action, data));
        }
        this.setState({showLoginPopup: true, activeLogin: activeLogin});
    };

    _closeLoginPopup = (keepActionData = false) => {
        if (!keepActionData) {
            this.props.dispatch(AuthActions.removePublicAction());
        }
        this.setState({showLoginPopup: false, activeLogin: false})
    };

    _userAccountDeleted = (isMyRequest = false) => {
        this.showSweetAlert(
            '',
            'The individual who sent you that Connection Request is no longer a member of Leafwire. We apologize for the inconvenience.',
            true,
            () => {
                this.props.history.push(`/filter-connections/q=${ENCODED_DEFAULT_FILTER[CONNECTIONS_SECTION.SUGGESTED]}`)
            },
            () => {
            },
            () => {
            },
            () => {
            },
            {
                confirmButtonText: 'Find More Connections',
            }
        )
    }

    _toggleLeafwireProPopup = (status = false) => {
        this.setState({leafwireProPopup: status})
    }

    _showConnectionDalyLimitMessage = () => {
        this.showSweetAlert(
            '',
            'You\'ve hit your limit for sending out connection requests for today. We are reviewing the account and will let you know when you are able to send more connection requests.',
            true,
            () => console.info('Maximum Daily Connection requests limit'),
            () => console.info('Maximum Daily Connection requests limit'),
            () => console.info('Maximum Daily Connection requests limit'),
            () => console.info('Maximum Daily Connection requests limit')
        )
    }

    render() {

        const {
            isBrowserCompatible,
            message,
            socket,
            createListing,
            isOpenReportModal,
            report,
            innerHeight,
            isOpenPostingGuideLinesModal,
            commonFriends,
            showMobileNav,
            blocked,
            joinPopUp,
            leaveGroupPopUp,
            isOpenMessagePopupModel,
            userToMessage,
            myFriends
        } = this.state;
        const cookiePolicyAccepted = this.getCookie('cookiePolicy');
        const childProps = {
            isAuthenticated: this.state.isAuthenticated,
            userHasAuthenticated: this.userHasAuthenticated,
            isAdmin: this.state.isAdmin,
            adminHasAuthenticated: this.adminHasAuthenticated,
            pageNotFound: this.pageNotFound,
            showSweetAlert: this.showSweetAlert,
            socket,
            customHistory: this.state.history,
            isDesktop: this.state.isDesktop,
            isMyMarketPlace: this.state.isMyMarketPlace,
            toggleShowFooter: this.toggleShowFooter,
            openCreateCompanyModel: this.openCreateCompanyModel,
            openSelectCompanyModel: this.openSelectCompanyModel,
            openReportModal: this.openReportModal,
            openCommonFriendsModal: this.openCommonFriendsModal,
            openPostingGuideLinesModal: this.openPostingGuideLinesModal,
            toggleLoading: this.toggleLoading,
            innerHeight,
            resetTopNavNotificationCounters: this.resetTopNavNotificationCounters,
            showMobileNav,
            toggleMobileNav: this.toggleMobileNav,
            blocked: blocked,
            marketplaceProPlusAdsCache: this.state.marketplaceProPlusAdsCache,
            joinGroup: this.joinGroup,
            leaveGroup: this.leaveGroup,
            showLoginPopup: this.showLoginPopup,
            closeLoginPopup: this.closeLoginPopup,
            isNewSignUp: this.state.isNewSignUp,
            toggleLeafwireProPopup: this.toggleLeafwireProPopup,
            openMyFriendsModal: this.openMyFriendsModal
        };

        const hideMessageBox = (this.props.location.pathname.includes('/message/') || this.props.location.pathname.includes('/admin'));

        return (
            <div className="start_point">
                {this.state.showLoginPopup && <Suspense fallback={<div>Loading...</div>}><Register
                    {...this.props}
                    activeLogin={this.state.activeLogin}
                    close={this.closeLoginPopup}
                    isNewSignUp={this.state.isNewSignUp}
                    isDesktop={this.state.isDesktop}
                /></Suspense>
                }
                {isOpenReportModal && <Suspense fallback={<div>Loading...</div>}><ReportModal
                    close={this.openReportModal}
                    report={report}
                    dispatch={this.props.dispatch}
                /></Suspense>}
                {commonFriends.isOpen && <Suspense fallback={<div>Loading...</div>}><CommonFriends
                    isOpen={commonFriends.isOpen}
                    count={commonFriends.count}
                    close={this.closeCommonFriendsModal.bind(this)}
                    friendId={commonFriends.friendId}
                    redirect={this.props.history.push}
                    user={commonFriends.user}
                    dispatch={commonFriends.dispatch}
                    page={commonFriends.page}
                    section={commonFriends.section}
                /></Suspense>}
                {myFriends.isOpen && <Suspense fallback={<div>Loading...</div>}><MyFriends
                    isOpen={myFriends.isOpen}
                    count={myFriends.count}
                    close={this.closeMyFriendsModal.bind(this)}
                    redirect={this.props.history.push}
                    user={myFriends.user}
                    dispatch={this.props.dispatch}
                    page={myFriends.page}
                    section={myFriends.section}
                    socket={socket}
                /></Suspense>}

                {isOpenPostingGuideLinesModal && <Suspense fallback={<div>Loading...</div>}><PostingGuideLines
                    close={this.openPostingGuideLinesModal}
                /></Suspense>}
                {createListing &&
                <Suspense fallback={<div>Loading...</div>}><AdminCompanies
                    type={createListing}
                    companies={(this.props.auth && this.props.auth.myCompanies) ? this.props.auth.myCompanies : []}
                    close={this.closeSelectCompanyModel}
                    switchCompany={this.switchCompany}
                    page={this.state.dataTrackingPage}
                    section={this.state.dataTrackingSection}
                    dispatch={this.props.dispatch}
                /></Suspense>}
                {this.state.isAuthenticated && !hideMessageBox && this.state.isDesktop &&
                <Suspense fallback={<div>Loading...</div>}>
                    <ChatPopup 
                        socket={socket} 
                        history={this.props.history} 
                        location={this.props.location} 
                        dispatch={this.props.dispatch}
                    />
                </Suspense>}
                <ToastAlert
                    history={this.props.history}
                    notifications={this.props.alert.notifications}
                    close={(notificationId) => this.props.dispatch(pullNotifications(notificationId))}
                    markAsRead={(notificationData) => this.props.dispatch(markAsRead(notificationData))}
                    viewMessage={(msgData) => {
                        this.props.dispatch(openMessage(msgData))
                    }}
                    socket={socket}
                    openMsgPopup={this.openMsgPopup}
                    openAttendeesPopup={(eventId) => this.setAttendeesPopup(eventId)}
                    isLoggedIn={this.state.isAuthenticated}
                    dispatch={this.props.dispatch}
                />
                {
                    leaveGroupPopUp && leaveGroupPopUp.open &&
                    <LeaveGroupModal
                        groupDetails={leaveGroupPopUp.groupDetails}
                        onClickClose={leaveGroupPopUp.onClickClose}
                        onClickSuccess={leaveGroupPopUp.onClickSuccess}
                        isButtonClicked={leaveGroupPopUp.isButtonClicked}
                    />
                }

                {
                    joinPopUp && joinPopUp.open &&
                    <JoinGroupModal
                        groupDetails={joinPopUp.groupDetails}
                        onClickClose={joinPopUp.onClickClose}
                        onClickSuccess={joinPopUp.onClickSuccess}
                        isButtonClicked={joinPopUp.isButtonClicked}
                    />
                }
                {this.state.attendeesPopup !== false && <Suspense fallback={<div>Loading...</div>}><AttendeesPopup
                    isOpen={this.state.attendeesPopup !== false}
                    close={() => this.setAttendeesPopup(false)}
                    eventId={this.state.attendeesPopup}
                    openMsgPopup={(user) => this.openMsgPopup(user)}
                    socket={socket}
                /></Suspense>}
                {isOpenMessagePopupModel && <Suspense fallback={<div>Loading...</div>}><MessengerPopUp
                    customClass={"msgPopUp p-0"}
                    isOpenModel={isOpenMessagePopupModel}
                    modelClose={this.closeMsgPopup}
                    userDetails={userToMessage}
                    companyId={null}
                    title={userToMessage ? userToMessage.FIRST_NAME + ' ' + userToMessage.LAST_NAME : null}
                    socket={socket}
                /></Suspense>}
                {
                    this.props.pro.isOpenInviteModal ?
                    <Suspense fallback={<div>Loading...</div>}><ProInvitesPopup showSweetAlert={this.showSweetAlert} socket={socket}/></Suspense> : null
                }
                <PopupAlert {...this.props.alert} dispatch={this.props.dispatch}/>
                <NotifyAlert {...this.props.alert} dispatch={this.props.dispatch}/>
                {
                    this.state.isCreateCompanyPopupOpen &&
                    <Suspense fallback={<div>Loading...</div>}><CreateCompanyModel
                        modelClose={this.closeCreateCompanyModel}
                        isForCreateProduct={this.state.RedirectMarketplaceAfterCreateCompany}
                        creatingCompanyFor={this.state.creatingCompanyFor}
                    /></Suspense>
                }
                <SweetAlert
                    show={this.state.sweetAlert.show}
                    title={this.state.sweetAlert.title}
                    text={this.state.sweetAlert.text}
                    showCancelButton={this.state.sweetAlert.showCancelButton}
                    onConfirm={() => this.performSweetAlertEvent('onConfirm')}
                    onCancel={() => this.performSweetAlertEvent('onCancel')}
                    onEscapeKey={() => this.performSweetAlertEvent('onEscapeKey')}
                    onOutsideClick={() => this.performSweetAlertEvent('onOutsideClick')}
                    confirmButtonText={this.state.sweetAlert.confirmButtonText || 'Ok'}
                    cancelButtonText={this.state.sweetAlert.cancelButtonText || 'Cancel'}
                />
                {this.state.leafwireProPopup && <Suspense fallback={<div>Loading...</div>}><LeafwireGetProPopup
                    onClickClose={() => this.setState({ leafwireProPopup: false })}
                /></Suspense>}
                <div className="app_assist">
                    <TopNavigation {...childProps}
                                   getLoginData={this.setLoginData}
                                   {...this.props}
                                   onSearch={this.onSearch}
                                   resetTopNavNotificationCounters={this.resetTopNavNotificationCounters}
                                   showMobileNav={showMobileNav}
                                   forceNewsfeedRefresh={this.forceNewsfeedRefresh}
                                   showLoginPopup={this.showLoginPopup}
                    />
                    <NonFadeOutAlert
                        showBrowserCompatibilityAlert={!isBrowserCompatible}
                        emulateSession={localStorage.getItem('previousSession')}
                        message={message}
                        type={4}
                        auth={this.props.auth}
                        logoutFromEmulateSession={() => this.props.dispatch(endEmulateSession(JSON.parse(localStorage.getItem('previousSession'))))}
                    />
                    {!this.state.pageNotFound && !this.state.showLoading && <Routes childProps={childProps}/>}
                    {this.state.pageNotFound && <NotFound {...this.props} childProps={childProps}/>}
                    {!this.state.pageNotFound && this.state.showLoading && <Loading/>}
                    {!cookiePolicyAccepted && !this.props.location.pathname.includes('/video-reference') && (!cookiePolicyAccepted || !this.state.isCookiePolicyAccepted) &&
                    <CookiePolicy confirmCookiePolicy={this.agreeCookiePolicy}/>}
                    {((!this.state.isAuthenticated && this.props.location.pathname !== '/') || (this.state.isAuthenticated && (this.props.location.pathname !== '/' && !this.props.location.pathname.includes('/groups') && !this.props.location.pathname.includes('/trn/') && !this.props.location.pathname.includes('/myfeed')))) &&
                    <Suspense fallback={<div>Loading...</div>}><Footer {...childProps} {...this.props} /></Suspense>}
                </div>

            </div>
        );

    }
}

const connectedContainer = connect(state => {
    return {
        auth: state.auth,
        alert: state.alert,
        search: state.search,
        route: state.route,
        unSeenLatestConversation: state.message.latestUnseenConversation,
        pendingConnections: state.connection.pendingConnectionCount,
        groupsAction: state.groups.action,
        groupCount: state.groups.groups.length,
        publicPendingAction: state.auth.publicPendingAction,
        pro: state.pro
    };

})(App);

export default withRouter(connectedContainer);