import React, { Component } from "react";
import { Link } from "react-router-dom";
import CompanyProfileImg from "../../assets/img/companyAvatar.jpg";
import { getPreview } from "../../libs/textFormat";
import { isURL } from "../../libs/validation";
import { DOMAINS } from '../../types/common';

class AdminCompanyList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            seeMore: false
        }
    }

    getCompanyList(companyList) {
        if (!this.state.seeMore && this.props.companies.length > 3) {
            companyList = companyList.slice(0, 3)
            return companyList
        }
        return companyList
    }

    render() {

        let companies = this.props.companies.map(item => {
            return (
                <Link to={`/${this.props.publicMarketplace ? (this.props.create ? 'marketplace/product-create/' : 'marketplace/company/') : 'profile-edit/company/'}${item._id}`} className="dropdown-item company-item close-on-click" title={item.COMPANY_NAME} key={item._id} onClick={() => {
                    if (localStorage.getItem('selectedCategories')) {
                        localStorage.removeItem('selectedCategories');
                    }
                    if (this.props.toggleActionDropDown) {
                        this.props.toggleActionDropDown(0);
                        this.props.switchCompany();
                    }
                    this.setState({ seeMore: false });
                    if (this.props.hideNavbar) { this.props.hideNavbar() }
                }}>
                    <span className="sm_cp_img"><img src={isURL(item.LOGO, { protocols: ['https'] }, DOMAINS.S3) ? item.LOGO : CompanyProfileImg} alt="Company Profile" className="img-fluid" /></span>
                    {getPreview(item.COMPANY_NAME, 15)}
                </Link>
            )
        });

        return (
            <React.Fragment>
                <div id="admincompanylist" className="AdminDropDown">
                    {this.getCompanyList(companies)}
                </div>
                {this.props.companies.length > 3 &&
                    <span id="company-see-more" className="dropdown-item link" style={{cursor: 'pointer'}} onClick={() => {
                        if (this.props.toggleActionDropDown) {
                            this.props.toggleActionDropDown(1);
                        }
                        this.setState({ seeMore: !this.state.seeMore })
                    }}
                    >{this.state.seeMore ? 'See Less...' : 'See More....'}</span>
                }
            </React.Fragment>
        )
    }
}

export default AdminCompanyList;