import React from "react";
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from "react-router-dom";
import { Loading } from '../../components/common';
import { fetchCustomLandingPage } from './../../actions/route';
import { LINKED_PAGES_FOR_CUSTOM_LANGING_PAGES, MEMBER_TYPE } from './../../types/common';
import { initlogin } from './../../actions/auth';

import {
    FETCH_CUSTOM_LANDING_PAGE_SUCCESS,
    FETCH_CUSTOM_LANDING_PAGE_FAIL
} from './../../types/landingPages';

class LandingPageRoute extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            customPageDetails: null
        }
    }

    componentDidMount() {
        if (!this.props.user._id && localStorage.getItem('token')) {
            this.props.dispatch(initlogin(localStorage.getItem('token')), false, null);
        }
        this.props.dispatch(fetchCustomLandingPage(this.props.computedMatch.params.landingPageUrl));
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.action === FETCH_CUSTOM_LANDING_PAGE_SUCCESS) {
            return { loading: false, customPageDetails: nextProps.customLandingPage }
        }
        if (nextProps.action === FETCH_CUSTOM_LANDING_PAGE_FAIL) {
            return { loading: false }
        }
        return null;
    }

    getRedirectLink = (redirectPage) => {
        switch (redirectPage) {
            case LINKED_PAGES_FOR_CUSTOM_LANGING_PAGES.NEWSFEED:
                return '/';
            case LINKED_PAGES_FOR_CUSTOM_LANGING_PAGES.GROUP_FEED:
                return '/';
            case LINKED_PAGES_FOR_CUSTOM_LANGING_PAGES.EVENTS:
                return '/events/all-events';
            case LINKED_PAGES_FOR_CUSTOM_LANGING_PAGES.JOBS:
                return 'https://jobs.leafwire.com/search';
            case LINKED_PAGES_FOR_CUSTOM_LANGING_PAGES.MARKETPLACE:
                return '/marketplace/public';
            default:
                return '/'
        };
    };

    componentDidUpdate() {
        /*if(this.props.props.isAuthenticated && !this.props.props.isNewSignUp && this.state.customPageDetails){
            localStorage.setItem('goto', this.getRedirectLink(this.state.customPageDetails.LINKED_PAGE))
        }*/

        if(this.props.props.isAuthenticated && this.props.user._id && this.props.user.MEMBER_TYPE !== MEMBER_TYPE.INVESTOR && this.state.customPageDetails){
            localStorage.setItem('goto', this.getRedirectLink(this.state.customPageDetails.LINKED_PAGE))
            console.info('set redirect', this.getRedirectLink(this.state.customPageDetails.LINKED_PAGE))
        }

    }

    render() {
        const { component: Component, user, ...rest } = this.props;
        let totalSteps = 0;
        (user.MEMBER_TYPE === MEMBER_TYPE.INVESTOR) ? totalSteps = 4 : totalSteps = 3;
        if (this.state.loading) {
            return <Loading />;
        } else {
            return (
                <Route {...rest} render={props => (
                    <div>
                        {/*{
                            // investor type select moved to register popup. no need to redirect this route
                            (this.props.props.isAuthenticated && totalSteps !== 0 && user._id && user.REGISTRATION_STEP < totalSteps) && <Redirect to="/registration"/>
                        }*/}
                        {
                            (this.props.props.isAuthenticated && !this.props.props.isNewSignUp && this.state.customPageDetails && user.REGISTRATION_STEP === totalSteps) && <Redirect to={this.getRedirectLink(this.state.customPageDetails.LINKED_PAGE)} />
                        }

                        {
                            !this.state.customPageDetails && <Redirect to={`/`} />
                        }
                        <Component {...this.props} />
                    </div>
                )}
                />
            )
        }
    }

};

export default withRouter(connect(state => {
    return {
        state,
        action: state.landingPages.action,
        landingPageIsFetching: state.landingPages.fetching,
        customLandingPage: state.landingPages.customLandingPage,
        user: state.auth.user
    };

})(LandingPageRoute));