import React, { Component } from "react";
import "./index.css";
import { isURL } from './../../../../libs/validation';
import { imageExists } from './../../../../libs/image';
import { ROUTES } from '../../../../config';

import defaultAvatorCompany from "./../../../../assets/img/default-corporate-image.jpg";
import defaultFundraisersCompany from "./../../../../assets/img/fundraisers-default.jpg";
import {get} from "../../../../libs/apiConnector";


class CompanyImageBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            avatar: this.props.slider ? defaultFundraisersCompany : defaultAvatorCompany,
            imageName: this.props.imgName
        }
    }

    componentWillMount() {
        if (this.props.imgName !== '' && !isURL(String.toString(this.props.imgName))) {
            this._getImageUrl(this.props.imgName);
        }
    }

    _getImageUrl(imageName) {
        get(`${ROUTES.IMAGE_PATH}/${'ex-mediam-' + imageName}/1`)
            .then(response => {
                if (response.status === 200) {
                    if (response.data) {
                        imageExists(response.data, (exists) => {
                            if (exists) {
                                this.setState({avatar: response.data})
                            } else {
                                this.setState({avatar: this.props.slider ? defaultFundraisersCompany : defaultAvatorCompany})
                            }
                        });
                    }
                } else {
                    this.setState({
                        avatar: this.props.slider ? defaultFundraisersCompany : defaultAvatorCompany
                    });
                }
            })
    }

    render() {
        return (
            <div className="fundrasersCompnayLogo d-flex align-items-center justify-content-center">
                <img src={this.state.avatar} className="img-fluid" alt="Welnvest" />
            </div>
        );
    }

}

export { CompanyImageBox };