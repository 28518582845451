const API = {
    PROTOCOL: process.env.REACT_APP_PROTOCOL,
    HOST: process.env.REACT_APP_HOST,
    PORT: process.env.REACT_APP_PORT,
    VERSION: process.env.REACT_APP_VERSION
};

//const BASE_URL = API.PROTOCOL + '://' + API.HOST + ':' + API.PORT + '/api/' + API.VERSION;  //Development
const BASE_URL = API.PROTOCOL + '://' + API.HOST + '/api/' + API.VERSION; //Production
const BASE_URL_2 = API.PROTOCOL + '://' + API.HOST + '/api/v2/newsfeed'; //Production

const SOCKET_URL = API.PROTOCOL + '://' + API.HOST;

const PATHS = {
    COMPANY_USER: '/company-user',
    COMPANY: '/company',
    INVESTOR: '/investor-user',
    NETWORKER: '',
    SYSTEM: '/system-user',
    USER_AUTH: '/users/auth',
    COMMON: '/common',
    POST: '/post',
    MESSAGE: '/message',
    CONTACTS: '/contacts',
    PAYMENTS: '/payment',
    DATA_ROOM: '/data-room',
    MARKET_PLACE: '/market-place',
    FEED_TEXT_LINKS: '/feed-text-link',
    JOB_SEEKER: '/job-seekers',
    EMPLOYER: '/employers',
    TRENDING_TOPICS: '/trending-topic',
    INDUSTRY_EVENTS: '/industry-event',
    ADMIN_REPORTS: '/report',
    CANDIDATE_SURVEY: '/survey',
    GROUPS: '/groups',
    JOBS: '/job',
    USERS: '/users',
    PRESS: '/press',
    FAQ: '/faqs',
    EVENTS: '/event',
    LANDING_PAGES: '/landingPages',
    REPORTS: '/reports',
    TRAINING: '/training',
    TRACKING: '/tracking',
    AUTO_CONNECTION: '/auto-connection-manager',
    TESTIMONIALS: '/testimonial',
    BANNER_AD: '/newsfeed-banner',
    JOB_TITLES: '/job-titles',
    JOB_TITLE_TAGS: '/job-title-tags',
    JOB_TITLES_ADMIN: '/job-titles-admin',
    WARNING_DISCLAIMER: '/warning-disclaimer',
    PRO: '/pro',
    VANITY: '/vanity-url',
};

module.exports = {
    SOCKET_URL,
    YAHOO: {
        Client_ID: 'dj0yJmk9N1Bod283b01weFgzJmQ9WVdrOU5HNXVVRTFzTnpZbWNHbzlNQS0tJnM9Y29uc3VtZXJzZWNyZXQmeD02Mw--',
        CLIENT_SECRET: 'faf4c2c76ca52d3ac00a2dea17795bcc04615ca6',
        AUTHENICATION: 'ZGoweUptazlOMUJvZDI4M2IwMXdlRmd6Sm1ROVdWZHJPVTVITlhWVlJURnpUbnBaYldOSGJ6bE5RUzB0Sm5NOVkyOXVjM1Z0WlhKelpXTnlaWFFtZUQwMk13LS06ZmFmNGMyYzc2Y2E1MmQzYWMwMGEyZGVhMTc3OTViY2MwNDYxNWNhNg==',
        CALLBACK_URL: 'http://leafwire.com/'
    },
    POST_SHARE_HOST: process.env.REACT_APP_POST_SHARE_HOST,
    GOOGLE: {
        CLIENT_ID: '1618350696-887v1v29vcger5561fpfpfocjitbf95o.apps.googleusercontent.com',
        API_KEY: 'rBZfqG9yANin-3tRulxQZMfB',
        SCOPES: 'https://www.google.com/m8/feeds'
    },
    WINDOWS_LIVE: {
        APP_ID: '24052d4c-1d6e-4e46-afdc-d28b61ee45e0',
        APP_PASSWORD: 'dheoMJNCL9249|++iupVY1}',
        APP_SCOPES: 'Contacts.Read',
        REDIRECT_URI: 'https://api.leafwire.com/authorize/'
    },
    TOOLS: {
        ADD_ADMIN: 1,
        EVENT: 2,
        POST_A_JOB: 3,
        MARKETPLACE: 4,
        LEAD_GENERATION: 5,
        DATA_ROOM: 6,
        BOARD_ROOM: 7,
        PAYMENTS: 8,
        ADVERTISE: 9
    },
    RECAPTCHA: {
        SITE_KEY: '6Lfxj4gUAAAAAFRFdHEU5L1qxKS5syNSWd8mdRKp',
        STATUS: false
    },

    THIRD_PARTY_URL: {
        GOOGLE_DOCS: 'http://docs.google.com/gview?url='
    },

    ANALYTICS: {
        EVENTS_TRACK: process.env.REACT_APP_EVENT_TRACKING_HOST + '/v1/events',
        X_API_KEY: process.env.REACT_APP_EVENT_TRACKING_XAPIKEY,
        TIMEOUT: process.env.REACT_APP_EVENT_TRACKING_SESSION_TIMEOUT,
    },

    ROUTES: {
        AUTHENICATION: BASE_URL + '/authentication',
        PROFILE: BASE_URL + '/profile',
        FUNDRISER: BASE_URL + '/fundrising',
        INVESTOR: BASE_URL + '/investor',
        NETWORKER: BASE_URL + '/networker',

        // AUTH

        SIGNUP: BASE_URL + PATHS.USER_AUTH + '/signup',
        SIGNUP_STEP_2: BASE_URL + PATHS.USER_AUTH + '/signupUserStepTwo',
        GET_JOB_TITLES: BASE_URL + PATHS.USERS + PATHS.JOB_TITLES,
        SIGNUP_AS_PROFESSIONAL: BASE_URL + PATHS.USER_AUTH + '/signupAsProfessional',
        SIGNIN: BASE_URL + PATHS.USER_AUTH + '/signin',
        REFRESH_TOKEN: BASE_URL + PATHS.USER_AUTH + '/refreshToken',
        LOGOUT: BASE_URL + PATHS.USER_AUTH + '/logout',
        USERDETAILS: BASE_URL + PATHS.SYSTEM + '/userDetails',
        ACTIVATION: BASE_URL + PATHS.SYSTEM + '/activate',
        ACTIVATION_EMP: BASE_URL + PATHS.SYSTEM + '/activateCompanyEmpUser',
        RESEND_EMAIL: BASE_URL + PATHS.SYSTEM + '/resendActivationEmail',
        FORGOT_PASSWORD: BASE_URL + PATHS.USER_AUTH + '/forgetPassword',
        CHECK_PASSWORD_TOKEN: BASE_URL + PATHS.USER_AUTH + '/validateForgetPasswordToken/',
        RESET_PASSWORD: BASE_URL + PATHS.USER_AUTH + '/resetPassword',
        SET_EMP_PASSWORD: BASE_URL + PATHS.USER_AUTH + '/set/empPassword',
        CHECK_EMAIL_EXISTS: BASE_URL + PATHS.USER_AUTH + '/checkExistingEmail',
        BLOCKUSER : BASE_URL + PATHS.USER_AUTH + '/blockUser',


        GET_UNREAD_MSG_COUNT: BASE_URL + PATHS.SYSTEM + '/getUnReadMessageCountByUserId',
        GET_CONNECTIONS_COUNT: BASE_URL + PATHS.SYSTEM + '/getConnectionsCount',
        GET_NONE_UPDATED_COMPANIES: BASE_URL + PATHS.SYSTEM + '/getAdminCompanyList',
        GET_ADMIN_COMPANY: BASE_URL + PATHS.SYSTEM + '/getMyCompanyList',
        LOGIN_SETTINGS: BASE_URL + PATHS.SYSTEM + '/updateLoginDetails',
        IS_LOGGED_IN_USER_COMPANY_ADMIN: BASE_URL + PATHS.SYSTEM + '/isLoggedInUserCompanyAdmin',
        CLOSE_CANDIDATE_SURVEY_LAST_SEEN: BASE_URL + PATHS.SYSTEM + '/closeCandidateSurvey',
        GET_EXPERT_CONTRIBUTOR_STATUS: BASE_URL + PATHS.SYSTEM + '/isLoggedInUserExpertContributor',
        GET_USER_PRO_DATA: BASE_URL + PATHS.SYSTEM + '/getLoggedInUserProData',
        /*common actions*/
        GET_EMPLOYEE_HISTORY: BASE_URL + PATHS.SYSTEM + '/getEmployeeHistory/',
        ADD_EMP_HISTORY: BASE_URL + PATHS.SYSTEM + '/addCompanyToEmployeeHistory/',
        REMOVE_COMPANY_FROM_HISTORY: BASE_URL + PATHS.SYSTEM + '/removeCompanyFromEmployeeHistory/',
        IMAGE_PATH: BASE_URL + PATHS.COMMON + '/generate_get_signed_url',
        CONTACT_US: BASE_URL + PATHS.COMMON + '/contactUsEmail',
        BLOCKUSERSTATUS: BASE_URL + PATHS.COMMON + '/checkBlockStatus',
        EDIT_EMP_HISTORY: BASE_URL + PATHS.SYSTEM + '/editEmployeementHistory/',
        CLEAR_CACHED_CONNECTIONS: BASE_URL + PATHS.COMMON + '/clearCachedConnection',
        SPAM_REPORT: BASE_URL + PATHS.SYSTEM + '/reportSpam',

        /*registration - company*/
        COMPANY_STEP_2: BASE_URL + PATHS.SYSTEM + '/step/two',
        COMPANY_STEP_3: BASE_URL + PATHS.SYSTEM + '/step/three',


        /*registration - investor*/
        // INVESTOR_OPTIONS: BASE_URL + PATHS.INVESTOR,
        INVESTOR_OPTIONS: BASE_URL + PATHS.SYSTEM + '/getInvestorTypes',
        INVESTOR_STEP_2: BASE_URL + PATHS.SYSTEM + '/investor/steptwo',
        INVESTOR_STEP_3: BASE_URL + PATHS.SYSTEM + '/investor/stepthree',
        INVESTOR_STEP_4: BASE_URL + PATHS.SYSTEM + '/investor/stepfour',


        // INVESTOR_STEP_2: BASE_URL + PATHS.INVESTOR + '/step/two',
        // INVESTOR_STEP_3: BASE_URL + PATHS.INVESTOR + '/step/three',
        // INVESTOR_STEP_4: BASE_URL + PATHS.INVESTOR + '/step/four',


        // ADMIN
        POST: BASE_URL + PATHS.POST + '/fetchAdminPosts',
        POST_COUNT: BASE_URL + PATHS.POST + '/adminPostCount',
        POST_SEARCH: BASE_URL + PATHS.POST + '/searchPosts',
        POST_SEARCH_RESULT_COUNT: BASE_URL + PATHS.POST + '/searchPostsResultCount',
        USER_REPORT: BASE_URL + PATHS.SYSTEM + '/report',
        FETCH_COMMENTS_FOR_ADMIN: BASE_URL + PATHS.POST + '/fetchCommentsForSinglePost',
        SET_PERMISSION: BASE_URL + PATHS.SYSTEM + '/changePermissionSettings',
        GET_PERMISSIONS: BASE_URL + PATHS.SYSTEM + '/getPermissionSettings',
        GET_USERS: BASE_URL + PATHS.SYSTEM + '/userSearch',
        GET_DELETED_PRODUCTS: BASE_URL + PATHS.MARKET_PLACE + '/getLeafwireAdminDeletedProducts',
        GET_DELETED_PRODUCT_COUNT: BASE_URL + PATHS.MARKET_PLACE + '/getLeafwireAdminDeletedProductsCount',
        ADD_EXPERT_CONTRIBUTER: BASE_URL + PATHS.SYSTEM + '/setUserAsExpertContributor',
        GET_ALL_EXPERT_CONTRIBUTER: BASE_URL + PATHS.SYSTEM + '/getAllExpertContributors',
        GET_ALL_EXPERT_CONTRIBUTER_COUNT: BASE_URL + PATHS.SYSTEM + '/getAllExpertContributorsCount',
        GET_MARKETPLACE_STATISTICS: BASE_URL + PATHS.REPORTS + '/fetchMarketplaceStatistics',
        GET_MARKETPLACE_STATISTICS_COUNT: BASE_URL + PATHS.REPORTS + '/fetchMarketplaceStatisticsCount',
        GET_STATISTICS_SEARCH: BASE_URL + PATHS.REPORTS + '/searchQueryMarketplaceStatistics',

        GET_USER_PRO_STATUS: BASE_URL + PATHS.SYSTEM + '/search-pro-users',
        UPDATE_USER_PRO_STATUS: BASE_URL + PATHS.SYSTEM + '/update-pro-user',
        TRACK_PRO_INVITES_MODAL_OPEN: BASE_URL + PATHS.REPORTS + '/proInvitationTracker',

        FETCH_PUBLISHED_PRODUCTS: BASE_URL + PATHS.MARKET_PLACE + '/listProductsForPrioritize',
        FETCH_PUBLISHED_PRODUCTS_COUNT: BASE_URL + PATHS.MARKET_PLACE + '/getPublishedProductCount',
        SET_MARKETPLACE_PRIORITY: BASE_URL + PATHS.MARKET_PLACE + '/setPriority',

        GET_MARKETING_CAMPAIGN_STATISTICS: BASE_URL + PATHS.SYSTEM + '/fetchMarketingCampaignStatistics',
        GET_MARKETING_CAMPAIGN_STATISTICS_COUNT: BASE_URL + PATHS.SYSTEM + '/fetchMarketingCampaignStatisticsCount',
        GET_MARKETING_CAMPAIGN_SUMMARY: BASE_URL + PATHS.REPORTS + '/fetchMarketingCampaignUTMStatistics',
        GET_MARKETING_CAMPAIGN_SUMMARY_COUNT: BASE_URL + PATHS.REPORTS + '/fetchMarketingCampaignUTMStatisticsCount',
        GET_NEWS_FEED_TEXT_LINK_SUMMARY: BASE_URL + PATHS.REPORTS + '/fetchTextLinkSummary',
        GET_NEWS_FEED_TEXT_LINK_SUMMARY_COUNT: BASE_URL + PATHS.REPORTS + '/fetchTextLinkSummaryCount',
        GET_MENTION_TAG_REPORTING_INFORMATION: BASE_URL + PATHS.POST + '/getMentionTagReportingInformation',

        GET_AUTO_CONNECTION_REQUESTS_STATISTICS: BASE_URL + PATHS.REPORTS + '/fetchAutoConnectionStatistics',
        GET_AUTO_CONNECTION_REQUESTS_STATISTICS_COUNT: BASE_URL + PATHS.REPORTS + '/fetchAutoConnectionStatisticsCount',

        ADD_COUPON_DETAILS: BASE_URL + PATHS.PAYMENTS + '/createCouponCode',
        GET_ALL_COUPON_DETAILS: BASE_URL + PATHS.PAYMENTS + '/getCouponCodeDetails',
        GET_ALL_COUPON_DETAILS_COUNT: BASE_URL + PATHS.PAYMENTS + '/getCouponCodeDetailsCount',

        GET_PREFERRED_PARTNERS_COUNT: BASE_URL + PATHS.SYSTEM + '/getPreferredPartnersCount',
        GET_PREFERRED_PARTNERS: BASE_URL + PATHS.SYSTEM + '/getPreferredPartners',
        ADD_PREFERRED_PARTNERS: BASE_URL + PATHS.SYSTEM + '/preferredPartners',
        EDIT_PREFERRED_PARTNERS: BASE_URL + PATHS.SYSTEM + '/preferredPartners',
        DELETE_PREFERRED_PARTNERS: BASE_URL + PATHS.SYSTEM + '/preferredPartners',
        GENERATE_GET_PARTNER_URL: BASE_URL + PATHS.SYSTEM + '/generate_get_partner_url',
        GENERATE_PUT_PARTNER_URL: BASE_URL + PATHS.SYSTEM + '/generate_put_partner_url',
        CREATE_LEAFWIRE_LINK: BASE_URL + PATHS.SYSTEM + '/createLeafwireLink',
        GET_LEAFWIRE_LINKS: BASE_URL + PATHS.REPORTS + '/fetchLeafwireLinks',
        GET_LEAFWIRE_LINKS_COUNT: BASE_URL + PATHS.REPORTS + '/fetchLeafwireLinksCount',
        GET_PUBLIC_LINK: BASE_URL + PATHS.SYSTEM + '/getLeafwireLink',
        DELETE_PUBLIC_LINK: BASE_URL + PATHS.SYSTEM + '/deleteLeafwireLink',

        UPDATE_REWARD_REDEMPTION: BASE_URL + PATHS.SYSTEM + '/updateUserDetailsForRewardsRedemption',
        ADD_REWARD_REDEMPTION: BASE_URL + PATHS.SYSTEM + '/addToRedemptionList',
        GET_ALL_REWARD_REDEMPTION: BASE_URL + PATHS.SYSTEM + '/getRewardRemptionUsers',
        GET_ALL_REWARD_REDEMPTION_COUNT: BASE_URL + PATHS.SYSTEM + '/getRewardRemptionUsersCount',

        UPDATE_CONFIGS: BASE_URL + PATHS.COMMON + '/updateConfigs',
        GET_CONFIGS: BASE_URL + PATHS.COMMON + '/getConfigs',

        GET_MEMBER_STATS: BASE_URL + PATHS.ADMIN_REPORTS + '/getMembersCountByMonth',
        GET_POSTS_STATS: BASE_URL + PATHS.ADMIN_REPORTS + '/getPostCountByMonth',
        GET_COMMENTS_STATS: BASE_URL + PATHS.ADMIN_REPORTS + '/getPostCommentCountByMonth',
        GET_CONNECTION_REQUESTS_STATS: BASE_URL + PATHS.ADMIN_REPORTS + '/getConnectionRequestCountByMonth',
        GET_CONNECTION_ACCEPTANCES_STATS: BASE_URL + PATHS.ADMIN_REPORTS + '/getConnectionRequestAcceptanceCountByMonth',
        GET_MARKETPLACE_COMPANIES_STATS: BASE_URL + PATHS.ADMIN_REPORTS + '/getCompanyCountWithMarketplaceListingByMonth',
        GET_COMPANIES_STATS: BASE_URL + PATHS.ADMIN_REPORTS + '/getCompanyProfileCountByMonth',
        GET_POST_STATS_BY_USER_REGISTERED_DATE: BASE_URL + PATHS.ADMIN_REPORTS + '/getPostCountByUserRegisteredDate',
        SET_MP_PACKAGE_DESCRIPTION: BASE_URL + PATHS.MARKET_PLACE + '/setPackageDescription',
        START_EMULATE_SESSION: BASE_URL + PATHS.SYSTEM + '/startEmulateSession',
        END_EMULATE_SESSION: BASE_URL + PATHS.SYSTEM + '/endEmulateSession',
        ADMIN_EDIT_EVENT_DISCOUNT_DETAILS: BASE_URL + PATHS.EVENTS + '/editAdminEventDiscount',
        GET_EVENT_DISCOUNT_DETAILS: BASE_URL + PATHS.EVENTS + '/getEventDiscountDetails',
        EDIT_USER_CONNECTION_LIMIT: BASE_URL + PATHS.SYSTEM + '/setUserConnectionCounts/',

        // ADMIN:-- CSV REPORTS
        GET_USER_TRACKING_STATS: BASE_URL + PATHS.REPORTS + '/user-tracker-data',
        GET_NEWS_FEED_BANNER_AD_TRACKING_STATS: BASE_URL + PATHS.REPORTS + '/news-feed-banner-ad-tracking-data',
        GET_POST_SHARE_TRACKING_STATS: BASE_URL + PATHS.REPORTS + '/post-share-tracking-data',
        GET_POST_LIKE_COMMENT_TRACKING_STATS: BASE_URL + PATHS.REPORTS + '/post-like-comment-tracking-data',
        GET_OPEN_NEWS_FEED_TRACKING_STATS: BASE_URL + PATHS.REPORTS + '/open-news-feed-tracking-data',
        GET_NEWS_FEED_VIEW_TRACKING_STATS: BASE_URL + PATHS.REPORTS + '/news-feed-view-tracking-data',
        GET_TEXT_LINK_VIEW_TRACKING_STATS: BASE_URL + PATHS.REPORTS + '/text-link-view-tracking-data',
        GET_TRENDING_TOPICS_TRACKING_STATS: BASE_URL + PATHS.REPORTS + '/trending-topics-tracking-data',
        GET_USER_PROFILE_VIEW_TRACKING_STATS: BASE_URL + PATHS.REPORTS + '/user-profile-view-tracking-data',
        GET_USER_LOGIN_TRACKING_STATS: BASE_URL + PATHS.REPORTS + '/user-login-tracking-data',
        GET_AUTO_CONNECTION_TRACKING_STATS: BASE_URL + PATHS.REPORTS + '/auto-connection-tracking-data',
        GET_MARKETPLACE_WEBSITE_TRACKING_STATS: BASE_URL + PATHS.REPORTS + '/marketplace-website-tracking-data',
        GET_USERS_PRO_STATUS_ADMIN_CHANGES_TRACKING_STATS: BASE_URL + PATHS.REPORTS + '/users-pro-status-tracking-data',
        GET_USERS_PRESS_TRACKING_STATS: BASE_URL + PATHS.REPORTS + '/press-tracking-data',
        
        GET_PENDING_USER_REVIEWS: BASE_URL + PATHS.ADMIN_REPORTS + '/getPendingUserReviews',
        GET_PENDING_USER_REVIEWS_COUNT: BASE_URL + PATHS.ADMIN_REPORTS + '/getPendingUserReviewsCount',
        GET_RESOLVED_USER_REVIEWS: BASE_URL + PATHS.ADMIN_REPORTS + '/getResolvedUserReviews',
        GET_RESOLVED_USER_REVIEWS_COUNT: BASE_URL + PATHS.ADMIN_REPORTS + '/getResolvedUserReviewsCount',
        GET_REPORTERS: BASE_URL + PATHS.ADMIN_REPORTS + '/getReporters',
        GET_REPORTED_POSTS: BASE_URL + PATHS.ADMIN_REPORTS + '/searchReportedPosts',
        GET_REPORTED_POSTS_COUNT: BASE_URL + PATHS.ADMIN_REPORTS + '/searchReportedPostsCount',
        RESOLVE_USER_REPORT: BASE_URL + PATHS.ADMIN_REPORTS + '/ResolveReport',
        TOGGLE_REVIEW_ACTION: BASE_URL + PATHS.ADMIN_REPORTS + '/ToggleReviewAction',
        GET_EMPLOYEMENT_HISTORY_REPORT: BASE_URL + PATHS.ADMIN_REPORTS + '/employmentHistoryReport',
        GET_ADMIN_STATS: BASE_URL + PATHS.ADMIN_REPORTS + '/getMembersCountReport',

        // ADMIN:-- VANITY WORDS LIST
        FETCH_VWL: BASE_URL + PATHS.VANITY + '/vanityUrlDoNotUseList',
        CREATE_VWL: BASE_URL + PATHS.VANITY + '/createVanityUrlDoNotUseList',
        DELETE_VWL: BASE_URL + PATHS.VANITY + '/daleteVanityUrlDoNotUseList',

        // ADMIN:-- JOBS MANAGER
        FETCH_JB_LISTINGS: BASE_URL + PATHS.JOBS + '/fetchJobsForAdminPanel',
        TURN_OFF_JOB_PRIORITY: BASE_URL + PATHS.JOBS + '/turnOffJobListing/',
        TURN_ON_JOB_PRIORITY: BASE_URL + PATHS.JOBS + '/turnOnJobListing/',
        UPDATE_PRIORITY_NUMBER: BASE_URL + PATHS.JOBS + '/setJobPriority/',
        GET_JB_WIDGET_TITLE: BASE_URL + PATHS.JOBS + '/getJobWidgetTitle',
        SET_JB_WIDGET_TITLE: BASE_URL + PATHS.JOBS + '/changeJobWidgetTitle/',
        GET_JB_WIDGET_VISIBILITY: BASE_URL + PATHS.JOBS + '/getJobWidgetVisibility',
        SET_JB_WIDGET_VISIBILITY: BASE_URL + PATHS.JOBS + '/changeJobWidgetVisibility/',
        UPDATE_JOB: BASE_URL + PATHS.JOBS + '/updateJob/',
        CREATE_JOB: BASE_URL + PATHS.JOBS + '/addJob/',
        CREATE_JOB_POSITIONS: BASE_URL + PATHS.JOBS + '/createJobPosition/',
        UPDATE_JOB_POSITIONS: BASE_URL + PATHS.JOBS + '/updateJobPosition/',
        LIST_JOB_POSITIONS: BASE_URL + PATHS.JOBS + '/listJobPositions/',
        LIST_RECRUITER_JOB_POSITIONS: BASE_URL + PATHS.JOBS + '/listHubspotJobPositions/',
        DELETE_JOB_POSITIONS: BASE_URL + PATHS.JOBS + '/deleteJobPosition/',
    
        //COMPANY_USER

        ADD_FUNDRAISING_ROUND: BASE_URL + PATHS.COMPANY_USER + '/updateCurrentFundraising/',
        GET_COMPANY_DETAILS: BASE_URL + PATHS.COMPANY_USER + '/about/',
        GET_COMPANY_DETAILS_NEW: BASE_URL + PATHS.COMPANY_USER + '/aboutCompany/',
        GET_INVESTORS: BASE_URL + PATHS.INVESTOR + '/searchInvestorCompanies/',
        GET_CURRENT_INVESTORS: BASE_URL + PATHS.COMPANY_USER + '/getCurrentInvestors/',
        ADD_INVESTOR: BASE_URL + PATHS.COMPANY_USER + '/addCompanyInvestor/',
        REMOVE_INVESTOR: BASE_URL + PATHS.COMPANY_USER + '/removeCompanyInvestor/',
        UPDATE_OVERVIEW: BASE_URL + PATHS.COMPANY_USER + '/updateCompanyOverview/',
        ADD_EMPLOYEE: BASE_URL + PATHS.COMPANY_USER + '/addCompanyEmployee/',
        EDIT_EMPLOYEE: BASE_URL + PATHS.COMPANY + '/editEmployee/',
        REMOVE_EMPLOYEE: BASE_URL + PATHS.COMPANY_USER + '/removeCompanyEmployee/',
        GET_EMPLOYEES: BASE_URL + PATHS.COMPANY_USER + '/companyEmployees/',
        GET_ALL_EMPLOYEES: BASE_URL + PATHS.COMPANY_USER + '/searchCompanyEmployees/',
        GET_ALL_EMPLOYEES_WITH_COMPANY: BASE_URL + PATHS.COMPANY_USER + '/searchEmployeesWithCompanyName/',
        GET_PRODUCTS: BASE_URL + PATHS.COMPANY_USER + '/getProducts/',
        ADD_PRODUCT: BASE_URL + PATHS.COMPANY_USER + '/addProductToShowcase/',
        UPDATE_PRODUCT: BASE_URL + PATHS.COMPANY_USER + '/updateProduct/',
        REMOVE_PRODUCT: BASE_URL + PATHS.COMPANY_USER + '/removeProduct/',
        UPDATE_PERSONAL_OVERVIEW: BASE_URL + PATHS.SYSTEM + '/updatePersonalBackground/',
        GET_ALL_COMPANIES: BASE_URL + PATHS.SYSTEM + '/searchCompaniesForEmployeeHistory/',
        GET_COMPANIES: BASE_URL + PATHS.COMPANY_USER + '/searchCompanies',
        GET_COMPANIES_BY_NAME: BASE_URL + PATHS.COMPANY_USER + '/searchCompaniesByName',
        LIST_COMPANIES_COUNT: BASE_URL + PATHS.COMPANY_USER + '/searchCompaniesResultsCount',
        UPDATE_ABOUT: BASE_URL + PATHS.SYSTEM + '/updateAbout/',
        ADD_VIDEO: BASE_URL + PATHS.COMPANY_USER + '/addPublicVideo/',
        UPDATE_ABOUT_COMPANY: BASE_URL + PATHS.COMPANY_USER + '/updateAbout/',
        MANAGE_MY_SUBSCRIPTIONS: BASE_URL + PATHS.PAYMENTS + '/manageSubscriptions',
        GET_MY_SUBSCRIPTION_REPORT: BASE_URL + PATHS.PAYMENTS + '/getSubscriptionReport',

        //COMPANY

        GET_REGULAR_EMPLOYEES: BASE_URL + PATHS.COMPANY + '/keyEmployee/getRegularEmployeesByCompanyId/',
        GET_NON_ADVISORS: BASE_URL + PATHS.COMPANY + '/advisors/getRegularEmployeesExceptAdvisorsByCompanyId/',
        ADD_ADVISER: BASE_URL + PATHS.COMPANY + '/advisor/add/',
        RESEND_ADVISER_REQUEST: BASE_URL + PATHS.COMPANY + '/advisor/resendAdvisorRequest/',
        ACCEPT_ADVISER_REQUEST: BASE_URL + PATHS.COMPANY + '/advisor/acceptAdvisorRequest/',
        REJECT_ADVISER_REQUEST: BASE_URL + PATHS.COMPANY + '/advisor/rejectAdvisorRequest/',
        REMOVE_ADVISERS: BASE_URL + PATHS.COMPANY + '/advisor/delete/',
        GET_ADVISERS: BASE_URL + PATHS.COMPANY + '/advisors/',
        GET_KEY_EMPLOYEED_COMPANIES: BASE_URL + PATHS.SYSTEM + '/getKeyEmployeedCompanies/',
        SET_FUNDRAISING_COMPANY: BASE_URL + PATHS.SYSTEM + '/updateFundraisingKeyEmployeedCompany/',
        CHECK_COMPANY_ADMIN: BASE_URL + PATHS.COMPANY + '/isAdmin/',
        IS_PAID: BASE_URL + PATHS.COMPANY + '/isSubscribed/',
        IS_BLUESNAP_CUSTOMER: BASE_URL + PATHS.COMPANY + '/isBluesnapCustomer/',
        GET_INDIVIDUAL_INVESTORS: BASE_URL + PATHS.COMPANY + '/getIndividualInvestors',
        GET_COMPANY_QUESTIONS: BASE_URL + PATHS.COMPANY + '/getFundraisingQuestions/',
        SAVE_COMPANY_QUESTIONS: BASE_URL + PATHS.COMPANY + '/saveFundraisingQuestions/',
        CREATE_COMPANY: BASE_URL + PATHS.COMPANY + '/createCompany',
        DELETE_COMPANY: BASE_URL + PATHS.COMPANY + '/deleteCompany/',


        //CONNECTIONS

        GET_CONNECTIONS: BASE_URL + PATHS.COMMON + '/getMyConnections',
        GET_MY_CONNECTIONS_COUNT: BASE_URL + PATHS.COMMON + '/getMyConnectionsCount',
        GET_SUGGESTIONS: BASE_URL + PATHS.COMMON + '/suggestConnections',
        GET_SUGGESTIONS_COUNT: BASE_URL + PATHS.COMMON + '/suggestConnectionsCount',
        GET_MUTUAL_CONNECTIONS: BASE_URL + PATHS.COMMON + '/getMutualConnections',
        GET_WIDGET_SUGGESTIONS: BASE_URL + PATHS.COMMON + '/fetchGrowYourNetworkConnections',

        GET_PUBLIC_SUGGESTIONS: BASE_URL + PATHS.COMMON + '/suggestConnectionsPublic',

        GET_PENDING_CONNECTIONS: BASE_URL + PATHS.COMMON + '/getPendingConnections',
        GET_PENDING_CONNECTION_COUNT: BASE_URL + PATHS.COMMON + '/getPendingConnectionsCount',
        GET_MY_FRIEND_CONNECTIONS: BASE_URL + PATHS.COMMON + '/getMyFriendsConnections',


        //INVESTOR

        GET_INVESTOR_TYPES: BASE_URL + PATHS.INVESTOR + '/',
        UPDATE_INVESTOR_DETAILS: BASE_URL + PATHS.INVESTOR + '/saveInvestorDetails',
        UPDATE_INVESTOR_OVERVIEW: BASE_URL + PATHS.INVESTOR + '/saveInvestorOverview',
        GET_NON_INVESTED_COMPANIES: BASE_URL + PATHS.COMPANY_USER + '/searchCompaniesExcludingInvested',
        GET_NON_INVESTED_INDIVIDUALS: BASE_URL + PATHS.COMPANY_USER + '/searchInvestorsExcludingInvested',
        GET_INVESTED_COMPANIES: BASE_URL + PATHS.INVESTOR + '/getInvestedCompanies',
        ADD_INVESTED_COMPANY: BASE_URL + PATHS.INVESTOR + '/addToInvestedCompanies',
        REMOVE_INVESTED_COMPANY: BASE_URL + PATHS.INVESTOR + '/removeFromInvestedCompanies',

        //NEWS FEED

        ADD_POST: BASE_URL + PATHS.POST + '/',
        EDIT_POST: BASE_URL + PATHS.POST + '/editPost/',

        NEWSFEED: BASE_URL_2 + PATHS.POST + '/fetchPostsForNewsFeed',
        NEWSFEED_COUNT: BASE_URL + PATHS.POST + '/newsFeedCount',
        EXTRA_NEWSFEED: BASE_URL_2 + PATHS.POST + '/fetchPostsForExtraNewsFeed',
        USER_FEED: BASE_URL_2 + PATHS.POST + '/fetchAllPostsOfUser/',
        USER_FEED_COUNT: BASE_URL + PATHS.POST + '/allPostsOfUserCount/',
        INCREASE_VIEW: BASE_URL + PATHS.COMMON + '/increaseView',
        FETCH_TRENDING_POST: BASE_URL + PATHS.POST + '/fetchTrendingPost',
        NEWSFEED_REPOST: BASE_URL_2 + PATHS.POST + '/repost',
        NEWSFEED_ADVERTISEMENT: BASE_URL + PATHS.POST + '/getNewsfeedAdvertisement',

        LINK_PREVIEW: BASE_URL + PATHS.COMMON + '/scrapeData/',
        LIKE_POST: BASE_URL + PATHS.POST + '/postLikeStatus/',
        ARCHIVE_POST: BASE_URL + PATHS.POST + '/archive/',
        VIEW_POST: BASE_URL + PATHS.POST + '/view',
        FETCH_COMMENTS: BASE_URL_2 + PATHS.POST + '/postComments/',
        ADD_COMMENT: BASE_URL + PATHS.POST + '/createPostComment/',
        EDIT_COMMENT: BASE_URL + PATHS.POST + '/editPostComment/',
        DELETE_COMMENT: BASE_URL + PATHS.POST + '/deletePostComment/',
        USER_RECENT_POST: BASE_URL + PATHS.POST + '/fetchRecentUserPosts/',
        USER_RECENT_POST_COUNT: BASE_URL + PATHS.POST + '/fetchRecentUserPostsCount/',
        FETCH_FUNDRAISING_COMPANIES: BASE_URL + PATHS.POST + '/fetchFundrasingCompaniesForNewsFeed',
        FUNDRAISING_COMPANY_COUNT: BASE_URL + PATHS.POST + '/fundrasingCompanyCountForNewsFeed',
        LIKED_USERS: BASE_URL + PATHS.POST + '/fetchPostLikedUsers',
        COMMENTER_DETAILS: BASE_URL + PATHS.POST + '/fetchPostCommenterDetails',
        GET_TEXT_LINKS_FOR_NEWSFEED: BASE_URL + PATHS.FEED_TEXT_LINKS + '/fetchTextLinksForNewsfeed',
        GET_TEXT_LINKS_FOR_NEWSFEED_COUNT: BASE_URL + PATHS.FEED_TEXT_LINKS + '/fetchTextLinksForNewsfeedCount',

        GENERATE_NEWS_FEED_PUT_SIGN_URL: BASE_URL + PATHS.COMMON + '/generate_newsfeed_put_signed_url',
        GENERATE_NEWS_FEED_SIGN_URL: BASE_URL + PATHS.COMMON + '/generate_newsfeed_get_signed_url',
        GENERATE_NEWS_FEED_VIDEO_UPLOAD: BASE_URL + PATHS.COMMON + '/generate-video-upload-url',
        SUGGEST_TAG: BASE_URL + PATHS.COMMON + '/suggestMentionTag',
        UPDATE_TAG_EMAIL_COUNT: BASE_URL + PATHS.POST + '/updateEmailCountOnTag/',

        LIKE_COMMENT: BASE_URL + PATHS.POST + '/likeComment/',
        UNLIKE_COMMENT: BASE_URL + PATHS.POST + '/unlikeComment/',
        COMMENT_LIKES: BASE_URL + PATHS.POST + '/getCommentLikes/',

        LOAD_SINGLE_POST_V_1: BASE_URL + PATHS.POST + '/fetchNewsfeedSinglePost',
        LOAD_SINGLE_POST: BASE_URL_2 + PATHS.POST + '/fetchNewsfeedSinglePost',
        BLOCK_POST: BASE_URL_2 + PATHS.POST + '/blockPost/',
        CHECK_PERSONAL_NEWSFEED_STATUS: BASE_URL_2 + PATHS.POST + '/getNewsfeedUserStatus/',

        //PROFILE

        PUBLIC_PROFILE: BASE_URL + PATHS.SYSTEM + '/publicProfile/',
        PUBLIC_PROFILE_INDIVIDUAL: BASE_URL + PATHS.SYSTEM + '/publicProfile/individual/',
        GET_KEY_EMP_FUNDRAISING: BASE_URL + PATHS.SYSTEM + '/getIndividualPublicProfileFundraisingDetails/',
        ADD_CONNECTION: BASE_URL + PATHS.COMMON + '/addConnection',
        ACCEPT_CONNECTION: BASE_URL + PATHS.COMMON + '/acceptConnection',
        REJECT_CONNECTION: BASE_URL + PATHS.COMMON + '/rejectConnection',
        
        VANITY_USERID: BASE_URL + PATHS.VANITY + '/userId',
        VANITY_CREATE_URL: BASE_URL + PATHS.VANITY + '/createVanityUrl',

        //SEARCH

        SEARCH_INDIVIDUALS: BASE_URL + PATHS.COMMON + '/filter',
        GET_SEARCH_COUNT: BASE_URL + PATHS.COMMON + '/filteredResultCount',
        SEARCH_COMPANIES: BASE_URL + PATHS.COMMON + '/filterCompanies',
        // TOP_SEARCH: BASE_URL + PATHS.SYSTEM + '/generalSearch',
        TOP_SEARCH: BASE_URL + PATHS.COMMON + '/advanceSearch',
        TOP_SEARCH_COUNT: BASE_URL + PATHS.COMMON + '/advanceSearchCount',
        GENERATE_PUT_SIGN_URL: BASE_URL + PATHS.COMMON + '/generate_put_signed_url',
        GENERATE_DATAROOM_UPLOAD_URL: BASE_URL + PATHS.DATA_ROOM + '/generate_upload_url',

        //MESSAGE
        LOAD_CONVERSATIONS: BASE_URL + PATHS.MESSAGE + '/load',
        SEARCH_CONVERSATIONS: BASE_URL + PATHS.MESSAGE + '/searchConversationThreads',
        CONVERSATION_COUNT: BASE_URL + PATHS.MESSAGE + '/conversationsCount',
        LOAD_CHAT_CONVERSATIONS: BASE_URL + PATHS.MESSAGE + '/loadInstantConversations',
        CHAT_CONVERSATION_COUNT: BASE_URL + PATHS.MESSAGE + '/loadInstantConversationCount',
        SEND_MESSAGE: BASE_URL + PATHS.MESSAGE + '/sendMessage',
        FETCH_COVERSATION_MESSAGES: BASE_URL + PATHS.MESSAGE + '/loadThread',
        FETCH_ADMIN_COVERSATION_MESSAGES: BASE_URL + PATHS.MESSAGE + '/getConversationThreadForAdmin',
        FETCH_ADMIN_COVERSATION_MESSAGE_COUNT: BASE_URL + PATHS.MESSAGE + '/getConversationThreadCountForAdmin',
        GET_COVERSATION_MESSAGE_COUNT: BASE_URL + PATHS.MESSAGE + '/getConversationThreadCount',
        GET_COMPANY_COVERSATION_MESSAGE_COUNT: BASE_URL + PATHS.MESSAGE + '/getCompanyConversationThreadCount',
        LOAD_UNSEEN: BASE_URL + PATHS.MESSAGE + '/getUnseenMessages',
        GET_LATEST_UNSEEN_CONVERSATION: BASE_URL + PATHS.MESSAGE + '/getUnseenLastConversation',
        GET_COVERSATION_PARTICIPANTS: BASE_URL + PATHS.MESSAGE + '/getConversationParticipants',
        LOAD_USERS_FOR_CONVERSATION: BASE_URL + PATHS.MESSAGE + '/searchUsersForChat',
        SEARCH_USER_COUNT: BASE_URL + PATHS.MESSAGE + '/searchUsersForChatCount',
        SEARCH_MY_CONNECTIONS: BASE_URL + PATHS.MESSAGE + '/searchMyConnections',
        SEEN_COVERSATION_MESSAGES: BASE_URL + PATHS.MESSAGE + '/updateMessagesToSeen',
        SET_CONVERSATION_MESSAGES_AS_READ: BASE_URL + PATHS.MESSAGE + '/markAsRead',
        SEEN_CONNECTION_MESSAGES: BASE_URL + PATHS.MESSAGE + '/updateConnectionMessagesToSeen',
        GET_RECIPIENT_CONNECTION_STATUS: BASE_URL + PATHS.MESSAGE + '/getRecipientConnectionStatus',
        FETCH_COMPANY_COVERSATION_MESSAGES: BASE_URL + PATHS.MESSAGE + '/getCompanyConversationThread',
        FETCH_COMPANY_COVERSATION_PARTICIPANTS: BASE_URL + PATHS.MESSAGE + '/getCompanyConversationParticipants',
        // GET_COMPANY_LATEST_UNSEEN_CONVERSATION: BASE_URL + PATHS.MESSAGE + '/getCompanyUnseenLastConversation',
        SEND_COMPANY_MESSAGE: BASE_URL + PATHS.MESSAGE + '/sendCompanyMessage',
        SEEN_COMPANY_COVERSATION_MESSAGES: BASE_URL + PATHS.MESSAGE + '/updateCompanyMessagesToSeen',
        DELETE_CONVERSATION_THREAD: BASE_URL + PATHS.MESSAGE + '/archiveThread',
        GET_INDIVIDUAL_CONNECTION: BASE_URL + PATHS.MESSAGE + '/getConversation/',
        MARK_ALL_MESSAGES_AS_READ: BASE_URL + PATHS.MESSAGE + '/markAsReadByModule',
        LOAD_RECENT_CONVERSATIONS: BASE_URL + PATHS.MESSAGE + '/getRecentlyMessagedUsers',
        LOAD_RECENT_CONVERSATIONS_COUNT: BASE_URL + PATHS.MESSAGE + '/getRecentlyMessagedUsersCount',
        GET_LEFT_PANEL_CONVERSATION_COUNT: BASE_URL + PATHS.MESSAGE + '/getLeftPanelconversationCount',
        GET_USER_CONVERSATION: BASE_URL + PATHS.MESSAGE + '/getPersonalConversation',
        SEEN_CHAT_MESSAGE: BASE_URL + PATHS.MESSAGE + '/updateConversationSeenByChat',
        FETCH_USER_UNSEEN_CHATS: BASE_URL + PATHS.MESSAGE + '/unread-conversations',
        UPDATE_LAST_MESSAGE_ACCESS_TIME: BASE_URL + PATHS.MESSAGE + '/update-conversations-last-access',

        //CONTACTS
        FETCH_CONTACTS: BASE_URL + PATHS.CONTACTS + '/fetch',
        SEND_INVITATIONS: BASE_URL + PATHS.CONTACTS + '/sendEmailsToContacts',
        VALIDATING_INVITATION_TOKEN: BASE_URL + PATHS.CONTACTS + '/validate',
        FETCHING_WINDOWS_LIVE_CONTACTS: BASE_URL + PATHS.CONTACTS + '/fetchWindowsLiveContacts',
        FETCHING_YAHOO_CONTACTS: BASE_URL + PATHS.CONTACTS + '/fetchYahooContacts',

        // ACCOUNT PREFERENCES\
        GET_COMMUNICATION_PREFERENCES: BASE_URL + PATHS.COMMON + '/getContactPreferences',
        SET_COMMUNICATION_PREFERENCE: BASE_URL + PATHS.COMMON + '/addPreferences',
        SET_INDUSTRY_ROLES: BASE_URL + PATHS.SYSTEM + '/setIndustryRoles',

        //TOOLS
        GET_TOOLS: BASE_URL + PATHS.COMMON + '/tools',

        // MANAGE_ADMISN
        GET_NORMAL_USERS: BASE_URL + PATHS.COMPANY + '/admin/getNormalUsersByCompanyId/',
        GET_EXISTING_ADMINS: BASE_URL + PATHS.COMPANY + '/admin/listExistingCompanyAdmins/',
        SAVE_ADMIN_CHANGES: BASE_URL + PATHS.COMPANY + '/admin/saveAdminChanges/',
        SET_MARKETPLACE_QUICK_SEARCH: BASE_URL + PATHS.MARKET_PLACE + '/setQuickSearchLink',

        DELETE_USER: BASE_URL + PATHS.USER_AUTH + '/deleteProfile/',

        //MANAGE_PAYMENTS

        CREATE_PAYMENT_FIELDS_TOKEN: BASE_URL + PATHS.PAYMENTS + '/createHostedPaymentFieldsToken',
        CREATE_COMPANY_SHOPPER: BASE_URL + PATHS.PAYMENTS + '/createCompanyShopper',
        CREATE_SUBSCRIPTION: BASE_URL + PATHS.PAYMENTS + '/subscription',
        FETCH_COMPANY_GATEWAYS: BASE_URL + PATHS.PAYMENTS + '/fetchPaymentGatewaysByCompanyId/',

        RENEW_SUBSCRIPTION: BASE_URL + PATHS.PAYMENTS + '/renewSubscription',
        ATTACH_NEW_CARD: BASE_URL + PATHS.PAYMENTS + '/attachCardToSubscription',
        FETCH_CARDS_BY_COMPANY: BASE_URL + PATHS.PAYMENTS + '/listAllCardsByCompanyId',

        SET_DEFAULT_CARD: BASE_URL + PATHS.PAYMENTS + '/setCustomerDefaultCard',
        ADD_CARD: BASE_URL + PATHS.PAYMENTS + '/addCard',
        DELETE_CARD: BASE_URL + PATHS.PAYMENTS + '/deleteCard',
        // EDIT_CARD: BASE_URL + PATHS.PAYMENTS + '/editCard',
        FETCH_PAYMENT_HISTORY: BASE_URL + PATHS.PAYMENTS + '/chargers',
        FETCH_SUBSCRIPTIONS: BASE_URL + PATHS.PAYMENTS + '/listSubscriptions',
        CANCEL_SUBSCRIPTION: BASE_URL + PATHS.PAYMENTS + '/cancelSubscription',
        RE_ACTIVATE_SUBSCRIPTION: BASE_URL + PATHS.PAYMENTS + '/reActivateSubscription',
        VALIDATE_COUPON_CODE: BASE_URL + PATHS.PAYMENTS + '/validateCouponCode',
        VALIDATE_REUSABLE_COUPON_CODE: BASE_URL + PATHS.PAYMENTS + '/validateReusableCouponCode',

        //new individual user payment routes
        FETCH_USER_GATEWAYS: BASE_URL + PATHS.PAYMENTS + '/fetchPaymentGatewaysByUserId',
        FETCH_SHOPPER: BASE_URL + PATHS.PAYMENTS + '/retrieveShopper/',
        FETCH_USER_SUBSCRIPTIONS: BASE_URL + PATHS.PAYMENTS + '/listCompanySubscriptionsCreatedByParticularUser',

        //DATA ROOM
        UPLOAD_DOCUMENT: BASE_URL + PATHS.DATA_ROOM + '/upload',
        DELETE_DOCUMENT: BASE_URL + PATHS.DATA_ROOM + '/delete',
        FETCH_DATA_ROOM_DOCS: BASE_URL + PATHS.DATA_ROOM + '/getCompanyDocuments',
        FETCH_DATA_ROOM_DOC_HISTORY: BASE_URL + PATHS.DATA_ROOM + '/listDocumentVersions',
        DATA_ROOM_DOC_COUNT: BASE_URL + PATHS.DATA_ROOM + '/getDocumentCount',
        VIEW_SINGLE_DOC: BASE_URL + PATHS.DATA_ROOM + '/generate_view_url',
        EDIT_DATAROOM_DOC: BASE_URL + PATHS.DATA_ROOM + '/update/',
        FETCH_DATAROOM_PERMISSION: BASE_URL + PATHS.DATA_ROOM + '/retrieveDataRoomPermission',
        REQUEST_DATAROOM_ACCESS: BASE_URL + PATHS.DATA_ROOM + '/requestDataRoomAccess',
        FETCH_PENDING_DATAROOM_REQUESTS: BASE_URL + PATHS.DATA_ROOM + '/listUsers',
        ACCEPT_DATAROOM_REQUEST: BASE_URL + PATHS.DATA_ROOM + '/acceptDataRoomRequest',
        REJECT_DATAROOM_REQUEST: BASE_URL + PATHS.DATA_ROOM + '/rejectDataRoomRequest',
        GET_PENDING_DATAROOM_REQUEST_COUNT: BASE_URL + PATHS.DATA_ROOM + '/listUsersCount',
        FETCH_SHAREDUSERS_DATAROOM_REQUESTS: BASE_URL + PATHS.DATA_ROOM + '/listUsers',
        FETCH_STATISTICS: BASE_URL + PATHS.DATA_ROOM + '/dataroomStatistics',
        FETCH_STATISTICS_COUNT: BASE_URL + PATHS.DATA_ROOM + '/dataroomStatisticsCount',
        GET_SHAREDUSERS_DATAROOM_REQUEST_COUNT: BASE_URL + PATHS.DATA_ROOM + '/listUsersCount',
        REVOKE_DATAROOM_ACCESS: BASE_URL + PATHS.DATA_ROOM + '/revokeDataRoomRequest/',
        REGRANT_DATAROOM_ACCESS: BASE_URL + PATHS.DATA_ROOM + '/reGrantDataRoomAccess/',
        SEND_DATAROOM_INVITATION: BASE_URL + PATHS.DATA_ROOM + '/sendDataRoomInvitation',
        UPDATE_DOCUMENT_VIEW_COUNT: BASE_URL + PATHS.DATA_ROOM + '/updateViewCount/',
        GET_AUTHORIZED_INVESTORS: BASE_URL + PATHS.DATA_ROOM + '/listAuthorizedUsers/',
        GET_UPLOADED_DOCS: BASE_URL + PATHS.DATA_ROOM + '/searchDocuments/',
        CHECK_INVITATION_VALID: BASE_URL + PATHS.DATA_ROOM + '/checkInvitationValid/',

        //MARKETPLACE
        GET_MY_PRODUCTS: BASE_URL + PATHS.MARKET_PLACE + '/listMyProducts/',
        GET_MY_PRODUCTS_COUNT: BASE_URL + PATHS.MARKET_PLACE + '/listMyProductsCount/',
        GET_LEAF_ADMIN_DELETED_PRODUCT_NAMES: BASE_URL + PATHS.MARKET_PLACE + '/getLeafwireAdminDeletedProductNameSuggestions',
        RETRIVE_TAGS: BASE_URL + PATHS.MARKET_PLACE + '/tags/',
        UPDATE_LAST_VIEWED_ON: BASE_URL + PATHS.MARKET_PLACE + '/updateLastViewedOn/',
        GENERATE_MARKET_PLACE_VIEW_URL: BASE_URL + PATHS.MARKET_PLACE + '/generate_view_url',
        GENERATE_MARKET_PLACE_UPLOAD_URL: BASE_URL + PATHS.MARKET_PLACE + '/generate_upload_url',
        CREATE_PRODUCT: BASE_URL + PATHS.MARKET_PLACE + '/product',
        GET_MARKETPLACE_PRODUCT_CATEGORIES: BASE_URL + PATHS.MARKET_PLACE + '/listProductCategories',
        VIEW_SINGLE_PRODUCT: BASE_URL + PATHS.MARKET_PLACE + '/viewSingleProduct/',
        DELETE_PRODUCT: BASE_URL + PATHS.MARKET_PLACE + '/deleteMyProduct/',
        UNPUBLISH_MY_PRODUCT: BASE_URL + PATHS.MARKET_PLACE + '/unpublishProduct/',
        LIST_PRODUCTS_FOR_WIDGET: BASE_URL + PATHS.MARKET_PLACE + '/listProductsForWidget',
        GET_MY_SINGLE_PRODUCT: BASE_URL + PATHS.MARKET_PLACE + '/viewMySingleProduct/',
        EDIT_PRODUCT: BASE_URL + PATHS.MARKET_PLACE + '/editMyProduct/',
        LIST_COMPANIES: BASE_URL + PATHS.COMMON + '/searchCompanies',
        GET_ALL_PRODUCTS: BASE_URL + PATHS.MARKET_PLACE + '/listPublicProducts',
        GET_ALL_PRODUCTS_COUNT: BASE_URL + PATHS.MARKET_PLACE + '/listPublicProductsCount',
        GET_ALL_PRODUCT_COUNTRIES: BASE_URL + PATHS.MARKET_PLACE + '/getProductCountries',
        GET_ALL_PRODUCT_STATES: BASE_URL + PATHS.MARKET_PLACE + '/getProductStates',
        GET_ALL_PRODUCT_CITIES: BASE_URL + PATHS.MARKET_PLACE + '/getProductCities',
        ADD_VIEWS_COUNT_ON_CLICK: BASE_URL + PATHS.MARKET_PLACE + '/addViewsCountOnClick',
        FETCH_MARKETPLACE_REPORTING_DASHBOARD_DETAILS: BASE_URL + PATHS.REPORTS + '/fetchReportingDashboardDetails',
        GET_MARKETPLACE_PACKAGE_DESCRIPTION: BASE_URL + PATHS.MARKET_PLACE + '/getPackageDescription',
        PUBLISH_PRODUCT: BASE_URL + PATHS.MARKET_PLACE + '/publishProduct',

        // News Feed Links. ADMIN Panel
        GET_NEWSFEED_TEXT_LINKS: BASE_URL + PATHS.FEED_TEXT_LINKS + '/fetchNewsFeedTextLinks',
        GET_NEWSFEED_TEXT_LINKS_COUNT: BASE_URL + PATHS.FEED_TEXT_LINKS + '/getNewsFeedTextLinkCount',

        ADD_NEWSFEED_TEXT_LINK: BASE_URL + PATHS.FEED_TEXT_LINKS + '/addTextLink',
        EDIT_NEWSFEED_TEXT_LINK: BASE_URL + PATHS.FEED_TEXT_LINKS + '/updateTextLink',
        ADD_CLICK_COUNT_ON_COMPANY_URL: BASE_URL + PATHS.MARKET_PLACE + '/visitCompanyUrl/',

        // JOB BOARD
        CREATE_JOB_SEEKER: BASE_URL + PATHS.JOB_SEEKER + '/create',
        VALIDATE_EMPLOYER: BASE_URL + PATHS.EMPLOYER + '/validateEmployer/',
        REGISTER_LEAFWIRE_EMPLOYER: BASE_URL + PATHS.EMPLOYER + '/create',
        CREATE_JBHQ_EMPLOYER: BASE_URL + PATHS.EMPLOYER + '/createEmployer',
        CREATE_JBHQ_SUBSCRIPTION: BASE_URL + PATHS.JOBS + '/createSubscription',
        CANCEL_JBHQ_SUBSCRIPTION: BASE_URL + PATHS.JOBS + '/cancelSubscription',
        REACTIVATE_JBHQ_SUBSCRIPTION: BASE_URL + PATHS.JOBS + '/reactivateSubscription',

        // Trending Topics. ADMIN Panel
        GET_TRENDING_TOPICS: BASE_URL + PATHS.TRENDING_TOPICS + '/listTrendingTopics',
        ADD_TRENDING_TOPIC: BASE_URL + PATHS.TRENDING_TOPICS + '/addTrendingTopic',
        EDIT_TRENDING_TOPIC: BASE_URL + PATHS.TRENDING_TOPICS + '/updateTrendingTopic/',
        ARCHIVE_TRENDING_TOPIC: BASE_URL + PATHS.TRENDING_TOPICS + '/archiveTrendingTopics/',
        SET_WIDGET_TITLE: BASE_URL + PATHS.TRENDING_TOPICS + '/changeTrendingTopicWidgetTitle',
        SET_WIDGET_VISIBILITY: BASE_URL + PATHS.TRENDING_TOPICS + '/changeTrendingTopicWidgetVisibility',
        GET_TRENDING_TOPIC_STATS: BASE_URL + PATHS.REPORTS + '/fetchTrendingTopicStatistics',
        GET_TRENDING_TOPIC_STATS_COUNT: BASE_URL + PATHS.REPORTS + '/fetchTrendingTopicStatisticsCount',
        SET_TRENDING_TOPIC_PRIORITY: BASE_URL + PATHS.TRENDING_TOPICS + '/setTrendingTopicPriority',

        // Trending Topics. Widget
        LIST_WIDGET_TRENDING_TOPICS: BASE_URL + PATHS.TRENDING_TOPICS + '/listTrendingTopicsForWidget',
        GET_WIDGET_TITLE: BASE_URL + PATHS.TRENDING_TOPICS + '/getWidgetTitle',
        GET_WIDGET_VISIBILITY: BASE_URL + PATHS.TRENDING_TOPICS + '/getTrendingTopicWidgetVisibility',
        LIST_WIDGET_TRENDING_TOPICS_STATS : BASE_URL + PATHS.REPORTS + '/fetchTrendingTopicStatisticsForWidget',

        // Industry Events. ADMIN Panel
        GET_INDUSTRY_EVENTS: BASE_URL + PATHS.INDUSTRY_EVENTS + '/listIndustryEvents',
        ADD_INDUSTRY_EVENT: BASE_URL + PATHS.INDUSTRY_EVENTS + '/addIndustryEvent',
        EDIT_INDUSTRY_EVENT: BASE_URL + PATHS.INDUSTRY_EVENTS + '/updateIndustryEvent/',
        ARCHIVE_INDUSTRY_EVENT: BASE_URL + PATHS.INDUSTRY_EVENTS + '/archiveIndustryEvent/',
        SET_INDUSTRY_EVENT_WIDGET_TITLE: BASE_URL + PATHS.INDUSTRY_EVENTS + '/changeTrendingTopicWidgetTitle',
        SET_INDUSTRY_EVENT_WIDGET_VISIBILITY: BASE_URL + PATHS.INDUSTRY_EVENTS + '/changeIndustryEventWidgetVisibility',
        GET_INDUSTRY_EVENT_STATS: BASE_URL + PATHS.REPORTS + '/fetchIndustryEventsStatistics',
        GET_INDUSTRY_EVENT_STATS_COUNT: BASE_URL + PATHS.REPORTS + '/fetchIndustryEventStatisticsCount',
        SET_INDUSTRY_EVENT_PRIORITY: BASE_URL + PATHS.INDUSTRY_EVENTS + '/setIndustryEventPriority',

        // Industry Event. Widget
        LIST_WIDGET_INDUSTRY_EVENTS: BASE_URL + PATHS.INDUSTRY_EVENTS + '/listIndustryEventsForWidget',
        GET_INDUSTRY_EVENT_WIDGET_TITLE: BASE_URL + PATHS.INDUSTRY_EVENTS + '/getWidgetTitle',
        GET_INDUSTRY_EVENT_WIDGET_VISIBILITY: BASE_URL + PATHS.INDUSTRY_EVENTS + '/getIndustryEventWidgetVisibility',

        // CUSTOM TEXT
        SAVE_PROMO_TEXT: BASE_URL + PATHS.MARKET_PLACE + '/savePromoText',
        GET_PROMO_TEXT_MP_JB: BASE_URL + PATHS.MARKET_PLACE + '/getAllPromoText',

        RESET_TOP_NAV_COUNT: BASE_URL + PATHS.SYSTEM + '/decreaseTopNavCount',
        GET_NOTIFIED_CONV_ID_SET: BASE_URL + PATHS.SYSTEM + '/getNotifiedConversationIds',
        GET_NOTIFIED_CHAT_ID_SET: BASE_URL + PATHS.SYSTEM + '/getNotifiedChatIds',
        GET_UNSEEN_TOP_NAV_CONV_ID_SET: BASE_URL + PATHS.SYSTEM + '/getUnseenTopNavConversationIds',
        GET_UNSEEN_CONNECTION_REQUESTERS_ID_SET: BASE_URL + PATHS.SYSTEM + '/getUnseenConnectionRequesters',
        RELOAD_COUNTERS: BASE_URL + PATHS.SYSTEM + '/reloadUserNotificationCounters',

        // TOOL TIPS
        GET_TOOL_TIPS: BASE_URL + PATHS.COMMON + '/getToolTip',
        SET_TOOL_TIPS: BASE_URL + PATHS.COMMON + '/setToolTip',
        GENERATE_TT_IMAGE_PUT_URL: BASE_URL + PATHS.COMMON + '/generateTTPutURL',

        // GROUP
        GENERATE_BACKGROUND_IMAGE_UPLOAD_LINK: BASE_URL + PATHS.GROUPS + '/generateTTPutURL',
        SET_GROUP_BACKGROUND_IMAGE: BASE_URL + PATHS.GROUPS + '/setGroupLandingPageImg',
        GET_GROUP_BACKGROUND_IMAGE: BASE_URL + PATHS.GROUPS + '/getGroupLandingPageImg',
        ADD_GROUP_POST: BASE_URL + PATHS.GROUPS + '/createPost/',
        FETCH_GROUPS: BASE_URL + PATHS.GROUPS + '/fetchGroups',
        FETCH_GROUP_DETAILS: BASE_URL + PATHS.GROUPS + '/fetchGroupDetails',
        FETCH_GROUP_FEED: BASE_URL + PATHS.GROUPS + '/fetchGroupPosts',
        GROUP_FEED_COUNT: BASE_URL + PATHS.GROUPS + '/groupNewsFeedCount',
        GROUP_ADD_LIKE: BASE_URL + PATHS.GROUPS + '/postLikeStatus/',
        GROUP_ADD_COMMENT: BASE_URL + PATHS.GROUPS + '/createPostComment/',
        JOIN_GROUP: BASE_URL + PATHS.GROUPS + '/joinGroup/',
        LEAVE_GROUP: BASE_URL + PATHS.GROUPS + '/leaveGroup/',
        GET_GROUP_STATS: BASE_URL + PATHS.GROUPS + '/fetchOverallGroupStats',
        FILTER_GROUP_STATS: BASE_URL + PATHS.GROUPS + '/filterGroupStats',
        ARCHIVE_GROUP_POST: BASE_URL + PATHS.GROUPS + '/archivePost/',
        FETCH_TRENDING_GROUP_POST: BASE_URL + PATHS.GROUPS + '/fetchTrendingGroupPost',
        LIST_GROUPS: BASE_URL + PATHS.GROUPS + '/',
        UPDATE_GROUPS: BASE_URL + PATHS.GROUPS + '/',
        GROUP_COUNT: BASE_URL + PATHS.GROUPS + '/groupsCount',
        GET_MUTUAL_GROUP_MEMBERS: BASE_URL + PATHS.GROUPS + '/getMutualMembers',

        // Jobs Widget
        LIST_JOBS_FOR_JOB_WIDGET: BASE_URL + PATHS.JOBS + '/listJobsForWidget',

        // Press
        GENERATE_PRESS_PUT_URL: BASE_URL + PATHS.PRESS + '/generatePutURL',
        SET_PRESS_DETAILS: BASE_URL + PATHS.PRESS + '/setPressDetails',
        GET_PRESS_DETAILS: BASE_URL + PATHS.PRESS + '/getPressDetails',
        GET_PRESS_COMPANY_IMAGES: BASE_URL + PATHS.PRESS + '/listPressCompanyImages',
        CREATE_PRESS_RELEASE: BASE_URL + PATHS.PRESS + '/createPressReleases',
        CREATE_PRESS_NEWS: BASE_URL + PATHS.PRESS + '/createPressNews',
        CREATE_PRESS_ON_AIR: BASE_URL + PATHS.PRESS + '/createPressOnAir',
        CREATE_PRESS_COMPANY: BASE_URL + PATHS.PRESS + '/createPressCompanyImage',
        CREATE_PRESS_QA: BASE_URL + PATHS.PRESS + '/createPressQandA',
        UPDATE_PRESS_RELEASE: BASE_URL + PATHS.PRESS + '/updatePressRelease/',
        UPDATE_PRESS_NEWS: BASE_URL + PATHS.PRESS + '/updatePressNews/',
        UPDATE_PRESS_COMPANY: BASE_URL + PATHS.PRESS + '/updatePressCompanyImage/',
        UPDATE_PRESS_ON_AIR: BASE_URL + PATHS.PRESS + '/updatePressOnAir/',
        UPDATE_PRESS_QA: BASE_URL + PATHS.PRESS + '/updatePressQA/',
        DELETE_PRESS_RECORD: BASE_URL + PATHS.PRESS + '/delete/',
        LIST_ALL_PRESS: BASE_URL + PATHS.PRESS + '/listAllPress/',
        LIST_PRESS_RELEASES: BASE_URL + PATHS.PRESS + '/listPressReleases/',
        LIST_PRESS_NEWS: BASE_URL + PATHS.PRESS + '/listPressNews/',
        LIST_PRESS_ON_AIR: BASE_URL + PATHS.PRESS + '/listPressOnAir/',
        LIST_PRESS_ON_QA: BASE_URL + PATHS.PRESS + '/listPressOnQA/',
        GET_PRESS_COUNT: BASE_URL + PATHS.PRESS + '/pressCount/',
        LIST_PRESS_QA: BASE_URL + PATHS.PRESS + '/listPressQA/',
        INCREASE_PRESS_VIEW_COUNT: BASE_URL + PATHS.PRESS + '/increasePressView',

        // Survey
        SUBMIT_SURVEY: BASE_URL + PATHS.ADMIN_REPORTS + '/recordJobSurvey',
        SUBMIT_CANDIDATE_SURVEY_OPEN: BASE_URL + PATHS.CANDIDATE_SURVEY + '/record-candidate-surveyOpen',
        SUBMIT_CANDIDATE_SURVEY: BASE_URL + PATHS.CANDIDATE_SURVEY + '/record-candidate-survey',
        UPLOAD_CANDIDATE_FILES: BASE_URL + PATHS.CANDIDATE_SURVEY + '/generate-upload-url',
        GET_CANDIDATE_SURVEY_DATA: BASE_URL + PATHS.REPORTS + '/fetchCandidateSurveyStats',

        // FAQ
        FAQ: BASE_URL + PATHS.FAQ + '/',

        // Events
        GET_EVENT_TAGS: BASE_URL + PATHS.EVENTS + '/listEventTags',
        CREATE_LOCAL_EVENT: BASE_URL + PATHS.EVENTS + '/createLocalEvent/',
        CREATE_GLOBAL_EVENT: BASE_URL + PATHS.EVENTS + '/createGlobalEvent/',
        CREATE_EVENT: BASE_URL + PATHS.EVENTS + '/createEventV2',
        EDIT_EVENT: BASE_URL + PATHS.EVENTS + '/editEventV2/',
        ADMIN_EDIT_EVENT: BASE_URL + PATHS.EVENTS + '/editAdminSingleEvent/',
        GET_SINGLE_EVENT: BASE_URL + PATHS.EVENTS + '/getSingleEvent/',
        GET_ADMIN_SINGLE_EVENT: BASE_URL + PATHS.EVENTS + '/getAdminSingleEvent/',
        PUBLISH_EVENT: BASE_URL + PATHS.EVENTS + '/publishEventV2',
        ADMIN_PUBLISH_EVENT: BASE_URL + PATHS.EVENTS + '/adminSingleEventPublish',
        GET_USER_EVENTS_COUNT: BASE_URL + PATHS.EVENTS + '/listUserEventsCount',
        GET_USER_EVENTS: BASE_URL + PATHS.EVENTS + '/listUserEvents',
        GET_OTHER_EVENTS_COUNT: BASE_URL + PATHS.EVENTS + '/listOtherActiveEventsCount',
        GET_OTHER_EVENTS: BASE_URL + PATHS.EVENTS + '/listOtherAciveEvents',
        FETCH_EVENT_SUBSCRIPTIONS: BASE_URL + PATHS.PAYMENTS + '/listEventSubscriptionsCreatedByParticularUser',
        UNPUBLISH_EVENT: BASE_URL + PATHS.EVENTS + '/unPublishEvent',
        ADMIN_UNPUBLISH_EVENT: BASE_URL + PATHS.EVENTS + '/adminUnPublishEvent',
        GET_FEATURED_EVENTS_COUNT : BASE_URL + PATHS.EVENTS + '/getFeaturedEventCount',
        GET_LOCAL_EVENTS: BASE_URL + PATHS.EVENTS + '/publishedList',
        GET_GEO_LOCATION_EVENTS: BASE_URL + PATHS.EVENTS + '/geoLocationBasedPublishedList',
        GET_LOCAL_EVENTS_COUNT: BASE_URL + PATHS.EVENTS + '/publishedListCount',
        GET_FEATURED_EVENTS: BASE_URL + PATHS.EVENTS + '/publishedFeautredList',
        GET_FEATURED_EVENTS_V2: BASE_URL + PATHS.EVENTS + '/publishedFeaturedListV2',
        GET_PUBLISHED_EVENTS: BASE_URL + PATHS.EVENTS + '/publishedListV2',
        GET_PUBLISHED_EVENTS_COUNT: BASE_URL + PATHS.EVENTS + '/publishedListCountV2',
        CREATE_WEBINAR: BASE_URL + PATHS.EVENTS + '/createWebinar',
        EDIT_WEBINAR: BASE_URL + PATHS.EVENTS + '/editWebinar/',
        PUBLISH_WEBINAR: BASE_URL + PATHS.EVENTS + '/publishWebinar',
        ADD_EVENT_TAG: BASE_URL + PATHS.EVENTS + '/createEventTag',
        UPDATE_EVENT_TAG: BASE_URL + PATHS.EVENTS + '/updateEventTag',
        DELETE_EVENT_TAG: BASE_URL + PATHS.EVENTS + '/deleteEventTag/',
        UPDATE_EVENT_INTERESTED: BASE_URL + PATHS.EVENTS + '/updateInterestedUsers/',
        SET_EVENT_NOTIFICATION: BASE_URL + PATHS.EVENTS + '/setEventNotification/',
        FETCH_EVENT_ATTENDEES: BASE_URL + PATHS.EVENTS + '/fetchEventAttendees/',

        // Custom Landing
        ADD_LANDING_PAGE: BASE_URL + PATHS.LANDING_PAGES + '/',
        GENERATE_LANDING_PAGE_IMAGE_PUT_URL: BASE_URL + PATHS.LANDING_PAGES + '/generateLandingPageImagePutURL',
        FETCH_LANDING_PAGES: BASE_URL + PATHS.LANDING_PAGES + '/',
        GET_LANDING_PAGE_COUNT: BASE_URL + PATHS.LANDING_PAGES + '/getLandingPageCount',
        UPDATE_LANDING_PAGE: BASE_URL + PATHS.LANDING_PAGES + '/',
        GET_CUSTOM_LANDING_PAGE: BASE_URL + PATHS.LANDING_PAGES + '/',
        CREATE_TRACKING_CUSTOM_LANDING_PAGE: BASE_URL + PATHS.LANDING_PAGES + '/createTracking',
        UPDATE_TRACKING_CUSTOM_LANDING_PAGE: BASE_URL + PATHS.LANDING_PAGES + '/updateTracking',

        // Training
        GET_ALL_TRAININGS: BASE_URL + PATHS.TRAINING + '/listTraining',
        CREATE_TRAINING: BASE_URL + PATHS.TRAINING + '/createTraining',
        UPDATE_TRAINING: BASE_URL + PATHS.TRAINING + '/updateTraining/',
        GENERATE_GET_TRAINING_URL: BASE_URL + PATHS.TRAINING + '/generate_get_training_url',
        GENERATE_PUT_TRAINING_URL: BASE_URL + PATHS.TRAINING + '/generate_put_training_url',
        GET_ACTIVE_TRAININGS: BASE_URL + PATHS.TRAINING + '/getActiveTrainings/',
        GET_ACTIVE_TRAININGS_COUNT: BASE_URL + PATHS.TRAINING + '/getActiveTrainingCount',

        TRACK_POST_SHARE: BASE_URL + PATHS.COMMON + '/trackPostShare',

        // Open Newsfeed Tracking
        CREATE_TRACKING_OPEN_NEWSFEED: BASE_URL + PATHS.REPORTS + '/createOpenNewsfeedTracking',
        UPDATE_TRACKING_OPEN_NEWSFEED: BASE_URL + PATHS.REPORTS + '/updateOpenNewsfeedTracking',
        GYN_CONNECT_CLICK_TRACKING: BASE_URL + PATHS.COMMON + '/growYourNetworkConnectionsConnectClickTracking',

        // Post Share Reporting. Admin Panel
        FETCH_POST_SHARE_STATS: BASE_URL + PATHS.REPORTS + '/fetchPostShareStatistics',
        FETCH_POST_SHARE_VIEW_STATS: BASE_URL + PATHS.REPORTS + '/fetchPostShareViewsStatistics',
        FETCH_NEWS_FEED_VIEW_STATS: BASE_URL + PATHS.REPORTS + '/fetchNewsFeedViewsStatistics',

        // Email Reporting
        FETCH_ADMIN_EMAIL_STATS: BASE_URL + PATHS.REPORTS + '/emailstats',
        FETCH_ADMIN_EMAIL_STATS_COUNT: BASE_URL + PATHS.REPORTS + '/emailStatsCount',
        
        // TRACKING
        TRACK_NEWSFEED_VIEW: BASE_URL + PATHS.TRACKING + '/trackNewsFeedView',

        // Auto Connection Request Manager
        SEARCH_USER_BY_EMAIL: BASE_URL + PATHS.AUTO_CONNECTION + '/searchSystemUsersByEmail',
        ADD_AUTO_CONNECTION_SENDER: BASE_URL + PATHS.AUTO_CONNECTION + '/addAutoConnectionSender',
        EDIT_AUTO_CONNECTION_SENDER: BASE_URL + PATHS.AUTO_CONNECTION + '/updateAutoConnectionSender/',
        FETCH_AUTO_CONNECTION_SENDERS: BASE_URL + PATHS.AUTO_CONNECTION + '/fetchAutoConnectionSenders',
        FETCH_AUTO_CONNECTION_SENDERS_COUNT: BASE_URL + PATHS.AUTO_CONNECTION + '/fetchAutoConnectionSendersCount',

        // Newsfeed Banner Ad
        GET_NEWSFEED_BANNER_AD: BASE_URL + PATHS.BANNER_AD + '/fetchActiveBanner',
        TRACK_NEWSFEED_BANNER_AD: BASE_URL + PATHS.TRACKING + '/trackNewsFeedBannerAdView',
        GENERATE_BANNER_AD_IMAGE_PUT_URL: BASE_URL + PATHS.BANNER_AD + '/generateNewsfeedBannerImagePutURL',
        CREATE_NEWSFEED_BANNER_AD: BASE_URL + PATHS.BANNER_AD + '/createNewsfeedBanner',
        GET_NEWSFEED_BANNER_STATISTICS: BASE_URL + PATHS.REPORTS + '/newsfeedBannerStatistics',
        GET_NEWSFEED_BANNER_ADS: BASE_URL + PATHS.BANNER_AD + '/fetchBannerAds',
        TURN_ON_BANNER_AD_STATUS: BASE_URL + PATHS.BANNER_AD + '/activateBannerAd/',
        TURN_OFF_BANNER_AD_STATUS: BASE_URL + PATHS.BANNER_AD + '/deactivateBannerAd/',
        ARCHIEVE_NEWSFEED_BANNER_AD: BASE_URL + PATHS.BANNER_AD + '/archieveNewsFeedBannerAd/',
        EDIT_NEWSFEED_BANNER_AD: BASE_URL + PATHS.BANNER_AD + '/updateNewsFeedBannerAd/',
        GET_NEWSFEED_BANNER_ADS_COUNT: BASE_URL + PATHS.BANNER_AD + '/fetchBannerAdsCount',
        VALIDATE_BANNER_AD: BASE_URL + PATHS.BANNER_AD + '/validateBannerAd/',
        UPDATE_BANNER_AD_STATUS : BASE_URL + PATHS.BANNER_AD + '/updateBannerAdStatus',

        // Testimonials
        GET_TESTIMONIAL_DATA: BASE_URL + PATHS.TESTIMONIALS + '/fetchTestimonials',
        GET_PUBLIC_TESTIMONIAL_DATA: BASE_URL + PATHS.TESTIMONIALS + '/fetchPublicTestimonials',
        GET_TESTIMONIAL_DATA_COUNT: BASE_URL + PATHS.TESTIMONIALS + '/testimonialsCount',
        GENERATE_TESTIMONIAL_PUT_URL: BASE_URL + PATHS.TESTIMONIALS + '/generateTestimonialPutURL',
        CREATE_TESTIMONIAL_DATA: BASE_URL + PATHS.TESTIMONIALS + '/',
        UPDATE_TESTIMONIAL_DATA: BASE_URL + PATHS.TESTIMONIALS + '/',
        DELETE_TESTIMONIAL_DATA: BASE_URL + PATHS.TESTIMONIALS + '/',

        // Job Title Manager
        GET_ALL_JOB_TITLES: BASE_URL + PATHS.USERS + PATHS.JOB_TITLES + '/',
        CREATE_JOB_TITLE: BASE_URL + PATHS.USERS + PATHS.JOB_TITLES_ADMIN + '/',
        UPDATE_JOB_TITLE: BASE_URL + PATHS.USERS + PATHS.JOB_TITLES_ADMIN + '/',
        SEARCH_JOB_TITLE: BASE_URL + PATHS.USERS + PATHS.JOB_TITLES_ADMIN + '/searchJobTitle',
        SEARCH_JOB_TITLE_COUNT: BASE_URL + PATHS.USERS + PATHS.JOB_TITLES_ADMIN + '/searchJobTitleCount',
        GET_JOB_TITLE_COUNT: BASE_URL + PATHS.USERS + PATHS.JOB_TITLES_ADMIN + '/jobTitleCount',

        // Job Title Tags
        GET_ALL_JOB_TITLE_TAGS: BASE_URL + PATHS.USERS + PATHS.JOB_TITLE_TAGS + '/',
        SEARCH_JOB_TITLE_TAGS: BASE_URL + PATHS.USERS + PATHS.JOB_TITLE_TAGS + '/searchJobTitleTags',

        // WARNING DISCLAIMER TERMS
        CREATE_WARNING_DISCLAIMER_TERM: BASE_URL + PATHS.WARNING_DISCLAIMER + '/createWarningDisclaimerTerm',
        GET_WARNING_DISCLAIMER_TERMS: BASE_URL + PATHS.WARNING_DISCLAIMER + '/fetchWarningDisclaimerTerms',
        TURN_ON_TERM_STATUS: BASE_URL + PATHS.WARNING_DISCLAIMER + '/activateWarningDisclaimerTerm/',
        TURN_OFF_TERM_STATUS: BASE_URL + PATHS.WARNING_DISCLAIMER + '/deactivateWarningDisclaimerTerm/',
        ARCHIEVE_TERM: BASE_URL + PATHS.WARNING_DISCLAIMER + '/archieveDisclaimerTerm/',
        EDIT_WARNING_DISCLAIMER_TERM: BASE_URL + PATHS.WARNING_DISCLAIMER + '/updateWarningDisclaimerTerm/',
        
        GET_WARNING_DISCLAIMER_TEXT: BASE_URL + PATHS.WARNING_DISCLAIMER + '/getWarningDisclaimerText',
        SET_WARNING_DISCLAIMER_TEXT: BASE_URL + PATHS.WARNING_DISCLAIMER + '/changeWarningDisclaimerText',
        
        // PRO routes
        FIND_PRO_INVITATIONS: BASE_URL + PATHS.PRO + '/find-invitations',
        FIND_USER_TO_SEND_PRO_INVITATION: BASE_URL + PATHS.PRO + '/find-users',
        SEND_PRO_INVITATION: BASE_URL + PATHS.PRO + '/send-invitation',


        //PRO
        PRO_INVITATION_RES: BASE_URL + PATHS.PRO + '/invitation-response',


    },
    CLIENT_BROWSER: (() => {
        var ua = navigator.userAgent, tem,
            M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
        return {
            browser: M.length > 0 ? M[0].toLowerCase() : null,
            version: M.length > 1 ? M[1] : null
        };
    })(),
    BROWSER_SUPPORT: {
        CHROME: {
            NAME: 'chrome',
            VERSION: 71
        },
        FIREFOX: {
            NAME: 'firefox',
            VERSION: 64
        },
        SAFARI: {
            NAME: 'safari',
            VERSION: 12
        },
        EDGE: {
            NAME: 'edge',
            VERSION: 18
        },
        OPERA: {
            NAME: 'opera',
            VERSION: 57
        },
        INTERNET_EXPLORA: {
            NAME: 'internet explora',
            VERSION: 11
        }
    },
    VIDEO_PLAYER_URL: process.env.REACT_APP_VIDEO_PLAYER_HLS_URL,
    VIDEO_PLAYER_ORIGINAL_URL: process.env.REACT_APP_VIDEO_PLAYER_ORIGINAL_URL,

};
