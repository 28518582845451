import axios from 'axios';
import { v1 as uuidv1 } from 'uuid';
import base64 from 'base-64';
import AuthTypes from '../types/authentication';
import InvestorTypes from '../types/investor';
import {
    ALERT,
    POP_ALERT,
    DOMAINS,
    MEMBER_TYPE,
    REGISTRATION_STEP,
    JOB_TITLES_LIMIT,
} from '../types/common';
import { showAlert, showPopupAlert } from './alert';
import WelcomeMessageTypes from '../types/welcomeMessage';
import { ROUTES } from '../config';
import { imageExists } from '../libs/image';
import { isURL } from '../libs/validation';
import MessageTypes from '../types/message';
import * as CompanyActions from './company';
import { getPublicProfileImagesList } from './profile';
import { signInValidator, errorHandler } from '../libs/commonFunctions';
import {
    GETTING_NOTIFIED_CONV_ID_SET,
    GETTING_NOTIFIED_CONV_ID_SET_SUCCESS,
    GETTING_NOTIFIED_CONV_ID_SET_FAIL,
    GETTING_NOTIFIED_CHAT_ID_SET,
    GETTING_NOTIFIED_CHAT_ID_SET_SUCCESS,
    GETTING_NOTIFIED_CHAT_ID_SET_FAIL,
    GETTING_UNSEEN_TOP_NAV_CONV_ID_SET,
    GETTING_UNSEEN_TOP_NAV_CONV_ID_SET_SUCCESS,
    GETTING_UNSEEN_TOP_NAV_CONV_ID_SET_FAIL
} from '../types/message-v2';
import {
    CLEAR_NEWSFEED_REACTED_DATA,
    EMPTY_PENDING_POST
} from '../types/newsFeed';
import { notifyResettingTopNavNotifications } from './../libs/socket';
import { get, post, put, del} from "../libs/apiConnector";
import moment from 'moment';

var md5 = require("md5");

export function setSessionID() {
    return function (dispatch) {
        try {
            if(!localStorage.getItem('LF_SESSION_ID')){
                    const v1options = {
                        msecs: new Date().getTime(), // RFC "timestamp" field (Number of milliseconds, unix epoch)
                    };
                    const sessionID = uuidv1(v1options);
                    localStorage.setItem('LF_SESSION_ID', sessionID);
            }
            dispatch({ type: AuthTypes.SESSION });
        } catch (ex) {
            return false
        }
    }
}

export function clearSessionID() {
    return function (dispatch) {
        localStorage.removeItem('LF_SESSION_ID');
        dispatch({ type: AuthTypes.CLEAR_SESSION });
        dispatch(setSessionID());
    }
}

export function getProfileImageUrl(imageName, type) { // type 0-Large Image, type 1-> Thumbnail
    return new Promise((resolve, reject) => {
        get(`${ROUTES.IMAGE_PATH}/${imageName}/${type}`)
            .then(response => {
                if (response.status === 200) {
                    if (response.data && isURL(response.data, { protocols: ['https'] }, DOMAINS.S3)) {
                        resolve(response.data);
                    } else {
                        reject(false);
                    }
                } else {
                    reject(false);
                }
            })
            .catch(() => {
                reject(false);
            });
    });
}

export function signUp(userdata) {
    return function (dispatch) {
        dispatch({ type: AuthTypes.ROUTE_ACCESS });
        dispatch({ type: AuthTypes.INSERTING_USER, payload: true });
        let updatedUserData = {...userdata}; //refresh token changes
        updatedUserData.utmSource = updatedUserData.utm_source ? updatedUserData.utm_source : '';
        updatedUserData.utmMedium = updatedUserData.utm_medium ? updatedUserData.utm_medium : '';
        updatedUserData.utmCampaign = updatedUserData.utm_campaign ? updatedUserData.utm_campaign : '';
        if(updatedUserData.utm_source)  delete updatedUserData.utm_source;
        if(updatedUserData.utm_medium)  delete updatedUserData.utm_medium;
        if(updatedUserData.utm_campaign)  delete updatedUserData.utm_campaign;
        post(`${ROUTES.SIGNUP}`, updatedUserData)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch(setSessionID());
                    let user = response.data;
                    if (user.data && user.data.MEMBER_TYPE === MEMBER_TYPE.PROFESSIONAL) user.data.IS_FIRST_TIME = true;

                    localStorage.setItem('userId', response.data.data._id);
                    localStorage.setItem('token', response.data.data.TOKEN);
                    localStorage.setItem('unsigned_profile_image', response.data.data.PHOTO);

                    if(response.data && response.data.data && response.data.data.CREATEDAT){ // set personalNewsfeedAvailable value in local storage
                        const newsfeedAvalability = moment.duration(moment().diff(moment(response.data.data.CREATEDAT))).asMinutes() > 20
                        localStorage.setItem('personalNewsfeedAvailable', newsfeedAvalability)
                        dispatch({type: AuthTypes.UPDATE_USER_NEWSFEED_STATUS, payload: newsfeedAvalability})
                        dispatch(checkPersonalNewsfeedStatus())
                    }

                    dispatch({ type: AuthTypes.SIGNUP_SUCCESS, payload: response.data });
                    dispatch({ type: AuthTypes.CLEAR_ACTION });
                    if (user.data.MEMBER_TYPE === MEMBER_TYPE.PROFESSIONAL || user.data.MEMBER_TYPE === MEMBER_TYPE.INVESTOR) {
                        dispatch(fetchToolTip())
                        updateOpenNewsfeedTracking();
                        dispatch({ type: AuthTypes.ROUTE_ACCESS_SUCCESS, payload: response.data.data });
                        // showAlert(dispatch, ALERT.SUCCESS, response.data.message);
                    }

                    if (document.referrer !== '') {
                        window.document.__defineGetter__('referrer', () => { return window.origin });   
                    }
                } else { // Internal server error
                    dispatch({ type: AuthTypes.SIGNUP_FAIL, payload: response.data });
                    showAlert(dispatch, ALERT.ERROR, response.data.message);
                    dispatch({ type: AuthTypes.CLEAR_ACTION });
                }
            }).catch((error) => {
                dispatch({ type: AuthTypes.SIGNUP_FAIL, payload: error });
                errorHandler(dispatch, error);
                dispatch({ type: AuthTypes.CLEAR_ACTION });
            });
    }
}

export function signUpStepTwo (userdata, jobHistory) {
    return function (dispatch) {
        dispatch({ type: AuthTypes.SIGNUP_STEP_TWO });
        let updatedUserdata = {...userdata};
        updatedUserdata.memberType = parseInt(updatedUserdata.memberType);
        post(`${ROUTES.SIGNUP_STEP_2}`, updatedUserdata)
            .then(response => {
                if (response.data.code === 200) {
                    let user = response.data;
                    // update user token if user type changed
                    if(user.data && user.data.MEMBER_TYPE === MEMBER_TYPE.INVESTOR) localStorage.setItem('token', user.data.TOKEN);
                    if (user.data && user.data.MEMBER_TYPE === MEMBER_TYPE.PROFESSIONAL) user.data.IS_FIRST_TIME = true;

                    dispatch({ type: AuthTypes.SIGNUP_STEP_TWO_SUCCESS, payload: response.data, memberType: parseInt(userdata.memberType) });
                    dispatch({ type: AuthTypes.CLEAR_ACTION });
                    if(jobHistory){
                        dispatch(addEmployeeHistory(localStorage.getItem('userId'), jobHistory));
                    } else if (user.data && user.data.MEMBER_TYPE !== MEMBER_TYPE.INVESTOR) {
                        dispatch(initlogin(localStorage.getItem('token')));
                    }
                } else { // Internal server error
                    dispatch({ type: AuthTypes.SIGNUP_STEP_TWO_FAIL, payload: response.data });
                    showAlert(dispatch, ALERT.ERROR, response.data.message);
                    dispatch({ type: AuthTypes.CLEAR_ACTION });
                }
            }).catch((exception) => {
            dispatch({type: AuthTypes.SIGNUP_STEP_TWO_FAIL, payload: exception});
            // showAlert(dispatch, ALERT.UNAVAILABLE, ERROR_MESSAGES.EXCEPTION_MESSAGE, 5000); removed to handle from webhelper.js
            dispatch({type: AuthTypes.CLEAR_ACTION});
        });
    }
}

export const getJobTitles = async (search) => {
    try {
        const res = await get(`${ROUTES.GET_JOB_TITLES}?searchTerm=${search}&limit=${JOB_TITLES_LIMIT}`);
        if (res && res.data && res.data.code === 200) {
            return {success: true, data: res.data.data}
        } else {
            return {success: false, data: []}
        }
    } catch (ex) {
        return {success: false, data: []}
    }
};

export function signupAsProfessional() {
    return function (dispatch) {
        get(ROUTES.SIGNUP_AS_PROFESSIONAL)
            .then(response => {
                if (response.data.code === 200) {
                    let user = response.data;
                    user.data.IS_FIRST_TIME = true;
                    dispatch(fetchToolTip());
                    // update user token if user type changed
                    if(user.data && user.data.MEMBER_TYPE === MEMBER_TYPE.PROFESSIONAL) localStorage.setItem('token', user.data.TOKEN);
                    dispatch({ type: AuthTypes.SIGNUP_SUCCESS, payload: response.data });
                    dispatch({ type: AuthTypes.SIGNUP_AS_PROFESSIONAL });
                    dispatch({ type: AuthTypes.ROUTE_ACCESS_SUCCESS, payload: response.data.data });
                    // showAlert(dispatch, ALERT.SUCCESS, response.data.message);
                } else { // Internal server error
                    dispatch({ type: AuthTypes.SIGNUP_FAIL, payload: response.data });
                    showAlert(dispatch, ALERT.ERROR, response.data.message);
                    dispatch({ type: AuthTypes.CLEAR_ACTION });
                }
            }).catch((error) => {
                dispatch({ type: AuthTypes.SIGNUP_FAIL, payload: error });
                errorHandler(dispatch, error);
                dispatch({ type: AuthTypes.CLEAR_ACTION });
            });
    }
}

export function dispatchProfileImageUrl(imgUrl) {
    return function (dispatch) {
        dispatch({ type: AuthTypes.GETTING_PROFILE_IMAGE_SUCCESS, payload: imgUrl });
    }
}

export function signIn(user) {
    return function (dispatch) {

        const signIn = signInValidator(user.email, user.password);

        if (!signIn.status) {
            showAlert(dispatch, ALERT.ERROR, signIn.msg);
        } else {
            let userdata = {
                email: user.email,
                password: md5(user.password)
            };
            dispatch({ type: AuthTypes.FETCHING, payload: true });
            dispatch({ type: AuthTypes.ROUTE_ACCESS });
            post(`${ROUTES.SIGNIN}`, userdata)
                .then(response => {
                    if (response.data.code === 200) {
                        dispatch(setSessionID());
                        if (response.data.data.MEMBER_TYPE < 3 && response.data.data.REGISTRATION_STEP === 3) {
                            localStorage.setItem('regsiration_process', response.data.data._id);
                        } else if (response.data.data.MEMBER_TYPE === 3 && response.data.data.REGISTRATION_STEP === 4) {
                            localStorage.setItem('regsiration_process', response.data.data._id);
                        }

                        localStorage.setItem('userId', response.data.data._id);
                        localStorage.setItem('token', response.data.data.TOKEN);
                        localStorage.setItem('unsigned_profile_image', response.data.data.PHOTO);
                        if(response.data && response.data.data && response.data.data.CREATEDAT){ // set personalNewsfeedAvailable value in local storage
                            const newsfeedAvalability = moment.duration(moment().diff(moment(response.data.data.CREATEDAT))).asMinutes() > 20
                            localStorage.setItem('personalNewsfeedAvailable', newsfeedAvalability)
                            dispatch({type: AuthTypes.UPDATE_USER_NEWSFEED_STATUS, payload: newsfeedAvalability})
                            dispatch(checkPersonalNewsfeedStatus())
                        }

                        dispatch(getAdminCompanyList());
                        dispatch(fetchToolTip())

                        dispatch({ type: AuthTypes.ROUTE_ACCESS_SUCCESS, payload: response.data.data });
                        if (response.data.data.PHOTO !== '') {
                            getProfileImageUrl('mediam-' + response.data.data.PHOTO, 1).then(photo => {
                                localStorage.setItem('profilePicture', photo);
                                dispatch({ type: AuthTypes.GETTING_PROFILE_IMAGE_SUCCESS, payload: photo });
                                getProfileImageUrl('small-' + response.data.data.PHOTO, 1).then(thumbnail => {
                                    localStorage.setItem('profileThumbnail', thumbnail);
                                    dispatch({ type: AuthTypes.GETTING_PROFILE_THUMBNAIL_IMAGE_SUCCESS, payload: thumbnail });
                                    getProfileImageUrl(response.data.data.PHOTO, 1).then(publicImage => {
                                        localStorage.setItem('publicProfilePicture', publicImage);
                                        dispatch({ type: AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_SUCCESS, payload: publicImage });
                                        dispatch({ type: AuthTypes.SIGNIN_SUCCESS, payload: response.data });
                                        dispatch({ type: AuthTypes.CLEAR_ACTION });
                                    }).catch(
                                        dispatch({ type: AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_FAIL })
                                    )

                                }).catch((err) => {
                                    dispatch({ type: AuthTypes.SIGNIN_SUCCESS, payload: response.data });
                                    dispatch({ type: AuthTypes.CLEAR_ACTION });
                                });
                            }).catch((err) => {
                                dispatch({ type: AuthTypes.SIGNIN_SUCCESS, payload: response.data });
                                dispatch({ type: AuthTypes.CLEAR_ACTION });
                            });
                        } else {
                            dispatch({ type: AuthTypes.SIGNIN_SUCCESS, payload: response.data });
                            dispatch({ type: AuthTypes.CLEAR_ACTION });
                        }

                        if (document.referrer !== '') {
                            window.document.__defineGetter__('referrer', () => { return window.origin });   
                        }    

                    } else {
                        dispatch({ type: AuthTypes.SIGNIN_FAIL, payload: response.data });
                        showAlert(dispatch, ALERT.ERROR, response.data.message);
                        dispatch({ type: AuthTypes.CLEAR_ACTION });
                    }
                }).catch((error) => {
                    dispatch({ type: AuthTypes.SIGNIN_FAIL, payload: error });
                    errorHandler(dispatch, error);
                    dispatch({ type: AuthTypes.CLEAR_ACTION });
                });
        }
    }
}

export function clearCompanyUnseenMessages() {
    return function (dispatch) {
        dispatch({ type: MessageTypes.CLEAR_LATEST_COMPANY_UNSEEN_CONVERSATION });
    }
}

export function checkPersonalNewsfeedStatus() {
  return function (dispatch) {
    if (localStorage.getItem("personalNewsfeedAvailable") === 'false') {
      const checkPersonalNewsfeedStatusInterval = setInterval(async () => {
        try {
            if(!localStorage.getItem("userId")){
                clearInterval(checkPersonalNewsfeedStatusInterval);
            } else {
                const { data, err } = await get(`${ROUTES.CHECK_PERSONAL_NEWSFEED_STATUS}${localStorage.getItem("userId")}`);
                if (data.code === 200 && data.data.newsfeedStatus) {
                  localStorage.setItem("personalNewsfeedAvailable", true);
                  dispatch({type: AuthTypes.UPDATE_USER_NEWSFEED_STATUS, payload: true})
                  clearInterval(checkPersonalNewsfeedStatusInterval);
                  console.info("Personal newsfeed is ready");
                } else {
                  console.info("Personal newsfeed unavailable");
                }
            }
          
        } catch (error) {
          console.error("personal newsfeed check failed!", error);
        }
      }, 60000);
    }
  };
}

export function initlogin(token, isFirstTime = false, responseMsg = null, cancelToken) {
    return function (dispatch) {
        dispatch({ type: AuthTypes.INITAL_FETCHING, payload: true });
        post(`${ROUTES.USERDETAILS}`, {emulateSessionId: localStorage.getItem('emulateSessionId')}, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch(setSessionID());
                    if(response.data && response.data.data && response.data.data.CREATEDAT && !localStorage.getItem('personalNewsfeedAvailable')){ // set personalNewsfeedAvailable value in local storage
                        const newsfeedAvalability = moment.duration(moment().diff(moment(response.data.data.CREATEDAT))).asMinutes() > 20
                        localStorage.setItem('personalNewsfeedAvailable', newsfeedAvalability)
                        dispatch({type: AuthTypes.UPDATE_USER_NEWSFEED_STATUS, payload: newsfeedAvalability})
                    } else if (localStorage.getItem('personalNewsfeedAvailable') === 'true') {
                        dispatch({type: AuthTypes.UPDATE_USER_NEWSFEED_STATUS, payload: true})
                    }
                    if ((response.data.data.MEMBER_TYPE === MEMBER_TYPE.INVESTOR && response.data.data.REGISTRATION_STEP === REGISTRATION_STEP.INVESTOR_STEP_04) || (response.data.data.MEMBER_TYPE === MEMBER_TYPE.PROFESSIONAL && response.data.data.REGISTRATION_STEP === REGISTRATION_STEP.PROFESSIONAL_STEP_03)) { // Investor or Professional
                        localStorage.setItem('regsiration_process', response.data.data._id);
                        dispatch({ type: AuthTypes.ROUTE_ACCESS_SUCCESS, payload: response.data.data });
                    }
                    if (response.data.data.MEMBER_TYPE === MEMBER_TYPE.INVESTOR && response.data.data.REGISTRATION_STEP < REGISTRATION_STEP.INVESTOR_STEP_04) {
                        dispatch({ type: InvestorTypes.SET_INVESTOR_DETAILS, payload: response.data.data.INVESTOR })
                    }

                    /*get admin companies*/
                    dispatch(fetchToolTip())
                    dispatch(getAdminCompanyList());

                    if (response.data.data.PHOTO !== '') {
                        if (!localStorage.getItem('profilePicture') || localStorage.getItem('profilePicture') === '') {
                            getProfileImageUrl('mediam-' + response.data.data.PHOTO, 1).then(photo => {
                                imageExists(photo, (exists) => {
                                    if (exists) {
                                        localStorage.setItem('profilePicture', photo);
                                        dispatch({ type: AuthTypes.GETTING_PROFILE_IMAGE_SUCCESS, payload: photo });

                                        getProfileImageUrl('small-' + response.data.data.PHOTO, 1).then(thumbnail => {
                                            imageExists(thumbnail, (exists) => {
                                                if (exists) {
                                                    localStorage.setItem('profileThumbnail', thumbnail);
                                                    dispatch({ type: AuthTypes.GETTING_PROFILE_THUMBNAIL_IMAGE_SUCCESS, payload: thumbnail });
                                                    getProfileImageUrl(response.data.data.PHOTO, 1).then(publicImage => {
                                                        localStorage.setItem('publicProfilePicture', publicImage);
                                                        dispatch({ type: AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_SUCCESS, payload: publicImage })
                                                    }).catch(
                                                        dispatch({ type: AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_FAIL })
                                                    )
                                                } else {
                                                    setTimeout(() => {
                                                        imageExists(thumbnail, (exists) => {
                                                            if (exists) {
                                                                localStorage.setItem('profileThumbnail', thumbnail);
                                                                dispatch({ type: AuthTypes.GETTING_PROFILE_THUMBNAIL_IMAGE_SUCCESS, payload: thumbnail });
                                                                getProfileImageUrl(response.data.data.PHOTO, 1).then(publicImage => {
                                                                    localStorage.setItem('publicProfilePicture', publicImage);
                                                                    dispatch({ type: AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_SUCCESS, payload: publicImage })
                                                                }).catch(
                                                                    dispatch({ type: AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_FAIL })
                                                                )
                                                            } else {
                                                                localStorage.setItem('profileThumbnail', thumbnail);
                                                                dispatch({ type: AuthTypes.GETTING_PROFILE_THUMBNAIL_IMAGE_FAIL });
                                                                getProfileImageUrl(response.data.data.PHOTO, 1).then(publicImage => {
                                                                    localStorage.setItem('publicProfilePicture', publicImage);
                                                                    dispatch({ type: AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_SUCCESS, payload: publicImage })
                                                                }).catch(
                                                                    dispatch({ type: AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_FAIL })
                                                                )
                                                            }
                                                        });
                                                    }, 5000);
                                                }
                                            });
                                        }).catch((err) => {
                                            dispatch({ type: AuthTypes.GETTING_PROFILE_THUMBNAIL_IMAGE_FAIL });
                                        });
                                    } else {
                                        setTimeout(() => {
                                            imageExists(photo, (exists) => {
                                                if (exists) {
                                                    localStorage.setItem('profilePicture', photo);
                                                    dispatch({ type: AuthTypes.GETTING_PROFILE_IMAGE_SUCCESS, payload: photo });
                                                    getProfileImageUrl('small-' + response.data.data.PHOTO, 1).then(thumbnail => {
                                                        imageExists(thumbnail, (exists) => {
                                                            if (exists) {
                                                                localStorage.setItem('profileThumbnail', thumbnail);
                                                                dispatch({ type: AuthTypes.GETTING_PROFILE_THUMBNAIL_IMAGE_SUCCESS, payload: thumbnail });
                                                                getProfileImageUrl(response.data.data.PHOTO, 1).then(publicImage => {
                                                                    localStorage.setItem('publicProfilePicture', publicImage);
                                                                    dispatch({ type: AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_SUCCESS, payload: publicImage })
                                                                }).catch(
                                                                    dispatch({ type: AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_FAIL })
                                                                )
                                                            } else {
                                                                setTimeout(() => {
                                                                    imageExists(thumbnail, (exists) => {
                                                                        if (exists) {
                                                                            localStorage.setItem('profileThumbnail', thumbnail);
                                                                            dispatch({ type: AuthTypes.GETTING_PROFILE_THUMBNAIL_IMAGE_SUCCESS, payload: thumbnail });
                                                                            getProfileImageUrl(response.data.data.PHOTO, 1).then(publicImage => {
                                                                                localStorage.setItem('publicProfilePicture', publicImage);
                                                                                dispatch({ type: AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_SUCCESS, payload: publicImage })
                                                                            }).catch(
                                                                                dispatch({ type: AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_FAIL })
                                                                            )
                                                                        } else {
                                                                            localStorage.setItem('profileThumbnail', thumbnail);
                                                                            dispatch({ type: AuthTypes.GETTING_PROFILE_THUMBNAIL_IMAGE_FAIL });
                                                                            getProfileImageUrl(response.data.data.PHOTO, 1).then(publicImage => {
                                                                                localStorage.setItem('publicProfilePicture', publicImage);
                                                                                dispatch({ type: AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_SUCCESS, payload: publicImage })
                                                                            }).catch(
                                                                                dispatch({ type: AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_FAIL })
                                                                            )
                                                                        }
                                                                    });
                                                                }, 5000);
                                                            }
                                                        });
                                                    }).catch((err) => {
                                                        dispatch({ type: AuthTypes.GETTING_PROFILE_THUMBNAIL_IMAGE_FAIL });
                                                    });
                                                } else {
                                                    dispatch({ type: AuthTypes.GETTING_PROFILE_IMAGE_FAIL });
                                                }
                                            });
                                        }, 5000);
                                    }
                                });
                            }).catch((err) => {
                                dispatch({ type: AuthTypes.GETTING_PROFILE_IMAGE_FAIL });
                            });
                        }
                    } else {
                        dispatch({ type: AuthTypes.PROFILE_IMAGE_REMOVED });
                        dispatch({ type: AuthTypes.CLEAR_ACTION });
                    }

                    let user = response.data
                    if (user.data) {
                        user.data.IS_FIRST_TIME = isFirstTime;
                    }
                    localStorage.setItem('unsigned_profile_image', response.data.data.PHOTO);
                    dispatch({ type: AuthTypes.SIGNIN_SUCCESS, payload: user });
                    dispatch({ type: AuthTypes.CLEAR_ACTION });
                    if (responseMsg) {
                        // showAlert(dispatch, ALERT.SUCCESS, responseMsg);
                    }
                    dispatch({ type: AuthTypes.CLEAR_ACTION });
                } else if (response.data.code === 222) {
                    dispatch({ type: AuthTypes.ACTIVATION_REQUIRED, payload: response.data })
                    showAlert(dispatch, ALERT.ERROR, response.data.message);
                } else {
                    dispatch(logout());
                    dispatch({ type: AuthTypes.SIGNIN_FAIL, payload: response.data });
                    dispatch({ type: AuthTypes.CLEAR_ACTION });
                }
            })
            .catch((error) => {
                
                if (axios.isCancel(error)) {
                    return;
                } else {
                    errorHandler(dispatch, error);
                    if(error.response && error.response.status === ALERT.UNAUTORIZED){
                        dispatch(logout());
                    }
                }
            })
    }
}

export function companyRegistrationStepTwo(data) {
    return function (dispatch) {
        post(`${ROUTES.COMPANY_STEP_2}`, data)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: AuthTypes.CLEAR_ACTION });
                    dispatch(initlogin(localStorage.getItem('token')))
                    dispatch({ type: AuthTypes.INSERTING_USER_DATA_SUCCESS, payload: 2, response: response.data });
                } else {
                    dispatch({ type: AuthTypes.INSERTING_USER_DATA_FAIL, payload: 2, response: response.data });
                    dispatch({ type: AuthTypes.CLEAR_ACTION });
                    showAlert(dispatch, ALERT.ERROR, response.data.message);
                }
            })
            .catch((error) => {
                dispatch({ type: AuthTypes.INSERTING_USER_DATA_FAIL, payload: 2, response: error });
                errorHandler(dispatch, error);
                dispatch({ type: AuthTypes.CLEAR_ACTION });
            });
    }
}

export function companyRegistrationStepThree(data) {
    let info = {
        userId: data.userId,
        companyName: data.companyName,
        currentJobTitle: data.currentJobTitle,
        // currentlyRaisingFund: data.currentlyRaisingFund
    };
    return function (dispatch) {
        post(`${ROUTES.COMPANY_STEP_3}`, info)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: AuthTypes.INSERTING_USER_DATA_SUCCESS, payload: 3, response: response.data });
                    dispatch({ type: AuthTypes.CLEAR_ACTION });
                } else {
                    dispatch({ type: AuthTypes.INSERTING_USER_DATA_FAIL, payload: 3, response: response.data });
                    dispatch({ type: AuthTypes.CLEAR_ACTION });
                    showAlert(dispatch, ALERT.ERROR, response.data.message);
                }
            })
            .catch((error) => {
                dispatch({ type: AuthTypes.INSERTING_USER_DATA_FAIL, payload: 3, response: error });
                dispatch({ type: AuthTypes.CLEAR_ACTION });
                errorHandler(dispatch, error);
            });
    }
}

export function showWelcomeMessage() {
    return function (dispatch) {
        dispatch({ type: AuthTypes.WELCOME_MESSAGE });
    }
}

export function clearLocalStorageValues() {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('profilePicture');
        localStorage.removeItem('profileThumbnail');
        localStorage.removeItem('publicProfilePicture');
        localStorage.removeItem('regsiration_process');
        localStorage.removeItem('selectedCategories');
        localStorage.removeItem('unsigned_profile_image');
        localStorage.removeItem('JBHQ_JOB_ID');
        localStorage.removeItem('previousSession');
        localStorage.removeItem('emulateSessionId');
        localStorage.removeItem('name');
}

export function logout(callback = null) {
    return function (dispatch) {
        if (localStorage.getItem('token')) {
            post(`${ROUTES.LOGOUT}`, {})
                .then(response => {
                    dispatch({ type: AuthTypes.LOGOUT });
                    dispatch({ type: CLEAR_NEWSFEED_REACTED_DATA })
                    dispatch({ type: EMPTY_PENDING_POST })
                    dispatch(clearSessionID());
                    clearLocalStorageValues()
                    if(callback && typeof callback === 'function'){
                        callback();
                    }
                }).catch((error) => {
                    dispatch({ type: AuthTypes.LOGOUT });
                    dispatch({ type: CLEAR_NEWSFEED_REACTED_DATA })
                    dispatch({ type: EMPTY_PENDING_POST })
                    dispatch(clearSessionID());
                    errorHandler(dispatch, error);
                    clearLocalStorageValues()
                });
        } else {
            dispatch(clearSessionID());
            clearLocalStorageValues()
        }
    }
}

export function activate(token) {
    return function (dispatch) {
        localStorage.removeItem("goto");
        dispatch({ type: AuthTypes.FETCHING, payload: true });
        post(`${ROUTES.ACTIVATION}`, { activationToken: token })
            .then(response => {
                if (response.data.code === 200) {
                    localStorage.setItem('userId', response.data.data._id);
                    localStorage.setItem('token', response.data.data.TOKEN);
                    localStorage.setItem('unsigned_profile_image', response.data.data.PHOTO);
                    dispatch(initlogin(localStorage.getItem('token')));
                    dispatch({ type: AuthTypes.ACTIVATION_SUCCESS, payload: response.data });
                    // showAlert(dispatch, ALERT.SUCCESS, response.data.message);
                } else if (response.data.code === 214) {
                    dispatch({ type: AuthTypes.TOKEN_EXPIRED });
                    showAlert(dispatch, ALERT.WARNING, response.data.message);
                } else if (response.data.code === 212) {
                    // Wrong Token or Already Activated
                    dispatch({ type: AuthTypes.INVALID_TOKEN });
                    dispatch(logout());
                    showAlert(dispatch, ALERT.ERROR, response.data.message);
                }
            })
            .catch((error) => {
                dispatch({ type: AuthTypes.ACTIVATION_FAIL, payload: error.response.data });
                errorHandler(dispatch, error);
            });
    }
}

export const activateEmployee = async (token, dispatch) => {
    try {
        const { data, err } = await post(`${ROUTES.ACTIVATION_EMP}`, { activationToken: token })
        if (data.code !== 200) {
            showPopupAlert(dispatch, POP_ALERT.WARNING, data.message);
            return {
                data: data.data,
                success: false
            };
        }

        return {
            data: data.data,
            success: !err
        };

    } catch (ex) {
        return {
            success: false
        };
    }
}

export function getWelcomeMessage(type) {
    return function (dispatch) {
        if (type === WelcomeMessageTypes.NETWORKER.TYPE) {
            dispatch({ type: AuthTypes.GETTING_WELCOME_MESSAGE_SUCCESS, payload: WelcomeMessageTypes.NETWORKER.MESSAGE });
            dispatch({ type: AuthTypes.CLEAR_ACTION });
        } else if (type === WelcomeMessageTypes.COMPANY.TYPE) {
            dispatch({ type: AuthTypes.GETTING_WELCOME_MESSAGE_SUCCESS, payload: WelcomeMessageTypes.COMPANY.MESSAGE });
            dispatch({ type: AuthTypes.CLEAR_ACTION });
        } else if (type === WelcomeMessageTypes.INVESTOR.TYPE) {
            dispatch({ type: AuthTypes.GETTING_WELCOME_MESSAGE_SUCCESS, payload: WelcomeMessageTypes.INVESTOR.MESSAGE });
            dispatch({ type: AuthTypes.CLEAR_ACTION });
        } else {
            dispatch({ type: AuthTypes.GETTING_WELCOME_MESSAGE_FAIL });
            dispatch({ type: AuthTypes.CLEAR_ACTION });
        }
    }
}

export function getEmployeeHistory(uid, cancelToken) {
    return function (dispatch) {
        dispatch({ type: AuthTypes.FETCHING_EMPLOYMENT_HISTORY });
        get(`${ROUTES.GET_EMPLOYEE_HISTORY}${uid}`, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: AuthTypes.FETCHING_EMPLOYMENT_HISTORY_SUCCESS, payload: response.data.data });
                } else {
                    dispatch({ type: AuthTypes.FETCHING_EMPLOYMENT_HISTORY_FAIL });
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    errorHandler(dispatch, error);
                    dispatch({ type: AuthTypes.FETCHING_EMPLOYMENT_HISTORY_FAIL });
                }
            })
    }
}

export function getEmployeeHistoryForProfileEdit(uid, cancelToken) {
    return function (dispatch) {
        dispatch({ type: AuthTypes.FETCHING_EMPLOYMENT_HISTORY_FOREDIT });
        get(`${ROUTES.GET_EMPLOYEE_HISTORY}${uid}`, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: AuthTypes.FETCHING_EMPLOYMENT_HISTORY_SUCCESS_FOREDIT, payload: response.data.data });
                } else {
                    dispatch({ type: AuthTypes.FETCHING_EMPLOYMENT_HISTORY_FAIL_FOREDIT });
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    errorHandler(dispatch, error);
                    dispatch({ type: AuthTypes.FETCHING_EMPLOYMENT_HISTORY_FAIL_FOREDIT });
                }
            })
    }
}

export function addEmployeeHistory(uid, data) {
    let info = {
        companyId: data.id,
        companyName: data.name,
        jobTitle: data.job,
        from: data.from,
        to: data.to,
        currentlyWorking: data.isCurrentlyWorking,
        description: data.description,
    };
    return function (dispatch) {
        dispatch({ type: AuthTypes.ADDING_EMPLOYMENT_HISTORY });
        put(`${ROUTES.ADD_EMP_HISTORY}${uid}`, info )
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: AuthTypes.ADDING_EMPLOYMENT_HISTORY_SUCCESS });
                    dispatch(getEmployeeHistoryForProfileEdit(localStorage.getItem('userId')))
                    dispatch(getEmployeeHistory(uid));
                    dispatch(initlogin(localStorage.getItem('token')));
                    dispatch(CompanyActions.getCompanyDetails(localStorage.getItem('userId')));
                    if (data.id === '') {
                        dispatch(CompanyActions.getCompanies(localStorage.getItem('userId')));
                    }
                } else {
                    dispatch({ type: AuthTypes.ADDING_EMPLOYMENT_HISTORY_FAIL });
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            })
            .catch((error) => {
                dispatch({ type: AuthTypes.ADDING_EMPLOYMENT_HISTORY_FAIL });
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message)
            })
    }
}

export function updateEmployeeHistory(newHistory, objectId) {
    return function (dispatch) {
        dispatch({ type: AuthTypes.UPDATING_EMPLOYMENT_HISTORY });
        put(`${ROUTES.EDIT_EMP_HISTORY}${objectId}`, newHistory )
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: AuthTypes.UPDATING_EMPLOYMENT_HISTORY_SUCCESS });
                    dispatch(getEmployeeHistoryForProfileEdit(localStorage.getItem('userId')))
                    dispatch(getEmployeeHistory(localStorage.getItem('userId')));
                    dispatch(CompanyActions.getCompanies(localStorage.getItem('userId')));
                    dispatch(initlogin(localStorage.getItem('token')));
                    dispatch(CompanyActions.getCompanyDetails(localStorage.getItem('userId')));
                } else {
                    dispatch({ type: AuthTypes.UPDATING_EMPLOYMENT_HISTORY_FAIL });
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            })
            .catch((error) => {
                dispatch({ type: AuthTypes.UPDATING_EMPLOYMENT_HISTORY_FAIL });
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message)
            })
    }
}

export function removeEmployeeHistory(uid, cid, oid) {
    return function (dispatch) {
        dispatch({ type: AuthTypes.DELETING_EMPLOYMENT_HISTORY });
        put(`${ROUTES.REMOVE_COMPANY_FROM_HISTORY}${uid}`, { employeeHistoryId: oid, companyId: cid } )
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: AuthTypes.DELETING_EMPLOYMENT_HISTORY_SUCCESS });
                    dispatch(getEmployeeHistoryForProfileEdit(localStorage.getItem('userId')))
                    dispatch(getEmployeeHistory(uid));
                    dispatch(initlogin(localStorage.getItem('token')));
                    dispatch(CompanyActions.getCompanyDetails(localStorage.getItem('userId')));
                } else {
                    dispatch({ type: AuthTypes.DELETING_EMPLOYMENT_HISTORY_FAIL });
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            })
            .catch((error) => {
                dispatch({ type: AuthTypes.DELETING_EMPLOYMENT_HISTORY_FAIL });
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message)
            })
    }
}

export function getProfileImage(imageName) {
    return function (dispatch) {
        dispatch({ type: AuthTypes.GETTING_PROFILE_IMAGE });
        if (imageName !== '') {
            localStorage.removeItem('profilePicture');
            localStorage.removeItem('profileThumbnail');
            localStorage.removeItem('publicProfilePicture');

            dispatch({ type: AuthTypes.CLEAR_PROFILE_IMAGE });
            dispatch({ type: AuthTypes.CLEAR_PROFILE_THUMBNAIL_IMAGE });

            getProfileImageUrl('mediam-' + imageName, 1).then(photo => {
                localStorage.setItem('profilePicture', photo);
                imageExists(photo, (exists) => {
                    if (exists) {
                        getProfileImageUrl('small-' + imageName, 1).then(thumbnail => {
                            localStorage.setItem('profileThumbnail', thumbnail);
                            getProfileImageUrl(imageName, 1).then(publicPhoto => {
                                localStorage.setItem('publicProfilePicture', publicPhoto);
                                dispatch({ type: AuthTypes.GETTING_PROFILE_IMAGE_SUCCESS, payload: photo });
                                dispatch({ type: AuthTypes.GETTING_PROFILE_THUMBNAIL_IMAGE_SUCCESS, payload: thumbnail });
                                dispatch({ type: AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_SUCCESS, payload: publicPhoto })
                            }).catch(err => {
                                dispatch({ type: AuthTypes.GETTING_PROFILE_IMAGE_FAIL });
                                dispatch({ type: AuthTypes.GETTING_PROFILE_THUMBNAIL_IMAGE_FAIL });
                                dispatch({ type: AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_FAIL })
                            });
                        }).catch((err) => {
                            dispatch({ type: AuthTypes.GETTING_PROFILE_IMAGE_FAIL });
                            dispatch({ type: AuthTypes.GETTING_PROFILE_THUMBNAIL_IMAGE_FAIL });
                            dispatch({ type: AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_FAIL })
                        });
                    } else {
                        setTimeout(() => {
                            imageExists(photo, (exists) => {
                                if (exists) {
                                    getProfileImageUrl(imageName, 1).then(thumbnail => {
                                        localStorage.setItem('profileThumbnail', thumbnail);
                                        getProfileImageUrl(imageName, 1).then(publicPhoto => {
                                            localStorage.setItem('publicProfilePicture', publicPhoto);
                                            dispatch({ type: AuthTypes.GETTING_PROFILE_IMAGE_SUCCESS, payload: photo });
                                            dispatch({ type: AuthTypes.GETTING_PROFILE_THUMBNAIL_IMAGE_SUCCESS, payload: thumbnail });
                                            dispatch({ type: AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_SUCCESS, payload: publicPhoto })
                                        }).catch(err => {
                                            dispatch({ type: AuthTypes.GETTING_PROFILE_IMAGE_FAIL });
                                            dispatch({ type: AuthTypes.GETTING_PROFILE_THUMBNAIL_IMAGE_FAIL });
                                            dispatch({ type: AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_FAIL })
                                        });
                                    }).catch((err) => {
                                        dispatch({ type: AuthTypes.GETTING_PROFILE_IMAGE_FAIL });
                                        dispatch({ type: AuthTypes.GETTING_PROFILE_THUMBNAIL_IMAGE_FAIL });
                                        dispatch({ type: AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_FAIL })
                                    });
                                } else {
                                    dispatch({ type: AuthTypes.GETTING_PROFILE_IMAGE_FAIL });
                                    dispatch({ type: AuthTypes.GETTING_PROFILE_THUMBNAIL_IMAGE_FAIL });
                                    dispatch({ type: AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_FAIL })
                                }
                            });
                        }, 5000)
                    }
                });
            }).catch((err) => {
                dispatch({ type: AuthTypes.GETTING_PROFILE_IMAGE_FAIL });
                dispatch({ type: AuthTypes.GETTING_PROFILE_THUMBNAIL_IMAGE_FAIL });
                dispatch({ type: AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_FAIL })
            });
        }
    }
}

export function clearWelcomeMessage() {
    return function (dispatch) {
        dispatch({ type: AuthTypes.CLEAR_WELCOME_MESSAGE });
    }
}

export function resetPassword(email) {
    return function (dispatch) {
        dispatch({ type: AuthTypes.RESETTING_PASSWORD, payload: WelcomeMessageTypes.RESET_PASSWORD_TOKEN.EMAIL_VALIDATING });
        post(`${ROUTES.FORGOT_PASSWORD}`, { "email": email })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: AuthTypes.RESETTING_PASSWORD_SUCCESS, payload: WelcomeMessageTypes.RESET_PASSWORD_TOKEN.EMAIL_VALIDATION_SUCCESS });
                } else if (response.data.code === 216) {
                    dispatch({ type: AuthTypes.RESETTING_PASSWORD_FAIL, payload: WelcomeMessageTypes.RESET_PASSWORD_TOKEN.EMAIL_VALIDATION_FAIL_ERROR });
                } else {
                    dispatch({ type: AuthTypes.RESETTING_PASSWORD_FAIL, payload: WelcomeMessageTypes.RESET_PASSWORD_TOKEN.EMAIL_VALIDATION_FAIL });
                }
            })
            .catch((error) => {
                errorHandler(dispatch, error);
                dispatch({ type: AuthTypes.RESETTING_PASSWORD_FAIL, payload: WelcomeMessageTypes.RESET_PASSWORD_TOKEN.EMAIL_VALIDATION_FAIL_ERROR });
            })
    }
}

export function validateResetToken(token) {
    return function (dispatch) {
        dispatch({ type: AuthTypes.VALIDATE_RESET_TOKEN, payload: WelcomeMessageTypes.RESET_PASSWORD.TOKEN_VALIDATING });
        get(`${ROUTES.CHECK_PASSWORD_TOKEN}${token}`)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: AuthTypes.VALIDATE_RESET_TOKEN_SUCCESS, token: response.data.data, payload: WelcomeMessageTypes.RESET_PASSWORD.TOKEN_VALIDATION_SUCCESS });
                } else {
                    dispatch({ type: AuthTypes.VALIDATE_RESET_TOKEN_FAIL, payload: WelcomeMessageTypes.RESET_PASSWORD.TOKEN_VALIDATION_ERROR });
                }
            })
            .catch((error) => {
                errorHandler(dispatch, error);
                dispatch({ type: AuthTypes.VALIDATE_RESET_TOKEN_FAIL, payload: WelcomeMessageTypes.RESET_PASSWORD.TOKEN_VALIDATION_ERROR });
            })
    }
}

export function updatePassword(password, token) {
    return function (dispatch) {
        dispatch({ type: AuthTypes.UPDATE_PASSWORD });
        post(`${ROUTES.RESET_PASSWORD}`, { "password": md5(password), "token": token })
            .then(response => {
                if (response.data.code === 200) {
                    let userdata = {
                        email: response.data.data,
                        password
                    };
                    dispatch(signIn(userdata));
                    dispatch({ type: AuthTypes.UPDATE_PASSWORD_SUCCESS, payload: WelcomeMessageTypes.RESET_PASSWORD.SUCCESS });
                    showPopupAlert(dispatch, POP_ALERT.SUCCESS, response.data.message);
                } else {
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                    dispatch({ type: AuthTypes.UPDATE_PASSWORD_FAIL, payload: WelcomeMessageTypes.RESET_PASSWORD_TOKEN.EMAIL_VALIDATION_FAIL });
                }
            })
            .catch((error) => {
                dispatch({ type: AuthTypes.UPDATE_PASSWORD_FAIL, payload: WelcomeMessageTypes.RESET_PASSWORD_TOKEN.EMAIL_VALIDATION_FAIL });
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
                dispatch({ type: AuthTypes.CLEAR_ACTION });
            })
    }
}

export function setEmpPassword(password, token) {
    return function (dispatch) {
        dispatch({ type: AuthTypes.UPDATE_PASSWORD });
        post(`${ROUTES.SET_EMP_PASSWORD}`, { "newPassword": md5(password), "token": token })
            .then(response => {
                if (response.data.code === 200) {
                    if (response.data.data) {
                        localStorage.setItem('userId', response.data.data._id);
                        localStorage.setItem('token', response.data.data.TOKEN);
                        localStorage.setItem('unsigned_profile_image', response.data.data.PHOTO);
                        dispatch(initlogin(response.data.data.TOKEN, true));
                        dispatch({ type: AuthTypes.UPDATE_PASSWORD_SUCCESS, payload: response.data.data });
                        // showAlert(dispatch, ALERT.SUCCESS, response.data.message);
                    } else {
                        dispatch({ type: AuthTypes.UPDATE_PASSWORD_SUCCESS, payload: response.data.data });
                        dispatch({ type: AuthTypes.UPDATE_PASSWORD_FAIL });
                    }
                } else {
                    dispatch({ type: AuthTypes.UPDATE_PASSWORD_FAIL });
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            })
            .catch((error) => {
                dispatch({ type: AuthTypes.UPDATE_PASSWORD_FAIL });
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
                dispatch({ type: AuthTypes.CLEAR_ACTION });
            })
    }
}

export function resendActivationEmail() {
    return function (dispatch) {
        dispatch({ type: AuthTypes.RESEND_ACTIVATION_EMAIL });
        get(ROUTES.RESEND_EMAIL)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: AuthTypes.RESEND_ACTIVATION_EMAIL_SUCCESS });
                    showAlert(dispatch, ALERT.SUCCESS, response.data.message);
                } else if (response.data.code === 220 || 212 || 214) {
                    dispatch({ type: AuthTypes.RESEND_ACTIVATION_EMAIL_FAIL });
                    showAlert(dispatch, ALERT.WARNING, response.data.message);
                }
            })
            .catch((error) => {
                dispatch({ type: AuthTypes.RESEND_ACTIVATION_EMAIL_FAIL });
                errorHandler(dispatch, error);
            })
    }
}

export function contactUs(data) {
    return function (dispatch) {
        post(`${ROUTES.CONTACT_US}`, data)
            .then(response => {
                if (response.data && response.data.response === 'Success') {
                    showPopupAlert(dispatch, POP_ALERT.SUCCESS, 'Message has been successfully sent');
                } else {
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            })
            .catch((error) => {
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
            })
    }
}

export function deleteAccount(props, fromNavBar = false) {
    return function (dispatch) {
        dispatch({ type: AuthTypes.DELETE_USER });
        del(`${ROUTES.DELETE_USER}${localStorage.getItem("userId")}`)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: AuthTypes.DELETE_USER_SUCCESS });
                    clearLocalStorageValues();
                    fromNavBar && props.history.push('/')
                    props.userHasAuthenticated(false);
                    props.socket.disconnect();
                    showPopupAlert(dispatch, POP_ALERT.SUCCESS, "Your account was successfully deleted");
                } else if (response.data.code === 214) {
                    dispatch({ type: AuthTypes.NOT_DELETABLE_COMPANY_ADMIN, payload: response.data.data });
                } else {
                    dispatch({ type: AuthTypes.DELETE_USER_FAIL, message: response.data.message });
                    // dispatch({type: AuthTypes.CLEAR_DELETE_USER_FAIL_MESSAGE});
                    // showAlert(dispatch, ALERT.ERROR, response.data.message, 3500);
                }
                dispatch({ type: AuthTypes.CLEAR_ACTION });
            })
            .catch((error) => {
                dispatch({ type: AuthTypes.DELETE_USER_FAIL });
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
            })
    }

}

export function getNonUpdatedCompanyNames(cancelToken) {
    return function (dispatch) {
        dispatch({ type: AuthTypes.GET_NONUPDATED_COMPANIES });
        get(`${ROUTES.GET_NONE_UPDATED_COMPANIES}`, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: AuthTypes.GET_NONUPDATED_COMPANIES_SUCCESS, payload: response.data.data })
                } else {
                    dispatch({ type: AuthTypes.GET_NONUPDATED_COMPANIES_FAIL })
                }
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({ type: AuthTypes.GET_NONUPDATED_COMPANIES_FAIL })
                }
            })
    }
}

export function hideNonUpdatedCompanyWarning() {
    return function (dispatch) {
        dispatch({ type: AuthTypes.HIDE_NONUPDATED_COMPANIES_WARNING })
    }
}

export function changePassword(password, newPassword) {
    return function (dispatch) {
        dispatch({ type: AuthTypes.CHANGE_PASSWORD });
        post(`${ROUTES.LOGIN_SETTINGS}`, { password: password, newPassword: newPassword })
            .then(response => {
                if (response.data.code === 200) {
                    if(response.data && response.data.data && response.data.data.accessToken){
                        localStorage.setItem('token', response.data.data.accessToken)
                    }
                    dispatch({ type: AuthTypes.CHANGE_PASSWORD_SUCCESS, status: response.data.code, message: response.data.message });
                    showPopupAlert(dispatch, POP_ALERT.SUCCESS, response.data.message);
                } else {
                    dispatch({ type: AuthTypes.CHANGE_PASSWORD_FAIL, status: response.data.code, message: response.data.message });
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            })
            .catch(error => {
                dispatch({ type: AuthTypes.CHANGE_PASSWORD_FAIL, status: 404, message: error.message });
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
            })
    }
}

export function changeEmail(newEmail, password) {
    return function (dispatch) {
        dispatch({ type: AuthTypes.CHANGE_EMAIL });
        post(`${ROUTES.LOGIN_SETTINGS}`, { password: password, newEmail: newEmail })
            .then(response => {
                if (response.data.code === 200) {
                    if(response.data && response.data.data && response.data.data.accessToken){
                        localStorage.setItem('token', response.data.data.accessToken)
                    }
                    dispatch({ type: AuthTypes.CHANGE_EMAIL_SUCCESS });
                    dispatch({ type: AuthTypes.CLEAR_ACTION });
                    showPopupAlert(dispatch, POP_ALERT.SUCCESS, response.data.message);
                } else {
                    dispatch({ type: AuthTypes.CHANGE_EMAIL_FAIL });
                    dispatch({ type: AuthTypes.CLEAR_ACTION });
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            })
            .catch(error => {
                dispatch({ type: AuthTypes.CHANGE_EMAIL_FAIL });
                dispatch({ type: AuthTypes.CLEAR_ACTION });
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
            })
    }
}

export function isLoggedInUserCompanyAdmin(cancelToken) {
    return function (dispatch) {
        dispatch({ type: AuthTypes.FETCHING_IS_LOGGED_IN_USER_COMPANY_ADMIN });
        get(`${ROUTES.IS_LOGGED_IN_USER_COMPANY_ADMIN}`, { cancelToken })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: AuthTypes.FETCH_IS_LOGGED_IN_USER_COMPANY_ADMIN_SUCCESS, payload: response.data.data });
                } else {
                    dispatch({ type: AuthTypes.FETCH_IS_LOGGED_IN_USER_COMPANY_ADMIN_FAIL });
                }
                dispatch({ type: AuthTypes.CLEAR_ACTION })
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({ type: AuthTypes.FETCH_IS_LOGGED_IN_USER_COMPANY_ADMIN_FAIL, payload: error.message });
                    dispatch({ type: AuthTypes.CLEAR_ACTION })
                    errorHandler(dispatch, error);
                }
            })
    }
}

export function clearUserImage() {
    return function (dispatch) {
        dispatch({ type: AuthTypes.CLEAR_PROFILE_IMAGE });
        dispatch({ type: AuthTypes.CLEAR_PROFILE_THUMBNAIL_IMAGE })
    }
}

export function getAdminCompanyList() {
    return function (dispatch) {
        dispatch({ type: AuthTypes.FETCHING_ADMIN_COMPANY_LIST });
        get(`${ROUTES.GET_ADMIN_COMPANY}`)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch(getPublicProfileImagesList(response.data.data, 'LOGO', AuthTypes.FETCHING_ADMIN_COMPANY_LIST_SUCCESS));
                    dispatch({ type: AuthTypes.FETCHING_ADMIN_COMPANY_LIST_SUCCESS, payload: response.data.data });
                } else {
                    dispatch({ type: AuthTypes.FETCHING_ADMIN_COMPANY_LIST_FAIL });
                }
            })
            .catch(err => {
                dispatch({ type: AuthTypes.FETCHING_ADMIN_COMPANY_LIST_FAIL });
            })
    }
}

export function setIndustryRoles(status, isFilled = false) {
    return function (dispatch) {
        if (status) {
            dispatch({ type: AuthTypes.SET_INDUSTRY_ROLES_SUCCESS, isFilled });
        } else {
            dispatch({ type: AuthTypes.SET_INDUSTRY_ROLES_FAIL });
        }
    }
}

export function resetTopNavCount(socket, moduleNo) {
    return function (dispatch) {
        dispatch({ type: AuthTypes.RESETTING_TOP_NAV_COUNTS });
        post(`${ROUTES.RESET_TOP_NAV_COUNT}`, { moduleNo })
            .then(response => {
                if (response.data.code === 200) {
                    notifyResettingTopNavNotifications(socket, localStorage.getItem('userId'), moduleNo);
                } else {
                    dispatch({ type: AuthTypes.RESET_TOP_NAV_COUNTS_FAIL });
                }
            })
            .catch(err => {
                console.error(err);
                dispatch({ type: AuthTypes.RESET_TOP_NAV_COUNTS_FAIL });
            })
    }
}

export function clearTopNavCount(moduleNo) {
    return function (dispatch) {
        dispatch({ type: AuthTypes.RESET_TOP_NAV_COUNTS_SUCCESS, moduleNo: moduleNo });
        // if (Number(moduleNo) === NOTIFICATION_TYPES.PERSONAL_MSG || Number(moduleNo) === NOTIFICATION_TYPES.PRODUCTS_MSG) {
        //     dispatch(getUnseenTopNavConvIdSet());   
        // }
    }
}

export function reduceNotificationCountersWhenDeleteConversation(type, conversationId) {
    return function (dispatch, getState) {
        const soFarNotifiedConversationIdSet = getState().messageV2.notifiedConversationIdSet;
        const soFarTopNavUnSeenConvIdSet = getState().messageV2.topNavUnSeenConvIdSet;
        dispatch({ type: AuthTypes.UPDATE_NOTIFICATION_COUNTERS_AFTER_DELETE, module: type, conversationId, soFarNotifiedConversationIdSet, soFarTopNavUnSeenConvIdSet });
    }
}

export function getNotifiedConvIdSet() {
    return function (dispatch) {
        dispatch({ type: GETTING_NOTIFIED_CONV_ID_SET });
        get(`${ROUTES.GET_NOTIFIED_CONV_ID_SET}`)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: GETTING_NOTIFIED_CONV_ID_SET_SUCCESS, payload: response.data.data });
                } else {
                    dispatch({ type: GETTING_NOTIFIED_CONV_ID_SET_FAIL });
                }
            })
            .catch(err => {
                dispatch({ type: GETTING_NOTIFIED_CONV_ID_SET_FAIL });
            })
    }
}

export function getInitChatIdSet() {
    return function (dispatch) {
        dispatch({ type: GETTING_NOTIFIED_CHAT_ID_SET });
        get(`${ROUTES.GET_NOTIFIED_CHAT_ID_SET}`)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: GETTING_NOTIFIED_CHAT_ID_SET_SUCCESS, payload: response.data.data });
                } else {
                    dispatch({ type: GETTING_NOTIFIED_CHAT_ID_SET_FAIL });
                }
            })
            .catch(err => {
                dispatch({ type: GETTING_NOTIFIED_CHAT_ID_SET_FAIL });
            })
    }
}

export function getUnseenTopNavConvIdSet() {
    return function (dispatch) {
        dispatch({ type: GETTING_UNSEEN_TOP_NAV_CONV_ID_SET });
        get(`${ROUTES.GET_UNSEEN_TOP_NAV_CONV_ID_SET}`)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: GETTING_UNSEEN_TOP_NAV_CONV_ID_SET_SUCCESS, payload: response.data.data });
                } else {
                    dispatch({ type: GETTING_UNSEEN_TOP_NAV_CONV_ID_SET_FAIL });
                }
            })
            .catch(err => {
                dispatch({ type: GETTING_UNSEEN_TOP_NAV_CONV_ID_SET_FAIL });
            })
    }
}

export function getUnseenConnectionRequestersIdSet() {
    return function (dispatch) {
        dispatch({ type: AuthTypes.GETTING_UNSEEN_CONNECTION_REQUESTERS_ID_SET });
        get(`${ROUTES.GET_UNSEEN_CONNECTION_REQUESTERS_ID_SET}`)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: AuthTypes.GETTING_UNSEEN_CONNECTION_REQUESTERS_ID_SET_SUCCESS, payload: response.data.data });
                } else {
                    dispatch({ type: AuthTypes.GETTING_UNSEEN_CONNECTION_REQUESTERS_ID_SET_FAIL });
                }
            })
            .catch(err => {
                dispatch({ type: AuthTypes.GETTING_UNSEEN_CONNECTION_REQUESTERS_ID_SET_FAIL });
            })
    }
}

export function reloadUserNotificationCounters() {
    return async function (dispatch) {
        dispatch({ type: AuthTypes.RELOAD_TOP_NAV_COUNTS });
        try {
            const res = await post(ROUTES.RELOAD_COUNTERS, {});
            if (res && res.data && res.data.code === 200) {
                dispatch({ type: AuthTypes.RELOAD_TOP_NAV_COUNTS_SUCCESS, payload: res.data.data });
            } else {
                console.error('Reload User Notification Counters Failed')
            }
        } catch (ex) {
            console.error(ex);
        }
    }
}

export const fetchToolTip = () => async dispatch => {
    dispatch({ type: AuthTypes.FETCH_TOOLTIP });
    try {
            const response = await get(ROUTES.GET_TOOL_TIPS);
            if (response && response.status === 200) {
                dispatch({ type: AuthTypes.FETCH_TOOLTIP_SUCCESS, payload: response.data.data })
            } else {
                dispatch({ type: AuthTypes.FETCH_TOOLTIP_FAIL, payload: [] })
            }
    } catch (ex) {
        dispatch({ type: AuthTypes.FETCH_TOOLTIP_FAIL, payload: [] })
    }
};

export const setPublicAction = (actionType, actionData) => dispatch => {
    dispatch({ type: AuthTypes.SET_PUBLIC_ACTION, actionType, actionData})
};

export const setAuthType = (authType) => dispatch => {
    dispatch({ type: AuthTypes.SET_PUBLIC_ACTION_AUTH, authType})
};

export const removePublicAction = () => dispatch => {
    dispatch({ type: AuthTypes.REMOVE_PUBLIC_ACTION})
};

export const updateOpenNewsfeedTracking = async ()  => {
    try {
        const response = await post(`${ROUTES.UPDATE_TRACKING_OPEN_NEWSFEED}`, { CUSTOM_TOKEN: localStorage.getItem('openNewsfeedToken'), USER_ID: localStorage.getItem('userId') });

        if (response && response.data && response.data.code === 200) {
            return({ success: true, data: response.data.data });
        } else {
            return({ success: false, data: [] });
        }
    } catch (exception) {
        return({ success: false, data: [] });
    }
};

export const closeCandidateSurvey = (closePopup = true) => async dispatch => {
    try {
        const response = await get(ROUTES.CLOSE_CANDIDATE_SURVEY_LAST_SEEN );
        if (response && response.status === 200) {
            dispatch({ type: AuthTypes.CLOSE_CANDIDATE_SURVEY_SUCCESS })
        } else {
            dispatch({ type: AuthTypes.CLOSE_CANDIDATE_SURVEY_FAIL })
        }
    } catch (ex) {
        dispatch({ type: AuthTypes.CLOSE_CANDIDATE_SURVEY_FAIL })
    }
};

export const trackCandidateSurvey = async (COMPLETION, SOURCE, UTM_VARIABLES) => {
    try {
        const response = await post(ROUTES.SUBMIT_CANDIDATE_SURVEY_OPEN, {COMPLETION, SOURCE, UTM_VARIABLES} );
        if (response && response.data && response.data.success) {
            return({ success: true, data: response.data.data });
        } else {
            return({ success: false, data: [] });
        }
    } catch (ex) {
        console.error(ex)
        return({ success: false, data: [] });
    }
};

export const recordCandidateSurvey = async (data) => {
    try {
        const response = await post(ROUTES.SUBMIT_CANDIDATE_SURVEY, data );
        if (response && response.data && response.data.success) {
            return({ success: true, data: response.data.data });
        } else {
            return({ success: false, data: [] });
        }
    } catch (ex) {
        console.error(ex)
        return({ success: false, data: [] });
    }
};

export const generateCandidateFilePutUrl = async (fileName) => {
    try {
        const response = await post(ROUTES.UPLOAD_CANDIDATE_FILES, { RESUME: fileName } );
        if (response && response.data) {
            return({ success: true, data: response.data });
        } else {
            return({ success: false, data: [] });
        }
    } catch (ex) {
        console.error(ex);
        return({ success: false, data: [] });
    }
}