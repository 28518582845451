import axios from 'axios';
import Admintypes from '../types/admin';
import AuthTypes from '../types/authentication';
import {getAdminCompanyList, getProfileImageUrl} from './auth';
import {ROUTES} from '../config/server';
import {ALERT, NOTIFICATION_TYPES, POP_ALERT, SOCKET} from '../types/common';
import {showAlert, showPopupAlert} from './alert';
import {get, post, put, del} from "../libs/apiConnector";

import * as ApiConnector from '../libs/apiConnector';
import {notifyReceiver} from "../libs/socket";

export const searchPost = async (searchParams) => {

    try {
        const {data, err} = await ApiConnector.post(ROUTES.POST_SEARCH, searchParams);
        return {
            posts: data,
            success: !err
        };
    } catch (e) {
        return {
            success: false
        };
    }
};

export const searchPostResultCount = async (searchParams) => {

    try {
        const {data, err} = await ApiConnector.post(ROUTES.POST_SEARCH_RESULT_COUNT, searchParams);
        return {
            postsCount: data,
            success: !err
        };
    } catch (e) {
        return {
            success: false
        };
    }
};

export const submitReport = async (report, dispatch) => {

    try {
        const {data, err} = await ApiConnector.post(ROUTES.USER_REPORT, report);
        if (data.code === 200) {
            showPopupAlert(dispatch, POP_ALERT.SUCCESS, data.message);
        } else {
            showPopupAlert(dispatch, POP_ALERT.ERROR, data.message);
        }
        return {
            data: data,
            success: !err
        };
    } catch (e) {
        showPopupAlert(dispatch, POP_ALERT.ERROR, 'Unexpected error occured while deleting the post');
        return {
            success: false
        };
    }
};

export const deletePost = async (postId, dispatch, socket) => {

    try {
        const {data, err} = await ApiConnector.put(ROUTES.ARCHIVE_POST + postId, {});
        if (data.code === 200) {
            socket.emit(SOCKET.NEWS_FEED_UPDATED, localStorage.getItem('userId'), true);
            showPopupAlert(dispatch, POP_ALERT.SUCCESS, data.message);
        } else {
            showPopupAlert(dispatch, POP_ALERT.ERROR, data.message);
        }
        return {
            data: data,
            success: !err
        };
    } catch (e) {
        showPopupAlert(dispatch, POP_ALERT.ERROR, 'Unexpected error occured while deleting the post');
        return {
            success: false
        };
    }
};

export const fetchComments = async (searchParams) => {

    try {
        const {data, err} = await ApiConnector.post(ROUTES.FETCH_COMMENTS_FOR_ADMIN, searchParams);
        return {
            comments: data,
            success: !err
        };
    } catch (e) {
        return {
            success: false
        };
    }
}

export const deleteComments = async (postId, commentId, dispatch, socket) => {
    try {
        const {data, err} = await ApiConnector.put(ROUTES.DELETE_COMMENT + postId, {commentId});
        if (data.code === 200) {
            socket.emit(SOCKET.NEWS_FEED_UPDATED, localStorage.getItem('userId'), true);
            showPopupAlert(dispatch, POP_ALERT.SUCCESS, data.message);
        } else {
            showPopupAlert(dispatch, POP_ALERT.ERROR, data.message);
        }
        return {
            comments: data,
            success: !err
        };
    } catch (e) {
        showPopupAlert(dispatch, POP_ALERT.ERROR, 'Unexpected error occured while deleting the comment');
        return {
            success: false
        };
    }
}

export const searchUsers = async (searchParams) => {

    try {
        const {data, err} = await ApiConnector.post(ROUTES.GET_USERS, searchParams);
        return {
            users: data,
            success: !err
        };
    } catch (e) {
        return {
            success: false
        };
    }
};

export const deleteUser = async (userId, dispatch, socket = null) => {

    try {
        const {data, err} = await ApiConnector.del(ROUTES.DELETE_USER + userId, {});
        if (data.code === 200) {
            if (socket) {
                socket.emit(SOCKET.USER_DELETED, localStorage.getItem('token'), userId);
                socket.emit(SOCKET.NEWS_FEED_UPDATED, localStorage.getItem('userId'), true);
            }
            showPopupAlert(dispatch, POP_ALERT.SUCCESS, data.message);
        } else {
            showPopupAlert(dispatch, POP_ALERT.ERROR, data.message);
        }
        return {
            comments: data,
            success: !err
        };
    } catch (e) {
        showPopupAlert(dispatch, POP_ALERT.ERROR, 'An unexpected error occured while deleting the user');
        return {
            success: false
        }
    }
}

export const fetchMessages = async (conversationId, skip, limit) => {
    try {

        if (skip === 0) {
            const {data, err} = await ApiConnector.post(ROUTES.FETCH_ADMIN_COVERSATION_MESSAGES + '/' + conversationId, {
                skip,
                limit
            });
            const countData = await ApiConnector.get(ROUTES.FETCH_ADMIN_COVERSATION_MESSAGE_COUNT + '/' + conversationId);
            return {
                messageCount: countData.data.data.COUNT,
                messages: data,
                success: !err && !countData.err
            };
        } else {
            const {data, err} = await ApiConnector.post(ROUTES.FETCH_ADMIN_COVERSATION_MESSAGES + '/' + conversationId, {
                skip,
                limit
            });
            return {
                messages: data,
                success: !err
            };
        }

    } catch (e) {
        return {
            success: false
        }
    }
}

export function clearAction() {
    return function (dispatch) {
        dispatch({type: Admintypes.EVENT_CLEAR_ACTION});
    }
}

export function getAdminDeletedMarketplaceProducts(filter, cancelToken) {
    return function (dispatch) {
        dispatch({type: Admintypes.FETCHING_ADMIN_DELETED_PRODUCTS});
        post(`${ROUTES.GET_DELETED_PRODUCTS}`, filter, {cancelToken})
            .then((response) => {
                if (response.data.code === 200) {
                    dispatch({type: Admintypes.FETCHING_ADMIN_DELETED_PRODUCTS_SUCCESS, payload: response.data.data});
                } else {
                    dispatch({type: Admintypes.FETCHING_ADMIN_DELETED_PRODUCTS_FAIL});
                }
                dispatch({type: Admintypes.CLEAR_ACTION});
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: Admintypes.FETCHING_ADMIN_DELETED_PRODUCTS_FAIL});
                    dispatch({type: Admintypes.CLEAR_ACTION});
                }
            })
    }
}

export function getAdminDeletedMarketplaceProductCount(filter, cancelToken) {
    return function (dispatch) {
        dispatch({type: Admintypes.FETCHING_ADMIN_DELETED_PRODUCT_COUNT});
        post(`${ROUTES.GET_DELETED_PRODUCT_COUNT}`, filter, {cancelToken})
            .then((response) => {
                if (response.data.code === 200) {
                    dispatch({
                        type: Admintypes.FETCHING_ADMIN_DELETED_PRODUCT_COUNT_SUCCESS,
                        payload: response.data.data
                    });
                } else {
                    dispatch({type: Admintypes.FETCHING_ADMIN_DELETED_PRODUCT_COUNT_FAIL});
                }
                dispatch({type: Admintypes.CLEAR_ACTION});
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: Admintypes.FETCHING_ADMIN_DELETED_PRODUCT_COUNT_FAIL});
                    dispatch({type: Admintypes.CLEAR_ACTION});
                }
            })
    }
}

export function clearDeletedProducts() {
    return function (dispatch) {
        dispatch({type: Admintypes.CLEAR_DELETED_PRODUCTS});
    }
}

export function clearDeletedProductCount() {
    return function (dispatch) {
        dispatch({type: Admintypes.CLEAR_DELETED_PRODUCT_COUNT});
    }
}

// leafire admin operations

export const addExpertContributer = (email, isExpert) => async dispatch => {
    try {
        dispatch({type: Admintypes.ADD_EXPERT_CONTRIBUTER})
        const response = await post(ROUTES.ADD_EXPERT_CONTRIBUTER, {email, isExpert})
        const {status, data} = response;

        if (status === 200) {
            if (!data.data) showAlert(dispatch, ALERT.WARNING, data.message);
            if (isExpert){
                dispatch(getAllExpertContributerCount());
                dispatch({type: Admintypes.ADD_EXPERT_CONTRIBUTER_SUCCESS, payload: data.data});
            } else {
                await dispatch({type: Admintypes.REMOVE_EXPERT_CONTRIBUTER_SUCCESS});
                await dispatch({type: Admintypes.CLEAR_ACTION});
            }
        } else {
            dispatch({type: Admintypes.ADD_EXPERT_CONTRIBUTER_FAIL})
        }
    } catch (exception) {
        dispatch({type: Admintypes.ADD_EXPERT_CONTRIBUTER_FAIL})
    }
}

export const getAllExpertContributer = (skip, limit) => async dispatch => {
    try {
        dispatch({type: Admintypes.GET_ALL_EXPERT_CONTRIBUTER})
        const response = await post(ROUTES.GET_ALL_EXPERT_CONTRIBUTER, {skip, limit})
        const {status, data} = response;

        if (status === 200) {
            dispatch(getAllExpertContributerCount());
            dispatch({type: Admintypes.GET_ALL_EXPERT_CONTRIBUTER_SUCCESS, payload: data.data})
        } else {
            dispatch({type: Admintypes.GET_ALL_EXPERT_CONTRIBUTER_FAIL})
        }
    } catch (exception) {
        dispatch({type: Admintypes.GET_ALL_EXPERT_CONTRIBUTER_FAIL})
    }
}

export const getAllExpertContributerCount = () => async dispatch => {
    try {
        dispatch({type: Admintypes.GET_ALL_EXPERT_CONTRIBUTER_COUNT})
        const response = await get(ROUTES.GET_ALL_EXPERT_CONTRIBUTER_COUNT)
        const {status, data} = response;

        if (status === 200) {
            dispatch({type: Admintypes.GET_ALL_EXPERT_CONTRIBUTER_COUNT_SUCCESS, payload: data.data})
        } else {
            dispatch({type: Admintypes.GET_ALL_EXPERT_CONTRIBUTER_COUNT_FAIL})
        }
    } catch (exception) {
        dispatch({type: Admintypes.GET_ALL_EXPERT_CONTRIBUTER_COUNT_FAIL})
    }
}

export const getMarketplaceStatistics = (fetchData) => async dispatch => {
    try {
        dispatch({type: Admintypes.GET_MARKETPLACE_STATISTICS})
        const response = await post(ROUTES.GET_MARKETPLACE_STATISTICS, {fetchData})
        const {status, data} = response;

        if (status === 200) {
            dispatch(getMarketplaceStatisticsCount(fetchData));
            dispatch({type: Admintypes.GET_MARKETPLACE_STATISTICS_SUCCESS, payload: data.data})
        } else {
            dispatch({type: Admintypes.GET_MARKETPLACE_STATISTICS_FAIL})
        }
    } catch (exception) {
        dispatch({type: Admintypes.GET_MARKETPLACE_STATISTICS_FAIL})
    }
}

export const getMarketplaceStatisticsCount = (fetchData) => async dispatch => {
    try {
        dispatch({type: Admintypes.GET_MARKETPLACE_STATISTICS_COUNT})
        const response = await post(ROUTES.GET_MARKETPLACE_STATISTICS_COUNT, {fetchData})
        const {status, data} = response;

        if (status === 200) {
            dispatch({type: Admintypes.GET_MARKETPLACE_STATISTICS_COUNT_SUCCESS, payload: data.data})
        } else {
            dispatch({type: Admintypes.GET_MARKETPLACE_STATISTICS_COUNT_FAIL})
        }
    } catch (exception) {
        dispatch({type: Admintypes.GET_MARKETPLACE_STATISTICS_COUNT_FAIL})
    }
}

export const searchQueryMarketplaceStatistics = (searchString, searchType, cancelToken) => async dispatch => {
    try {
        dispatch({type: Admintypes.CLEAR_GET_STATISTICS_SEARCH})
        dispatch({type: Admintypes.GET_STATISTICS_SEARCH})
        const response = await post(ROUTES.GET_STATISTICS_SEARCH, {searchString, searchType}, {
            cancelToken,
            headers: {'X-Custom-Header': searchString}
        })
        const {status, data, config} = response;

        if (status === 200 && config.headers['X-Custom-Header'] === searchString) {
            dispatch({type: Admintypes.GET_STATISTICS_SEARCH_SUCCESS, payload: data.data})
        } else {
            dispatch({type: Admintypes.GET_STATISTICS_SEARCH_FAIL})
        }
    } catch (exception) {
        dispatch({type: Admintypes.GET_STATISTICS_SEARCH_FAIL})
    }
}

export const addCoupon = couponDetails => async dispatch => {
    try {
        dispatch({type: Admintypes.ADD_COUPON_DETAILS})
        const response = await post(ROUTES.ADD_COUPON_DETAILS, {couponDetails})
        const {status, data} = response;

        if (status === 200) {
            if (!data.data) showAlert(dispatch, ALERT.WARNING, data.message);
            dispatch(getAllCouponDetailsCount(couponDetails));
            dispatch({type: Admintypes.ADD_COUPON_DETAILS_SUCCESS, payload: data.data});
        } else {
            dispatch({type: Admintypes.ADD_COUPON_DETAILS_FAIL})
        }
    } catch (exception) {
        dispatch({type: Admintypes.ADD_COUPON_DETAILS_FAIL})
    }
}

export const getAllCouponDetails = couponDetails => async dispatch => {
    try {
        dispatch({type: Admintypes.GET_ALL_COUPON_DETAILS})
        const response = await post(ROUTES.GET_ALL_COUPON_DETAILS, {couponDetails})
        const {status, data} = response;

        if (status === 200) {
            dispatch(getAllCouponDetailsCount(couponDetails));
            dispatch({type: Admintypes.GET_ALL_COUPON_DETAILS_SUCCESS, payload: data.data})
        } else {
            dispatch({type: Admintypes.GET_ALL_COUPON_DETAILS_FAIL})
        }
    } catch (exception) {
        dispatch({type: Admintypes.GET_ALL_COUPON_DETAILS_FAIL})
    }
}

export const getAllCouponDetailsCount = couponDetails => async dispatch => {
    try {
        dispatch({type: Admintypes.GET_ALL_COUPON_DETAILS_COUNT})
        const response = await post(ROUTES.GET_ALL_COUPON_DETAILS_COUNT, {couponDetails})
        const {status, data} = response;

        if (status === 200) {
            dispatch({type: Admintypes.GET_ALL_COUPON_DETAILS_COUNT_SUCCESS, payload: data.data})
        } else {
            dispatch({type: Admintypes.GET_ALL_COUPON_DETAILS_COUNT_FAIL})
        }
    } catch (exception) {
        dispatch({type: Admintypes.GET_ALL_COUPON_DETAILS_COUNT_FAIL})
    }
}

export const listProductsForPrioritize = (skip, limit) => async dispatch => {
    try {
        dispatch({type: Admintypes.FETCHING_PRODUCTS_FOR_PRIORITIZE});
        const response = await get(`${ROUTES.FETCH_PUBLISHED_PRODUCTS}/${skip}/${limit}`)
        const {status, data} = response;
        if (status === 200) {
            dispatch({type: Admintypes.FETCH_PRODUCTS_FOR_PRIORITIZE_SUCCESS, payload: data.data});
        } else {
            dispatch({type: Admintypes.FETCH_PRODUCTS_FOR_PRIORITIZE_FAIL});
        }
    } catch (exception) {
        dispatch({type: Admintypes.FETCH_PRODUCTS_FOR_PRIORITIZE_FAIL});
    }
}

export const getPublishedProductCount = () => async dispatch => {
    try {
        dispatch({type: Admintypes.FETCHING_PUBLISHED_PRODUCTS_COUNT});
        const response = await get(`${ROUTES.FETCH_PUBLISHED_PRODUCTS_COUNT}`)
        const {status, data} = response;
        if (status === 200) {
            dispatch({type: Admintypes.FETCH_PUBLISHED_PRODUCTS_COUNT_SUCCESS, payload: data.data})
        } else {
            dispatch({type: Admintypes.FETCH_PUBLISHED_PRODUCTS_COUNT_SUCCESS})
        }
    } catch (exception) {
        dispatch({type: Admintypes.FETCH_PUBLISHED_PRODUCTS_COUNT_SUCCESS})
    }
}

export const setMarketplaceProductPriority = (productId, number, isFeatured = false) => async dispatch => {
    try {
        dispatch({type: Admintypes.SET_PRODUCTS_PRIORITY});
        const response = await put(`${ROUTES.SET_MARKETPLACE_PRIORITY}/${productId}`, {number, isFeatured})
        const {status, data} = response;
        if (status === 200) {
            dispatch({type: Admintypes.SET_PRODUCTS_PRIORITY_SUCCESS, payload: data.data});
            showAlert(dispatch, ALERT.SUCCESS, response.data.message);
        } else {
            dispatch({type: Admintypes.SET_PRODUCTS_PRIORITY_FAIL});
            showAlert(dispatch, ALERT.SUCCESS, response.data.message);
        }
    } catch (exception) {
        dispatch({type: Admintypes.SET_PRODUCTS_PRIORITY_FAIL});
    }
}

export const getMarketingCampaignStatistics = (fetchData) => async dispatch => {
    try {
        dispatch({type: Admintypes.GET_MARKETING_CAMPAIGN_STATISTICS})
        const response = await post(ROUTES.GET_MARKETING_CAMPAIGN_STATISTICS, {fetchData})
        const {status, data} = response;

        if (status === 200) {
            dispatch(getMarketingCampaignStatisticsCount(fetchData));
            dispatch({type: Admintypes.GET_MARKETING_CAMPAIGN_STATISTICS_SUCCESS, payload: data.data})
        } else {
            dispatch({type: Admintypes.GET_MARKETING_CAMPAIGN_STATISTICS_FAIL})
        }
    } catch (exception) {
        dispatch({type: Admintypes.GET_MARKETING_CAMPAIGN_STATISTICS_FAIL})
    }
}

export const getMarketingCampaignStatisticsCount = (fetchData) => async dispatch => {
    try {
        dispatch({type: Admintypes.GET_MARKETING_CAMPAIGN_STATISTICS_COUNT})
        const response = await post(ROUTES.GET_MARKETING_CAMPAIGN_STATISTICS_COUNT, {fetchData})
        const {status, data} = response;

        if (status === 200) {
            dispatch({type: Admintypes.GET_MARKETING_CAMPAIGN_STATISTICS_COUNT_SUCCESS, payload: data.data})
        } else {
            dispatch({type: Admintypes.GET_MARKETING_CAMPAIGN_STATISTICS_COUNT_FAIL})
        }
    } catch (exception) {
        dispatch({type: Admintypes.GET_MARKETING_CAMPAIGN_STATISTICS_COUNT_FAIL})
    }
}

export const getMarketingCampaignSummary = (fetchData) => async dispatch => {
    try {
        dispatch({type: Admintypes.CLEAR_GET_MARKETING_CAMPAIGN_SUMMARY_AND_COUNT})
        dispatch({type: Admintypes.GET_MARKETING_CAMPAIGN_SUMMARY})
        const response = await post(ROUTES.GET_MARKETING_CAMPAIGN_SUMMARY, {fetchData})
        const {status, data} = response;

        if (status === 200) {
            dispatch(getMarketingCampaignSummaryCount(fetchData));
            dispatch({type: Admintypes.GET_MARKETING_CAMPAIGN_SUMMARY_SUCCESS, payload: data.data})
        } else {
            dispatch({type: Admintypes.GET_MARKETING_CAMPAIGN_SUMMARY_FAIL})
        }
    } catch (exception) {
        dispatch({type: Admintypes.GET_MARKETING_CAMPAIGN_SUMMARY_FAIL})
    }
}

export const getMarketingCampaignSummaryCount = (fetchData) => async dispatch => {
    try {
        dispatch({type: Admintypes.GET_MARKETING_CAMPAIGN_SUMMARY_COUNT})
        const response = await post(ROUTES.GET_MARKETING_CAMPAIGN_SUMMARY_COUNT, {fetchData})
        const {status, data} = response;

        if (status === 200) {
            dispatch({type: Admintypes.GET_MARKETING_CAMPAIGN_SUMMARY_COUNT_SUCCESS, payload: data.data})
        } else {
            dispatch({type: Admintypes.GET_MARKETING_CAMPAIGN_SUMMARY_COUNT_FAIL})
        }
    } catch (exception) {
        dispatch({type: Admintypes.GET_MARKETING_CAMPAIGN_SUMMARY_COUNT_FAIL})
    }
}

export const searchCompany = async (searchParams) => {

    try {
        const {data, err} = await ApiConnector.put(ROUTES.GET_COMPANIES, searchParams);
        return {
            companies: data,
            success: !err
        };
    } catch (e) {
        return {
            success: false
        };
    }
};

export const searchCompanyResultsCount = async (searchParams) => {

    try {
        const {data, err} = await ApiConnector.post(ROUTES.LIST_COMPANIES_COUNT, searchParams);
        return {
            companiesCount: data,
            success: !err
        };
    } catch (e) {
        return {
            success: false
        };
    }
};

export const addToCompanyAdmin = async (companyId, requestBody, dispatch) => {


    try {
        const {data, err} = await ApiConnector.put(ROUTES.ADD_EMPLOYEE + companyId, requestBody);
        if (data.code === 200) {
            showPopupAlert(dispatch, POP_ALERT.SUCCESS, data.message);
        } else {
            showPopupAlert(dispatch, POP_ALERT.ERROR, data.message);
        }
        return {
            data: data,
            success: !err
        };
    } catch (e) {
        showPopupAlert(dispatch, POP_ALERT.ERROR, 'Unexpected error occured while setting admin to the company');
        return {
            success: false
        };
    }
};

export const removeFromAdmin = async (companyId, dispatch) => {

    try {
        const userId = localStorage.getItem('userId')
        const {data, err} = await ApiConnector.put(ROUTES.REMOVE_EMPLOYEE + companyId, {userId});
        if (data.code === 200) {
            showPopupAlert(dispatch, POP_ALERT.SUCCESS, data.message);
        } else {
            showPopupAlert(dispatch, POP_ALERT.ERROR, data.message);
        }
        return {
            data: data,
            success: !err
        };
    } catch (e) {
        showPopupAlert(dispatch, POP_ALERT.ERROR, 'Unexpected error occured while removing admin from the company');
        return {
            success: false
        };
    }
};

export const getNewsFeedTextLinkSummary = (fetchData, clear = false) => async dispatch => {
    try {
        if (clear) dispatch({type: Admintypes.CLEAR_GET_NEWS_FEED_TEXT_LINK_SUMMARY_AND_COUNT})
        dispatch({type: Admintypes.GET_NEWS_FEED_TEXT_LINK_SUMMARY})
        const response = await post(ROUTES.GET_NEWS_FEED_TEXT_LINK_SUMMARY, {fetchData})
        const {status, data} = response;

        if (status === 200) {
            dispatch(getNewsFeedTextLinkSummaryCount(fetchData));
            dispatch({type: Admintypes.GET_NEWS_FEED_TEXT_LINK_SUMMARY_SUCCESS, payload: data.data})
        } else {
            dispatch({type: Admintypes.GET_NEWS_FEED_TEXT_LINK_SUMMARY_FAIL})
        }
    } catch (exception) {
        dispatch({type: Admintypes.GET_NEWS_FEED_TEXT_LINK_SUMMARY_FAIL})
    }
}

export const getNewsFeedTextLinkSummaryCount = fetchData => async dispatch => {
    try {
        dispatch({type: Admintypes.GET_NEWS_FEED_TEXT_LINK_SUMMARY_COUNT})
        const response = await post(ROUTES.GET_NEWS_FEED_TEXT_LINK_SUMMARY_COUNT, {fetchData})
        const {status, data} = response;

        if (status === 200) {
            dispatch({type: Admintypes.GET_NEWS_FEED_TEXT_LINK_SUMMARY_COUNT_SUCCESS, payload: data.data})
        } else {
            dispatch({type: Admintypes.GET_NEWS_FEED_TEXT_LINK_SUMMARY_COUNT_FAIL})
        }
    } catch (exception) {
        dispatch({type: Admintypes.GET_NEWS_FEED_TEXT_LINK_SUMMARY_COUNT_FAIL})
    }
}

export const getMentionTagReportingInformation = async fetchData => {
    try {
        const response = await post(ROUTES.GET_MENTION_TAG_REPORTING_INFORMATION, {fetchData})
        const {status, data} = response;
        if (status === 200) {
            return ({success: true, data: data.data[0] ? data.data[0] : {}})
        } else {
            return ({success: false, data: {}})
        }
    } catch (exception) {
        return ({success: false, data: {}})
    }
}

export const updateRewardRedemption = userList => async dispatch => {
    try {
        dispatch({type: Admintypes.UPDATE_REWARD_REDEMPTION})
        const response = await post(ROUTES.UPDATE_REWARD_REDEMPTION, {userList})
        const {status, data} = response;

        if (status === 200) {
            if (!data.data) showAlert(dispatch, ALERT.WARNING, data.message);
            dispatch(getAllRewardRedemption());
            dispatch({type: Admintypes.UPDATE_REWARD_REDEMPTION_SUCCESS, payload: data.data});
        } else {
            dispatch({type: Admintypes.UPDATE_REWARD_REDEMPTION_FAIL})
        }
    } catch (exception) {
        dispatch({type: Admintypes.UPDATE_REWARD_REDEMPTION_FAIL})
    }
}


export const addRewardRedemption = userIds => async dispatch => {
    try {
        dispatch({type: Admintypes.ADD_REWARD_REDEMPTION})
        const response = await post(ROUTES.ADD_REWARD_REDEMPTION, {userIds})
        const {status, data} = response;

        if (status === 200) {
            if (!data.data) showAlert(dispatch, ALERT.WARNING, data.message);
            dispatch(getAllRewardRedemption());
            dispatch({type: Admintypes.ADD_REWARD_REDEMPTION_SUCCESS, payload: data.data});
        } else {
            dispatch({type: Admintypes.ADD_REWARD_REDEMPTION_FAIL, payload: data.data})
        }
    } catch (exception) {
        dispatch({type: Admintypes.ADD_REWARD_REDEMPTION_FAIL})
    }
}

export const getAllRewardRedemption = (skip, limit) => async dispatch => {

    try {
        dispatch({type: Admintypes.GET_ALL_REWARD_REDEMPTION})

        const response = await post(ROUTES.GET_ALL_REWARD_REDEMPTION, {skip, limit})
        const {status, data} = response;

        if (status === 200) {
            dispatch(getAllRewardRedemptionCount());
            dispatch({type: Admintypes.GET_ALL_REWARD_REDEMPTION_SUCCESS, payload: data.data})
        } else {
            dispatch({type: Admintypes.GET_ALL_REWARD_REDEMPTION_FAIL})
        }
    } catch (exception) {
        dispatch({type: Admintypes.GET_ALL_REWARD_REDEMPTION_FAIL})
    }
}

export const getAllRewardRedemptionCount = () => async dispatch => {
    try {
        dispatch({type: Admintypes.GET_ALL_REWARD_REDEMPTION_COUNT})
        const response = await get(ROUTES.GET_ALL_REWARD_REDEMPTION_COUNT)
        const {status, data} = response;

        if (status === 200) {
            dispatch({type: Admintypes.GET_ALL_REWARD_REDEMPTION_COUNT_SUCCESS, payload: data.data})
        } else {
            dispatch({type: Admintypes.GET_ALL_REWARD_REDEMPTION_COUNT_FAIL})
        }
    } catch (exception) {
        dispatch({type: Admintypes.GET_ALL_EXPERT_CONTRIBUTER_COUNT_FAIL})
    }
}

export const updateConfig = (configs) => async dispatch => {
    try {
        dispatch({type: Admintypes.UPDATE_EMAIL_DIET});
        const response = await put(ROUTES.UPDATE_CONFIGS, configs)
        const {status, data} = response;
        if (status === 200) {
            dispatch(getConfig());
            dispatch({type: Admintypes.UPDATE_EMAIL_DIET_SUCCESS, payload: data.data});
            showAlert(dispatch, ALERT.SUCCESS, response.data.message);
        } else {
            dispatch({type: Admintypes.UPDATE_EMAIL_DIET_FAIL});
            showAlert(dispatch, ALERT.SUCCESS, response.data.message);
        }
    } catch (exception) {
        dispatch({type: Admintypes.UPDATE_EMAIL_DIET_FAIL});
    }
}

export const getConfig = () => async dispatch => {
    try {
        dispatch({type: Admintypes.GET_EMAIL_CONFIG});
        const response = await get(ROUTES.GET_CONFIGS)
        const {status, data} = response;
        if (status === 200) {
            dispatch({type: Admintypes.GET_EMAIL_CONFIG_SUCCESS, payload: data.data});
        } else {
            dispatch({type: Admintypes.GET_EMAIL_CONFIG_FAIL});
        }
    } catch (exception) {
        dispatch({type: Admintypes.GET_EMAIL_CONFIG_FAIL});
    }
}

export const startEmulateSession = (emulateUserId) => async dispatch => {

    try {
        const response = await post(ROUTES.START_EMULATE_SESSION, {emulateUserId})
        const {data} = response;
        if (data.code === 200) {

            const previousSession = {
                userId: localStorage.getItem('userId'),
                token: localStorage.getItem('token'),
                unsigned_profile_image: localStorage.getItem('unsigned_profile_image'),
                profilePicture: localStorage.getItem('profilePicture'),
                profileThumbnail: localStorage.getItem('profileThumbnail'),
                publicProfilePicture: localStorage.getItem('publicProfilePicture'),
            }

            localStorage.setItem('previousSession', JSON.stringify(previousSession));
            localStorage.setItem('emulateSessionId', localStorage.getItem('userId'));
            localStorage.setItem('userId', data.data._id);
            localStorage.setItem('token', data.data.TOKEN);
            localStorage.setItem('name', data.data.FIRST_NAME + ' ' + data.data.LAST_NAME);
            localStorage.setItem('unsigned_profile_image', data.data.PHOTO);

            dispatch(getAdminCompanyList());
            if (data.data.PHOTO !== '') {
                getProfileImageUrl('mediam-' + data.data.PHOTO, 1).then(photo => {
                    localStorage.setItem('profilePicture', photo);
                    dispatch({type: AuthTypes.GETTING_PROFILE_IMAGE_SUCCESS, payload: photo});
                    getProfileImageUrl('small-' + data.data.PHOTO, 1).then(thumbnail => {
                        localStorage.setItem('profileThumbnail', thumbnail);
                        dispatch({type: AuthTypes.GETTING_PROFILE_THUMBNAIL_IMAGE_SUCCESS, payload: thumbnail});
                        getProfileImageUrl(data.data.PHOTO, 1).then(publicImage => {
                            localStorage.setItem('publicProfilePicture', publicImage);
                            dispatch({type: AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_SUCCESS, payload: publicImage});
                            dispatch({type: AuthTypes.SIGNIN_SUCCESS, payload: data});
                            dispatch({type: AuthTypes.CLEAR_ACTION});
                            window.location.reload();
                        }).catch(
                            dispatch({type: AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_FAIL})
                        )

                    }).catch((err) => {
                        dispatch({type: AuthTypes.SIGNIN_SUCCESS, payload: data});
                        dispatch({type: AuthTypes.CLEAR_ACTION});
                        window.location.reload();
                    });
                }).catch((err) => {
                    dispatch({type: AuthTypes.SIGNIN_SUCCESS, payload: data});
                    dispatch({type: AuthTypes.CLEAR_ACTION});
                    window.location.reload();
                });
            } else {
                localStorage.removeItem('unsigned_profile_image');
                localStorage.removeItem('profilePicture');
                localStorage.removeItem('profileThumbnail');
                localStorage.removeItem('publicProfilePicture');
                window.location.reload();
            }

        } else {
            showPopupAlert(dispatch, POP_ALERT.ERROR, data.message);
        }
    } catch (exception) {
        showPopupAlert(dispatch, POP_ALERT.ERROR, 'An unexpected error occured while starting the emulate session');
    }

}

export const endEmulateSession = (previousSession) => async dispatch => {

    try {
        const response = await post(ROUTES.END_EMULATE_SESSION, {})
        const {data} = response;
        if (data.code === 200) {
            localStorage.removeItem('previousSession');
            localStorage.removeItem('emulateSessionId');
            localStorage.setItem('userId', previousSession.userId);
            localStorage.setItem('token', previousSession.token);
            localStorage.setItem('unsigned_profile_image', previousSession.unsigned_profile_image);
            localStorage.setItem('profilePicture', previousSession.profilePicture);
            localStorage.setItem('profileThumbnail', previousSession.profileThumbnail);
            localStorage.setItem('publicProfilePicture', previousSession.publicProfilePicture);
            window.location.reload();
        } else {
            showPopupAlert(dispatch, POP_ALERT.ERROR, data.message);
        }

    } catch (exception) {
        showPopupAlert(dispatch, POP_ALERT.ERROR, 'An unexpected error occured while ending the emulate session');
    }
}

export const getJBListings = async () => {
    try {
        const response = await get(ROUTES.FETCH_JB_LISTINGS)
        if (response && response.data.code === 200) {
            return {success: response.data.success, data: response.data.data}
        } else {
            return {
                success: false,
                data: (response && response.data && response.data.message ? response.data.message : 'unknown error occured')
            }
        }
    } catch (exception) {
        return {success: false, data: exception}
    }
}

export const turnOnJobListing = async (jobId) => {
    try {
        const response = await put(ROUTES.TURN_ON_JOB_PRIORITY + jobId, {})
        if (response && response.data.code === 200) {
            return {success: response.data.success, data: response.data.data}
        } else {
            return {
                success: false,
                data: (response && response.data && response.data.message ? response.data.message : 'unknown error occured')
            }
        }
    } catch (exception) {
        return {success: false, data: exception}
    }
}

export const turnOffJobListing = async (jobId) => {
    try {
        const response = await put(ROUTES.TURN_OFF_JOB_PRIORITY + jobId, {})
        if (response && response.data.code === 200) {
            return {success: response.data.success, data: response.data.data}
        } else {
            return {
                success: false,
                data: (response && response.data && response.data.message ? response.data.message : 'unknown error occured')
            }
        }
    } catch (exception) {
        return {success: false, data: exception}
    }
}


export const updatePriority = async (jobId, priority) => {
    try {
        const response = await put(ROUTES.UPDATE_PRIORITY_NUMBER + jobId, {PRIORITY_NUMBER: priority})
        if (response && response.data.code === 200) {
            return {success: response.data.success, data: response.data.data}
        } else {
            return {
                success: false,
                data: (response && response.data && response.data.message ? response.data.message : 'unknown error occured')
            }
        }
    } catch (exception) {
        return {success: false, data: exception}
    }
}

export const getJBWidgetVisibility = async () => {
    try {
        const response = await get(ROUTES.GET_JB_WIDGET_VISIBILITY)
        if (response && response.data.code === 200) {
            return {success: response.data.success, data: response.data.data}
        } else {
            return {
                success: false,
                data: (response && response.data && response.data.message ? response.data.message : 'unknown error occured')
            }
        }
    } catch (exception) {
        return {success: false, data: exception}
    }
}

export const setJBWidgetVisibility = async displayJobWidget => {
    try {
        const response = await put(ROUTES.SET_JB_WIDGET_VISIBILITY, {displayJobWidget})
        if (response && response.data.code === 200) {
            return {success: response.data.success, data: response.data.data}
        } else {
            return {
                success: false,
                data: (response && response.data && response.data.message ? response.data.message : 'unknown error occured')
            }
        }
    } catch (exception) {
        return {success: false, data: exception}
    }
}

export const getJobWidgetTitle = async () => {
    try {
        const response = await get(ROUTES.GET_JB_WIDGET_TITLE)
        if (response && response.data.code === 200) {
            return {success: response.data.success, data: response.data.data}
        } else {
            return {
                success: false,
                data: (response && response.data && response.data.message ? response.data.message : 'unknown error occured')
            }
        }
    } catch (exception) {
        return {success: false, data: exception}
    }
}

export const setJobWidgetTitle = async widgetTitle => {
    try {
        const response = await put(ROUTES.SET_JB_WIDGET_TITLE, {widgetTitle})
        if (response && response.data.code === 200) {
            return {success: response.data.success, data: response.data.data}
        } else {
            return {
                success: false,
                data: (response && response.data && response.data.message ? response.data.message : 'unknown error occured')
            }
        }
    } catch (exception) {
        return {success: false, data: exception}
    }
}

export const editJob = async (jobId, details) => {
    try {
        const response = await put(ROUTES.UPDATE_JOB + jobId, details)
        if (response && response.data.code === 200) {
            return {success: response.data.success, data: response.data.data}
        } else {
            return {
                success: false,
                data: (response && response.data && response.data.message ? response.data.message : 'unknown error occured')
            }
        }
    } catch (exception) {
        return {success: false, data: exception}
    }
}

export const createJob = async (details) => {
    try {
        const response = await post(ROUTES.CREATE_JOB, details)
        if (response && response.data.code === 200) {
            return {success: response.data.success, data: response.data.data}
        } else {
            return {
                success: false,
                data: (response && response.data && response.data.message ? response.data.message : 'unknown error occured')
            }
        }
    } catch (exception) {
        return {success: false, data: exception}
    }
}

export const createJobPosition = async (details) => {
    try {
        const response = await post(ROUTES.CREATE_JOB_POSITIONS, details)
        if (response && response.data.code === 200) {
            return {success: response.data.success, data: response.data.data}
        } else {
            return {
                success: false,
                data: (response && response.data && response.data.message ? response.data.message : 'unknown error occured')
            }
        }
    } catch (exception) {
        return {success: false, data: exception}
    }
}

export const editJobPosition = async (details) => {
    try {
        const response = await put(ROUTES.UPDATE_JOB_POSITIONS, details)
        if (response && response.data.code === 200) {
            return {success: response.data.success, data: response.data.data}
        } else {
            return {
                success: false,
                data: (response && response.data && response.data.message ? response.data.message : 'unknown error occured')
            }
        }
    } catch (exception) {
        return {success: false, data: exception}
    }
}

export const listJobPositions = async (params) => {
    try {
        const response = await post(ROUTES.LIST_JOB_POSITIONS, {listLevels: params.listLevels})
        if (response && response.data.code === 200) {
            return {success: response.data.success, data: response.data.data}
        } else {
            return {
                success: false,
                data: (response && response.data && response.data.message ? response.data.message : 'unknown error occured')
            }
        }
    } catch (exception) {
        return {success: false, data: exception}
    }
}

export const fetchRecruiterJobPositions = async () => {
    try {
        const response = await post(ROUTES.LIST_RECRUITER_JOB_POSITIONS, {})
        if (response && response.data.code === 200) {
            return {success: response.data.success, data: response.data.data}
        } else {
            return {
                success: false,
                data: (response && response.data && response.data.message ? response.data.message : 'unknown error occured')
            }
        }
    } catch (exception) {
        return {success: false, data: exception}
    }
}

export const updateJobPositionStatus = async (positionId) => {
    try {
        const response = await del(ROUTES.DELETE_JOB_POSITIONS + positionId)
        if (response && response.data.code === 200) {
            return {success: response.data.success, data: response.data.data}
        } else {
            return {
                success: false,
                data: (response && response.data && response.data.message ? response.data.message : 'unknown error occured')
            }
        }
    } catch (exception) {
        return {success: false, data: exception}
    }
}

export const submitSurvey = async (surveyData) => {
    try {
        const response = await post(ROUTES.SUBMIT_SURVEY, surveyData)
        if (response && response.data.code === 200) {
            return {success: response.data.success, data: response.data.data}
        } else {
            return {
                success: false,
                data: (response && response.data && response.data.message ? response.data.message : 'unknown error occured')
            }
        }
    } catch (exception) {
        return {success: false, data: exception}
    }
}

export const getAdminStats = async () => {

    try {
        const {data, err} = await ApiConnector.get(ROUTES.GET_ADMIN_STATS);
        return {
            stats: data,
            success: !err
        };
    } catch (e) {
        return {
            success: false
        };
    }
};

export const getCandidateSurveyData = async (type = 10) => {


    try {
        const {data, err} = await ApiConnector.post(ROUTES.GET_CANDIDATE_SURVEY_DATA, {type});
        return {
            data: data.data ? data.data : {},
            success: !err
        };
    } catch (e) {
        return {
            success: false
        };
    }
};

export const fetchTestimonials = async (details) => {

    try {
        const {data, err} = await ApiConnector.post(ROUTES.GET_TESTIMONIAL_DATA, details);
        return {
            data: data.data ? data.data : {},
            success: !err
        };
    } catch (e) {
        return {
            success: false
        };
    }
};

export const fetchPublicTestimonials = async () => {

    try {
        const {data, err} = await ApiConnector.get(ROUTES.GET_PUBLIC_TESTIMONIAL_DATA);
        return {
            data: data.data ? data.data : {},
            success: !err
        };
    } catch (e) {
        return {
            success: false
        };
    }
};

export const fetchTestimonialsCount = async () => {

    try {
        const {data, err} = await ApiConnector.get(ROUTES.GET_TESTIMONIAL_DATA_COUNT);
        return {
            data: data.data ? data.data : {},
            success: !err
        };
    } catch (e) {
        return {
            success: false
        };
    }
};

export const addTestimonial = async (details) => {

    try {
        const {data} = await ApiConnector.post(ROUTES.CREATE_TESTIMONIAL_DATA, details);
        return {
            data: data.data ? data.data : {},
            message: data.message,
            success: data.code === 200
        };
    } catch (e) {
        return {
            success: false
        };
    }
};

export const updateTestimonial = async (id, details) => {

    try {
        const {data} = await ApiConnector.put(`${ROUTES.UPDATE_TESTIMONIAL_DATA}${id}`, details);
        return {
            data: data.data ? data.data : {},
            message: data.message,
            success: data.code === 200
        };
    } catch (e) {
        return {
            success: false
        };
    }
};

export const deleteTestimonial = async (id) => {

    try {
        const {data} = await ApiConnector.del(`${ROUTES.DELETE_TESTIMONIAL_DATA}${id}`, {});
        return {
            data: data.data ? data.data : {},
            message: data.message,
            success: data.code === 200
        };
    } catch (e) {
        return {
            success: false
        };
    }
};

export const fetchAdminEmailStats = async (filter) => {

    try {
        const {data, err} = await ApiConnector.post(ROUTES.FETCH_ADMIN_EMAIL_STATS, {fetchData: filter});
        return {
            data: data.data ? data.data : {},
            success: !err
        };
    } catch (e) {
        return {
            success: false
        };
    }
};

export const fetchAdminEmailStatsCount = async (filter) => {

    try {
        const {data} = await ApiConnector.post(ROUTES.FETCH_ADMIN_EMAIL_STATS_COUNT, {fetchData: filter});
        return {
            data: data.data ? data.data : {},
            success: data && data.code === 200
        };
    } catch (e) {
        return {
            success: false
        };
    }
};

export const getAutoConnectionRequestStatistics = (fetchData) => async dispatch => {
    try {
        dispatch({ type: Admintypes.GET_AUTO_CONNECTION_REQUESTS_STATISTICS })
        const response = await post(ROUTES.GET_AUTO_CONNECTION_REQUESTS_STATISTICS, { fetchData })
        const { status, data } = response;

        if (status === 200) {
            dispatch(getAutoConnectionRequestStatisticsCount(fetchData));
            dispatch({ type: Admintypes.GET_AUTO_CONNECTION_REQUESTS_STATISTICS_SUCCESS, payload: data.data })
        } else {
            dispatch({ type: Admintypes.GET_AUTO_CONNECTION_REQUESTS_STATISTICS_FAIL })
        }
    } catch (exception) {
        dispatch({ type: Admintypes.GET_AUTO_CONNECTION_REQUESTS_STATISTICS_FAIL })
    }
};

export const getAutoConnectionRequestStatisticsCount = (fetchData) => async dispatch => {
    try {
        dispatch({ type: Admintypes.GET_AUTO_CONNECTION_REQUESTS_STATISTICS_COUNT })
        const response = await post(ROUTES.GET_AUTO_CONNECTION_REQUESTS_STATISTICS_COUNT, { fetchData })
        const { status, data } = response;

        if (status === 200) {
            dispatch({ type: Admintypes.GET_AUTO_CONNECTION_REQUESTS_STATISTICS_COUNT_SUCCESS, payload: data.data[0].TOTAL_COUNT })
        } else {
            dispatch({ type: Admintypes.GET_AUTO_CONNECTION_REQUESTS_STATISTICS_COUNT_FAIL })
        }
    } catch (exception) {
        dispatch({ type: Admintypes.GET_AUTO_CONNECTION_REQUESTS_STATISTICS_COUNT_FAIL })
    }
};

export const updateEventDiscountDetails = (fetchData) => async dispatch => {
    try {
        dispatch({ type: Admintypes.UPDATING_EVENT_DISCOUNT })
        const response = await post(ROUTES.ADMIN_EDIT_EVENT_DISCOUNT_DETAILS, fetchData)
        const { status } = response;

        if (status === 200) {
            dispatch({ type: Admintypes.UPDATING_EVENT_DISCOUNT_SUCCESS, payload: true })
            return true
        } else {
            dispatch({ type: Admintypes.UPDATING_EVENT_DISCOUNT_FAIL })
        }
    } catch (exception) {
        dispatch({ type: Admintypes.UPDATING_EVENT_DISCOUNT_FAIL })
    }
};
export const getUsersProStatusData = async (dispatch, data) => {
    let config = {
        headers: {
            Authorization: localStorage.getItem('token')
        }
    };

    try {
        const response = await ApiConnector.post(
            ROUTES.GET_USER_PRO_STATUS,
            data,
            config
        );

        if (response.data && response.data.success && response.data.data) {
            return response;
        } else {
            showPopupAlert(
                dispatch,
                POP_ALERT.ERROR,
                'Unexpected error occured while getting pro status'
            );
            return null;
        }
    } catch (e) {
        showPopupAlert(
            dispatch,
            POP_ALERT.ERROR,
            'Unexpected error occured while getting pro status'
        );
        return null;
    }
};

export const updateUsersProStatusData = async (dispatch, userId, data) => {
    let config = {
        headers: {
            Authorization: localStorage.getItem('token')
        }
    };

    try {
        const response = await ApiConnector.put(
            ROUTES.UPDATE_USER_PRO_STATUS + '/' + userId,
            data,
            config
        );

        if (response.data && response.data.success && response.data.data) {
            return response;
        } else {
            showPopupAlert(
                dispatch,
                POP_ALERT.ERROR,
                'Unexpected error occured while saving pro status'
            );
            return null;
        }
    } catch (e) {
        showPopupAlert(
            dispatch,
            POP_ALERT.ERROR,
            'Unexpected error occured while saving pro status'
        );
        return null;
    }
};

export const updateUserConnectionLimits = async (userId, allowAnotherTwentyConnections, unlimitedConnections, socket) => {

    try {
        const {data, err} = await ApiConnector.put(`${ROUTES.EDIT_USER_CONNECTION_LIMIT}${userId}`, { allowAnotherTwentyConnections, unlimitedConnections });

        if(data.code === 200 && (allowAnotherTwentyConnections || unlimitedConnections) && socket){
            notifyReceiver(
                socket,
                userId,
                NOTIFICATION_TYPES.CONNECTION_LIMIT_EXTENDED,
                {}
            );
        } else if(!socket) {
            console.error('Socket not available')
        }

        return {
            data: data.data,
            success: data.code === 200
        };
    } catch (e) {
        return {
            success: false
        };
    }
};
