import React, { Component } from "react";
import defaultAvatorCompany from "./../../../../assets/img/companyAvatar.jpg";
import defaultAvatorPeople from "./../../../../assets/img/avatar.jpeg";

import docIcon from "./../../../../assets/img/doc.svg";
import docxIcon from "./../../../../assets/img/docx.svg";
import pdfIcon from "./../../../../assets/img/pdf.svg";

import "./index.css";
import { isURL } from './../../../../libs/validation';
import { DOMAINS } from './../../../../types/common';
import { ROUTES } from '../../../../config';
import {get} from "../../../../libs/apiConnector";

class FeedAvatarProfile extends Component {

  constructor(props){
    super(props);
    this.state = {
      avatar: this.props.isCompany ? defaultAvatorCompany : defaultAvatorPeople
    }
  }

  componentWillMount(){
    if(!isURL(this.props.avatar, { protocols: ['https'] }, DOMAINS.S3) && this.props.avatar !== '' && !this.props.noFetch){
      this._getImageUrl(this.props.avatar);
    }
  }

  _getImageUrl(imageName){
    get(`${ROUTES.IMAGE_PATH}/${imageName}/1`)
      .then(response => {
        if (response.status === 200) {
          if (response.data) {
            this.setState({avatar:response.data});
          }
        }
      })
  }

  getProfile = (avatar, type, size) => {
    let fileType = type !== undefined ? type.split("/"): "";
    let fileIcon = fileType[1] === "msword" ? docIcon : fileType[1] === "pdf" ? pdfIcon : fileType[1] === "vnd.openxmlformats-officedocument.wordprocessingml.document" ? docxIcon : "";
  
    if (avatar) {
      if (fileType[0] === 'application') {
        return (
          <div className="avatar_profile" style={{backgroundImage: "url(" + fileIcon + ")", width: size + "px", height: size + "px"}}>
            {/*this.getProgileType(type)*/}
          </div>
        );
      } else if (fileType[0] === 'image') {
        return (
          <div className="avatar_profile" style={{backgroundImage: "url(" + this.props.avatar + ")", width: size + "px", height: size + "px"}}>
            {/*this.getProgileType(type)*/}
          </div>
        );
      } else if (fileType[0] === 'video') {
        return (
          <div className="avatar_profile" style={{backgroundImage: "url(" + this.props.avatar + ")", width: size + "px", height: size + "px"}}>
            {/*this.getProgileType(type)*/}
          </div>
        );
      } else {
        return (<div></div>);
      }
    } else {
      if (fileType[0] === 'application') {
        return (
          <div className="avatar_profile" style={{backgroundImage: "url(" + fileIcon + ")", width: size + "px", height: size + "px"}}>
            {/*this.getProgileType(type)*/}
          </div>
        );
      } else if (fileType[0] === 'image') {
        return (
          <div className="avatar_profile" style={{backgroundImage: "url(" + this.props.avatar + ")", border: "1px solid #DDD", width: size + "px", height: size + "px"}}>
            {/*this.getProgileType(type)*/}
          </div>
        );
      } else {
        return (<div></div>);
      }
    }
  };

  /*getProfile = (avatar, type, size) => {
    if (avatar) {
      return (
        <div
          className="avatar_profile"
          style={{
            backgroundImage: "url(" + this.props.avatar + ")",
            width: size + "px",
            height: size + "px"
          }}
        >
          {this.getProgileType(type)}
        </div>
      );
    } else {
      return (
        <div
          className="avatar_profile"
          style={{
            backgroundImage: "url(" + this.props.avatar + ")",
            border: "1px solid #DDD",
            width: size + "px",
            height: size + "px"
          }}
        >
          {this.getProgileType(type)}
        </div>
      );
    }
  };*/

  getProgileType = type => {
    if (type) {
      return (
        <div className="avatar_profile_type justify-content-center align-items-center d-flex font_medium">
          {type}
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    return this.getProfile(this.props.avatar, this.props.type, this.props.size);
  }

}

FeedAvatarProfile.defaulProps = {
  size: null,
  type: "FUNDRAISING"
};

export { FeedAvatarProfile };