import React, { Component } from "react";
import "./index.css";
import avatar from "./../../../../assets/img/default-male.jpg";
import { isURL } from "../../../../libs/validation";

class NewsFeedAvatar extends Component {

  renderImg = () => isURL(this.props.profilePicture) ? this.props.profilePicture : avatar;

  render() {
    return (
      <React.Fragment>
        <div className="profile_img_wrp ">
          <div className="profile_container">
            <img alt="profile" className="img-fluid profile-image w-100" src={this.renderImg()} />
            {/* {memberType && <div className={memeberStyles}>{memberType}</div>} */}
          </div>
          {this.props.children}
        </div>
      </React.Fragment>
    );
  }

}

NewsFeedAvatar.defaultProps = {
  profile: null,
  height: null,
};

export { NewsFeedAvatar };
