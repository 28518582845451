import AuthTypes from '../types/authentication';
import landingPageTypes from '../types/landingPages';
import {ROUTES} from '../config';
import {getAdminCompanyList, fetchToolTip, logout} from './auth'
import {MEMBER_TYPE, REGISTRATION_STEP} from '../types/common';
import {get, post} from "../libs/apiConnector";

export const routeLogin = (token) => async dispatch => {
    try {
        const response = await post(`${ROUTES.USERDETAILS}`, {emulateSessionId: localStorage.getItem('emulateSessionId')})
        if (response) {
            if (response.data.code === 200) {

                await dispatch(getAdminCompanyList());
                const user = response.data.data;
                // ROUTE
                if (
                    (user.MEMBER_TYPE === MEMBER_TYPE.INVESTOR && user.REGISTRATION_STEP === REGISTRATION_STEP.INVESTOR_STEP_04) ||
                    (user.MEMBER_TYPE === MEMBER_TYPE.PROFESSIONAL && user.REGISTRATION_STEP === REGISTRATION_STEP.PROFESSIONAL_STEP_03) ||
                    (user.MEMBER_TYPE === MEMBER_TYPE.ADMIN)
                ) {
                    dispatch(fetchToolTip())
                    dispatch({type: AuthTypes.UPDATE_USER, payload: user})
                    dispatch({type: AuthTypes.ROUTE_ACCESS_SUCCESS, payload: user});
                } else {
                    dispatch({type: AuthTypes.CLEAR_ROUTE_ACCESS, payload: []});
                }

                dispatch({type: AuthTypes.CLEAR_ACTION});
            }
        } else {
            dispatch(logout());
            dispatch({type: AuthTypes.CLEAR_ROUTE_ACCESS, payload: []});
            dispatch({type: AuthTypes.CLEAR_ACTION});
        }
    } catch (exception) {
        dispatch({type: AuthTypes.CLEAR_ROUTE_ACCESS, payload: []});
        dispatch({type: AuthTypes.CLEAR_ACTION});
        dispatch(logout());
    }
}

export const fetchCustomLandingPage = (landingPageUrl) => async (dispatch, getState) => {
    try {
        dispatch({type: landingPageTypes.FETCHING_CUSTOM_LANDING_PAGE});
        const response = await get(`${ROUTES.GET_CUSTOM_LANDING_PAGE}${landingPageUrl}`);
        if (response && response.data.code === 200) {
            if (!getState().landingPages.customLandingPage || (getState().landingPages.customLandingPage._id !== response.data.data._id)) {
                const CUSTOM_PAGE_URL = response.data.data.PAGE_URL;
                const CUSTOM_TOKEN = 'CUSTOM_LANDING_' + Math.floor(100000 + Math.random() * 900000);
                const createTrackingResponse = await post(`${ROUTES.CREATE_TRACKING_CUSTOM_LANDING_PAGE}`, {
                    'CUSTOM_PAGE_URL': CUSTOM_PAGE_URL,
                    'CUSTOM_TOKEN': CUSTOM_TOKEN
                });
                if (createTrackingResponse && createTrackingResponse.data.code === 200) {
                    localStorage.setItem('customLandingToken', CUSTOM_TOKEN);
                }
            }
            dispatch({type: landingPageTypes.FETCH_CUSTOM_LANDING_PAGE_SUCCESS, payload: response.data.data});
        } else {
            dispatch({type: landingPageTypes.FETCH_CUSTOM_LANDING_PAGE_FAIL});
        }
    } catch (exception) {
        console.error(exception);
        dispatch({type: landingPageTypes.FETCH_CUSTOM_LANDING_PAGE_FAIL});
    }
}