import React, { Component, useState, useEffect } from 'react';
import axios from 'axios';
import { EditorState, convertToRaw, ContentState, genKey, SelectionState, Modifier } from 'draft-js';

import Editor from 'draft-js-plugins-editor';

import createMentionPlugin from 'draft-js-mention-plugin';
import editorStyles from './style.css';
import { fetchTagList } from '../../../actions/newsFeed';
import 'draft-js-mention-plugin/lib/plugin.css';

import defaultUser from "./../../../assets/sample/avt.png";
import defaultCompany from "./../../../assets/img/companyAvatar.jpg";
import { getProfileImageUrl } from '../../../actions/auth';

export default class MentionTagInput extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editorState: (this.props.isEdit && this.props.value) ? this.props.value : EditorState.createEmpty(),
      suggestions: [],
      suggestionOpen: false
    };

    this.mentionPlugin = createMentionPlugin({
      key: genKey(),
      supportWhitespace: true,
      mentionTrigger: '@',
      entityMutability:'IMMUTABLE',
      mentionRegExp: '[^]*'
    });
    this.requestCancelToken = null;
  }

  onSearchChange = ({ value }) => {
    this.requestCancelToken && this.requestCancelToken.cancel('Cancelled Request');
    // An import statment would break server-side rendering.
    require('whatwg-fetch'); // eslint-disable-line global-require
    this.setState({ suggestions: [], suggestionOpen: true });
    let isSp = value.replace(/ /g, '').length > (value.length - 4);
    if (value.length >= 3 && isSp) {
      this.requestCancelToken = axios.CancelToken.source();
      fetchTagList(value, this.requestCancelToken.token).then(response => {
        if (response) this.setState({ suggestions: response.tagResults, suggestionOpen: false })
      }).catch(exception => {
        console.error('exception', exception);
      })
    }
  };

    focus = () => {
        this.editor.focus();
        if (this.props.toggleMobileNav) {
            this.props.toggleMobileNav(false)
        }
    };

    blur = () => {
        if (this.props.toggleMobileNav) {
            this.props.toggleMobileNav(true)
        }
    };

  onChange = (editorState) => {
    const richText = convertToRaw(editorState.getCurrentContent());
    const dataWithEntities = richText && (Object.entries(richText.entityMap).length > 0 && richText.entityMap.constructor === Object) ? richText : null;
    const returnPlainText = editorState.getCurrentContent().getPlainText('');
    const returnRichText = dataWithEntities
    //call handleText func after set state LEAF - 5274
    if (this.props.maxLength) {
      const contentState = editorState.getCurrentContent();
      const oldContent = this.state.editorState.getCurrentContent();
      if (contentState === oldContent || contentState.getPlainText().length < this.props.maxLength) {
        this.setState({ editorState }, () => {
          this.props.handleText( returnPlainText, returnRichText);
        });
      } else {
        const editorState = EditorState.undo(
          EditorState.push(
            this.state.editorState,
            ContentState.createFromText(oldContent.getPlainText()),
            'delete-character'
          )
        );
        this.setState({ editorState }, () => {
          this.props.handleText( returnPlainText, returnRichText);
        });
      }
    } else {
      this.setState({
        editorState,
      }, () => {
        this.props.handleText( returnPlainText, returnRichText);
      });
    }
  };

  resetTextBox = () => {
    let { editorState } = this.state;
    let contentState = editorState.getCurrentContent();
    const firstBlock = contentState.getFirstBlock();
    const lastBlock = contentState.getLastBlock();
    const allSelected = new SelectionState({
      anchorKey: firstBlock.getKey(),
      anchorOffset: 0,
      focusKey: lastBlock.getKey(),
      focusOffset: lastBlock.getLength(),
      hasFocus: true,
    });
    contentState = Modifier.removeRange(contentState, allSelected, 'backward');
    editorState = EditorState.push(editorState, contentState, 'remove-range');
    editorState = EditorState.forceSelection(
      editorState,
      contentState.getSelectionAfter(),
    );

    this.setState({ editorState });
  }

  onAddMention = (tag) => {
    this.setState({ suggestions: [], suggestionOpen: false })
  }

  handleReturn = (e) => {
    if (this.props.onKeyPress && this.state.editorState && this.state.editorState.getCurrentContent().getPlainText('').trim().length > 0 && !this.state.suggestionOpen) {
      this.props.onKeyPress();
      this.resetTextBox()
      return 'handled'
    }
    return 'not-handled'
  }

  render() {
    const { MentionSuggestions } = this.mentionPlugin;
    const plugins = [this.mentionPlugin];

    return (
      <div className={`${editorStyles.editor} post_input`} id="create_new_post" onClick={this.focus} onBlur={this.blur}>
        <Editor
          editorState={this.state.editorState}
          onChange={this.onChange}
          plugins={plugins}
          ref={(element) => { this.editor = element; }}
          placeholder={this.props.placeholder}
          handleReturn={this.handleReturn}
          stripPastedStyles={true}
        />
        <MentionSuggestions
          onSearchChange={this.onSearchChange}
          suggestions={this.state.suggestions}
          onAddMention={this.onAddMention}
          entryComponent={Entry}
          onOpen={() => this.setState({suggestionOpen: true})}
          onClose={() => this.setState({suggestionOpen: false})}
        />
      </div>
    );
  }
}

const Entry = (props) => {
  const {
    mention,
    theme,
    searchValue, // eslint-disable-line no-unused-vars
    isFocused, // eslint-disable-line no-unused-vars
    ...parentProps
  } = props;

  const [ image, setImage ] = useState(null)

  useEffect(() => {
    if (mention.TYPE === 1) setImage(defaultUser)
    else if (mention.TYPE === 2) setImage(defaultCompany)
    if (mention.PHOTO) {
      getProfileImageUrl('small-' + mention.PHOTO, 1).then(response => {
        if (response) {
          setImage(response)
        }
      })
    }
  }, [mention])

  return (
    <div key={mention._id} {...parentProps}>
      <div className={`suggestion ${theme.mentionSuggestionsEntryContainer}`}>
        <div className={`suggestion_image ${theme.mentionSuggestionsEntryContainerLeft}`}>
          <img
            alt=""
            src={image}
            className={theme.mentionSuggestionsEntryAvatar}
            role="presentation"
          />
        </div>

        <div className={`suggestion_details ${theme.mentionSuggestionsEntryContainerRight}`}>
          <div className={theme.mentionSuggestionsEntryText}>
            {mention.name}
          </div>
          {(mention.JOB_TITLE && mention.COMPANY_NAME) && <div className={`job_title ${theme.mentionSuggestionsEntryText}`}>
            {mention.JOB_TITLE + ' at ' + mention.COMPANY_NAME}
          </div>}
        </div>
      </div>
    </div>
  );
};
