module.exports = {
    EVENT_ACTION : {
        CLICK : 'click',
        VIEW : 'view',
        IMPRESSION : 'impression',
        P_CREATE: 'post_created',
        P_REMOVE : 'post_removed',
        P_LIKE : 'post_liked',
        P_DISLIKE : 'post_disliked',
        P_COMMENT_LIKE : 'post_comment_liked',
        P_COMMENT_DISLIKE : 'post_comment_disliked',
        P_COMMENT : 'post_commented',
        P_COMMENT_DELETE : 'post_comment_delete',
    },

    EVENT_TYPES : {
        TRACK_USER_EVENT: "TRACK_USER_EVENT",
        TRACK_USER_EVENT_SUCCESS: "TRACK_USER_EVENT_SUCCESS",
        TRACK_USER_EVENT_FAIL: "TRACK_USER_EVENT_FAIL"
    },

    ACTIVITIES : {
        CLICK : 1,
        VIEW : 2,
        IMPRESSION : 3,
    }

};