import React from "react";

const TransparentCard = ({ children, title, icon }) => {
  return (
    <div className="view_card mb-4">
      <div className="tp_card_header d-flex p-all25">
        <div>{icon}</div>
        <h3 className="font_20 font_dark_gray_two font_medium m-l15">{title}</h3>
      </div>
      {children}
    </div>
  );
};
export { TransparentCard };
