import {put} from "../libs/apiConnector";
import {ANALYTICS} from '../config';
import {EVENT_ACTION, EVENT_TYPES, ACTIVITIES} from '../types/eventTracking';
import moment from 'moment';
import { browserName, osName, deviceType } from 'react-device-detect';
import { clearSessionID } from './auth';

export const setPayload = ({user_id, page, section= null, element_type= null, element_id = null, action_type=null, is_user_profile = null, profile_id = null, post_id = null, post_share_platform = null }) => {
    try {
        if(!user_id || !page ) {
            return {success: false, obj: null}
        }
        if(!localStorage.getItem('LF_SESSION_ID')){
            return {success: false, obj: null}
        }

        const clickes = [EVENT_ACTION.CLICK, EVENT_ACTION.P_CREATE, EVENT_ACTION.P_LIKE, EVENT_ACTION.P_DISLIKE, EVENT_ACTION.P_COMMENT_LIKE, EVENT_ACTION.P_COMMENT_DISLIKE, EVENT_ACTION.P_COMMENT, EVENT_ACTION.P_COMMENT_DELETE]

        const payloadObj = {
            user_id: user_id,
            page: page,
            section: section,
            element_type: element_type,
            element_id: element_id,
            activity: clickes.includes(action_type) ? ACTIVITIES.CLICK : (action_type.toString() === EVENT_ACTION.VIEW ? ACTIVITIES.VIEW : (action_type.toString() === EVENT_ACTION.IMPRESSION ? ACTIVITIES.IMPRESSION : null)),
            browser: browserName.toUpperCase(),
            device: deviceType.toString() === 'browser' ? 'COMPUTER' : deviceType.toUpperCase(),
            os: osName.toUpperCase(),
            date_time: moment.utc().format(),
            is_user_profile: is_user_profile,
            profile_id : profile_id,
            session_id: localStorage.getItem('LF_SESSION_ID'),
            ip_address:'',
            post_create: action_type.toString() === EVENT_ACTION.P_CREATE ? true : ( action_type.toString() === EVENT_ACTION.P_REMOVE ? false : null),
            post_id: post_id,
            post_like: action_type.toString() === EVENT_ACTION.P_LIKE ? true : ( action_type.toString() === EVENT_ACTION.P_DISLIKE ? false : null),
            post_comment: action_type.toString() === EVENT_ACTION.P_COMMENT ? true : ( action_type.toString() === EVENT_ACTION.P_COMMENT_DELETE ? false : null),
            post_comment_like : action_type.toString() === EVENT_ACTION.P_COMMENT_LIKE ? true : ( action_type.toString() === EVENT_ACTION.P_COMMENT_DISLIKE ? false : null),
            post_share_platform: post_share_platform
        }
        return {success: true, obj: payloadObj}
    } catch (ex) {
        return {success: false, obj: null}
    }
    
}

export const trackEvent = (data) => async dispatch => {
    try {
        dispatch({type: EVENT_TYPES.TRACK_USER_EVENT});

        if(!localStorage.getItem('LF_SESSION_SET_TIME')){
            localStorage.setItem('LF_SESSION_SET_TIME', new Date());
        } else {
            const timeDiff = moment(new Date()).diff(moment(localStorage.getItem('LF_SESSION_SET_TIME')), 'minutes');
            if(timeDiff > ANALYTICS.TIMEOUT) {
                await dispatch(clearSessionID())
            }
            localStorage.setItem('LF_SESSION_SET_TIME', new Date());
        }
        const payloadObj = setPayload(data);

        if(payloadObj.success) {
            const res = await put(`${ANALYTICS.EVENTS_TRACK}`, payloadObj.obj, {headers: {'Content-Type': 'application/json', 'x-api-key' : ANALYTICS.X_API_KEY}});
            if (res && res.data && res.data.statusCode === 202) {
                dispatch({type: EVENT_TYPES.TRACK_USER_EVENT_SUCCESS});
            } else {
                dispatch({type: EVENT_TYPES.TRACK_USER_EVENT_FAIL});
            }

        }else {
            dispatch({type: EVENT_TYPES.SET_USER_EVENT_ARRAY_FAIL});
        }

    } catch (ex) {
        dispatch({type: EVENT_TYPES.TRACK_USER_EVENT_FAIL});
        console.error(ex);
    }
};

export const trackImpressions = (coordinates, user, page, section, itemId) => async dispatch => {
    try {
        if ( !(coordinates.top > window.innerHeight)) {

            const linkTypeSections = ['GROW_YOUR_NETWORK', 'TRENDING_TOPIC', 'TRAINING_THREAD']

            const eventPayload = {
                user_id: (user) ? user : null,
                page: page, 
                section: section ? section : null, 
                element_type: (section.toString() === "NEWSFEED") ? 'POST' : (linkTypeSections.includes(section) ? 'LINK' : null),
                element_id: (section === "GROW_YOUR_NETWORK" ? null : (section === "NEWSFEED" ? null : itemId)),
                action_type: EVENT_ACTION.IMPRESSION,
                profile_id: (section.toString() === "GROW_YOUR_NETWORK") ? itemId : null,
                post_id: (section.toString() === "NEWSFEED") ? itemId : null,
                is_user_profile: (section.toString() === "GROW_YOUR_NETWORK") ? true : null
            }

            dispatch(trackEvent(eventPayload));
            return true;
        } else return false

    } catch (ex) {
        console.log(ex)
        console.error(ex);
    }
};

