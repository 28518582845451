import React, { Component } from "react";
import "./styles.css";
import defaultCoverPhoto from "./../../../assets/sample/coverImage.png";
import { isURL } from './../../../libs/validation';
import { ROUTES } from '../../../config';
import {get} from "../../../libs/apiConnector";

class CoverImage extends Component {

  constructor(props){
    super(props);
    this.state = {
      avatar: null,
      imageList: [],
      imageTypes: ["image/gif", "image/png", "image/jpeg", "image/jpg", "image/bmp", "image/webp"],
      adminImageTypes: ["gif", "png", "jpeg", "jpg", "bmp", "webp"],
    }
  }

  componentWillMount() {
    if (this.state.imageTypes.indexOf(this.props.imgSrc.TYPE) !== -1 || this.state.adminImageTypes.indexOf(this.props.imgSrc.TYPE)) {
      if(!isURL(String.toString(this.props.imgSrc.FILE_NAME)) && this.props.imgSrc.FILE_NAME !== ''){
        this._getImageUrl(this.props.imgSrc.FILE_NAME);
      } else {
        this.setState({avatar: defaultCoverPhoto});
      }
    } else {
      this.setState({avatar: defaultCoverPhoto});
    }
  }

  _getImageUrl(imageName){
    get(`${ROUTES.IMAGE_PATH}/${imageName}/0`)
      .then(response => {
        if (response.status === 200) {
          if (response.data) {
            this.setState({avatar: response.data});
          }
        }
      })
  }

  render() {

    let style = { borderRadius: this.props.viewmood === true ? "7px 7px 0px 0px" : null };

    return (
      <div>
        {
          this.state.avatar !== null && <img src={this.state.avatar} style={style} className="post_image" alt="" />
        }
      </div>
    );

  }
}

CoverImage.defaultProps = {
  imgSrc: defaultCoverPhoto,
  viewmood: false
};

export { CoverImage };