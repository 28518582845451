import React from "react";
import "./index.css";

const Left = ({ children }) => {

  return (
    <div className="card_default LeftMenu p-r30 p-l30">
      <ul>
        {children}
      </ul>
    </div>
  );
};

const LeftMenuItem = ({ name, style, onClick, menuId, icon, children }) => {
  return (
    <li className={style}>
      <button className="p-t17 p-b17 font_16 font_light" onClick={onClick.bind(this, menuId)}>{icon}{name}</button>
        {children}
    </li>
  );
}

const LeftSecondUl = ({ children }) => {
  return (
    <div className="collapse">
      <ul className="flex-column pl-2 nav">
        {children}
      </ul>
    </div>
  );
};

const LeftSubMenuItem = ({ name, style, onClick, menuId }) => {
  return (
    <li className={style}>
      <button className="p-t20 p-b20 font_14 font_light" onClick={onClick.bind(this, menuId)}>{name}</button>
    </li>
    );
  };
  
  export { Left, LeftMenuItem, LeftSecondUl, LeftSubMenuItem };
  
