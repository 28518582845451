import axios from 'axios';
import AuthTypes from '../types/authentication';
import InvestorTypes from '../types/investor';
import {ALERT, POP_ALERT} from '../types/common';
import {showAlert, showPopupAlert} from './alert';
import {ROUTES} from '../config';
import defaultAvatorPeople from "../assets/img/default-male.jpg";
import {errorHandler} from '../libs/commonFunctions';
import {initlogin} from './auth';
import {get, post, put} from "../libs/apiConnector";

export function getInvesterTypes() {

    return function (dispatch) {
        dispatch({type: AuthTypes.FETCHING_INVESTOR_TYPES, payload: true});
        get(`${ROUTES.INVESTOR_OPTIONS}/`)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: AuthTypes.GETTING_INVESTOR_TYPES_SUCCESS, payload: response.data});
                } else {
                    dispatch({type: AuthTypes.GETTING_INVESTOR_TYPES_FAIL, payload: response.data});

                }
            })
            .catch((error) => {
                dispatch({type: AuthTypes.GETTING_INVESTOR_TYPES_FAIL});
                // showAlert(dispatch, ALERT.ERROR, error.message);
            })
    }
}

export function investorRegistrationStepTwo(data) {
    let info = {
        typeId: data.typeId,
        optionId: data.optionId,
        userId: data.userId
    };
    return function (dispatch) {
        dispatch({type: AuthTypes.FETCHING, payload: true});
        post(`${ROUTES.INVESTOR_STEP_2}`, info)
            .then(response => {
                if (response.data.code === 200) {
                    let user = response.data;
                    if (user.data) user.data.IS_FIRST_TIME = true;
                    dispatch({type: AuthTypes.INSERTING_USER_DATA_SUCCESS, payload: 2, response: user});
                    dispatch(initlogin(localStorage.getItem('token')));
                } else {
                    dispatch({type: AuthTypes.INSERTING_USER_DATA_FAIL, payload: 2, response: response.data});
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            })
            .catch((error) => {
                dispatch({type: AuthTypes.INSERTING_USER_DATA_FAIL, payload: 2, response: error});
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
            })
    }
}

export function investorRegistrationStepThree(data) {
    let info = {
        city: data.city,
        country: data.country,
        userId: data.userId
    };
    return function (dispatch) {
        post(`${ROUTES.INVESTOR_STEP_3}`, info)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: AuthTypes.INSERTING_USER_DATA_SUCCESS, payload: 3, response: response.data});
                    dispatch({type: AuthTypes.CLEAR_ACTION});
                } else {
                    dispatch({type: AuthTypes.INSERTING_USER_DATA_FAIL, payload: 3, response: response.data});
                    dispatch({type: AuthTypes.CLEAR_ACTION});
                    showAlert(dispatch, ALERT.ERROR, response.data.message);
                }
            })
            .catch((error) => {
                dispatch({type: AuthTypes.INSERTING_USER_DATA_FAIL, payload: 3, response: error});
                dispatch({type: AuthTypes.CLEAR_ACTION});
                errorHandler(dispatch, error);
            });
    }
}

export function investorRegistrationStepFour(data) {
    let info = {
        userId: data.userId,
        companyName: data.companyName,
        jobTitle: data.jobTitle,
    };
    return function (dispatch) {
        dispatch({type: AuthTypes.FETCHING, payload: true});
        post(`${ROUTES.INVESTOR_STEP_4}`, info)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: AuthTypes.INSERTING_USER_DATA_SUCCESS, payload: 4, response: response.data});
                    dispatch({type: AuthTypes.CLEAR_ACTION});
                } else {
                    dispatch({type: AuthTypes.INSERTING_USER_DATA_FAIL, payload: 4, response: response.data});
                    dispatch({type: AuthTypes.CLEAR_ACTION});
                    showAlert(dispatch, ALERT.ERROR, response.data.message);
                }
            })
            .catch((error) => {
                dispatch({type: AuthTypes.INSERTING_USER_DATA_FAIL, payload: 4, response: error});
                dispatch({type: AuthTypes.CLEAR_ACTION});
                errorHandler(dispatch, error);
            })
    }
}

export function getInvestorDetails() {
    return function (dispatch) {
        post(`${ROUTES.USERDETAILS}`, {})
            .then(response => {
                if (response.data.code === 200) {

                    if (response.data.data.SYSTEM_USER_MEMBER_TYPE === 3) {
                        dispatch({type: InvestorTypes.SET_INVESTOR_DETAILS, payload: response.data.data.INVESTOR[0]})
                    }

                } else if (response.data.code === 222) {
                    dispatch({type: AuthTypes.ACTIVATION_REQUIRED, payload: response.data})
                    showAlert(dispatch, ALERT.ERROR, response.data.message);
                } else {
                }
            })
            .catch((exception) => {

            })
    }
}

export function updateOverview(data, IId) {
    let info = data;
    return function (dispatch) {
        dispatch({type: InvestorTypes.UPDATING_INVESTOR_OVERVIEW});

        put(`${ROUTES.UPDATE_INVESTOR_DETAILS}/${IId}`, info)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: InvestorTypes.UPDATING_INVESTOR_OVERVIEW_SUCCESS, payload: response.data.data});
                    dispatch({type: AuthTypes.UPDATE_INVESTOR_OVERVIEW_SUCCESS, payload: response.data.data});
                    dispatch({type: InvestorTypes.CLEAR});
                } else {
                    dispatch({type: InvestorTypes.UPDATING_INVESTOR_OVERVIEW_FAIL});
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            })
            .catch((error) => {
                dispatch({type: InvestorTypes.UPDATING_INVESTOR_OVERVIEW_FAIL});
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message)
            });

    }
}

export function updateInvestorThesis(IId, data) {
    return function (dispatch) {
        dispatch({type: InvestorTypes.UPDATING_INVESTOR_THESIS});

        put(`${ROUTES.UPDATE_INVESTOR_OVERVIEW}/${IId}`, {overview: data})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: InvestorTypes.UPDATING_INVESTOR_THESIS_SUCCESS, payload: response.data.data});
                    dispatch({type: AuthTypes.UPDATE_INVESTOR_THESIS_SUCCESS, payload: response.data.data});
                    dispatch({type: AuthTypes.UPDATE_USER_PERSONAL_SUMMARY_SUCCESS});
                    dispatch({type: AuthTypes.CLEAR_ACTION});
                    dispatch({type: InvestorTypes.CLEAR});
                    showPopupAlert(dispatch, POP_ALERT.SUCCESS, response.data.message);
                } else {
                    dispatch({type: InvestorTypes.UPDATING_INVESTOR_THESIS_FAIL});
                    dispatch({type: AuthTypes.UPDATE_USER_PERSONAL_SUMMARY_FAIL});
                    dispatch({type: AuthTypes.CLEAR_ACTION});
                    dispatch({type: InvestorTypes.CLEAR});
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            })
            .catch((error) => {
                dispatch({type: InvestorTypes.UPDATING_INVESTOR_OVERVIEW_FAIL});
                dispatch({type: AuthTypes.UPDATE_USER_PERSONAL_SUMMARY_FAIL});
                dispatch({type: AuthTypes.CLEAR_ACTION});
                dispatch({type: InvestorTypes.CLEAR});
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message)
            })
    }
}

export function getCompanies(IId, cancelToken) {
    return function (dispatch) {
        dispatch({type: InvestorTypes.GET_COMPANY_LIST});
        put(`${ROUTES.GET_NON_INVESTED_COMPANIES}/${IId}`, {searchBy: ''}, {cancelToken})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: InvestorTypes.GET_COMPANY_LIST_SUCCESS, payload: response.data.data});
                } else {
                    dispatch({type: InvestorTypes.GET_COMPANY_LIST_FAIL});
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: InvestorTypes.GET_COMPANY_LIST_FAIL});
                }
            })
    }
}

export function getInvestedCompanies(iid, cancelToken) {
    return function (dispatch) {
        dispatch({type: InvestorTypes.GET_INVESTED_COMPANY_LIST});
        get(`${ROUTES.GET_INVESTED_COMPANIES}/${iid}`, {cancelToken})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: InvestorTypes.GET_INVESTED_COMPANY_LIST_SUCCESS, payload: response.data.data})
                } else {
                    dispatch({type: InvestorTypes.GET_INVESTED_COMPANY_LIST_FAIL})
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: InvestorTypes.GET_INVESTED_COMPANY_LIST_FAIL})
                }
            })
    }
}

export function addInvestedCompany(cid, iid) {
    return function (dispatch) {
        dispatch({type: InvestorTypes.ADD_INVESTED_COMPANY});
        put(`${ROUTES.ADD_INVESTED_COMPANY}/${iid}`, {companyId: cid})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: InvestorTypes.ADD_INVESTED_COMPANY_SUCCESS});
                    dispatch(getCompanies(iid));
                    dispatch(getInvestedCompanies(iid));
                } else {
                    dispatch({type: InvestorTypes.ADD_INVESTED_COMPANY_FAIL});
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            })
            .catch((error) => {
                dispatch({type: InvestorTypes.ADD_INVESTED_COMPANY_FAIL});
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message)
            })
    }
}

export function deleteInvestedCompany(cid, iid) {
    return function (dispatch) {
        dispatch({type: InvestorTypes.DELETE_INVESTED_COMPANY});
        put(`${ROUTES.REMOVE_INVESTED_COMPANY}/${iid}`, {companyId: cid})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: InvestorTypes.DELETE_INVESTED_COMPANY_SUCCESS});
                    dispatch(getCompanies(iid));
                    dispatch(getInvestedCompanies(iid));
                } else {
                    dispatch({type: InvestorTypes.DELETE_INVESTED_COMPANY_FAIL})
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
            })
            .catch((error) => {
                dispatch({type: InvestorTypes.DELETE_INVESTED_COMPANY_FAIL});
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message)
            })
    }
}

export function getAllInvestors(searchStr, companyId, cancelToken) {

    return function (dispatch) {
        dispatch({type: InvestorTypes.FETCHING_COMPANY_INVESTORS, payload: true, searchStr});
        put(`${ROUTES.GET_INDIVIDUAL_INVESTORS}/` + companyId, {searchStr}, {cancelToken})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({
                        type: InvestorTypes.FETCHING_COMPANY_INVESTORS_SUCCESS,
                        payload: response.data.data,
                        searchStr
                    });
                } else {
                    dispatch({type: InvestorTypes.FETCHING_COMPANY_INVESTORS_FAIL, payload: response.data});
                    // showAlert(dispatch, ALERT.ERROR, response.data.message);
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: InvestorTypes.FETCHING_COMPANY_INVESTORS_FAIL, payload: error});
                    // showAlert(dispatch, ALERT.ERROR, error.message);
                }
            })
    }
}

export function getAutorizedInvestors(companyId, cancelToken) {

    return function (dispatch) {
        dispatch({type: InvestorTypes.FETCHING_AUTHORIZED_INVESTORS});
        post(`${ROUTES.GET_AUTHORIZED_INVESTORS}` + companyId, {}, {cancelToken})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: InvestorTypes.FETCHING_AUTHORIZED_INVESTORS_SUCCESS, payload: response.data.data});
                    dispatch({type: InvestorTypes.CLEAR});
                } else {
                    dispatch({type: InvestorTypes.FETCHING_AUTHORIZED_INVESTORS_FAIL, payload: response.data});
                    dispatch({type: InvestorTypes.CLEAR});
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: InvestorTypes.FETCHING_AUTHORIZED_INVESTORS_FAIL, payload: error});
                    dispatch({type: InvestorTypes.CLEAR});
                }
            })
    }
}

export function selectInvestor(data) {
    return function (dispatch) {
        if (data.PHOTO) {
            get(`${ROUTES.IMAGE_PATH}/${'small-' + data.PHOTO}/1`)
                .then(response => {
                    if (response.status === 200) {
                        data.PHOTO = response.data ? response.data : defaultAvatorPeople;
                        dispatch({type: InvestorTypes.SELECT_INVESTOR, payload: data});
                    }
                });
        } else {
            data.PHOTO = defaultAvatorPeople;
            dispatch({type: InvestorTypes.SELECT_INVESTOR, payload: data});
        }
    }
}

export function clearSelectedInvestor() {
    return function (dispatch) {
        dispatch({type: InvestorTypes.CLEAR_SELECTED_INVESTORS});
    }
}

export function removeSelectedInvestor(id, index) {

    return function (dispatch) {
        dispatch({type: InvestorTypes.DELETE_SELECTED_INVESTOR, payload: index});
    }

}