import axios from "axios";
import { ROUTES } from '../config';
import { clearLocalStorageValues } from '../actions/auth';
// import { datadogLogs } from '@datadog/browser-logs'

const s3Axios = axios.create();
const axiosWithoutHeaders = axios.create();

let isRefreshing = false;
let requests = [];

axios.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem("token");
    if (accessToken) {
        config.headers["Content-Type"] = 'application/json';
        config.headers["Authorization"] = accessToken;
    }
    return config;
}, (err) => {
    Promise.reject(err);
});

axios.interceptors.response.use((response) => response, (error) => {
    const originalRequest = error.config;
    let token = localStorage.getItem("token");
    if (token && error.response.status === 401 && !originalRequest._retry) {
        if (!isRefreshing) {
            isRefreshing = true;
            originalRequest._retry = true;
            return axiosWithoutHeaders
                .post(ROUTES.REFRESH_TOKEN, { token })
                .then((res) => {
                    if (res.status === 200) {
                        localStorage.setItem("token", res.data.data);
                        requests.forEach(cb => cb(token))
                        requests = []
                        return axios(originalRequest)
                    } else {
                        clearLocalStorageValues();
                        window.location = '/';
                        console.error('token refresh error');
                    }
                })
                .catch(e => {
                    clearLocalStorageValues();
                    window.location = '/';
                    console.error('refresh failed');
                })
                .finally(() => {
                    isRefreshing = false
                })
        } else {
            // token is being refreshed and a promise that resolve has not been executed is returned
            return new Promise((resolve) => {
                // Put resolve in the queue, save it in a function form, and execute it directly after token refreshes
                requests.push((token) => {
                    resolve(axios(originalRequest))
                })
            })
        }
    } else {
        if(error && error.response && error.response.status && error.response.status !== 401 && error.response.status > 400){ //show server error banner except 401
            window.dispatchEvent(new CustomEvent("serverUnavailableError", {}));
            // datadogLogs.logger.error('API request error', error)
        }
        return Promise.reject(error)
    }
});

axiosWithoutHeaders.interceptors.response.use((response) => response, (error) => {
    let token = localStorage.getItem("token");
    if (token && (error.response.status === 401 || error.response.status === 400 || error.response.status === 404)) {
        clearLocalStorageValues();
        window.location = '/';
        console.error('refresh failed');
    } else {
        // datadogLogs.logger.error('API token refresh error', error)
        return Promise.reject(error)
    }
});

export const get = (url, config) => (axios.get(url, config));

export const post = (url, body, config) => (axios.post(url, body, config));

export const put = (url, body, config) => (axios.put(url, body, config));

export const imgPut = (url, body, config) => (s3Axios.put(url, body, config));

export const del = (url, body, config) => (axios.delete(url, config, body));