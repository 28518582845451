const commonValues = require('./common');
const { encodeUrlParams } = require('./../libs/textFormat');

const defaultSearchOptions = {
    ALL: {
        isCompanyLoadMore: false,
        isUsersLoadMore: false,
        limit: commonValues.SEARCH_LIMIT,
        memberType: 6,
        searchTerm: "",
        searchType: 2,
        skip: 0
    },
    PROFESSIONAL: {
        companyName: "",
        isCompanyLoadMore: false,
        isUsersLoadMore: false,
        jobTitle: "",
        limit: commonValues.SEARCH_LIMIT,
        location: "",
        memberType: 2,
        searchType: 2,
        skip: 0
    },
    INVESTOR: {
        companyName: "",
        companySize: "",
        companyType: "",
        fundingStage: "",
        investmentType: "",
        investorType: "",
        isCompanyLoadMore: false,
        isUsersLoadMore: false,
        jobTitle: "",
        limit: commonValues.SEARCH_LIMIT,
        location: "",
        maxAmount: "",
        memberType: 1,
        minAmount: "",
        plantTouching: "",
        productStage: "",
        searchType: 2,
        skip: 0
    },
    COMPANY_PROFILES: {
        companySize: "",
        companyStage: "",
        companyType: "",
        investmentType: "",
        isCompanyLoadMore: false,
        isUsersLoadMore: false,
        limit: commonValues.SEARCH_LIMIT,
        location: "",
        maxAmount: "",
        memberType: 4,
        minAmount: "",
        searchType: 2,
        skip: 0
    },
    FUND_RAISING_COMPANY_PROFILES: {
        companyName: "",
        estValuationMax: "",
        estValuationMin: "",
        fundingStage: "",
        investmentType: "",
        isCompanyLoadMore: false,
        isUsersLoadMore: false,
        licencing: "",
        limit: commonValues.FUND_RAISING_SEARCH_LIMIT,
        location: "",
        maxAmount: "",
        memberType: 5,
        minAmount: "",
        minInvestmentMax: "",
        minInvestmentMin: "",
        plantTouching: "",
        productStage: "",
        searchType: 2,
        skip: 0
    }
};

module.exports = {

    FILTER_PARAMS: [
        {
            value: 6,
            label: "All"
        },
        {
            value: 1,
            label: "Investors"
        },
        {
            value: 2,
            label: "Professionals"
        }
    ],
    COMPANY_STAGE: [
        {
            value: 1,
            label: "Seed Round"
        },
        {
            value: 2,
            label: "Series A"
        },
        {
            value: 3,
            label: "Series B"
        },
        {
            value: 4,
            label: "Series C"
        },
        {
            value: 5,
            label: "Growth"
        },
        {
            value: 6,
            label: "Bridge"
        },
        {
            value: 7,
            label: "Other"
        }
    ],
    PRODUCT_STAGE: [
        {
            value: 1,
            label: "In development"
        },
        {
            value: 2,
            label: "Beta"
        },
        {
            value: 3,
            label: "Full Launch"
        }
    ],
    COMPANY_SIZE: [
        {
            value: 1,
            label: "0 to 10 Employees"
        },
        {
            value: 2,
            label: "10 to 20 Employees"
        },
        {
            value: 3,
            label: "20 to 30 Employees"
        },
        {
            value: 4,
            label: "30 to 100 Employees"
        },
        {
            value: 5,
            label: "100 to 500 Employees"
        },
        {
            value: 6,
            label: "500+ Employees"
        }
    ],
    COMPANY_TYPE: [
        {
            value: 1,
            label: "Accounting and Bookkeeping"
        },
        {
            value: 2,
            label: "Adult-Use Cannabis Provider/Dispensary"
        },
        {
            value: 3,
            label: "Advocacy or Non-Profit"
        },
        {
            value: 4,
            label: "Analytical Testing and Lab Services"
        },
        {
            value: 5,
            label: "Cannabis Accessories"
        },
        {
            value: 6,
            label: "Cannabis Cultivation Supply"
        },
        {
            value: 7,
            label: "Cannabis Cultivator/Grower"
        },
        {
            value: 8,
            label: "CBD Products"
        },
        {
            value: 9,
            label: "Compliance"
        },
        {
            value: 11,
            label: "Construction and Contracting"
        },
        {
            value: 12,
            label: "Consulting"
        },
        {
            value: 13,
            label: "Data Services"
        },
        {
            value: 14,
            label: "Delivery Service"
        },
        {
            value: 15,
            label: "Education and Training"
        },
        {
            value: 16,
            label: "Energy and Sustainability"
        },
        {
            value: 17,
            label: "Equipment Manufacturing"
        },
        {
            value: 18,
            label: "Events and Conferences"
        },
        {
            value: 19,
            label: "Extractions and Infused Products"
        },
        {
            value: 20,
            label: "Financial Services and Payment Solutions"
        },
        {
            value: 21,
            label: "Health Care Professionals"
        },
        {
            value: 22,
            label: "Hemp Products"
        },
        {
            value: 23,
            label: "Human Resources and Staffing"
        },
        {
            value: 24,
            label: "Information Technology and Software"
        },
        {
            value: 25,
            label: "Insurance"
        },
        {
            value: 26,
            label: "Investment and Asset Management"
        },
        {
            value: 27,
            label: "Legal Services"
        },
        {
            value: 28,
            label: "Marketing, Branding, and Public Relations"
        },
        {
            value: 29,
            label: "Media and Information"
        },
        {
            value: 30,
            label: "Medical Cannabis Provider"
        },
        {
            value: 31,
            label: "Packaging and Labeling"
        },
        {
            value: 32,
            label: "Real Estate"
        },
        {
            value: 33,
            label: "Security"
        },
        {
            value: 34,
            label: "Waste Management"
        },
        {
            value: 35,
            label: "Science/Research"
        },
        {
            value: 36,
            label: "Technology/Tracking/POS"
        },
        {
            value: 37,
            label: "Other"
        }
    ],
    /*INVESTMENT_TYPES: [
        {
            value: 1,
            label: "Equity"
        },
        {
            value: 2,
            label: "Convertible Note"
        },
        {
            value: 3,
            label: "Line of Credit"
        },
        {
            value: 4,
            label: "Other"
        }
    ],*/
    INVESTMENT_TYPES: [
        {
            value: 1,
            label: "Equity"
        },
        {
            value: 2,
            label: "Line of Credit"
        },
        {
            value: 3,
            label: "Loan"
        },
        {
            value: 4,
            label: "Convertible Debt"
        },
        {
            value: 5,
            label: "Other"
        }
    ],
    INVESTMENT_STAGES: [
        {
            value: 1,
            label: "Seed"
        },
        {
            value: 2,
            label: "Growth"
        },
    ],
    AMOUNT: [
        {
            value: 1,
            label: "$0 to $9999"
        },
        {
            value: 2,
            label: "$10000 to $99999"
        },
        {
            value: 3,
            label: "$100000 to $999999"
        },
        {
            value: 4,
            label: "$1000000 to $99999999999"
        }
    ],
    POST_TYPES: [
        {
            value: 2,
            label: 'announcement'/* , 3 = event, 4 = status Change*/
        },
        {
            value: 3,
            label: 'event'
        },
        {
            value: 4,
            label: 'status Change'
        }
    ],
    PLANT_TOUCHING: [
        {
            value: 1,
            label: 'Yes'
        },
        {
            value: 2,
            label: 'No'
        },
        {
            value: 3,
            label: 'Both'
        }
    ],
    FULL_TIME_JOB: [
        {
            value: 1,
            label: 'Yes'
        },
        {
            value: 2,
            label: 'No'
        }
    ],
    LICENSE: [
        {
            value: 1,
            label: 'Yes'
        },
        {
            value: 2,
            label: 'No'
        }
    ],
    PRODUCT_STATUS: [
        {
            value: 3,
            label: 'All'
        },
        {
            value: 1,
            label: 'Published'
        },
        {
            value: 2,
            label: 'Not Published'
        }
    ],
    TOOLS: {
        'Events': {
            name: 'Events',
            icon: "far fa-calendar-alt tool__button__icon",
            path: '/events'
        },
        'Lead Generation': {
            name: 'Lead Generation',
            icon: "fab fa-pagelines tool__button__icon",
            path: '/lead-generation'
        },
        'Post a Job': {
            name: 'Post a Job',
            icon: "fas fa-clipboard tool__button__icon",
            path: '/post-a-job'
        },
        'Marketplace': {
            name: 'Marketplace',
            icon: "fas fa-shopping-cart  tool__button__icon",
            path: '/marketplace/company'
        },
        'Add Admin': {
            name: 'Add Admin',
            icon: "fas fa-user-plus tool__button__icon",
            path: '/manage-admins'
        },
        'Settings': {
            name: 'Settings',
            icon: "fas fa-sliders-h tool__button__icon",
            path: '/manage-admins'
        },
        'Board Room': {
            name: 'Board Room',
            icon: "fas fa-gavel tool__button__icon",
            path: '/board-room'
        },
        'Payments': {
            name: 'Payments',
            icon: "far fa-credit-card tool__button__icon",
            path: '/payments'
        },
        'Advertise': {
            name: 'Advertise',
            icon: "fas fa-bullhorn tool__button__icon",
            path: '/advertise'
        },
        'Data Room': {
            name: 'Data Room',
            icon: "fas fa-database tool__button__icon",
            path: '/data-room'
        },
    },
    TOOLS_STATUS: {
        ENABLED: 1,
        DISABLED: 2
    },
    EMAIL_TEMP: [
        {
            value: 1,
            label: "Welcome to event"
        },
        {
            value: 2,
            label: "Event Postponed"
        },
        {
            value: 3,
            label: "Event canceled"
        },
    ],
    LICENCING: [
        {
            value: 1,
            label: "Yes"
        },
        {
            value: 2,
            label: "No"
        }
    ],
    ACCOUNT_SETTINGS: ['Change Email and Password',
        // 'Delete Account',
        //'Personal Preferences'
    ],
    ADVANCED_SEARCH_DEFAULT: defaultSearchOptions,
    ADVANCED_SEARCH_DEFAULT_ENCODED: {
        ALL: encodeUrlParams(defaultSearchOptions.ALL),
        PROFESSIONAL: encodeUrlParams(defaultSearchOptions.PROFESSIONAL),
        INVESTOR: encodeUrlParams(defaultSearchOptions.INVESTOR),
        COMPANY_PROFILES: encodeUrlParams(defaultSearchOptions.COMPANY_PROFILES),
        FUND_RAISING_COMPANY_PROFILES: encodeUrlParams(defaultSearchOptions.FUND_RAISING_COMPANY_PROFILES)
    },
    HEADER_TYPES: {
        DEFAULT: '',
        MARKETPLACE: [
            '/marketplace/company/',
        ],
        MARKETPLACE_EDIT: [
            '/marketplace/product-create/',
            '/marketplace/product-edit/',
        ],
        MARKETPLACE_PUBLIC: [
            '/marketplace/view-product'
        ]
    },
    HEADER: {
        DEFAULT: 0,
        MARKETPLACE: 1,
        MARKETPLACE_EDIT: 2,
        MARKETPLACE_PUBLIC: 3
    },
    UTM_VARIABLES: [
        {
            value: 'ALL',
            label: 'All'
        },
        {
            value: 'UTM_SOURCE',
            label: 'UTM Source'
        },
        {
            value: 'UTM_MEDIUM',
            label: 'UTM Medium'
        },
        {
            value: 'UTM_CAMPAIGN',
            label: 'UTM Campaign'
        }
    ],
    CANDIDATE_POPUP_SOURCE_VALUES: [
        {
            value: commonValues.CANDIDATE_POPUP_SOURCE.ALL,
            label: 'All'
        },
        {
            value: commonValues.CANDIDATE_POPUP_SOURCE.POPUP,
            label: 'Pop-up'
        },
        {
            value: commonValues.CANDIDATE_POPUP_SOURCE.INTERNAL_EMAIL,
            label: 'Internal Email'
        },
        {
            value: commonValues.CANDIDATE_POPUP_SOURCE.PARTNER_EMAIL,
            label: 'Partner Email'
        },
        {
            value: commonValues.CANDIDATE_POPUP_SOURCE.WIDGET,
            label: 'Widget'
        },
        {
            value: commonValues.CANDIDATE_POPUP_SOURCE.JOB_BOARD,
            label: 'Job Board'
        },
    ],
    DEFAULT_FILTER: {
        name: '',
        memberType: 6,
        industryRole: '',
        investorType: '',
        companyType: '',
        fundingStage: '',
        productStage: '',
        investmentType: '',
        companySize: '',
        plantTouching: '',
        minAmount: '',
        maxAmount: '',
        companyName: '',
        jobTitle: '',
        location: '',
        city: '',
        state: '',
        country: '',
        countryCode: ''
    },
    DEFAULT_INVESTOR_FILTER: {
        industryRole: '',
        investorType: '',
        companyType: '',
        fundingStage: '',
        productStage: '',
        investmentType: '',
        companySize: '',
        plantTouching: '',
        minAmount: '',
        maxAmount: '',
    },
    // Please update in types/common if there's any change
    INDUSTRY_ROLE: [
        {label: 'Grower/Cultivation', value: 1},
        {label: 'Ancillary Service Provider', value: 2},
        {label: 'Retail/Dispensary', value: 3},
        {label: 'Policy Advocate', value: 4},
        {label: 'Media', value: 5},
        {label: 'Science/Research', value: 6},
        {label: 'Technology', value: 7},
        {label: 'Caregiver', value: 8},
        {label: 'Investor', value: 9},
        {label: 'Other', value: 10},
    ],
    ENCODED_DEFAULT_FILTER: {
        1: '',
        2: 'eyJhY3Rpb24iOiJ0b2dnbGVTZWN0aW9uIiwiYWN0aXZlU2VjdGlvbiI6MiwiZGF0YSI6bnVsbH0=',
        3: 'eyJhY3Rpb24iOiJ0b2dnbGVTZWN0aW9uIiwiYWN0aXZlU2VjdGlvbiI6MywiZGF0YSI6bnVsbH0=',
        4: 'eyJhY3Rpb24iOiJ0b2dnbGVTZWN0aW9uIiwiYWN0aXZlU2VjdGlvbiI6NCwiZGF0YSI6eyJza2lwIjowfX0=',
    },
    MP_PACKAGE: [
        {label: 'Basic', value: 1},
        {label: 'Professional', value: 2},
        {label: 'Pro Plus', value: 3},
        {label: 'Platinum', value: 4},
    ],
    TOOL_TIP_PAGE: {
        1: 'Homepage',
        2: 'MP Page',
        3: 'COMPANY PAGE',
        4: 'POP-UPS',
        5: 'Event Form',
        6: 'Connection Popup',
        7: 'PRO Messages'
    },
    TOOL_TIP_SECTION_HOME: {
        1: 'Search',
        2: 'Link',
        3: 'Post',
        4: 'Profile',
        5: 'Marketplace Widget',
        6: 'Trending Topics',
        7: 'Industry Events',
        8: 'Grow Your Network',
        9: 'Groups Widget',
        10: 'Jobs Widget'
    },
    TOOL_TIP_SECTION_MP: {
        1: 'Product Name',
        2: 'Product Description',
        3: 'Your Website Link',
        4: 'Choose Categories',
        5: 'Featured Listing',
        6: 'Graphics/Images',
        7: 'Special Offer',
    },
    TOOL_TIP_SECTION_COMPANY_PAGE: {
        1: 'Company Name',
        2: 'Company Name - Edit Page'
    },
    TOOL_TIP_SECTION_POP_UPS: {
        1: 'NF Edit - Company Name',
        2: 'NF Edit - Job Title',
    },
    TOOL_TIP_SECTION_EVENT_FORM: {
        1: 'Event Package',
        2: 'Event Name',
        3: 'Event Tags',
        4: 'Event URL',
        5: 'Event Special Offer',
        6: 'Mute Event Notification',
    },
    TOOL_TIP_SECTION_PRO_MESSAGE: {
        1: 'Connect Page - Recommended Connections (Non-PRO User)',
        2: 'User Profile',
        3: 'Messaging - Pro Message',
        4: 'Messaging - Non Pro User (Bar)',
        5: 'Connect Page - Recommended Connections (No PRO Messages)'
    },
    TOOL_TIP_SECTION_CONNECTION_MESSAGE: {
        1: 'Connection Popup - send message',
    },
    EMAIL_TEMPLATE_TYPES: [
        {
            value: 'ALL',
            label: 'ALL'
        },
        {
            value: 'd-7c025a2b08b04d1f85bf35d9b35dd619',
            label: 'WELCOME',
        },
        {
            value: 'd-35885e22736749f5a6f8c3fe69dc0e39',
            label: 'SECOND_EMAIL',
        },
        {
            value: 'd-fb88e32074364d82a0f9e22f4cd734ee',
            label: 'THIRD_EMAIL',
        },
        {
            value: '6455779a-17dc-4a94-aba5-cbe3f3cc635a',
            label: 'CONNECTION_ACCEPT',
        },
        {
            value: 'ce729590-dcdf-4e5b-9ef8-ae74a18006a1',
            label: 'PASSWORD_RESET',
        },
        {
            value: '259dc9ca-f97a-45b6-93a7-bc1549c68e76',
            label: 'CONTACT_US',
        },
        {
            value: '31c5364f-0245-4a80-bd82-012485010231',
            label: 'SUMMARY',
        },
        {
            value: '68e7c81c-63f3-4657-9ea7-bc1dd9e32586',
            label: 'PENDING_CONNECTIONS',
        },
        {
            value: '5bcd168a-c1d8-4a37-9685-372ccbdfa716',
            label: 'PENDING_CONNECTIONS_WITH_MESSAGE',
        },
        {
            value: '0548b92d-774e-42be-b868-b88c5c1f9645',
            label: 'ADVISOR_REQUEST',
        },
        {
            value: 'd-1a974a9ab38f40e7bbbfb55f89875e95',
            label: 'EVENT_PROMOTION',
        },
        {
            value: 'e085a483-26a3-4738-8207-1c8b52dd0342',
            label: 'NOTIFY_NEW_CONVERSATION',
        },
        {
            value: '0cbec992-b9d2-4508-9415-f0fa18e7b24d',
            label: 'NOTIFY_NEW_CONVERSATION_MARKETPLACE',
        },
        {
            value: 'c05799e2-4f6c-44e4-89fe-9c7b76556281',
            label: 'DATAROOM_REQUEST_ACCEPT',
        },
        {
            value: 'dbd4946a-8d25-4efe-b030-f9a5a8b22060',
            label: 'NOTIFY_NEW_COMMENT',
        },
        {
            value: 'cd59e140-0d34-4c56-b452-ca0d737d08a7',
            label: 'DATAROOM_INVITATION',
        },
        {
            value: 'd-f1f29f2e564f454da724376ae5ddbed8',
            label: 'UNSEEN_MESSAGES',
        },
        {
            value: 'd-71d37b6ff2624d7da9eb577ff98ac628',
            label: 'COMPANY_DELETE',
        },
        {
            value: 'd-d45fe18761214c30a5a9f15ddaf83b3b',
            label: 'MARKETPLACE_PRODUCT_DELETE',
        },
        {
            value: 'd-8eaf1b94d1ba4a02988e48d8fe07987d',
            label: 'MARKETPLACE_PRODUCT_EDIT',
        },
        {
            value: 'd-5f9e826013974d4a89eae8b100edce93',
            label: 'INITIAL_CHARGE',
        },
        {
            value: 'd-678199724798415cbc20772dce5a6b7a',
            label: 'SUBSCRIPTION_CANCELLATION',
        },
        {
            value: 'd-01c979e81d824defb35ce118191478f7',
            label: 'RECURRING_CHARGE',
        },
        {
            value: 'd-79985f4e2d8349298abf87ff33dc5301',
            label: 'RECURRING_CHARGE_FAILURE',
        },
        {
            value: '6b1fcfb7-9513-47ce-a10f-18013e4bac36',
            label: 'USER_REPORTS_I',
        },
        {
            value: 'f71e2a3f-cb3d-4b33-9eb6-ae1c9e4f5629',
            label: 'NOTIFY_NEW_TAG',
        },
        {
            value: '16f43467-5937-4fe1-8ffc-748827b470d5',
            label: 'USER_BLOCKED',
        },
        {
            value: '2c7f8fa5-a70d-43e5-b635-c11ef4d29651',
            label: 'USER_BLOCKED_ADMIN',
        },
        {
            value: '129252dc-dea2-4c6b-a3c2-eda9adb7aa39',
            label: 'NOTIFY_MARKETPLACE_SOLDOUT_FEATURED_CATEGORY',
        },
        {
            value: 'd-b77f853bbda74553a3673a391ee060f6',
            label: 'ONE_TIME_CHARGE',
        },
        {
            value: 'd-b401b62c143e4465857b7d45e4dc7848',
            label: 'JOB_SURVEY',
        },
    ]
}