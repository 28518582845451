import React, {Component} from "react";
import {Link} from 'react-router-dom';
import {AvatarProfile} from "./../../../common";
import {getPreview} from "../../../../libs/textFormat";
import "./index.css";
import {PUBLIC_PENDING_ACTIONS} from '../../../../types/common';
import {trackEvent} from '../../../../actions/eventTracking';
import {EVENT_ACTION} from '../../../../types/eventTracking';
import {MUTUAL} from '../../../../types/dataTrackingElementIds';

class Connection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isConnecting: false
        };
        this.wrapperRef = React.createRef();
        this._add = this._add.bind(this);
    }

    _add = (id, viewId) => {
        this.setState({isConnecting: true});
        this.props.addConnection(id, this.props.index);
        this.props.recordConnectClick(viewId);
    }

    /* data project - event trcking */
    componentDidMount() {
        this.listenToScroll();
        window.addEventListener('scroll', this.listenToScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll);
    }

    listenToScroll = () => {
        if (this.props.postRendered && this.wrapperRef.current) {
            this.props.postRendered(this.wrapperRef.current.getBoundingClientRect(), this.props.id);
        }
    }

    /* END : data project - event trcking */

    render() {
        return (
            <div ref={this.wrapperRef} className="d-flex flex-row justify-content-between m-b9 connection-row">
                <div className="d-flex flex-row network-item-leftwrp character_limit_wrp width-100">
                    <div className="gyn_img" style={{width: "60px"}}>
                        <Link
                            style={{textDecoration: 'none'}}
                            to={this.props.openLogin ? '/' : `/user-profile/${this.props.id}/${this.props.viewId}`}
                            onClick={() => {
                                /* data project - event trcking */
                                if (this.props.user) {
                                    const eventPayload = {
                                        user_id: this.props.user._id,
                                        page: this.props.page,
                                        section: this.props.section,
                                        element_type: 'LINK',
                                        element_id: null,
                                        action_type: EVENT_ACTION.CLICK,
                                        profile_id: this.props.id,
                                        is_user_profile: true
                                    }
                                    this.props.dispatch(trackEvent(eventPayload));
                                }
                                /* END : data project - event trcking */

                                if (this.props.openLogin) {
                                    this.props.openLogin(PUBLIC_PENDING_ACTIONS.CW_SEE_PROFILE, `/user-profile/${this.props.id}/${this.props.viewId}`)
                                } else {
                                    this.props.setProfileViewLocation(this.props.viewId)
                                }
                            }}
                        >
                            <AvatarProfile avatar={this.props.avatar} size={60} key={this.props.index}
                                           avatarSize="small-"/>
                        </Link>
                        <button
                            disabled={this.props.isAdding ? true : false}
                            className="circle_add_button d-flex justify-content-center align-items-center"
                            onClick={this._add.bind(this, this.props.id, this.props.viewId)}
                        >
                            {
                                this.props.isAdding ?
                                    <i className="fa fa-spinner fa-spin" style={{fontsize: "20px"}}/> :
                                    <i className="fas fa-plus"/>
                            }
                        </button>
                    </div>
                    <div className="m-l10 d-flex flex-column justify-content-center character_limit_wrp mp_right_wrp">
                        <Link
                            style={{ textDecoration: 'none' }}
                            to={this.props.openLogin ? '/' : `/user-profile/${this.props.id}/${this.props.viewId}`}
                            onClick={() => {
                                /* data project - event trcking */
                                if (this.props.user) {
                                    const eventPayload = {
                                        user_id: this.props.user._id,
                                        page: this.props.page,
                                        section: this.props.section,
                                        element_type: 'LINK',
                                        element_id: null,
                                        action_type: EVENT_ACTION.CLICK,
                                        profile_id: this.props.id,
                                        is_user_profile: true
                                    }
                                    this.props.dispatch(trackEvent(eventPayload));
                                }
                                /* END : data project - event trcking */

                                if (this.props.openLogin) {
                                    this.props.openLogin(PUBLIC_PENDING_ACTIONS.CW_SEE_PROFILE, `/user-profile/${this.props.id}/${this.props.viewId}`)
                                } else {
                                    this.props.setProfileViewLocation(this.props.viewId)
                                }
                            }}
                        >
                            <div className="m-b1 character_limit connection-name">{getPreview(this.props.name, 30)}</div>
                            </Link>

                            <div className="badges d-flex align-items-center">
                                {this.props.isProUser && <div className="badge badge-primary m-r5">PRO</div>}
                                {this.props.isExpertContributor &&
                                    <div className="badge badge-primary m-r5 green">EXPERT</div>}
                                {(this.props.isProUser || this.props.isExpertContributor) && this.props.mutualFriendCount > 0 && <span className="m-r5">•</span>}
                                {this.props.mutualFriendsCount > 0 && <span
                                    style={{ 'lineHeight': '1.1', 'cursor': 'pointer' }}
                                    onClick={() => {
                                        /* data project - event trcking */
                                        if (this.props.user) {
                                            const eventPayload = {
                                                user_id: this.props.user._id,
                                                page: this.props.page,
                                                section: this.props.section,
                                                element_type: this.props.section !== 'GROW_YOUR_NETWORK' ? 'LINK' : null,
                                                element_id: MUTUAL.MUTUAL_CON,
                                                action_type: EVENT_ACTION.CLICK
                                            }
                                            this.props.dispatch(trackEvent(eventPayload));
                                        }
                                        /* END : data project - event trcking */
                                        this.props.openCommonFriendsModal(this.props.mutualFriendsCount, this.props.id)
                                    }}
                                    className="font_11 font_bold text-left mutual-connections">
                                    {this.props.mutualFriendsCount} {this.props.mutualFriendsCount === 1 ? 'Mutual Connection' : 'Mutual Connections'}
                                </span>}
                            </div>
                            {this.props.isProUser && this.props.headline && this.props.headline !== null &&
                                <div className="headline">{this.props.headline}</div>}
                            {this.props.headline == null && <div className="m-b1 character_limit network-position">
                                {this.props.isIndevidual ? "" : getPreview(this.props.position, 55)}
                            </div>}
                        
                    </div>
                </div>
            </div>
        );
    }
}

Connection.defaultProps = {
    name: null,
    position: null
};

export {Connection};
