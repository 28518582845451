import { combineReducers } from 'redux';
import * as adminReducer from './admin';
import * as alertReducer from './alert';
import * as authReducer from './auth';
import * as investorReducer from './investor';
import * as companyReducer from './company';
import * as profileReducer from './profile';
import * as searchReducer from './search';
import * as connectionReducer from './connections';
import * as newsFeedReducer from './newsFeed';
import * as messageReducer from './message';
import * as messageReducerV2 from './message-v2';
import * as contactReducer from './contacts';
import * as preferencesReducer from './accountPreferences';
import * as settingsReducer from './settings';
import * as managePaymentReducer from './managePayments';
import * as dataRoomReducer from './dataRoom';
import * as marketPlaceReducer from './marketPlace';
import * as routeReducer from './route';
import * as imageReducer from './images';
import * as groupsReducer from './groups';
import * as eventsReducer from './events';
import * as landingPagesReducer from './landingPages';
import * as proReducer from './pro';
import * as vanityWordsReducer from './vanityWords';
import chatPopupsReducer from './chatPopups';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import AuthTypes from '../types/authentication';

const appReducer = combineReducers({
    admin: adminReducer.admin,
    alert: alertReducer.alert,
    auth: authReducer.auth,
    investor: investorReducer.investor,
    company: companyReducer.company,
    profile: profileReducer.profile,
    search: searchReducer.search,
    connection: connectionReducer.connections,
    newsFeed: persistReducer({
        key: "newsfeedPendingPost",
        storage,
        debug: true,
        whitelist: ['pendingPublish'] 
    },newsFeedReducer.newsFeed),
    message: messageReducer.messages,
    messageV2: messageReducerV2.messages,
    contact: contactReducer.contacts,
    preferences: preferencesReducer.accountPreferences,
    settings: settingsReducer.settings,
    managePayments: managePaymentReducer.managePayment,
    dataRoom: dataRoomReducer.dataRoom,
    marketplace: marketPlaceReducer.marketPlace,
    route: routeReducer.route,
    images: imageReducer.images,
    groups: groupsReducer.groups,
    events: eventsReducer.events,
    landingPages: landingPagesReducer.landingPages,
    pro: proReducer.pro,
    vanityWords: vanityWordsReducer.vanityWords,
    chats: persistReducer(
        {
          key: "conversations", // key for localStorage key, will be: "persist:form"
          storage,
          debug: true,
          blacklist: ['conversations', 'conversationsCount', 'isTyping', 'threadFetching'],
        },
        chatPopupsReducer
      ),
});

const rootReducer = (state, action) => {
    if (action.type === AuthTypes.LOGOUT) {
        localStorage.clear();
        state = {
            admin: adminReducer.initialState,
            alert: alertReducer.initialState,
            auth: authReducer.initialState,
            investor: investorReducer.initialState,
            company: companyReducer.initialState,
            profile: profileReducer.initialState,
            search: searchReducer.initialState,
            connection: connectionReducer.initialState,
            newsFeed: newsFeedReducer.initialState,
            message: messageReducer.initialState,
            messageV2: messageReducerV2.initialState,
            contact: contactReducer.initialState,
            preferences: preferencesReducer.initialState,
            settings: settingsReducer.initialState,
            managePayments: managePaymentReducer.initialState,
            dataRoom: dataRoomReducer.initialState,
            marketplace: marketPlaceReducer.initialState,
            route: routeReducer.initialState,
            images: imageReducer.initialState,
            groups: groupsReducer.initialState,
            events: eventsReducer.initialState,
            landingPages: landingPagesReducer.initialState,
            pro: proReducer.initialState,
            vanityWords: vanityWordsReducer.initialState
        }
    }

    return appReducer(state, action)
}

export default rootReducer;