import React, { useRef, useEffect } from "react";
import "./index.css";
import classnames from "classnames";


const CardDefault = ({ postRendered=null, postMouseEnter=null, postMouseLeave=null, children, transparent, customClass, ID, translate }) => {

  const wrapperRef = useRef(null);

  let cx = classnames("card_default_bottom_space" ,{ "card_default": !transparent }, customClass);
  let extra = ID !== null ? { id: ID } : {};


/* data project - event trcking */
  useEffect(() => {
    listenToScroll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener('touchmove', listenToScroll);
    window.addEventListener('scroll', listenToScroll);
    return () => {
      window.removeEventListener('touchmove', listenToScroll)
      window.removeEventListener('scroll', listenToScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listenToScroll = () => {
    if(postRendered && wrapperRef.current) {
        postRendered(wrapperRef.current.getBoundingClientRect());
      }
  }
/* END : data project - event trcking */


  return (
      <div ref={wrapperRef}  onMouseEnter={postMouseEnter ? postMouseEnter : null} onMouseLeave={postMouseLeave ? postMouseLeave : null} className={cx} {...extra} style={{ transform : translate > 0 ? `translateY(-${translate}px)` :  `unset`}} >
        {children}
      </div>
  );
};
CardDefault.defaultProps = {
  transparent: null
};
export { CardDefault };
