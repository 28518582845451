import { ROUTES } from '../config';
import { get } from "./apiConnector"
import ProductImg from "../assets/img/fundraisers-default.jpg";

export function getFileType(fileName) {
    return fileName.split('.').pop();
}

export function imageExists(url, callback, isProfilePic = false) {
    let img = new Image();
    img.onload = function () { callback(true); };
    img.onerror = function () {
        if (!isProfilePic) callback(false);
        else if (localStorage.getItem('unsigned_profile_image')) {
            const imageName = localStorage.getItem('unsigned_profile_image');
            const getImage = async (imageName) => {
                try {
                    const image = await get(`${ROUTES.IMAGE_PATH}/${imageName}/1`);
                    return image.data;
                } catch (ex) {
                    callback(false);
                }
            }

            getImage('mediam-' + imageName)
                .then(profilePicture => {
                    localStorage.removeItem('profilePicture');
                    localStorage.setItem('profilePicture', profilePicture);

                    getImage('small-' + imageName)
                        .then(profileThumbnail => {
                            localStorage.removeItem('profileThumbnail');
                            localStorage.setItem('profileThumbnail', profileThumbnail);

                            getImage(imageName)
                                .then(publicProfilePicture => {
                                    localStorage.removeItem('publicProfilePicture');
                                    localStorage.setItem('publicProfilePicture', publicProfilePicture);
                                });
                        });
                    callback(true);
                })
            callback(false);
        } else callback(false);
    };
    img.referrerPolicy = 'no-referrer'
    img.src = url;
}

export const getImageUrl = async (imgName, size, path = ROUTES.IMAGE_PATH) => {
    try {
        const res = await get(`${path}/${size + imgName}/1`);
        return res
    } catch (ex) {
        return null
    }
};

export const getMarketplaceWidgetImage = async (alternative, companyLogo, companyId) => {
    try {
        let res = ProductImg;
        if(alternative){
            const { data } = await get(`${ROUTES.GENERATE_MARKET_PLACE_VIEW_URL}/${alternative}/${companyId}`);
            res = data;
        } 
        if (companyLogo) {
            const { data } = await get(`${ROUTES.IMAGE_PATH}/${companyLogo}/${companyId}`);
            res = data;
        }
        return res
    } catch (ex) {
        return ProductImg;
    }
};

export const getGroupBanner = async (imgName) => {
    try {
        const res = await get(`${ROUTES.IMAGE_PATH}/${imgName}/0`);
        return res
    } catch (ex) {
        return null
    }
};

export const uploadProgress = ({ loaded, total }) => {
    try {
        const progress = (loaded / total);
        return Math.round(progress * 100);
    } catch (ex) {
        return null
    }
};

