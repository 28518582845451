import React, {useState} from "react";
import likeReaction from "./../../../assets/img/reactions/like.svg";
import loveReaction from "./../../../assets/img/reactions/love.svg";
import hahaReaction from "./../../../assets/img/reactions/haha.svg";
import sadReaction from "./../../../assets/img/reactions/sad.svg";
import angryReaction from "./../../../assets/img/reactions/angry.svg";
import { USER_EMOTION_TYPES } from './../../../types/common'
import "./index.css";


const ReactionsPopup = ({ react, ...props}) => {
    const [reactionTitle, setReactionTitle] = useState('');

    return (
        <div
            className="reaction-options-wrapper reaction-options-popup"
            id={`${props.idPrefix}_popup`}
        >
            <div className="reaction-options">
                {reactionTitle && <div className="reaction-name">{reactionTitle}</div>}
                <div className="reaction like" onMouseEnter={() => setReactionTitle('Like')} onMouseOut={() => setReactionTitle('')}>
                    <img src={likeReaction} alt="Like" onClick={() => react(USER_EMOTION_TYPES.LIKE)} onMouseEnter={() => setReactionTitle('Like')}/>
                </div>
                <div className="reaction love" onMouseEnter={() => setReactionTitle('Love')} onMouseOut={() => setReactionTitle('')}>
                    <img src={loveReaction} alt="Love" onClick={() => react(USER_EMOTION_TYPES.HEART)} onMouseEnter={() => setReactionTitle('Love')}/>
                </div>
                <div className="reaction haha" onMouseEnter={() => setReactionTitle('Haha')} onMouseOut={() => setReactionTitle('')}>
                    <img src={hahaReaction} alt="Haha" onClick={() => react(USER_EMOTION_TYPES.HAHA)} onMouseEnter={() => setReactionTitle('Haha')}/>
                </div>
                <div className="reaction sad" onMouseEnter={() => setReactionTitle('Sad')} onMouseOut={() => setReactionTitle('')}>
                    <img src={sadReaction} alt="Sad" onClick={() => react(USER_EMOTION_TYPES.SAD)} onMouseEnter={() => setReactionTitle('Sad')}/>
                </div>
                <div className="reaction angry" onMouseEnter={() => setReactionTitle('Angry')} onMouseOut={() => setReactionTitle('')}>
                    <img src={angryReaction} alt="Angry" onClick={() => react(USER_EMOTION_TYPES.ANGRY)} onMouseEnter={() => setReactionTitle('Angry')}/>
                </div>
            </div>
        </div>
    );


}

export {ReactionsPopup};
