import searchTypes from '../types/search';
import { MEMBER_TYPE, PROFILE_TYPES, SEARCH_MEMBER_TYPE } from '../types/common';

export const initialState = {
    fetching: false,
    fetchMoreType: null,
    error: null,
    action: 0,
    topSearchCount: 0,
    people: [],
    filterResults: [],
    investors: [],
    companies: [],
    peopleCount: null,
    companyCount: null,
    investorCount: null,
    profiles: [],
    fetchingTopSearch: false,
    fetchingTopSearchCount: false,
    errorTopSearch: null,
    investmentTypes: [],
    investorFilter: {
        investmentType: null,
        plantTouching: null
    },
    searchStr: '',

    v2allPeople: [],
    v2professional: [],
    v2investors: [],
    v2company: [],

    v2allPeopleCount: 0,
    v2professionalCount: 0,
    v2investorsCount: 0,
    v2companyCount: 0,
};

export const search = (state = initialState, action) => {
    switch (action.type) {
        case searchTypes.CLEAR_ACTION:
            return {
                ...state,
                action: 0
            };

        case searchTypes.FETCHING_SEARCH_INDIVIDUALS:
            return {
                ...state,
                fetching: true,
                action: searchTypes.FETCHING_SEARCH_INDIVIDUALS,
                // people: [],
                // investors: []
            };
        case searchTypes.FETCHING_SEARCH_INDIVIDUALS_SUCCESS:

            if (action.memberType && action.memberType === MEMBER_TYPE.INVESTOR) {
                return {
                    ...state,
                    fetching: false,
                    action: searchTypes.FETCHING_SEARCH_INDIVIDUALS_SUCCESS,
                    investors: action.payload,
                    filterResults: action.payload
                }
            } else {
                return {
                    ...state,
                    fetching: false,
                    action: searchTypes.FETCHING_SEARCH_INDIVIDUALS_SUCCESS,
                    people: action.payload,
                    filterResults: action.payload 
                }
            }

        case searchTypes.SET_SEARCH_INDIVIDUALS_IMAGES:
            if (action.memberType && action.memberType === MEMBER_TYPE.INVESTOR) {
                let updated = state.investors.map(item => {
                    return {...item,PROFILE_PHOTO: action.payload[item._id] ? action.payload[item._id] : item.PROFILE_PHOTO}
                });
                return {
                    ...state,
                    investors: updated,
                }
            } else {
                let updated = state.people.map(item => {
                    return {...item,PROFILE_PHOTO: action.payload[item._id] ? action.payload[item._id] : item.PROFILE_PHOTO}
                });
                return {
                    ...state,
                    people: updated
                }
            }

        case searchTypes.FETCHING_SEARCH_INDIVIDUALS_FAIL:
            return {
                ...state,
                fetching: false,
                action: searchTypes.FETCHING_SEARCH_INDIVIDUALS_FAIL
            };

        case searchTypes.FETCHING_SEARCH_COMPANIES:
            return {
                ...state,
                fetching: true,
                // companies: [],
                action: searchTypes.FETCHING_SEARCH_COMPANIES,
                
            };
        case searchTypes.FETCHING_SEARCH_COMPANIES_SUCCESS:

            return {
                ...state,
                fetching: false,
                action: searchTypes.FETCHING_SEARCH_COMPANIES_SUCCESS,
                companies: action.payload,
                filterResults: action.payload
            };
        case searchTypes.FETCHING_SEARCH_COMPANIES_FAIL:
            return {
                ...state,
                fetching: false,
                action: searchTypes.FETCHING_SEARCH_COMPANIES_FAIL
            };

        case searchTypes.FETCHING_PEOPLE_PROFILE_IMAGES_SUCCESS:
            return {
                ...state,
                people: action.payload,
                action: searchTypes.FETCHING_PEOPLE_PROFILE_IMAGES_SUCCESS
            };
        case searchTypes.FETCHING_COMPANY_PROFILE_IMAGES_SUCCESS:
            return {
                ...state,
                companies: action.payload,
                action: searchTypes.FETCHING_COMPANY_PROFILE_IMAGES_SUCCESS
            };
        case searchTypes.FETCHING_INVESTOR_PROFILE_IMAGES_SUCCESS:
            return {
                ...state,
                investors: action.payload,
                action: searchTypes.FETCHING_INVESTOR_PROFILE_IMAGES_SUCCESS
            };

        case searchTypes.FETCHING_MORE_RESULTS:
            return {
                ...state,
                fetching: true,
                action: searchTypes.FETCHING_MORE_RESULTS,
                fetchMoreType: action.usertype
            };
        case searchTypes.FETCHING_MORE_RESULTS_SUCCESS:
            if (action.profileType && action.profileType === PROFILE_TYPES.COMPANY) {
                return {
                    ...state,
                    fetching: false,
                    action: searchTypes.FETCHING_SEARCH_COMPANIES_SUCCESS,
                    companies: state.companies.concat(action.payload),
                    filterResults: state.filterResults.concat(action.payload),
                    fetchMoreType: null
                };
            } else if (action.profileType && action.profileType === PROFILE_TYPES.INDIVIDUAL) {

                if (action.memberType && action.memberType === MEMBER_TYPE.INVESTOR) {
                    return {
                        ...state,
                        fetching: false,
                        action: searchTypes.FETCHING_SEARCH_INDIVIDUALS_SUCCESS,
                        investors: state.investors.concat(action.payload),
                        filterResults: state.filterResults.concat(action.payload),
                        fetchMoreType: null
                    }
                } else {
                    return {
                        ...state,
                        fetching: false,
                        action: searchTypes.FETCHING_SEARCH_INDIVIDUALS_SUCCESS,
                        people: state.people.concat(action.payload),
                        filterResults: state.filterResults.concat(action.payload),
                        fetchMoreType: null
                    }
                }

            }
            break;
        case searchTypes.FETCHING_SEARCH_COUNT_INDIVIDUALS_SUCCESS:
            if (action.userType === MEMBER_TYPE.INVESTOR) {
                return {
                    ...state,
                    investorCount: action.payload.length > 0 ? action.payload[0].COUNT : 0,
                    companyCount: action.payload.length > 0 ? action.payload[0].COUNT : 0,
                    userType: action.userType,
                }
            } else {
                return {
                    ...state,
                    peopleCount: action.payload.length > 0 ? action.payload[0].COUNT : 0,
                    companyCount: action.payload.length > 0 ? action.payload[0].COUNT : 0,
                    userType: action.userType
                }
            }
        case searchTypes.FETCHING_GENERAL_SEARCH:
            return {
                ...state,
                fetchingTopSearch: true,
                action: searchTypes.FETCHING_GENERAL_SEARCH
            }

        case searchTypes.FETCHING_GENERAL_SEARCH_SUCCESS:
            return {
                ...state,
                fetchingTopSearch: false,
                action: searchTypes.FETCHING_GENERAL_SEARCH_SUCCESS,
                profiles: action.payload
            }
        case searchTypes.FETCHING_GENERAL_SEARCH_FAIL:
            return {
                ...state,
                fetchingTopSearch: false,
                action: searchTypes.FETCHING_GENERAL_SEARCH_FAIL,
                errorTopSearch: action.payload
            }

        case searchTypes.FETCHING_TOP_SEARCH_COUNT:
            return {
                ...state,
                fetchingTopSearchCount: true,
                action: searchTypes.FETCHING_TOP_SEARCH_COUNT
            }

        case searchTypes.FETCH_TOP_SEARCH_COUNT_SUCCESS:
            return {
                ...state,
                fetchingTopSearchCount: false,
                action: searchTypes.FETCH_TOP_SEARCH_COUNT_SUCCESS,
                topSearchCount: action.payload.companyCount + action.payload.userCount
            }
        case searchTypes.FETCH_TOP_SEARCH_COUNT_FAIL:
            return {
                ...state,
                fetchingTopSearchCount: false,
                action: searchTypes.FETCH_TOP_SEARCH_COUNT_FAIL,
                topSearchCount: 0
            }
        case searchTypes.CLEAR_TOP_SEARCH_COUNT:
            return {
                ...state,
                action: searchTypes.CLEAR_TOP_SEARCH_COUNT,
                topSearchCount: 0
            }

        case searchTypes.CLEAR_SEARCH_STATES:
            return {
                ...state,
                people: [],
                investors: [],
                companies: [],
            }
        case searchTypes.CLEAR_SEARCH_TEXT:
            return {
                ...state,
                action: searchTypes.CLEAR_SEARCH_TEXT
            }
        case searchTypes.CLEAR_TOP_SEARCH:
            return {
                ...state,
                action: searchTypes.CLEAR_TOP_SEARCH,
                profiles: [],
                fetchingTopSearch: false,
                fetchingTopSearchCount: false
            };
        case searchTypes.SETTING_INVESTMENT_TYPES:
            return {
                ...state,
                action: searchTypes.SETTING_INVESTMENT_TYPES,
                investmentTypes: action.payload
            };
        case searchTypes.CLEAR_INVESTMENT_TYPES:
            return {
                ...state,
                action: searchTypes.CLEAR_INVESTMENT_TYPES,
                investmentTypes: []
            };
        case searchTypes.SET_SEARCH_TYPES:
            return {
                ...state,
                action: searchTypes.SET_SEARCH_TYPES,
                investorFilter: {
                    companyType: action.companyType,
                    plantTouching: action.plantTouching
                }
            };
        case searchTypes.CLEAR_SEARCH_TYPES:
            return {
                ...state,
                action: searchTypes.CLEAR_SEARCH_TYPES,
                investorFilter: {
                    companyType: null,
                    plantTouching: null
                }
            };

        case searchTypes.V2_ADVANCED_SEARCH_SUCCESS:
            let newResults = {};
            switch (action.memberType){ // set results consider (member type, isInit)
                case SEARCH_MEMBER_TYPE.ALL:
                    newResults.v2allPeople = action.isInit ? action.payload.userSet : state.v2allPeople.concat(action.payload.userSet ? action.payload.userSet : []);
                    newResults.v2company = action.isInit ? action.payload.companySet : state.v2company.concat(action.payload.companySet ? action.payload.companySet : []);
                    break;
                case SEARCH_MEMBER_TYPE.INVESTOR:
                    newResults.v2investors = action.isInit ? action.payload : state.v2investors.concat(action.payload);
                    break;
                case SEARCH_MEMBER_TYPE.PROFESSIONAL:
                    newResults.v2professional = action.isInit ? action.payload : state.v2professional.concat(action.payload);
                    break;
                case SEARCH_MEMBER_TYPE.COMPANY_PROFILES:
                    newResults.v2company = action.isInit ? action.payload : state.v2company.concat(action.payload);
                    break;
                case SEARCH_MEMBER_TYPE.FUND_RAISING:
                    newResults.v2company = action.isInit ? action.payload : state.v2company.concat(action.payload);
                    break;
                default:
                    break;
            }

            return{
                ...state,
                ...newResults,
                action: searchTypes.V2_ADVANCED_SEARCH_SUCCESS
            };

        case searchTypes.V2_ADVANCED_SEARCH_COUNT_SUCCESS:
            let newResults2 = {};
            switch (action.memberType){ // set results consider (member type, isInit)
                case SEARCH_MEMBER_TYPE.ALL:
                    newResults2.v2allPeopleCount = action.payload.userCount;
                    newResults2.v2companyCount = action.payload.companyCount;
                    break;
                case SEARCH_MEMBER_TYPE.INVESTOR:
                    newResults2.v2investorsCount = action.payload[0].COUNT;
                    break;
                case SEARCH_MEMBER_TYPE.PROFESSIONAL:
                    newResults2.v2professionalCount = action.payload[0].COUNT;
                    break;
                case SEARCH_MEMBER_TYPE.COMPANY_PROFILES:
                    newResults2.v2companyCount = action.payload[0].COUNT;
                    break;
                case SEARCH_MEMBER_TYPE.FUND_RAISING:
                    newResults2.v2companyCount = action.payload[0].COUNT;
                    break;
                default:
                    break;
            }
            return{
                ...state,
                ...newResults2,
                action: searchTypes.V2_ADVANCED_SEARCH_COUNT_SUCCESS
            };

        case searchTypes.V2_CLEAR_SEARCH_RESULTS:
            return {
                ...state,
                v2allPeople: [],
                v2professional: [],
                v2investors: [],
                v2company: [],
                v2allPeopleCount: 0,
                v2professionalCount: 0,
                v2investorsCount: 0,
                v2companyCount: 0,
            };

        default:
            return state
    }
};