import PaymentTypes from '../types/managePayments';
import {REPLACE_PAYMENT_METHOD} from './../types/marketPlace';
import {ROUTES} from '../config';
import {PAYMENT_GATEWAYS, POP_ALERT} from '../types/common';
import {getTools} from './company';
import {showPopupAlert} from './alert';
import {get, post, put} from "../libs/apiConnector";
import {initlogin} from './auth'

export function createHostedPaymentFieldsToken() {
    return function (dispatch) {
        dispatch({type: PaymentTypes.CREATING_PAYMENT_FIELDS_TOKEN});
        post(`${ROUTES.CREATE_PAYMENT_FIELDS_TOKEN}`, {})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({
                        type: PaymentTypes.CREATE_PAYMENT_FIELDS_TOKEN_SUCCESS,
                        payload: response.data.data.headers
                    });
                } else {
                    dispatch({type: PaymentTypes.CREATE_PAYMENT_FIELDS_TOKEN_FAIL});
                }
                dispatch({type: PaymentTypes.CLEAR_ACTION});
            })
            .catch((error) => {
                dispatch({type: PaymentTypes.CREATE_PAYMENT_FIELDS_TOKEN_FAIL});
                dispatch({type: PaymentTypes.CLEAR_ACTION});
            })
    }
}

export function createCustomer(companyId, gatewayId, token, moduleId) {
    return function (dispatch) {
        dispatch({type: PaymentTypes.CREATING_CUSTOMER});
        post(`${ROUTES.CREATE_COMPANY_SHOPPER}`, {
            companyId: companyId,
            gatewayId: gatewayId,
            pfToken: token.cardDetails,
            fName: token.fName,
            lName: token.lName
        })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: PaymentTypes.CREATE_CUSTOMER_SUCCESS});
                    dispatch(createSubscription(companyId, response.data.data.vaultedShopperId, moduleId, gatewayId));
                } else {
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                    dispatch({type: PaymentTypes.CREATE_CUSTOMER_FAIL});
                }
                dispatch({type: PaymentTypes.CLEAR_ACTION});
            })
            .catch((error) => {
                showPopupAlert(dispatch, POP_ALERT.WARNING, 'Unexpected error occurred while creating the customer');
                dispatch({type: PaymentTypes.CREATE_CUSTOMER_FAIL});
                dispatch({type: PaymentTypes.CLEAR_ACTION});
            })
    }
}

export function createSubscription(companyId, shopperId, moduleId, gatewayId, cardDetails = null) {
    return function (dispatch) {
        let data = {cardDetails};
        if (cardDetails && cardDetails.cardDetails) {
            data = {...cardDetails}
        }
        dispatch({type: PaymentTypes.CREATING_SUBSCRIPTION});
        post(`${ROUTES.CREATE_SUBSCRIPTION}`,
            {companyId: companyId, shopperId: shopperId, moduleId: moduleId, gatewayId: gatewayId, ...data})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch(getTools(companyId));
                    showPopupAlert(dispatch, POP_ALERT.SUCCESS, response.data.message);
                    dispatch({type: PaymentTypes.CREATE_SUBSCRIPTION_SUCCESS});
                } else {
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                    dispatch({type: PaymentTypes.CREATE_SUBSCRIPTION_FAIL});
                }
                dispatch({type: PaymentTypes.CLEAR_ACTION});
            })
            .catch((error) => {
                showPopupAlert(dispatch, POP_ALERT.WARNING, 'Unexpected error occurred while creating the subscription');
                dispatch({type: PaymentTypes.CREATE_SUBSCRIPTION_FAIL});
                dispatch({type: PaymentTypes.CLEAR_ACTION});
            })
    }
}

export function renewSubscription(companyId, shopperId, moduleId, gatewayId, cardDetails = null) {
    return function (dispatch) {
        let data = {cardDetails};
        if (cardDetails.cardDetails) {
            data = {...cardDetails}
        }
        dispatch({type: PaymentTypes.RENEWING_SUBSCRIPTION});
        post(`${ROUTES.RENEW_SUBSCRIPTION}`,
            {companyId: companyId, shopperId: shopperId, moduleId: moduleId, gatewayId: gatewayId, ...data})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch(getTools(companyId));
                    showPopupAlert(dispatch, POP_ALERT.SUCCESS, response.data.message);
                    dispatch({type: PaymentTypes.RENEW_SUBSCRIPTION_SUCCESS});
                } else {
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                    dispatch({type: PaymentTypes.RENEW_SUBSCRIPTION_FAIL});
                }
                dispatch({type: PaymentTypes.CLEAR_ACTION});
            })
            .catch((error) => {
                showPopupAlert(dispatch, POP_ALERT.WARNING, 'Unexpected error occurred while renewing the subscription');
                dispatch({type: PaymentTypes.RENEW_SUBSCRIPTION_FAIL});
                dispatch({type: PaymentTypes.CLEAR_ACTION});
            })
    }
}

export function fetchPaymentGatewaysByCompanyId(compantId) {
    return function (dispatch) {
        dispatch({type: PaymentTypes.FETCHING_COMPANY_PAYMENT_GATEWAYS});
        get(`${ROUTES.FETCH_COMPANY_GATEWAYS}${compantId}`)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: PaymentTypes.FETCH_COMPANY_PAYMENT_GATEWAYS_SUCCESS, payload: response.data});
                } else {
                    dispatch({type: PaymentTypes.FETCH_COMPANY_PAYMENT_GATEWAYS_FAIL});
                }
                dispatch({type: PaymentTypes.CLEAR_ACTION});
            })
            .catch(err => {
                dispatch({type: PaymentTypes.FETCH_COMPANY_PAYMENT_GATEWAYS_FAIL});
                dispatch({type: PaymentTypes.CLEAR_ACTION});
            })
    }
}

export function fetchCards(shopperId) { // to delete
    return function (dispatch) {
        /*dispatch({ type: PaymentTypes.FETCHING_CARDS });
        get(`${ROUTES.FETCH_CARDS}/${shopperId}`)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({ type: PaymentTypes.FETCH_CARDS_SUCCESS, payload: response.data.data.paymentSources });
                } else {
                    dispatch({ type: PaymentTypes.FETCH_CARDS_FAIL });
                }
                dispatch({ type: PaymentTypes.CLEAR_ACTION });
            })
            .catch((error) => {
                dispatch({ type: PaymentTypes.FETCH_CARDS_FAIL });
                dispatch({ type: PaymentTypes.CLEAR_ACTION });
            })*/
    }
}

export function setDefaultCard(cardId, customerId) {
    return function (dispatch) {
        dispatch({type: PaymentTypes.SETTING_DEFAULT_CARD});
        put(`${ROUTES.SET_DEFAULT_CARD}`, {cardId: cardId, customerId: customerId})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: PaymentTypes.SETTING_DEFAULT_CARD_SUCCESS});
                    showPopupAlert(dispatch, POP_ALERT.SUCCESS, response.data.message);
                } else {
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                    dispatch({type: PaymentTypes.SETTING_DEFAULT_CARD_FAIL});
                }
                dispatch({type: PaymentTypes.CLEAR_ACTION});
            })
            .catch((error) => {
                showPopupAlert(dispatch, POP_ALERT.WARNING, 'Unexpected error occured');
                dispatch({type: PaymentTypes.SETTING_DEFAULT_CARD_FAIL});
                dispatch({type: PaymentTypes.CLEAR_ACTION});
            })
    }
}

export function addCard(shopperId, cardDetails, fName, lName, zipCode, gatewayId = PAYMENT_GATEWAYS.BLUE_SNAP) {
    return function (dispatch) {
        dispatch({type: PaymentTypes.ADDING_NEW_CARD});
        put(`${ROUTES.ADD_CARD}`, {shopperId, cardDetails, fName, lName, zipCode, gatewayId})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({
                        type: PaymentTypes.ADDING_NEW_CARD_SUCCESS,
                        payload: response.data.data.paymentSources ? response.data.data.paymentSources.creditCardInfo ? response.data.data.paymentSources.creditCardInfo : [] : []
                    });
                } else {
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                    dispatch({type: PaymentTypes.ADDING_NEW_CARD_FAIL});
                }
                dispatch({type: PaymentTypes.CLEAR_ACTION});
            })
            .catch((error) => {
                showPopupAlert(dispatch, POP_ALERT.WARNING, 'Unexpected error occured');
                dispatch({type: PaymentTypes.ADDING_NEW_CARD_FAIL});
                dispatch({type: PaymentTypes.CLEAR_ACTION});
            })
    }
}

export function deleteCard(shopperId, cardDetails, gatewayId = PAYMENT_GATEWAYS.BLUE_SNAP) {
    return function (dispatch) {
        dispatch({type: PaymentTypes.DELETING_CARD});
        put(`${ROUTES.DELETE_CARD}`, {shopperId, gatewayId, cardDetails})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: PaymentTypes.DELETE_CARD_SUCCESS});
                    // showAlert(dispatch, ALERT.SUCCESS, response.data.message);
                } else {
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                    dispatch({type: PaymentTypes.DELETE_CARD_FAIL});
                }
                dispatch({type: PaymentTypes.CLEAR_ACTION});
            })
            .catch((error) => {
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
                dispatch({type: PaymentTypes.DELETE_CARD_FAIL});
                dispatch({type: PaymentTypes.CLEAR_ACTION});
            })
    }
}

export function fetchPaymentHistory({id, pageSize, after, before}) {
    let query = 'charges?gettotal=true&pagesize=' + pageSize;
    if (after) {
        query += '&after=' + after;
    }
    if (before) {
        query += '&before=' + before;
    }
    return function (dispatch) {
        dispatch({type: PaymentTypes.FETCHING_PAYMENT_HISTORY, subscriptionId: id});
        put(`${ROUTES.FETCH_PAYMENT_HISTORY}/${id}`, {query})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({
                        type: PaymentTypes.FETCH_PAYMENT_HISTORY_SUCCESS,
                        payload: response.data.data.charges,
                        subscriptionId: id,
                        totalResults: response.data.data.totalResults
                    });
                } else {
                    dispatch({type: PaymentTypes.FETCH_PAYMENT_HISTORY_FAIL});
                }
                dispatch({type: PaymentTypes.CLEAR_ACTION});
            })
            .catch((error) => {
                dispatch({type: PaymentTypes.FETCH_PAYMENT_HISTORY_FAIL});
                dispatch({type: PaymentTypes.CLEAR_ACTION});
            })
    }
}

export function fetchSubscriptions(companyId) {
    return function (dispatch) {
        dispatch({type: PaymentTypes.FETCHING_SUBSCRIPTIONS});
        post(`${ROUTES.FETCH_USER_SUBSCRIPTIONS}`, {companyId: companyId, gatewayId: PAYMENT_GATEWAYS.BLUE_SNAP})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: PaymentTypes.FETCH_SUBSCRIPTIONS_SUCCESS, payload: response.data});
                } else {
                    dispatch({type: PaymentTypes.FETCH_SUBSCRIPTIONS_FAIL});
                }
                dispatch({type: PaymentTypes.CLEAR_ACTION});
            })
            .catch((error) => {
                dispatch({type: PaymentTypes.FETCH_SUBSCRIPTIONS_FAIL});
                dispatch({type: PaymentTypes.CLEAR_ACTION});
            })
    }
}

export function cancelSubscription(subscriptionId, companyId, cancelDate) {
    return function (dispatch) {
        dispatch({type: PaymentTypes.CANCELING_SUBSCRIPTION});
        put(`${ROUTES.CANCEL_SUBSCRIPTION}`, {
            gatewayId: PAYMENT_GATEWAYS.BLUE_SNAP,
            subscriptionId: subscriptionId,
            cancelDate: cancelDate
        })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: PaymentTypes.CANCEL_SUBSCRIPTION_SUCCESS});
                } else {
                    dispatch({type: PaymentTypes.CANCEL_SUBSCRIPTION_FAIL});
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
                dispatch({type: PaymentTypes.CLEAR_ACTION});
                dispatch(fetchSubscriptions(companyId));
            })
            .catch((error) => {
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
                dispatch({type: PaymentTypes.CANCEL_SUBSCRIPTION_FAIL});
                dispatch({type: PaymentTypes.CLEAR_ACTION});
                dispatch(fetchSubscriptions(companyId));
            })
    }
}

export function reActivateSubscription(subscriptionId, companyId) {
    return function (dispatch) {
        dispatch({type: PaymentTypes.RE_ACTIVATING_SUBSCRIPTION});
        put(`${ROUTES.RE_ACTIVATE_SUBSCRIPTION}`, {
            gatewayId: PAYMENT_GATEWAYS.BLUE_SNAP,
            subscriptionId: subscriptionId
        })
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: PaymentTypes.RE_ACTIVATE_SUBSCRIPTION_SUCCESS});
                } else {
                    dispatch({type: PaymentTypes.RE_ACTIVATE_SUBSCRIPTION_FAIL});
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
                dispatch({type: PaymentTypes.CLEAR_ACTION});
                dispatch(fetchSubscriptions(companyId));
            })
            .catch((error) => {
                showPopupAlert(dispatch, POP_ALERT.WARNING, error.message);
                dispatch({type: PaymentTypes.RE_ACTIVATE_SUBSCRIPTION_FAIL});
                dispatch({type: PaymentTypes.CLEAR_ACTION});
                dispatch(fetchSubscriptions(companyId));
            })
    }
}

export function attachNewCardToSubscription(shopperId, subscriptionId, gatewayId, cardDetails, companyId = null) {
    return function (dispatch) {
        dispatch({type: PaymentTypes.ATTACHING_NEW_CARD_TO_SUBSCRIPTION});
        let data = {cardDetails};
        if (cardDetails.cardDetails) {
            data = {...cardDetails}
        }
        put(`${ROUTES.ATTACH_NEW_CARD}`, {
            shopperId,
            subscriptionId,
            gatewayId,
            ...data
        })
            .then(response => {
                if (response.data.code === 200) {
                    if (
                        response.data.data
                        && response.data.data.subscriptionId
                        && response.data.data.paymentSource
                        && response.data.data.paymentSource.creditCardInfo
                        && response.data.data.paymentSource.creditCardInfo.creditCard
                    ) {
                        dispatch({
                            type: REPLACE_PAYMENT_METHOD,
                            subId: response.data.data.subscriptionId,
                            cardDetails: response.data.data.paymentSource.creditCardInfo.creditCard
                        });
                    }
                    dispatch({type: PaymentTypes.ATTACHING_NEW_CARD_TO_SUBSCRIPTION_SUCCESS});
                    showPopupAlert(dispatch, POP_ALERT.SUCCESS, response.data.message);
                } else {
                    dispatch({type: PaymentTypes.ATTACHING_NEW_CARD_TO_SUBSCRIPTION_FAIL});
                    showPopupAlert(dispatch, POP_ALERT.WARNING, response.data.message);
                }
                if (companyId) {
                    dispatch(fetchSubscriptions(companyId));
                }
                dispatch({type: PaymentTypes.CLEAR_ACTION});
            })
            .catch(err => {
                dispatch({type: PaymentTypes.ATTACHING_NEW_CARD_TO_SUBSCRIPTION_FAIL});
                dispatch({type: PaymentTypes.CLEAR_ACTION});
                showPopupAlert(dispatch, POP_ALERT.WARNING, err.message);
            })
    }
}

export function fetchAllCardsByCompanyId(CompanyId, paymentGateWay = PAYMENT_GATEWAYS.BLUE_SNAP) {
    return function (dispatch) {
        dispatch({type: PaymentTypes.FETCHING_CARDS_BY_COMPANY_ID});
        get(`${ROUTES.FETCH_CARDS_BY_COMPANY}/${CompanyId}/${paymentGateWay}`)
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({
                        type: PaymentTypes.FETCHING_CARDS_BY_COMPANY_ID_SUCCESS,
                        payload: response.data.data.creditCardInfo
                    })
                } else {
                    dispatch({type: PaymentTypes.FETCHING_CARDS_BY_COMPANY_ID_FAIL})
                }
            })
            .catch(err => {
                dispatch({type: PaymentTypes.FETCHING_CARDS_BY_COMPANY_ID_FAIL});
            })
    }
}

export function clearCards() { // to delete
    return function (dispatch) {
        // dispatch({ type: PaymentTypes.CLEAR_CARDS });
    }
}

export function clearPaymentHistory() {
    return function (dispatch) {
        dispatch({type: PaymentTypes.CLEAR_PAYMENT_HISTORY});
    }
}

export function clearSubscriptions() {
    return function (dispatch) {
        dispatch({type: PaymentTypes.CLEAR_SUBSCRIPTIONS});
    }
}

export function showAlert(type, message) {
    return function (dispatch) {
        showPopupAlert(dispatch, type, message);
    }
}

/*individual user payment actions*/

export const fetchPaymentGatewaysByUserId = (id) => async dispatch => {
    try {
        dispatch({type: PaymentTypes.FETCHING_USER_PAYMENT_GATEWAYS});
        const res = await get(`${ROUTES.FETCH_USER_GATEWAYS}`);
        if (res.data.code === 200) {
            dispatch({type: PaymentTypes.FETCH_USER_PAYMENT_GATEWAYS_SUCCESS, payload: res.data});
        } else {
            dispatch({type: PaymentTypes.FETCH_USER_PAYMENT_GATEWAYS_FAIL});
        }
        dispatch({type: PaymentTypes.CLEAR_ACTION});
    } catch (ex) {
        dispatch({type: PaymentTypes.FETCH_USER_PAYMENT_GATEWAYS_FAIL});
        dispatch({type: PaymentTypes.CLEAR_ACTION});
    }

};

export const fetchShopper = (shopperId) => async dispatch => {
    try {
        dispatch({type: PaymentTypes.FETCHING_SHOPPER});
        const res = await get(`${ROUTES.FETCH_SHOPPER}${shopperId}`);
        if (res.data && res.data.data && res.data.data.code === 200 && res.data.data.data) {
            dispatch({type: PaymentTypes.FETCH_SHOPPER_SUCCESS, payload: res.data.data.data.paymentSources});
        } else {
            dispatch({type: PaymentTypes.FETCH_SHOPPER_FAIL});
        }
        dispatch({type: PaymentTypes.CLEAR_ACTION});
    } catch (ex) {
        dispatch({type: PaymentTypes.FETCH_SHOPPER_FAIL});
        dispatch({type: PaymentTypes.CLEAR_ACTION});
    }
};

export const clearShopper = () => dispatch => {
    dispatch({type: PaymentTypes.CLEAR_SHOPPER})
};

export const cancelJobBoardSubscription = (gatewayId, subscriptionId) => async dispatch => {
    try {
        dispatch({type: PaymentTypes.CANCEL_JOB_BOARD_SUBSCRIPTION})
        const response = await post(`${ROUTES.CANCEL_JBHQ_SUBSCRIPTION}`, {gatewayId, subscriptionId})
        if (response && response.data && response.data.code) {
            dispatch(initlogin(localStorage.getItem('token')))
            dispatch({type: PaymentTypes.CANCEL_JOB_BOARD_SUBSCRIPTION_SUCCESS, payload: response.data.data});
            showPopupAlert(dispatch, POP_ALERT.SUCCESS, response.data.message, 'Success')
        } else {
            dispatch({type: PaymentTypes.CANCEL_JOB_BOARD_SUBSCRIPTION_FAIL});
            showPopupAlert(dispatch, POP_ALERT.ERROR, response.data.message, 'Error');
        }

    } catch (exception) {
        showPopupAlert(dispatch, POP_ALERT.ERROR, exception, 'Error');
        dispatch({type: PaymentTypes.CANCEL_JOB_BOARD_SUBSCRIPTION_FAIL})
    }
};

export const reactivateJobBoardSubscription = (gatewayId, subscriptionId) => async dispatch => {
    try {
        dispatch({type: PaymentTypes.REACTIVATE_JOB_BOARD_SUBSCRIPTION})
        const response = await post(`${ROUTES.REACTIVATE_JBHQ_SUBSCRIPTION}`, {gatewayId, subscriptionId})

        if (response && response.data && response.data.code) {
            dispatch(initlogin(localStorage.getItem('token')))
            dispatch({type: PaymentTypes.REACTIVATE_JOB_BOARD_SUBSCRIPTION_SUCCESS, payload: response.data.data});
            showPopupAlert(dispatch, POP_ALERT.SUCCESS, response.data.message, 'Success')
        } else {
            dispatch({type: PaymentTypes.REACTIVATE_JOB_BOARD_SUBSCRIPTION_FAIL});
            showPopupAlert(dispatch, POP_ALERT.ERROR, response.data.message, 'Error');
        }

    } catch (exception) {
        showPopupAlert(dispatch, POP_ALERT.ERROR, exception, 'Error');
        dispatch({type: PaymentTypes.REACTIVATE_JOB_BOARD_SUBSCRIPTION_FAIL})
    }
};
