import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { CardDefault } from "../../components/common";
import { ConnectionRecommonds, GroupsMobile } from '../../components/common/contentLoaders/feed';
import { getPreview } from "../../libs/textFormat";
import { fetchGroups } from "../../actions/groups";
import { Link } from 'react-router-dom';
import footerimage from "../../assets/img/groups/poweredbyMJBiz.png"
import "./index.css";
import ToolTip from '../../components/common/tooltip';
import { PUBLIC_PENDING_ACTIONS } from '../../types/common';
import { removePublicAction } from '../../actions/auth';
import { trackEvent } from '../../actions/eventTracking';
import {EVENT_ACTION} from '../../types/eventTracking';

const Group = ({ user, dispatch, item, joinGroup, index, isMobile, closeModal, postRendered }) => {

        /* data project - event trcking */
        const wrapperRef = useRef(null);

        useEffect(() => {
            listenToScroll()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
    
        useEffect(() => {
            window.addEventListener('touchmove', listenToScroll);
            window.addEventListener('scroll', listenToScroll);
            return () => {
            window.removeEventListener('touchmove', listenToScroll)
            window.removeEventListener('scroll', listenToScroll)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
    
        const listenToScroll = () => {
            if(postRendered && wrapperRef.current) {
                postRendered(wrapperRef.current.getBoundingClientRect(), item._id);
            }
        }
        /* END : data project - event trcking */

    return (
        item.IS_JOINED ?
        <div ref={wrapperRef}>
            <Link
                style={{ textDecoration: 'none', cursor: 'pointer' }}
                to={'/groups/' + item._id}
                onClick={e => {
                    if(isMobile && closeModal){
                        closeModal();
                    }

                    if(user) {
                        /* data project - event trcking */
                        const eventPayload = {
                            user_id: (user) ? user._id : null,
                            page: 'HOME', 
                            section: 'GROUP', 
                            element_type: 'LINK',
                            element_id: item._id,
                            action_type: EVENT_ACTION.CLICK
                        }
                        dispatch(trackEvent(eventPayload));
                        /* END : data project - event trcking */
                    }
                }}
            >
                <div className="group-row" id={'group_widget_'+item._id}>
                    <button className='action-button leaf-btn green hollow member'>Member</button>
                    <span className="font_dark_gray_two mb-0">{getPreview(item.GROUP_NAME, 70)}</span>
                </div>
            </Link> 
                </div> :
            <div ref={wrapperRef} className="group-row" id={'group_widget_'+item._id}>
                <button onClick={() => joinGroup(item, index)} className='action-button leaf-btn green join'>Join Group</button>
                <span onClick={() => joinGroup(item, index)} className="font_dark_gray_two mb-0">{getPreview(item.GROUP_NAME, 70)}</span>
            </div>
    );
};

function GroupsWidget(props) {

    const {
        postRendered, user, dispatch,
        fetchUserGroups,
        removePublicAction,
        fetchingGroups,
        groups,
        isMobile,
        closeModal,
        joinGroup,
        toolTip,
        toggleZindex,
        overflowValue,
        publicPendingAction,
        redirect,
        onDataFetch
    } = props;

    useEffect(() => {
        if(!fetchingGroups && groups.length === 0){
            fetchUserGroups();
        }
        if(publicPendingAction && publicPendingAction.type && publicPendingAction.type === PUBLIC_PENDING_ACTIONS.GW_JOIN){
            let action = publicPendingAction;
            removePublicAction();
            redirect(`/groups/${action.data._id}`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(!fetchingGroups && groups.length > 0 && onDataFetch){
            setTimeout(() => onDataFetch(), 100)
        }
    }, [fetchingGroups, groups, onDataFetch])

    const _joinGroup = (group, index) => {
        if(user) {
            /* data project - event trcking */
            const eventPayload = {
                user_id: (user) ? user._id : null,
                page: 'HOME', 
                section: 'GROUP', 
                element_type: 'BUTTON',
                element_id: group._id,
                action_type: EVENT_ACTION.CLICK
            }
            dispatch(trackEvent(eventPayload));
            /* END : data project - event trcking */
        }
        joinGroup(group, index)
    };

    return (
        <React.Fragment>
            {fetchingGroups && !isMobile && <ConnectionRecommonds />}
            {fetchingGroups && isMobile && <GroupsMobile/>}
            {
                !fetchingGroups && groups.length > 0 &&
                <CardDefault customClass="newsfeed-widget groups-widget" translate={overflowValue}>
                    <div className="p-all10">
                        {props.children}
                        <div className="d-flex justify-content-center">
                            <div className="font_gray_light font_18 m-b7 widget_header groups" id="GroupsWidgetTitle">Groups</div>
                            {toolTip && toolTip.VISIBLE && <ToolTip tooltip={toolTip} toggleZindex={toggleZindex}/>}
                        </div>
                        <div className="m-t1">
                            {
                                groups.map((item, i) => {
                                    return <Group 
                                    postRendered={postRendered} 
                                    user={user}
                                    dispatch={dispatch} 
                                    key={item._id} 
                                    item={item} joinGroup={_joinGroup} index={i} isMobile={isMobile} closeModal={closeModal} />
                                })
                            }
                        </div>
                        <img src={footerimage} alt="mj business daily" className="footer-image"/>
                    </div>
                </CardDefault>
            }
        </React.Fragment>
    )
}


const mapStateToProps = function (state) {
    return {
        groups: state.groups.groups,
        fetchingGroups: state.groups.fetchingGroups
    }
}

export default connect(mapStateToProps, { fetchUserGroups: fetchGroups, removePublicAction: removePublicAction })(GroupsWidget);