import React from "react";
import "./index.css";
// import { MEMBER_TYPE } from '../../../../types/common';
import avatar from "./../../../../assets/img/default-male.jpg";
import companyAvatar from "./../../../../assets/img/default-corporate-image.jpg";
import productAvatar from "./../../../../assets/sample/briefcase.svg";

const ProfileImage = ({ profile, memberType, height, member, isAdmin, isCompany, isProduct, openProfileImageEdit }) => {


  let profilePicture = isCompany ? (isProduct ? productAvatar : companyAvatar) : avatar;
  let pictureUrl = (profile ? profile : profilePicture);
  

  // let memeberStyles = '';
  // if (member === MEMBER_TYPE.PROFESSIONAL) {
  //   memeberStyles = 'profile_type raising_funder';
  // } else if (member === MEMBER_TYPE.INVESTOR) {
  //   memeberStyles = 'profile_type investor';
  // }
  // if(isAdmin){
  //   memberType='';
  // }

  return (
    <React.Fragment>
      <div className="profile_img_wrp ">
        <div className="profile_container">
          <img alt="profile" className="img-fluid profile-image w-100" src={pictureUrl} onClick={() => {
            if(openProfileImageEdit)openProfileImageEdit()
          }}/>
          {/* {memberType && <div className={memeberStyles}>{memberType}</div>} */}
        </div>
      </div>
    </React.Fragment>
  );
};

ProfileImage.defaultProps = {
  profile: null,
  height: null,
  isAdmin: false
}

export { ProfileImage };
