import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import axios from 'axios';
import { Popover, PopoverBody } from 'reactstrap';
import { AvatarProfile } from "../../components/common";
import { Link } from 'react-router-dom';

import { THUMBNAIL, CONNECTION_STATUS, CONNECT_VIA, STATUS, NOTIFICATION_TYPES, SYSTEM_USER_STATUS,
    BLOCKUSER_MSG_ERROR_MSG, POP_ALERT, BLOCKUSER_CONNECT_ERROR_MSG, BLOCKUSER_POPUP_CLASSNAME, BLOCKUSER_ACCEPT_ERROR_MSG, BLOCKUSER_REJECT_ERROR_MSG } from "./../../types/common";
import { getPreview } from './../../libs/textFormat';

import * as NewsFeedActions from "../../actions/newsFeed";
import * as NewsFeedTypes from "../../types/newsFeed";

import { FetchingPopover } from './../../components/common/contentLoaders/feed';
import { trackEvent } from '../../actions/eventTracking';
import {EVENT_ACTION} from '../../types/eventTracking';
import {FEED} from '../../types/dataTrackingElementIds';
import { showPopupAlert } from "../../actions/alert";
import { checkBlockStatus } from '../../actions/profile';

class CommentorOverview extends PureComponent {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            popoverOpen: false,
            commenterId: this.props.commenterId || null,
            commenterDetails: null,
            isAcceptOrRejectButtonClicked: false,
            isConnectButtonClicked: false,
            fetching: true
        };
        this.requestCancelToken = axios.CancelToken.source()
    }

    componentDidMount = () => {
        this.props.dispatch(NewsFeedActions.fetchCommenterDetails(this.state.commenterId, this.requestCancelToken.token));
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.commenterId !== prevState.commenterId) {
            nextProps.dispatch(NewsFeedActions.fetchCommenterDetails(nextProps.commenterId));
            return { commenterId: nextProps.commenterId };
        }
        if (nextProps.newsFeedAction === NewsFeedTypes.FETCH_COMMENTER_DETAILS_SUCCESS && nextProps.commenterDetails !== prevState.commenterDetails) {
            if(nextProps.setUserRecentlyDeleted && nextProps.commenterDetails && nextProps.commenterDetails.SYSTEM_USER_STATUS === SYSTEM_USER_STATUS.DELETED){
                nextProps.setUserRecentlyDeleted(true)
            }
            return { commenterDetails: nextProps.commenterDetails, fetching: false };
        }
        if (nextProps.newsFeedAction === NewsFeedTypes.FETCH_COMMENTER_DETAILS_FAIL) {
            return { fetching: false };
        }

        if (nextProps.newsFeedAction === NewsFeedTypes.CONNECTION_ACCEPT_SUCCESS) {
            nextProps.dispatch(NewsFeedActions.fetchCommenterDetails(prevState.commenterId));
            return { isAcceptOrRejectButtonClicked: false };
        }
        if (nextProps.newsFeedAction === NewsFeedTypes.CONNECTION_ACCEPT_FAIL) {
            return { isAcceptOrRejectButtonClicked: false };
        }
        if (nextProps.newsFeedAction === NewsFeedTypes.CONNECTION_REJECT_SUCCESS) {
            nextProps.dispatch(NewsFeedActions.fetchCommenterDetails(prevState.commenterId));
            return { isAcceptOrRejectButtonClicked: false };
        }
        if (nextProps.newsFeedAction === NewsFeedTypes.CONNECTION_REJECT_FAIL) {
            return { isAcceptOrRejectButtonClicked: false };
        }
        if (nextProps.newsFeedAction === NewsFeedTypes.CONNECTION_ADD_SUCCESS) {
            nextProps.dispatch(NewsFeedActions.fetchCommenterDetails(prevState.commenterId));
            return { isConnectButtonClicked: false };
        }
        if (nextProps.newsFeedAction === NewsFeedTypes.CONNECTION_ADD_FAIL) {
            return { isConnectButtonClicked: false };
        }
        return null;

    }

    toggle = () => {
        // this.setState({
        //     popoverOpen: !this.state.popoverOpen
        // });
    }

    /* data project - event trcking */
    _setPayload = () => {
        const eventPayload = {
            user_id: localStorage.getItem('userId'),
            page: this.props.page, 
            section: this.props.section, 
            element_type: 'BUTTON',
            element_id: null,
            action_type: EVENT_ACTION.CLICK,
            post_id: this.props.postId
        }
        return eventPayload;
    }
    /* END : data project - event trcking */

    _openMsgPopup = async () => {
        if(this.props.user) {
            /* data project - event trcking */
            let eventPayload = this._setPayload();
            eventPayload.element_id =  FEED.PROFILE_PO_MSG;
            eventPayload.profile_id = this.props.commenterId;
            eventPayload.is_user_profile = true;
            await this.props.dispatch(trackEvent(eventPayload));
            /* END : data project - event trcking */
        }

        const {success} = await checkBlockStatus(this.props.commenterId);
        if(success) {
            let user = {
                SYSTEM_USER_ID: this.state.commenterDetails._id,
                COMPANY_NAME: this.state.commenterDetails.COMPANY_NAME,
                CONNECTION_STATUS: this.state.commenterDetails.CONNECTIONS,
                FIRST_NAME: this.state.commenterDetails.FIRST_NAME,
                LAST_NAME: this.state.commenterDetails.LAST_NAME,
                MEMBER_TYPE: this.state.commenterDetails.MEMBER_TYPE,
                JOB_TITLE: this.state.commenterDetails.JOB_TITLE,
                PHOTO: this.state.commenterDetails.PHOTO
            };
            this.props.openMsgPopup(user);
        } else {
            showPopupAlert(this.props.dispatch, POP_ALERT.OK, '', BLOCKUSER_MSG_ERROR_MSG, '', BLOCKUSER_POPUP_CLASSNAME);
        }
    }

    _accept = async (requesterId, type) => {
        if(this.props.user) {
            /* data project - event trcking */
            let eventPayload =this._setPayload();
            eventPayload.element_id = FEED.PROFILE_PO_ACCEPT;
            eventPayload.profile_id = this.props.commenterId;
            eventPayload.is_user_profile = true;
            await this.props.dispatch(trackEvent(eventPayload));
            /* END : data project - event trcking */
        }

        const {success} = await checkBlockStatus(this.props.commenterId);
        if(success) {
            this.setState({
                isAcceptOrRejectButtonClicked: true
            });
            this.props.dispatch(NewsFeedActions.acceptConnectionRequest(requesterId, null, type, this.props.socket));
        } else {
            showPopupAlert(this.props.dispatch, POP_ALERT.OK, '', BLOCKUSER_ACCEPT_ERROR_MSG, '', BLOCKUSER_POPUP_CLASSNAME);
        }
    }

    _reject = async (requesterId, type) => {
        if(this.props.user) {
            /* data project - event trcking */
            let eventPayload = this._setPayload();
            eventPayload.element_id = FEED.PROFILE_PO_REJECT;
            eventPayload.profile_id = this.props.commenterId;
            eventPayload.is_user_profile = true;
            await this.props.dispatch(trackEvent(eventPayload));
            /* END : data project - event trcking */
        }

        const {success} = await checkBlockStatus(this.props.commenterId);
        if(success) {
            this.setState({
                isAcceptOrRejectButtonClicked: true
            });
            this.props.dispatch(NewsFeedActions.rejectConnectionRequest(requesterId, null, type, 2, this.props.socket));
        } else {
            showPopupAlert(this.props.dispatch, POP_ALERT.OK, '', BLOCKUSER_REJECT_ERROR_MSG, '', BLOCKUSER_POPUP_CLASSNAME);
        }

    }

    _connect = async (userId, type) => {
        if(this.props.user) {
            /* data project - event trcking */
            let eventPayload = this._setPayload();
            eventPayload.element_id = FEED.PROFILE_PO_CONNECT;
            eventPayload.profile_id = this.props.commenterId;
            eventPayload.is_user_profile = true;
            await this.props.dispatch(trackEvent(eventPayload));
            /* END : data project - event trcking */
        }

        const {success} = await checkBlockStatus(this.props.commenterId);
        if(success) {
            const socketInfo = {
                socket: this.props.socket,
                userId: userId,
                type: NOTIFICATION_TYPES.CONNECTION_REQUESTS
            };
            this.props.dispatch(NewsFeedActions.addConnection(userId, null, type, '', socketInfo));
        } else {
            showPopupAlert(this.props.dispatch, POP_ALERT.OK, '', BLOCKUSER_CONNECT_ERROR_MSG, '', BLOCKUSER_POPUP_CLASSNAME);
        }

    }

    componentWillUnmount(){
        this.requestCancelToken.cancel('Cancelled Request')
    }

    render() {
        
        if (this.state.fetching) {
            return (
                <span onMouseOver={() => { this.setState({ popoverOpen: true }); this.props.setOnPopOver(true); }} onMouseLeave={() => { this.setState({ popoverOpen: false }); this.props.setOnPopOver(false); this.props.hidePopOver() }}>
                    <Popover trigger="hover" placement={this.props.item.placement} isOpen={true} target={'Popover-' + this.props.id} toggle={this.toggle}>
                        <PopoverBody>
                            <FetchingPopover />
                        </PopoverBody>
                    </Popover>
                </span>
            )
        } else if (this.state.commenterDetails) {
            return (
                <span onMouseOver={() => { this.setState({ popoverOpen: true }); this.props.setOnPopOver(true); }} onMouseLeave={() => { this.setState({ popoverOpen: false }); this.props.setOnPopOver(false); this.props.hidePopOver() }}>
                    <Popover trigger="hover" placement={this.props.item.placement} isOpen={this.props.show || this.state.popoverOpen} target={'Popover-' + this.props.id} toggle={this.toggle}>
                        <PopoverBody>
                            <div className="hoverProfile">
                                <div className="feed-img"></div>
                                <div className="feed-item with-img">
                                    <div className="media poster ">
                                        <div className="e-date">
                                            <AvatarProfile avatar={this.state.commenterDetails.PHOTO} size={90} key={this.state.commenterId} avatarSize={THUMBNAIL.MEDIUM} />
                                        </div>
                                        <div className="media-body p-t10">
                                            <div className="hoverProfileHeader">
                                                <h6 className="m-b5 font_bold font_white">{getPreview(this.state.commenterDetails.FIRST_NAME + ' ' + this.state.commenterDetails.LAST_NAME, 15)}</h6>
                                                {
                                                    this.state.commenterDetails.HEADLINE &&
                                                    <p className="font_12 font_light m-0  font_white">{`${getPreview(this.state.commenterDetails.HEADLINE, 15)}`}</p>
                                                }
                                                {
                                                    !this.state.commenterDetails.HEADLINE && this.state.commenterDetails.JOB_TITLE &&
                                                    <p className="font_12 font_light m-0  font_white">{`${getPreview(this.state.commenterDetails.JOB_TITLE, 15)}, `}
                                                        {this.state.commenterDetails.COMPANY_STATUS === STATUS.NOT_DELETED ? <Link className="font_lightBlue" to={`/profile/company/${this.state.commenterDetails.COMPANY_ID}`}>
                                                            {getPreview(this.state.commenterDetails.COMPANY_NAME, 15)}
                                                        </Link> : getPreview(this.state.commenterDetails.COMPANY_NAME, 15)}
                                                    </p>
                                                }
                                            </div>
                                            {
                                                this.state.commenterDetails.CONNECTIONS && (this.state.commenterDetails.SYSTEM_USER_STATUS === SYSTEM_USER_STATUS.ACTIVE) &&
                                                <div className="hoverProvileBtn  d-flex justify-content-between m-t25">
                                                    {
                                                        this.state.commenterDetails.CONNECTIONS.length === 0 &&
                                                        <button disabled={this.state.isConnectButtonClicked} onClick={this._connect.bind(this, this.state.commenterId, CONNECT_VIA.NEWSFEED)} className="btn gray_clr_line"><i className="fas fa-user-plus font_16 font_gray p-all3"></i> Connect</button>
                                                    }
                                                    {
                                                        /*this.state.commenterDetails.CONNECTIONS.length === 1 && this.state.commenterDetails.CONNECTIONS[0].STATUS === CONNECTION_STATUS.CONNECTED &&*/
                                                        <button className="btn gray_clr_line" onClick={this._openMsgPopup.bind(this)}><i className="fas fa-comment-dots font_16 font_gray p-all3"></i> Message</button>
                                                    }
                                                    {
                                                        this.state.commenterDetails.CONNECTIONS.length === 1 && this.state.commenterDetails.CONNECTIONS[0].STATUS === CONNECTION_STATUS.PENDING &&
                                                        this.state.commenterDetails.CONNECTIONS[0].REQUESTER === localStorage.getItem('userId') &&
                                                        <label disabled={true} className="btn pendingA">Pending Approval</label>
                                                    }
                                                    {
                                                        this.state.commenterDetails.CONNECTIONS.length === 1 && this.state.commenterDetails.CONNECTIONS[0].STATUS === CONNECTION_STATUS.PENDING &&
                                                        this.state.commenterDetails.CONNECTIONS[0].RECIPIENT === localStorage.getItem('userId') &&
                                                        <span className="d-flex connect-accept-reject-buttons">
                                                            <button disabled={this.state.isAcceptOrRejectButtonClicked} onClick={this._accept.bind(this, this.state.commenterId, CONNECT_VIA.NEWSFEED)} className="btn gray_clr_line">Accept</button>
                                                            <button disabled={this.state.isAcceptOrRejectButtonClicked} onClick={this._reject.bind(this, this.state.commenterId, CONNECT_VIA.NEWSFEED)} className="btn gray_clr_line">Reject</button>
                                                        </span>
                                                    }
                                                    {
                                                        this.state.commenterDetails.CONNECTIONS.length === 1 && this.state.commenterDetails.CONNECTIONS[0].STATUS === CONNECTION_STATUS.BLOCKED &&
                                                        <button disabled={true} className="btn gray_clr_line">Blocked</button>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </PopoverBody>
                    </Popover>
                </span>
            );
        } else {
            return (
                <div></div>
            );
        }

    }

}

// export default CommentorOverview;
export default connect(
    state => {
        return {
            newsFeedAction: state.newsFeed.action,
            commenterDetails: state.newsFeed.commenterDetails
        }
    }
)(CommentorOverview);