import React, { Component } from 'react';
import '../input/searchDropdown/index.css';
import classname from 'classnames';
import { Link } from 'react-router-dom';
import * as SearchActions from '../../types/search';
import { MEMBER_TYPE } from '../../types/common';
import { AvatarProfile } from '../common';
import {
  ADVANCED_SEARCH_DEFAULT_ENCODED,
  ADVANCED_SEARCH_DEFAULT,
} from './../../types/data';
import { encodeUrlParams, getPreview } from './../../libs/textFormat';
import { systemFormatAddress } from './../../libs/commonFunctions';

class SearchDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupVisible: false,
      selectedItem: {
        value: this.props.selectedValue || '',
        label: '',
      },
      prevItem: {
        value: '',
        label: '',
      },
      searchText: this.props.text || '',
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.clearSearchText = this._clearSearchText.bind(this);
    this.hidePopup = this._hidePopup.bind(this);
  }

  setDefault = () => {
    this.setState({
      selectedItem: {
        value: '',
        label: '',
      },
    });
  };

  handleClick() {
    if (this.props.isAuthenticated) {
      if (!this.state.popupVisible) {
        this.props.togglezIndex(true);
        if (this.props.onFocus) {
          this.props.onFocus();
        }
        document.addEventListener('click', this.handleOutsideClick, false);
      } else {
        this.props.togglezIndex(false);
        document.removeEventListener('click', this.handleOutsideClick, false);
      }

      this.setState((prevState) => ({
        popupVisible: !prevState.popupVisible,
      }));
    } else if (this.props.openLogin) {
      this.props.openLogin();
    }
  }

  handleOutsideClick = (e) => {
    if (this.node && this.node.contains && this.node.contains(e.target)) {
      return;
    }
    this.handleClick();
  };

  search = (e) => {
    if (!this.state.popupVisible) {
      this.handleClick();
    }
    this.props.onchange(e);
    this.setState({
      searchText: e.target.value,
    });
    if (e.target && e.which === 13) {
      this.hidePopup();
      if (this.props.hideNavbar) {
        this.props.hideNavbar();
      }
    }
  };

  setItem = (item, location) => {
    this.props.history.push(`/user-profile/` + item.SYSTEM_USER_ID);
    if (this.props.hideNavbar) {
      this.props.hideNavbar();
    }
    this.clearSearchText();
    this.hidePopup();
    // this.setState({ selectedItem: item });
    // this.handleClick();
    // if (this.props.submit) {
    //     this.props.submit(item, this.props.inputname);
    // }
  };

  setCompany = (item, location) => {
    this.props.history.push(`/profile/company/` + item.COMPANY_ID);
    if (this.props.hideNavbar) {
      this.props.hideNavbar();
    }
    this.clearSearchText();
    this.hidePopup();
    // this.setState({ selectedItem: item });
    // this.handleClick();
    // if (this.props.submit) {
    //     this.props.submit(item, this.props.inputname);
    // }
  };

  // populateIndividual = data => {
  //     let dropdownItem = classname(
  //         this.props.size === "small"
  //             ? "dropdown_item dropdown_item_small"
  //             : "dropdown_item"
  //     );

  //     let users = [];

  //     data.forEach((item, i) => {
  //         users.push(
  //             <div key={i+'2'+item.SYSTEM_USER_ID} className={dropdownItem} onClick={this.setItem.bind(this, item)}>
  //                 {item.FIRST_NAME + " " + item.LAST_NAME}
  //                 {item.JOB_TITLE && <br />}
  //                 {item.JOB_TITLE && item.JOB_TITLE + ' @ ' + item.COMPANY_NAME}
  //             </div>
  //         )
  //     });

  //     return users;

  // };

  populateFields = () => {
    let fields = this.props.profiles;
    let sortedArray = [];
    let dropdownItem = classname(
      this.props.size === 'small'
        ? 'dropdown_item dropdown_item_small'
        : 'dropdown_item'
    );
    fields.forEach((item, i) => {
      item.LOWER_NAME.toLowerCase().startsWith(
        this.state.searchText.toLowerCase()
      )
        ? sortedArray.unshift(
            <div
              key={i + '2' + item.SYSTEM_USER_ID}
              className={dropdownItem}
              onClick={this.setItem.bind(this, item)}
            >
              {item.FIRST_NAME + ' ' + item.LAST_NAME}
              {item.JOB_TITLE && <br />}
              {item.JOB_TITLE && item.JOB_TITLE + ' @ ' + item.COMPANY_NAME}
            </div>
          )
        : sortedArray.push(
            <div
              key={i + '2' + item.SYSTEM_USER_ID}
              className={dropdownItem}
              onClick={this.setItem.bind(this, item)}
            >
              {item.FIRST_NAME + ' ' + item.LAST_NAME}
              {item.JOB_TITLE && <br />}
              {item.JOB_TITLE && item.JOB_TITLE + ' @ ' + item.COMPANY_NAME}
            </div>
          );
    });
    return sortedArray;
  };

  populateCompanies = () => {
    let data = this.props.profiles;
    let companies = [];
    let companyarr = [];
    let dropdownItem = classname(
      this.props.size === 'small'
        ? 'dropdown_item dropdown_item_small'
        : 'dropdown_item'
    );

    data.forEach((item, i) => {
      if (item.COMPANY_NAME) {
        if (
          item.COMPANY_NAME.toLowerCase().includes(
            this.state.searchText.toLowerCase()
          )
        ) {
          if (!companyarr.includes(item.COMPANY_NAME)) {
            companyarr.push(item.COMPANY_NAME);
            item.COMPANY_NAME.toLowerCase().startsWith(
              this.state.searchText.toLowerCase()
            )
              ? companies.unshift(
                  <div
                    key={i + '1'}
                    className={dropdownItem}
                    onClick={this.setCompany.bind(this, item)}
                  >
                    {item.COMPANY_NAME}
                    <br />
                  </div>
                )
              : companies.push(
                  <div
                    key={i + '1'}
                    className={dropdownItem}
                    onClick={this.setCompany.bind(this, item)}
                  >
                    {item.COMPANY_NAME}
                    <br />
                  </div>
                );
          }
        }
      }
    });

    return companies;
  };

  _clearSearchText = () => {
    this.setState({ searchText: '' });
    this.refs.input.value = '';
    this.props.clearTopSearch();
  };

  _hidePopup = () => {
    if (this.state.popupVisible) {
      this.handleClick();
    }
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.action === SearchActions.CLEAR_TOP_SEARCH &&
      this.state.searchText !== ''
    ) {
      this.refs.input.value = '';
      this.setState({
        searchText: '',
      });
    }
  }

  populateSearchResults = () => {
    let data = this.props.profiles;
    let dropdownItem = classname(
      this.props.size === 'small'
        ? 'dropdown_item dropdown_item_small'
        : 'dropdown_item'
    );

    return data.map((item, i) => {
      return (
        <div
          key={item._id}
          className={dropdownItem}
          onClick={this.redirect.bind(this, item)}
        >
          {item.FLAG === '1' && (
            <div
              className="d-flex align-items-center ddsr_item"
              onClick={this.props.setProfileViewLocation}
            >
              <div>
                <AvatarProfile
                  isCompany={false}
                  avatar={item.PHOTO}
                  size={42}
                  key={this.props.index}
                  avatarSize="small-"
                />
              </div>
              <div className="m-l20 ddsr_right">
                <div className="d-flex align-items-center">
                  <div className="name">
                    {item.FIRST_NAME + ' ' + item.LAST_NAME + '  '}
                  </div>
                  <div className="badges d-flex align-items-centers m-l5">
                    {item.IS_PRO_MEMBER && (
                      <div className="badge badge-primary m-r5">PRO</div>
                    )}
                    {item.IS_EXPERT_CONTRIBUTOR &&
                      item.IS_EXPERT_CONTRIBUTOR.STATUS && (
                        <div className="badge badge-primary m-r5 green">
                          Expert
                        </div>
                      )}
                  </div>
                </div>
                {item.HEADLINE && item.HEADLINE !== null && (
                  <div className="headline">{item.HEADLINE}</div>
                )}
                {(!item.HEADLINE || item.HEADLINE === null) &&
                  item.JOB_TITLE && (
                    <div className="job-title">
                      {' '}
                      {item.JOB_TITLE +
                        ', ' +
                        getPreview(item.COMPANY_NAME, 100)}
                    </div>
                  )}
                <div className="d-flex align-items-center user-info">
                  <div className="location">
                    {systemFormatAddress(item.CITY, item.STATE, item.COUNTRY)}
                  </div>
                  {item.SYSTEM_USER_ID !== this.props.auth.user._id &&
                    item.CITY &&
                    item.MUTUAL_FRIENDS_COUNT > 0 && (
                      <div className="m-l5 m-r5">•</div>
                    )}
                  {item.SYSTEM_USER_ID !== this.props.auth.user._id &&
                    item.MUTUAL_FRIENDS_COUNT > 0 && (
                      <span>
                        <span
                          onClick={() =>
                            this.props.showMutualPopUp(
                              item.MUTUAL_FRIENDS_COUNT,
                              this.props.creatorId
                            )
                          }
                          style={{ cursor: 'pointer' }}
                          className="font_light font_11 m-0"
                        >
                          {item.MUTUAL_FRIENDS_COUNT} Mutual{' '}
                          {item.MUTUAL_FRIENDS_COUNT > 1
                            ? 'Connections'
                            : 'Connection'}
                        </span>
                      </span>
                    )}
                </div>
              </div>
            </div>
          )}

          {item.FLAG === '2' && (
            <div className="d-flex  align-items-center ddsr_item">
              <div>
                <AvatarProfile
                  isCompany={true}
                  avatar={item.LOGO}
                  size={42}
                  key={this.props.index}
                  avatarSize="small-"
                />
              </div>
              <div className="m-l20 font_medium" title={item.COMPANY_NAME}>
                {' '}
                {getPreview(item.COMPANY_NAME, 100)}{' '}
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  redirect = (item) => {
    if (item.FLAG === '1') {
      this.clearSearchText();
      this.hidePopup();
      this.props.history.push(`/user-profile/` + item.SYSTEM_USER_ID);
      if (this.props.hideNavbar) {
        this.props.hideNavbar();
      }
    } else if (item.FLAG === '2') {
      this.clearSearchText();
      this.hidePopup();
      this.props.history.push(`/profile/company/` + item.COMPANY_ID);
      if (this.props.hideNavbar) {
        this.props.hideNavbar();
      }
    }
  };

  redirectToAdvanceSearch = (text) => {
    this.hidePopup();
    if (this.props.hideNavbar) {
      this.props.hideNavbar();
    }
    let params = ADVANCED_SEARCH_DEFAULT.ALL;
    params.searchTerm = text;
    let hash = encodeUrlParams(params);
    this.props.history.push(`/advanced-search/all-users/q=${hash}`);
  };

  handleDivClick = (path) => {
    this.props.hideNavbar && this.props.hideNavbar();
    this.props.history.push(path);
    this.hidePopup();
  };

  render() {
    let dropdown = classname(
      'dropdown_body ',
      this.props.size === 'small'
        ? 'dropdown_body_small'
        : 'dropdown_body_regular',
      this.state.popupVisible === true ? 'media_active' : 'media_hide',
      'menu-search-dropdown'
    );

    let dropdownItem = classname(
      this.props.size === 'small'
        ? 'dropdown_item dropdown_item_small'
        : 'dropdown_item'
    );

    return (
      <React.Fragment>
        <input
          id="TopSearchInput"
          className="form-control navigation_input"
          ref="input"
          type="text"
          placeholder="Search"
          aria-label="Search"
          onKeyUp={this.search.bind(this)}
          onClick={this.handleClick}
          autoComplete="off"
          disabled={!this.props.isAuthenticated}
        />

        {this.state.searchText && (
          <i className="fa fa-close" onClick={this.clearSearchText} />
        )}

        {this.props.profiles &&
          this.props.profiles.length > 0 &&
          this.state.searchText !== '' && (
            <div
              className={dropdown}
              ref={(node) => {
                this.node = node;
              }}
              style={{ zIndex: 9999 }}
            >
              {this.props.searching && (
                <div className={dropdownItem} style={{ color: '#9ccc65' }}>
                  {' '}
                  Loading...
                </div>
              )}

              {!this.props.searching && this.populateSearchResults()}

              {/* {!this.props.searching && this.populateCompanies()}
                    {!this.props.searching && this.populateFields()} */}

              {/* {!this.props.searching && this.populateIndividual(investors)}
                    {!this.props.searching && this.populateIndividual(fundrisers)} */}
              {/*{this.populateCompanies(fundrisersCompanies)}*/}
              {/*{this.populateCompanies(investorCompanies)}*/}

              {!this.props.searching && this.props.count > 10 && (
                <div
                  className={dropdownItem}
                  onClick={this.redirectToAdvanceSearch.bind(
                    this,
                    this.state.searchText
                  )}
                >
                  See All Results
                  {/* <Link style={{ textDecoration: 'none' }} to={`/advanced-search/all-users/q=${this.state.searchText}`} > See All Results</Link> */}
                </div>
              )}
              {/* {items} */}
            </div>
          )}

        {this.props.profiles &&
          (this.props.profiles.length === 0 ||
            this.state.searchText === '') && (
            <div
              className={dropdown}
              ref={(node) => {
                this.node = node;
              }}
              style={{ zIndex: 9999 }}
            >
              {this.props.searching && (
                <div className={dropdownItem} style={{ color: '#9ccc65' }}>
                  {' '}
                  Loading...
                </div>
              )}
              {!this.props.searching && (
                <div
                  onClick={() =>
                    this.handleDivClick(
                      `/advanced-search/investors/q=${ADVANCED_SEARCH_DEFAULT_ENCODED.INVESTOR}`
                    )
                  }
                  className={dropdownItem}
                >
                  <Link
                    to={''}
                    style={{ textDecoration: 'none' }}
                    id="TopSearchOptionInvestors"
                  >
                    <i className="fas fa-users" /> Search Investors
                  </Link>
                </div>
              )}
              {!this.props.searching && (
                <div
                  onClick={() =>
                    this.handleDivClick(
                      `/advanced-search/professionals/q=${ADVANCED_SEARCH_DEFAULT_ENCODED.PROFESSIONAL}`
                    )
                  }
                  className={dropdownItem}
                >
                  <Link
                    style={{ textDecoration: 'none' }}
                    to={''}
                    id="TopSearchOptionProfessionals"
                  >
                    <i className="fas fa-users" /> Search Professionals
                  </Link>
                </div>
              )}
              {!this.props.searching && (
                <div
                  onClick={() =>
                    this.handleDivClick(
                      `/advanced-search/companies/q=${ADVANCED_SEARCH_DEFAULT_ENCODED.COMPANY_PROFILES}`
                    )
                  }
                  className={dropdownItem}
                >
                  <Link
                    style={{ textDecoration: 'none' }}
                    to={''}
                    id="TopSearchOptionCompanies"
                  >
                    <i className="fas fa-building" /> Search Companies
                  </Link>
                </div>
              )}
              {!this.props.searching &&
                this.props.auth &&
                this.props.auth.user &&
                this.props.auth.user.MEMBER_TYPE === MEMBER_TYPE.INVESTOR && (
                  <div
                    onClick={() =>
                      this.handleDivClick(
                        `/advanced-search/fundraisingCompanies/q=${ADVANCED_SEARCH_DEFAULT_ENCODED.FUND_RAISING_COMPANY_PROFILES}`
                      )
                    }
                    className={dropdownItem}
                  >
                    <Link
                      style={{ textDecoration: 'none' }}
                      to={''}
                      id="TopSearchOptionFunraisingCompanies"
                    >
                      <i className="fas fa-building" /> Search Fundraising
                      Companies
                    </Link>
                  </div>
                )}
            </div>
          )}
      </React.Fragment>
    );
  }
}

SearchDropdown.defaultProps = {
  type: 'text',
  size: null,
};

export default SearchDropdown;
