import chatTypes, { STATUS } from '../types/chat';
import { playNotificationSound } from '../libs/socket'


export const initialState = {
    threads: [],
    conversations: {}, // {userId: [...messages]} 
    conversationsCount: {}, // {userId: [...messages]}
    isTyping: [], // conversation ids
    threadFetching: [], // conversation ids
    notifications: [], // conversation ids
    minimizeCoversations: [], // conversation ids
    gettingConversations: false,
    gettingMoreConversations: false,
    chats: [],
    chatCount: 0,
    openNewChat: false,
    chatLastActive: {},
    replaceChat: null,
    initialUnseenConversations: [],

    action: 0
};

const chats = (state = initialState, action) => {
    switch (action.type) {
        case chatTypes.OPEN_EXISTING_CHAT:
            const found = state.threads.some(item => item.coversationId === action.data.coversationId);
            if (!found) {
                if (action.data.newNotification) {
                    playNotificationSound();
                }
                return {
                    ...state,
                    threads: [action.data, ...state.threads],
                };
            }
            return state
        case chatTypes.CLOSE_EXISTING_CHAT:
            return {
                ...state,
                threads: action.data,
            };
        case chatTypes.OPEN_NEW_CHAT:
            return {
                ...state,
                openNewChat: action.isOpen
            };
        case chatTypes.GET_CHAT_MESSAGES + chatTypes.STATUS.FETCHING:
            return {
                ...state,
                threadFetching: updateArrayState(state.threadFetching, action.conversationId, true),
            };
        case chatTypes.GET_CHAT_MESSAGES + chatTypes.STATUS.SUCCESS:
            let updatedConversation = {
                ...state.conversations
            };
            let currentMessages = (!action.shouldReset && state.conversations[action.messages[0].CONVERSATION_ID]) ? state.conversations[action.messages[0].CONVERSATION_ID] : [];
            updatedConversation[action.messages[0].CONVERSATION_ID] = [...action.messages.reverse(), ...currentMessages];
            return {
                ...state,
                conversations: updatedConversation,
                threadFetching: updateArrayState(state.threadFetching, action.conversationId, false),
            };
        case chatTypes.GET_CHAT_MESSAGES + chatTypes.STATUS.FAIL:

            return {
                ...state,
                threadFetching: updateArrayState(state.threadFetching, action.conversationId, false),
            };
        case chatTypes.GET_CHAT_MESSAGES_COUNT + chatTypes.STATUS.SUCCESS:
            let conversationsCount = {
                ...state.conversationsCount
            };
            conversationsCount[action.userId] = action.count;
            return {
                ...state, conversationsCount: conversationsCount,
            };
        case chatTypes.SEND_CHAT_MESSAGE:
            let conversations = {
                ...state.conversations
            };
            let currentConversations = state.conversations[action.data.CONVERSATION_ID] ? state.conversations[action.data.CONVERSATION_ID] : [];
            conversations[action.data.CONVERSATION_ID] = [...currentConversations, action.data];
            let conversationCount = {
                ...state.conversationsCount
            };
            conversationCount[action.userId] = conversationCount[action.userId] + 1;
            return {
                ...state, conversations: conversations, conversationsCount: conversationCount
            };
        case chatTypes.UPDATE_CHAT_THREAD:
            let updatedThreads = {
                ...state.conversations
            };
            let currentThreadMessages = (!action.shouldReset && state.conversations[action.data.CONVERSATION_ID]) ? state.conversations[action.data.CONVERSATION_ID] : [];
            updatedThreads[action.data.CONVERSATION_ID] = [...currentThreadMessages, action.data];
            let conversationThreadCount = {
                ...state.conversationsCount
            };
            conversationThreadCount[action.data.SENDER_DETAILS.SYSTEM_USER_ID] = conversationThreadCount[action.data.SENDER_DETAILS.SYSTEM_USER_ID] + 1;
            return {
                ...state, conversations: updatedThreads,
            };
        case chatTypes.UPDATE_CHAT_THREAD_TYPING_STATUS:
            return {
                ...state,
                isTyping: updateArrayState(state.isTyping, action.conversationId, action.status),
            };
        case chatTypes.FILTER_DELETED_CHAT:
            return {
                ...state,
                threads: state.threads.filter(tr => { return tr.coversationId !== action.conversationId }),
                chats: state.chats.filter(c => { return c._id !== action.conversationId })
            };
        case chatTypes.UPDATE_CHAT_ONLINE_STATUS:
            const newTreadList = state.threads.map(item => {
                if (item.coversationId === action.conversationId) {
                    if(action.blockStatus) {
                        return { ...item, failedMessage: action.message, blockStatus: true }
                    } else {
                        return { ...item, failedMessage: action.message }
                    }
                } else {
                    return item
                }
            });
            return {
                ...state,
                threads: newTreadList
            };
        case chatTypes.NOTIFICATION_CHAT_POP:
            const conversationFound = state.notifications.some(item => item === action.conversationId);
            if (!conversationFound && state.minimizeCoversations.indexOf(action.conversationId) !== -1) {
                return {
                    ...state,
                    notifications: [action.conversationId, ...state.notifications],
                };
            }
            return state;
        case chatTypes.NEW_CHAT_NOTIFICATION:
            let newChatList = state.chats.filter(c => { return c._id !== action.data._id });
            return {
                ...state,
                chats: [{ ...action.data }, ...newChatList]
            };
        case chatTypes.MINIMIZE_CONVERSATIONS:
            let minimizeList = [...state.minimizeCoversations]
            const minimizeCoversationFound = minimizeList.some(item => item === action.conversationId);
            if (!minimizeCoversationFound) {
                return {
                    ...state,
                    minimizeCoversations: [action.conversationId, ...state.minimizeCoversations],
                };
            }

            minimizeList.splice(state.minimizeCoversations.indexOf(action.conversationId), 1);
            const filteredNotifications = state.notifications.filter((conversationId) => {
                return conversationId !== action.conversationId
            });
            return {
                ...state,
                minimizeCoversations: minimizeList,
                notifications: filteredNotifications,
            };
        case chatTypes.CLEAN_CHATPOP_STATES:

            const minConversations = state.minimizeCoversations.filter((conversationId) => {
                return conversationId !== action.conversationId
            });
            const notifications = state.notifications.filter((conversationId) => {
                return conversationId !== action.conversationId
            });

            return {
                ...state,
                notifications: notifications, // conversation ids
                minimizeCoversations: minConversations // conversation ids
            };
        case chatTypes.CLEAN_SINGLE_CHATPOP_STATES:
            const comThreads = state.threads.filter((item) => {
                return item.userId !== action.userId && item.module !== action.module
            });

            return {
                ...state,
                threads: comThreads
            };

        // fetch conversations
        case chatTypes.GET_CHAT_CONVERSATIONS + STATUS.FETCHING:
            return {
                ...state,
                gettingConversations: action.shouldReset,
                gettingMoreConversations: !action.shouldReset,
                action: chatTypes.GET_CHAT_CONVERSATIONS + STATUS.FETCHING,
            };
        case chatTypes.GET_CHAT_CONVERSATIONS + STATUS.SUCCESS:
            return {
                ...state,
                gettingConversations: false,
                gettingMoreConversations: false,
                chats: action.shouldReset ? action.conversations : state.chats.concat(action.conversations),
                action: chatTypes.GET_CHAT_CONVERSATIONS + STATUS.SUCCESS,
            };
        case chatTypes.GET_CHAT_CONVERSATIONS + STATUS.FAIL:
            return {
                ...state,
                gettingConversations: false,
                gettingMoreConversations: false,
                action: chatTypes.GET_CHAT_CONVERSATIONS + STATUS.FAIL,
            };

        // fetch conversation count
        case chatTypes.GET_CHAT_CONVERSATIONS_COUNT + STATUS.FETCHING:
            return {
                ...state,
                action: chatTypes.GET_CHAT_CONVERSATIONS_COUNT + STATUS.FETCHING
            };
        case chatTypes.GET_CHAT_CONVERSATIONS_COUNT + STATUS.SUCCESS:
            return {
                ...state,
                action: chatTypes.GET_CHAT_CONVERSATIONS_COUNT + STATUS.SUCCESS,
                chatCount: action.count
            };
        case chatTypes.GET_CHAT_CONVERSATIONS_COUNT + STATUS.FAIL:
            return {
                ...state,
                action: chatTypes.GET_CHAT_CONVERSATIONS_COUNT + STATUS.FAIL
            };
        case chatTypes.SOCKET_NEW_CHAT_MESSAGE:
            let newChats = state.chats;
            if (newChats.length > 0 && newChats[0]._id === action.conversationId) {
                newChats[0] = { ...newChats[0], RECENT_MESSAGE: action.message, LAST_MESSAGE_SEEN: { ...newChats[0].LAST_MESSAGE_SEEN, IS_SEEN: false } };
            }
            return {
                ...state,
                chats: newChats
            };
        case chatTypes.SOCKET_SEEN_CHAT_MESSAGE:
            let tempChats = state.chats.map(c => { return c._id === action.conversationId ? { ...c, LAST_MESSAGE_SEEN: { ...c.LAST_MESSAGE_SEEN, IS_SEEN: true } } : c });
            return {
                ...state,
                chats: tempChats
            };
        case chatTypes.SET_CHAT_ACTIVE_STATUS:
            let newActiveStatus = { ...state.chatLastActive };
            if (action.status && newActiveStatus[action.conversationId]) {
                delete newActiveStatus[action.conversationId]
            } else if (!action.status) {
                newActiveStatus[action.conversationId] = Date.now()
            }
            return {
                ...state,
                chatLastActive: newActiveStatus
            }
        case chatTypes.REPLACE_INACTIVE_CHAT:
            return {
                ...state,
                replaceChat: {
                    oldConversationData: action.oldConversation,
                    newConversationData: action.newConversation
                }
            }
        case chatTypes.CLEAR_REPLACE_INACTIVE_CHAT:
            return {
                ...state,
                replaceChat: null
            }
        case chatTypes.INIT_UNSEEN_CHATS:
            return {
                ...state,
                initialUnseenConversations: action.conversations
            }
        case chatTypes.CLEAR_INIT_UNSEEN_CHATS:
            return {
                ...state,
                initialUnseenConversations: state.initialUnseenConversations.filter(i => { return i._id !== action.conversationId })
            }
        case chatTypes.REFETCH_MY_CONVERSATIONS: {
            return {
                ...state,
                action: chatTypes.REFETCH_MY_CONVERSATIONS
            }
        }
        case chatTypes.CLEAR_CHAT_ACTION: {
            return {
                ...state,
                action: 0
            }
        }
        default:
            return state
    }
};

const updateArrayState = (arrayState, conversationId, status = false) => {
    let stateValue = [...arrayState];
    if (!status && arrayState.indexOf(conversationId) >= 0) {
        const index = stateValue.indexOf(conversationId);
        if (index > -1) {
            stateValue.splice(index, 1);
            return stateValue;
        } else {
            stateValue.push(conversationId);
            return stateValue;
        }
    }

    if (status && arrayState.indexOf(conversationId) === -1) {
        stateValue.push(conversationId);
        return stateValue;
    }

    return stateValue;

}
export default chats;