import React, {Component} from "react";
import SearchDropdown from './searchDropdown';
import * as Data from '../../types/data';
import {
    LEAFWIRE_ADMIN_EMAILS,
    CONNECTIONS_SECTION,
    MESSAGE_SECTION_TYPES,
    NOTIFICATION_TYPES,
    MEMBER_TYPE,
    NAVIGATION_PAGE,
    COMPANY_LIST_POPUP,
    HIDE_SEARCH_BAR,
    USER_PROFILE_VIEW_LOCATIONS,
    PUBLIC_PENDING_ACTIONS,
    ALERT
} from '../../types/common';
import LogoMain from "../../assets/img/logo-light.svg";
import ProfileImg from "../../assets/img/default-male.jpg";
import "./index.css";
import className from "classnames";
import {Link} from "react-router-dom";
import {logout, removePublicAction} from "../../actions/auth";
import {clearTopSearchText} from '../../actions/search';
import Tooltip from './toolTip';
import {imageExists} from '../../libs/image';
import AuthTypes from './../../types/authentication';
import AdminCompanyList from './adminCompanyList';
import AccountSettings from './AccountSettings';
import {ADVANCED_SEARCH_DEFAULT_ENCODED, HEADER, ENCODED_DEFAULT_FILTER} from '../../types/data';
import {switchCompany} from "../../actions/company";
import {createJobSeeker, autoLoginJBHQ} from "../../actions/jobBoard";
import {postAJob} from '../../libs/commonMethods';
import {encodeUrlParams} from "../../libs/textFormat";
import ToolTip from "../common/tooltip";
import TT from "./../../types/tooltipsData";
import MobileGroupPopup from './mobileGroupsPopup';
import {JOIN_GROUP, STATUS, CLOSE_MOBILE_POPUP} from '../../types/groups';
import {setProfileViewLocation} from "../../actions/profile";
import {clearNewsfeedSinglePost} from '../../actions/newsFeed';
import {trackEvent} from '../../actions/eventTracking';
import {EVENT_ACTION} from '../../types/eventTracking';
import {TOP_NAV} from '../../types/dataTrackingElementIds';
import {PAGES} from '../../types/common';

class IsAuth extends Component {

    constructor(props) {
        super(props);
        this.state = {
            scrollHeight: null,
            scrolled: false,
            profileImage: localStorage.getItem('profileThumbnail') ? localStorage.getItem('profileThumbnail') : ProfileImg,
            actionDropDownVisible: false,
            actionDropDownReadyToHide: true,
            LeafwireAdminLoggedIn: false,
            chagezIndex: false,
            currentPage: 0,
            showMobileGroupPopup: false,
            pageName: 'HOME', //data project - event trcking
        };
        this.handleScroll = this.handleScroll.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.clearTopSearch = this._clearTopSearch.bind(this);
        this.toggleActionDropDown = this.toggleActionDropDown.bind(this);
        this.switchCompany = this._switchCompany.bind(this);
        this.redirectToJBHQ = this._redirectToJBHQ.bind(this);
        this.toggleMobileGroupPopup = this._toggleMobileGroupPopup.bind(this);
    }

    componentDidMount() {
        const isProfilePic = true;
        window.addEventListener('scroll', this.handleScroll);
        imageExists(localStorage.getItem('profileThumbnail'), (exists) => {
            if (exists) {
                this.setState({profileImage: localStorage.getItem('profileThumbnail')})
            } else {
                this.setState({profileImage: ProfileImg})
            }
        }, isProfilePic);
        if (LEAFWIRE_ADMIN_EMAILS.indexOf(this.props.auth.user.EMAIL) >= 0) {
            this.setState({LeafwireAdminLoggedIn: true});
        }
        window.addEventListener('click', this.handleClick, false);
        this.handleCurrentPage()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.handleCurrentPage()
        }
        if (this.props.groupsAction === JOIN_GROUP + STATUS.SUCCESS && this.state.showMobileGroupPopup) {
            this.toggleMobileGroupPopup(false)
        }

        if (this.props.groupsAction === CLOSE_MOBILE_POPUP && this.state.showMobileGroupPopup) {
            this.toggleMobileGroupPopup(false)
        }

        if (!prevProps.isAuthenticated && this.props.isAuthenticated) {
            if (this.props.publicPendingAction && this.props.publicPendingAction.type && this.props.publicPendingAction.type === PUBLIC_PENDING_ACTIONS.TN_REDIRECT) {
                let action = this.props.publicPendingAction;
                this.props.dispatch(removePublicAction());
                if (action.data) {
                    this.props.history.push(action.data)
                }
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('click', this.handleClick, false)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.action === AuthTypes.CLEAR_PROFILE_THUMBNAIL_IMAGE) {
            this.setState({
                profileImage: ProfileImg
            })
        }
        if (nextProps.auth.action === AuthTypes.SIGNIN_SUCCESS) {
            this.setState({
                profileImage: ProfileImg,
                LeafwireAdminLoggedIn: (nextProps.auth && nextProps.auth.user && nextProps.auth.user.EMAIL) ? LEAFWIRE_ADMIN_EMAILS.indexOf(nextProps.auth.user.EMAIL) >= 0 : false
            }, () => {
                imageExists(localStorage.getItem('profileThumbnail'), (exists) => {
                    if (exists) {
                        this.setState({profileImage: localStorage.getItem('profileThumbnail')})
                    } else {
                        this.setState({profileImage: ProfileImg})
                    }
                }, true);
            })
        }
        if (nextProps.auth.action === AuthTypes.SIGNUP_SUCCESS) {
            this.setState({
                profileImage: ProfileImg,
                LeafwireAdminLoggedIn: false
            })
        }
        if (nextProps.auth.action === AuthTypes.GETTING_PROFILE_THUMBNAIL_IMAGE_SUCCESS) {
            this.setState({
                profileImage: nextProps.auth.profileThumbnailUrl
            })
        }
    }

    handleScroll() {
        if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0) > 0) {
            this.setState({
                scrolled: true,
                scrollHeight: window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
            });
        } else {
            this.setState({scrolled: false, scrollHeight: null});
        }
    }

    handleClick() {
        if (this.state.actionDropDownReadyToHide) {
            this.setState({actionDropDownVisible: false})
            this.setState({chagezIndex: false})
        }
    }

    _logOut = () => {
        this.props.dispatch(logout(() => {
            this.props.history.push('/');
            this.props.userHasAuthenticated(false);
            this.props.socket.disconnect();
        }));
    };

    _clearTopSearch = () => {
        this.props.dispatch(clearTopSearchText())
    };

    handleImageError() {
        this.setState({profileImage: ProfileImg});
    }

    navigator = () => {
        if (!this.props.location.pathname.includes('/page') && localStorage.getItem("token")) {
            window.location.reload();
        }
    };

    toggleActionDropDown = (status = 0, isMobile = false) => {
        /*
        * status 0 = hide, 1 = show, 2 = toggle
        */
        if (isMobile) {
            this.setState({actionDropDownReadyToHide: false})
        }
        if (status === 0) {
            this.togglezIndex(false);
            this.setState({actionDropDownVisible: false})
        } else if (status === 1) {
            this.togglezIndex(true);
            this.setState({actionDropDownVisible: true})
        } else if (status === 2) {
            this.togglezIndex(!this.state.actionDropDownVisible);
            this.setState({actionDropDownVisible: !this.state.actionDropDownVisible})
        }
    };

    removeLocalStorageElements() {
        if (localStorage.getItem('selectedCategories')) {
            localStorage.removeItem('selectedCategories');
        }
    }

    _switchCompany = () => {
        this.props.dispatch(switchCompany())
    };

    togglezIndex = (status = null) => {
        this.setState(curSt => {
            return {
                chagezIndex: status !== null ? status : !curSt.chagezIndex
            }
        })
    }

    handleCurrentPage = () => {
        if (this.props.location.pathname.includes('/message')) {
            this.setState({currentPage: NAVIGATION_PAGE.MESSAGES})
        } else if (this.props.location.pathname.includes('/marketplace')) {
            this.setState({currentPage: NAVIGATION_PAGE.MARKETPLACE_PUBLIC})
        } else if (this.props.location.pathname.includes('/filter-connections')) {
            this.setState({currentPage: NAVIGATION_PAGE.FILTER_CONNECTIONS})
        } else if (this.props.location.pathname.includes('/events')) {
            this.setState({currentPage: NAVIGATION_PAGE.EVENTS})
        } else if (this.props.location.pathname.includes('/groups')) {
            this.setState({currentPage: NAVIGATION_PAGE.GROUPS})
        } else if (this.props.location.pathname === '/training') {
            this.setState({currentPage: NAVIGATION_PAGE.TRAINING})
        } else if (this.props.location.pathname.includes('/user-profile-edit')) {
            this.setState({currentPage: NAVIGATION_PAGE.PERSONAL_PROFILE})
        } else if (this.props.location.pathname === '/') {
            this.setState({currentPage: NAVIGATION_PAGE.HOME})
        } else {
            this.setState({currentPage: NAVIGATION_PAGE.DEFAULT})
        }

        let pageName = this.props.location.pathname.split('/')[1];
        const subpageName = this.props.location.pathname.split('/')[2];
        if (pageName === 'contact-us') pageName = this.props.location.search

        let page;
        if (PAGES[pageName]) page = PAGES[pageName];
        else if (PAGES[subpageName]) page = PAGES[subpageName];
        else if (pageName === '') page = PAGES['0'];
        else page = pageName.replace(/-/g, '_').toUpperCase();

        this.setState({pageName: page});
    };

    _redirectToJBHQ = () => {
        this.props.toggleLoading(true);
        createJobSeeker()
            .then(res => {
                if (res.success) {
                    const {email, password} = res.data;
                    if (email && password) {
                        autoLoginJBHQ({email, password, jobkey: localStorage.getItem('JBHQ_JOB_ID')})
                    } else {
                        this.props.showSweetAlert('Error', 'Unexpected error occurred', false, () => {
                        }, () => {
                        });
                        this.props.toggleLoading(false);
                    }
                } else {
                    this.props.showSweetAlert('Error', 'The job board is currently unavailable. It should be back shortly.', false, () => {
                    }, () => {
                    });
                    this.props.toggleLoading(false);
                }
            })
            .catch(ex => {
                this.props.showSweetAlert('Error', ex.message, false, () => {
                }, () => {
                });
                this.props.toggleLoading(false);
            });
    };

    _toggleMobileGroupPopup = (status) => {
        this.setState({showMobileGroupPopup: status})
    };

    _setProfileViewLocation = () => {
        this.props.dispatch(setProfileViewLocation(USER_PROFILE_VIEW_LOCATIONS.TOP_SEARCH))
    };

    /* data project - event trcking */
    _setEventTrackingPayload = (link) => {
        const spanType = [TOP_NAV.NEW_LIST, TOP_NAV.EDIT_LIST, TOP_NAV.REPORTING, TOP_NAV.POST_JOB]
        if (link && this.props.auth.user) {
            const payload = {
                user_id: this.props.auth.user._id,
                page: this.state.pageName,
                section: 'ME_SECTION',
                element_type: spanType.includes(link) ? 'SPAN' : 'LINK',
                element_id: link,
                action_type: EVENT_ACTION.CLICK
            }

            this.props.dispatch(trackEvent(payload));
        }
    }

    /* END : data project - event trcking */


    render() {

        const connectionRedirection = (isUserHasPendingConnections) => {
            if (isUserHasPendingConnections) {
                const encoded = encodeUrlParams({action: 'toggleSection', activeSection: CONNECTIONS_SECTION.PENDING});
                return `/filter-connections/q=${encoded}`;
            }
            return `/filter-connections/q=${ENCODED_DEFAULT_FILTER[CONNECTIONS_SECTION.SUGGESTED]}`;
        };

        const marketplaceRedirection = (isMarketplaceInfoRequests) => {
            if (isMarketplaceInfoRequests) {
                return `/message/${MESSAGE_SECTION_TYPES.PRODUCTS.id}/${MESSAGE_SECTION_TYPES.All.id}`
            }

            return '/marketplace/public'
        };

        let {isMyMarketPlace, showMobileNav, isAuthenticated} = this.props;
        const {pathname} = this.props.location

        const hideNavBar = () => {
            document.getElementById("hideNavBar").click()
        };

        let navLayout = className("navbar", "navbar-expand-lg", !this.props.location.pathname.includes('/video-reference') ? "fixed-top" : "", this.state.scrollHeight <= 0 ? "navigation-animate-up" : "navigation-animate-down");
        let marketplaceDefaultAdd = [];
        let marketplaceDefaultEdit = [];

        if (this.props.auth && this.props.auth.myCompanies && this.props.auth.myCompanies.length > 0) {
            this.props.auth.myCompanies.forEach(com => {
                marketplaceDefaultAdd.push(com._id);
                if (com.PRODUCTS > 0) {
                    marketplaceDefaultEdit.push(com._id)
                }
            })
        }

        return (
            <div className="nav-wrapper">
                <nav id="navigation" className={navLayout}
                     style={(this.props.alert.show === true && this.props.alert.type !== ALERT.UNAVAILABLE) ? {
                         top: '70px',
                         zIndex: this.state.chagezIndex ? 10007 : 10006
                     } : {top: '0px', zIndex: this.state.chagezIndex ? 10007 : 10006}}>
                    <div
                        className={`navbar_dekstop_view container${this.props.location.pathname.includes('/message') ? '-fluid message-container' : ''}`}>
                        <div className="d-flex bd-highlight">
                            <Link to="/" className="navbar-brand" onClick={() => {
                                this.props.dispatch(clearNewsfeedSinglePost())
                            }}>
                                <img src={LogoMain}
                                     className={this.state.scrollHeight <= 0 ? "navigation_logo-l" : "navigation_logo-s"}
                                     alt="company logo"/>
                            </Link>
                            {HIDE_SEARCH_BAR.filter(url => pathname.includes(url)).length !== 1 &&
                            <div className="form-inline my-2 my-lg-0">
                                <div className="nav_input_cont d-flex align-items-center">
                                    <div>
                                        <div className="icon_cont">
                                            <i className="fas fa-search font_20 font_white"/>
                                        </div>
                                        <SearchDropdown
                                            {...this.props}
                                            clearTopSearch={this.clearTopSearch}
                                            data={Data.COMPANY_STAGE}
                                            inputname="cStage"
                                            onchange={this.props.onSearch}
                                            profiles={this.props.search.profiles}
                                            searching={this.props.search.fetchingTopSearch}
                                            topSearch={true}
                                            action={this.props.search.action}
                                            count={this.props.search.topSearchCount}
                                            togglezIndex={this.togglezIndex}
                                            setProfileViewLocation={this._setProfileViewLocation}
                                            openLogin={() => this.props.showLoginPopup(PUBLIC_PENDING_ACTIONS.TN_REDIRECT, null)}
                                        />
                                    </div>
                                    {this.props.auth.toolTips && this.props.auth.toolTips.length > 0 && this.props.auth.toolTips[TT.PAGES.NEWS_FEED][TT.NF_SECTION.SEARCH] && this.props.auth.toolTips[TT.PAGES.NEWS_FEED][TT.NF_SECTION.SEARCH].VISIBLE &&
                                    <ToolTip
                                        tooltip={this.props.auth.toolTips[TT.PAGES.NEWS_FEED][TT.NF_SECTION.SEARCH]}/>}
                                </div>
                            </div>}
                        </div>

                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fas fa-bars"/>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <div className="navbar-collapse collapse show justify-content-end">
                                <ul className="navbar-nav d-flex align-items-center" data-toggle="collapse">
                                    {
                                        <li className={`nav-item m-l4 m-r4 ${this.state.currentPage === NAVIGATION_PAGE.HOME ? 'active' : ''}`}>
                                            {
                                                <Link to="/"
                                                      onClick={() => {
                                                          this.removeLocalStorageElements();
                                                          this.props.dispatch(clearNewsfeedSinglePost());
                                                          if (!isAuthenticated) {
                                                              //not logged action
                                                              this.props.showLoginPopup(PUBLIC_PENDING_ACTIONS.TN_REDIRECT, null)
                                                          }
                                                      }}
                                                      className="nav-link nav_link_custom ">
                                                    <div className="home-menuItem">
                                                        <div
                                                            className="menu-item d-flex flex-column align-items-center">
                                                            <i className="fas fa-home font_18 font_gray p-all3"/>
                                                            <div className="menu-item-name">Home</div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            }
                                        </li>
                                    }

                                    <li className={`nav-item m-l4 m-r4`}>
                    <span style={{'cursor': 'pointer'}}
                          onClick={() => {
                              if (isAuthenticated) {
                                  this.redirectToJBHQ()
                              } else {
                                  //not logged action
                                  window.location.href = 'https://jobs.leafwire.com/';
                              }
                          }}
                          className="nav-link nav_link_custom">
                      <div className="chat-menuItem">
                        <div className="menu-item d-flex flex-column align-items-center">
                          <i onClick={this.removeLocalStorageElements}
                             className="fas fa-briefcase font_18 font_gray p-all3"/>
                          <div className="menu-item-name">Jobs</div>
                        </div>
                      </div>
                    </span>
                                    </li>

                                    <li className={`nav-item m-l4 m-r4 ${this.state.currentPage === NAVIGATION_PAGE.MARKETPLACE_PUBLIC ? 'active' : ''}`}>
                                        {
                                            <Link
                                                to={isAuthenticated ? marketplaceRedirection(this.props.auth.user && this.props.auth.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT && this.props.auth.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT > 0) : '/'}
                                                className="nav-link nav_link_custom "
                                                onClick={() => {
                                                    if (!isAuthenticated) {
                                                        //not logged action
                                                        this.props.showLoginPopup(PUBLIC_PENDING_ACTIONS.TN_REDIRECT, '/marketplace/public')
                                                    }
                                                }}
                                            >
                                                <div className="marketplace-menuItem" onClick={() => {
                                                    this.props.auth.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT > 0 && this.props.resetTopNavNotificationCounters(NOTIFICATION_TYPES.PRODUCTS_MSG)
                                                }}>
                                                    <div className="menu-item d-flex flex-column align-items-center">
                                                        {/* <i className="fas fa-store font_18 font_gray p-all3" /> */}
                                                        <i onClick={this.removeLocalStorageElements}
                                                           className="fas fa-store font_18 font_gray p-all3">
                                                            {
                                                                this.props.auth.user && this.props.auth.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT &&
                                                                this.props.auth.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT > 0 ?
                                                                    <span
                                                                        className="chat-active notification-number">{this.props.auth.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT > 99 ? '99+' : this.props.auth.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT}</span> : ''
                                                            }
                                                        </i>
                                                        <div className="menu-item-name">Marketplace</div>
                                                    </div>
                                                </div>
                                            </Link>
                                        }
                                    </li>

                                    <li className={`nav-item m-l4 m-r4 ${this.state.currentPage === NAVIGATION_PAGE.EVENTS ? 'active' : ''}`}>
                                        {
                                            <Link
                                                to={isAuthenticated ? '/events/all-events' : '/'}
                                                className="nav-link nav_link_custom "
                                                onClick={() => {
                                                    if (!isAuthenticated) {
                                                        //not logged action
                                                        this.props.showLoginPopup(PUBLIC_PENDING_ACTIONS.TN_REDIRECT, '/events/all-events')
                                                    }
                                                }}
                                            >
                                                <div className="chat-menuItem">
                                                    <div className="menu-item d-flex flex-column align-items-center">
                                                        {/* <i className="fas fa-user font_18 font_gray p-all3" /> */}
                                                        <i onClick={this.removeLocalStorageElements}
                                                           className="fas fa-calendar-alt font_18 font_gray p-all3">
                                                        </i>
                                                        <div className="menu-item-name">Events</div>
                                                    </div>
                                                </div>
                                            </Link>
                                        }
                                    </li>

                                    <li className={`nav-item m-l4 m-r4 ${this.state.currentPage === NAVIGATION_PAGE.TRAINING ? 'active' : ''}`}>
                                        {
                                            <Link
                                                to={isAuthenticated ? '/training' : '/'}
                                                className="nav-link nav_link_custom "
                                                onClick={() => {
                                                    if (!isAuthenticated) {
                                                        //not logged action
                                                        this.props.showLoginPopup(PUBLIC_PENDING_ACTIONS.TN_REDIRECT, '/training')
                                                    }
                                                }}
                                            >
                                                <div className="chat-menuItem">
                                                    <div className="menu-item d-flex flex-column align-items-center">
                                                        {/* <i className="fas fa-user font_18 font_gray p-all3" /> */}
                                                        <i onClick={this.removeLocalStorageElements}
                                                           className="far fa-file-certificate font_18 font_gray p-all3">
                                                        </i>
                                                        <div className="menu-item-name">Training</div>
                                                    </div>
                                                </div>
                                            </Link>
                                        }
                                    </li>

                                    <li className={`nav-item m-l4 m-r4 ${this.state.currentPage === NAVIGATION_PAGE.FILTER_CONNECTIONS ? 'active' : ''}`}>
                                        {
                                            <Link
                                                to={isAuthenticated ? connectionRedirection(this.props.auth.user && this.props.auth.user.TOP_NAV_PENDING_CONNECTION_COUNT && this.props.auth.user.TOP_NAV_PENDING_CONNECTION_COUNT > 0) : '/'}
                                                className="nav-link nav_link_custom "
                                                onClick={() => {
                                                    if (!isAuthenticated) {
                                                        //not logged action
                                                        this.props.showLoginPopup(PUBLIC_PENDING_ACTIONS.TN_REDIRECT, `/filter-connections/q=${ENCODED_DEFAULT_FILTER[CONNECTIONS_SECTION.SUGGESTED]}`)
                                                    }
                                                }}
                                            >
                                                <div className="chat-menuItem">
                                                    <div className="menu-item d-flex flex-column align-items-center">
                                                        {/* <i className="fas fa-user font_18 font_gray p-all3" /> */}
                                                        <i id="navbar_link_connect"
                                                           onClick={this.removeLocalStorageElements}
                                                           className="fas fa-user-friends font_18 font_gray p-all3">
                                                            {
                                                                this.props.auth.user && this.props.auth.user.TOP_NAV_PENDING_CONNECTION_COUNT &&
                                                                this.props.auth.user.TOP_NAV_PENDING_CONNECTION_COUNT > 0 ?
                                                                    <span
                                                                        className="chat-active notification-number">{this.props.auth.user.TOP_NAV_PENDING_CONNECTION_COUNT > 99 ? '99+' : this.props.auth.user.TOP_NAV_PENDING_CONNECTION_COUNT}</span> : ''
                                                            }
                                                        </i>
                                                        <div className="menu-item-name">Connect</div>
                                                    </div>
                                                </div>
                                            </Link>
                                        }
                                    </li>

                                    <li className={`nav-item m-l4 m-r4 ${this.state.currentPage === NAVIGATION_PAGE.MESSAGES ? 'active' : ''}`}>
                    <span
                        style={{'cursor': 'pointer'}}
                        onClick={() => {
                            if (isAuthenticated) {
                                this.props.auth.user.TOP_NAV_CONVERSATION_COUNT > 0 && this.props.resetTopNavNotificationCounters(NOTIFICATION_TYPES.PERSONAL_MSG);
                                this.props.history.push(`/message/${MESSAGE_SECTION_TYPES.All.id}/${MESSAGE_SECTION_TYPES.All.id}`)
                            } else {
                                //not logged action
                                this.props.showLoginPopup(PUBLIC_PENDING_ACTIONS.TN_REDIRECT, `/message/${MESSAGE_SECTION_TYPES.All.id}/${MESSAGE_SECTION_TYPES.All.id}`)
                            }
                        }}
                        className="nav-link nav_link_custom">
                      <div onClick={this.removeLocalStorageElements} className="chat-menuItem">
                        <div className="menu-item d-flex flex-column align-items-center">
                          <i className="fas fa-comment-alt font_18 font_gray p-all3">
                            {
                                this.props.auth.user && this.props.auth.user.TOP_NAV_CONVERSATION_COUNT &&
                                this.props.auth.user.TOP_NAV_CONVERSATION_COUNT > 0 ?
                                    <span
                                        className="chat-active notification-number">{this.props.auth.user.TOP_NAV_CONVERSATION_COUNT > 99 ? '99+' : this.props.auth.user.TOP_NAV_CONVERSATION_COUNT}</span> : ''
                            }
                          </i>
                          <div className="menu-item-name">Messages</div>
                        </div>
                      </div>
                    </span>
                                    </li>

                                    <li
                                        className="nav-item dropdown"
                                        onMouseLeave={() => this.setState({actionDropDownReadyToHide: true})}
                                        onMouseEnter={() => this.setState({actionDropDownReadyToHide: false})}
                                    >
                                        <Link to="#" className="nav-link active" role="button" aria-haspopup="true"
                                              aria-expanded="false">
                                            <Tooltip message={'Me'} position={'top'}>
                        <span
                            id="navbarDropdown"
                            className="cp_img_menu"
                            onClick={() => {
                                if (isAuthenticated) {
                                    this.toggleActionDropDown(2)
                                } else {
                                    //not logged action
                                    this.props.showLoginPopup(PUBLIC_PENDING_ACTIONS.TN_REDIRECT, null)
                                }
                            }}>
                          <img src={isAuthenticated ? this.state.profileImage : ProfileImg} alt='profile'
                               className="img-fluid"/>
                        </span>
                                                {!isAuthenticated && <span className="sign-in-link"
                                                                           onClick={() => this.props.showLoginPopup(PUBLIC_PENDING_ACTIONS.TN_REDIRECT, null, true)}>Sign In</span>}
                                            </Tooltip>
                                        </Link>
                                        <div
                                            className={`dropdown-menu droupdownMenuBox profile_popover${this.state.actionDropDownVisible ? ' show' : ''}`}
                                            aria-labelledby="navbarDropdown">

                                            {
                                                this.state.LeafwireAdminLoggedIn &&
                                                <React.Fragment>
                                                    <Link to="/admin" className="dropdown-item"
                                                          onClick={() => this.toggleActionDropDown(0)}>
                                                        Admin Panel
                                                    </Link>
                                                    <div className="dropdown-divider"/>
                                                </React.Fragment>
                                            }

                                            <Link to={'/user-profile-edit/' + localStorage.getItem('userId')}
                                                  onClick={() => {
                                                      this._setEventTrackingPayload(TOP_NAV.EDIT_PROF);
                                                      this.setState({actionDropDownVisible: false})
                                                  }} className="dropdown-item nav-link nav_link_custom">
                                                Edit Profile
                                            </Link>

                                            <div className="dropdown-divider"/>
                                            <Link onClick={() => {
                                                this._setEventTrackingPayload(TOP_NAV.MP);
                                                this.setState({actionDropDownVisible: false})
                                            }} to="/marketplace/public"
                                                  className="dropdown-item nav-link nav_link_custom">
                                                Marketplace
                                            </Link>
                                            <span
                                                onClick={() => {
                                                    if (marketplaceDefaultAdd.length === 0) {
                                                        if (this.props.openCreateCompanyModel) {
                                                            this.props.openCreateCompanyModel()
                                                        }
                                                    } else if (marketplaceDefaultAdd.length === 1) {
                                                        this.props.history.push(`/marketplace/product-create/${marketplaceDefaultAdd[0]}`);
                                                    } else {
                                                        this.props.openSelectCompanyModel(COMPANY_LIST_POPUP.ADD_LISTING, this.state.pageName, 'ME_SECTION');
                                                    }
                                                    this._setEventTrackingPayload(TOP_NAV.NEW_LIST);
                                                    this.setState({actionDropDownVisible: false})
                                                }}
                                                className="dropdown-item createListingLink"
                                                style={{color: '#007bff', cursor: 'pointer', padding: '0 1.5rem'}}>
                        <i className="fas fa-plus-circle font_14 p-all3" style={{color: 'rgb(0, 123, 255)'}}/>
                        Create a new listing
                      </span>
                                            {marketplaceDefaultEdit.length > 0 && (
                                                <React.Fragment>
                          <span
                              onClick={() => {
                                  if (marketplaceDefaultEdit.length === 1) {
                                      this.props.history.push(`/marketplace/company/${marketplaceDefaultEdit[0]}`);
                                  } else {
                                      this.props.openSelectCompanyModel(COMPANY_LIST_POPUP.EDIT_LISTING, this.state.pageName, 'ME_SECTION');
                                  }
                                  this._setEventTrackingPayload(TOP_NAV.EDIT_LIST);
                                  this.setState({actionDropDownVisible: false});
                              }}
                              className="dropdown-item createListingLink"
                              style={{color: '#007bff', cursor: 'pointer', padding: '0 1.5rem'}}>
                            <i className="fas fa-edit font_14 font_gray p-all3" style={{color: 'rgb(0, 123, 255)'}}/>
                            Edit an existing listing
                          </span>
                                                    {this.props.auth && this.props.auth.showReportingDashboard && this.props.auth.showReportingDashboard.length > 0 &&
                                                    <span
                                                        onClick={() => {
                                                            this.props.history.push(`/marketplace/reportingDashboard`);
                                                            this._setEventTrackingPayload(TOP_NAV.REPORTING);
                                                            this.setState({actionDropDownVisible: false});
                                                        }}
                                                        className="dropdown-item createListingLink" style={{
                                                        color: '#007bff',
                                                        cursor: 'pointer',
                                                        padding: '0 1.5rem'
                                                    }}>
                            <i className="fas fa-file-alt font_14 font_gray p-all3"
                               style={{color: 'rgb(0, 123, 255)', marginRight: '3px'}}/>
                            Reporting Dashboard
                          </span>}
                                                </React.Fragment>)
                                            }
                                            <div className="dropdown-divider"/>
                                            <Link
                                                to=""
                                                className="dropdown-item nav-link nav_link_custom"
                                                onClick={() => {
                                                    this.setState({actionDropDownVisible: false});
                                                    this.redirectToJBHQ();
                                                }}
                                            >
                                                Job Board
                                            </Link>
                                            <span
                                                className="dropdown-item createListingLink"
                                                style={{color: '#007bff', cursor: 'pointer', padding: '0 1.5rem'}}
                                                onClick={() => {
                                                    this._setEventTrackingPayload(TOP_NAV.POST_JOB);
                                                    let companyList = (this.props.auth && this.props.auth.myCompanies) ? this.props.auth.myCompanies : [];
                                                    postAJob(companyList, this.props.history.push, this.props.openCreateCompanyModel, this.props.openSelectCompanyModel);
                                                }}
                                            >
                        <i className="fas fa-plus-circle font_14 p-all3" style={{color: 'rgb(0, 123, 255)'}}/>
                        Post a Job
                      </span>
                                            {
                                                this.props.auth && this.props.auth.user && this.props.auth.user.IS_SUBSCRIBED_TO_ANY_MODULES && (
                                                    <div>
                                                        <div className="dropdown-divider"/>
                                                        <Link to="/manage-subscriptions" onClick={() => {
                                                            this._setEventTrackingPayload(TOP_NAV.MANAGE_SUB);
                                                            this.setState({actionDropDownVisible: false})
                                                        }}
                                                              className="dropdown-item nav-link nav_link_custom close-on-click">
                                                            Manage Subscriptions
                                                        </Link>
                                                        <div className="dropdown-divider"/>
                                                    </div>
                                                )
                                            }
                                            <div className="dropdown-divider"/>
                                            <Link onClick={() => {
                                                this._setEventTrackingPayload(TOP_NAV.CONNECTIONS);
                                                this.setState({actionDropDownVisible: false})
                                            }}
                                                  to={`/filter-connections/q=${ENCODED_DEFAULT_FILTER[CONNECTIONS_SECTION.SUGGESTED]}`}
                                                  className="dropdown-item nav-link nav_link_custom">
                                                Connections
                                            </Link>
                                            {/* <Link onClick={() => this.setState({ actionDropDownVisible: false })} to="" className="dropdown-item nav-link nav_link_custom">
                        Calendar
                      </Link> */}
                                            {this.props.auth.user && this.props.auth.user.MEMBER_TYPE === MEMBER_TYPE.INVESTOR &&
                                            <Link onClick={() => {
                                                this._setEventTrackingPayload(TOP_NAV.FIND_COMPANY);
                                                this.setState({actionDropDownVisible: false})
                                            }}
                                                  to={`/advanced-search/fundraisingCompanies/q=${ADVANCED_SEARCH_DEFAULT_ENCODED.FUND_RAISING_COMPANY_PROFILES}`}
                                                  className="dropdown-item nav-link nav_link_custom">
                                                Find Fundraising Companies
                                            </Link>}
                                            {/* <Link onClick={() => this.setState({ actionDropDownVisible: false })} to="" className="dropdown-item nav-link nav_link_custom">
                        Blogs/Videos
                      </Link> */}

                                            <div className="dropdown-divider"/>

                                            {
                                                this.props.auth.myCompanies.length > 0 &&
                                                <p className="dropdown-title">My Companies</p>
                                            }

                                            <AdminCompanyList
                                                switchCompany={this.switchCompany}
                                                companies={this.props.auth.myCompanies}
                                                toggleActionDropDown={this.toggleActionDropDown}
                                            />

                                            <Link id="createNewCompany" onClick={() => {
                                                this._setEventTrackingPayload(TOP_NAV.CREATE_COMPANY);
                                                this.toggleActionDropDown(0)
                                            }} to="/create-company" className="dropdown-item"><i
                                                className="fas fa-plus-circle font_18 font_gray p-all3"
                                                style={{color: 'rgb(0, 123, 255)'}}/> Create New Company</Link>


                                            <AccountSettings {...this.props}
                                                             userHasAuthenticated={this.props.userHasAuthenticated}
                                                             page={this.state.pageName}/>

                                            <div className="dropdown-divider"/>

                                            <button className="dropdown-item nav-link nav_link_custom" onClick={() => {
                                                this._logOut();
                                                this.toggleActionDropDown(2)
                                            }}>
                                                <i className="fas fa-sign-out-alt font_18 font_gray p-all3"/> Log Out
                                            </button>

                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* Mobile view */}

                    <div className="navbar_mobile_view container" id="mobile-search-scroll-div">
                        <div className="d-flex bd-highlight">
                            <Link
                                to="/"
                                className="navbar-brand"
                                onClick={() => {
                                    this.props.dispatch(clearNewsfeedSinglePost());
                                    if (!isAuthenticated) {
                                        //not logged action
                                        this.props.showLoginPopup(PUBLIC_PENDING_ACTIONS.TN_REDIRECT, null)
                                    } else {
                                        if (window.location.pathname === '/') {
                                            let feed = document.getElementById('newsfeeds');
                                            if (feed) {
                                                feed.scrollIntoView({behavior: "smooth"});
                                                this.props.forceNewsfeedRefresh()
                                            }
                                        }
                                    }
                                }}
                            >
                                <img src={LogoMain}
                                     className={this.state.scrollHeight <= 0 ? "navigation_logo-l" : "navigation_logo-s"}
                                     alt="company logo"/>
                            </Link>
                        </div>

                        {isAuthenticated && <button
                            className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarSupportedContentMobile"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <i className="fas fa-bars"/>
                        </button>}

                        {!isAuthenticated && <button
                            onClick={() => {
                                //not logged action
                                this.props.showLoginPopup(PUBLIC_PENDING_ACTIONS.TN_REDIRECT, null)
                            }}
                            className="navbar-toggler"
                            type="button"
                        >
                            <i className="fas fa-bars"/>
                        </button>}

                        <div className="collapse navbar-collapse" id="navbarSupportedContentMobile">
                            <div className="navbar-collapse collapse show">
                                <ul className="navbar-nav ml-auto">

                                    {
                                        <li className={`nav-item m-l5 m-r5 ${this.state.currentPage === NAVIGATION_PAGE.HOME ? 'active' : ''}`}
                                            data-toggle="collapse" data-target="#navbarSupportedContentMobile">
                                            <Link
                                                to="/"
                                                onClick={() => {
                                                    this.props.dispatch(clearNewsfeedSinglePost());
                                                    this.removeLocalStorageElements();
                                                }} className="nav-link nav_link_custom ">
                                                <div className="chat-menuItem">
                                                    <i
                                                        className="fas fa-home font_18 font_gray m-r5" />
                                                    <span className="menu-item-name-mobile">Home</span>
                                                </div>
                                            </Link>
                                        </li>
                                    }
                                    <li className={`nav-item m-l5 m-r5 ${this.state.currentPage === NAVIGATION_PAGE.PERSONAL_PROFILE ? 'active' : ''}`}
                                        data-toggle="collapse" data-target="#navbarSupportedContentMobile">
                                        <Link to={'/user-profile-edit/' + localStorage.getItem('userId')}
                                              onClick={() => {
                                                  this._setEventTrackingPayload(TOP_NAV.EDIT_PROF);
                                                  this.setState({actionDropDownVisible: false})
                                              }} className="nav-link nav_link_custom ">
                                            <div className="chat-menuItem">
                                                <i
                                                    className="fas fa-user-circle font_18 font_gray m-r5" />
                                                <span className="menu-item-name-mobile">View My Profile</span>
                                            </div>
                                        </Link>
                                    </li>

                                    <li
                                        className={`nav-item m-l5 m-r5`}
                                        data-toggle="collapse"
                                        data-target="#navbarSupportedContentMobile"
                                    >
                                        <span
                                            style={{'cursor': 'pointer'}}
                                            onClick={this.redirectToJBHQ}
                                            className="nav-link nav_link_custom "
                                        >
                                            <div className="chat-menuItem">
                                                <i className="fas fa-briefcase font_18 font_gray m-r5"/>
                                            </div>
                                            <span className="menu-item-name-mobile">Jobs</span>
                                        </span>
                                    </li>

                                    {
                                        isMyMarketPlace === HEADER.DEFAULT && this.props.auth &&
                                        this.props.auth.user && this.props.auth.user.MEMBER_TYPE === MEMBER_TYPE.INVESTOR &&
                                        <li className={`nav-item m-l5 m-r5 ${this.state.currentPage === NAVIGATION_PAGE.FUNDRAISING_COMPANIES ? 'active' : ''}`}
                                            data-toggle="collapse" data-target="#navbarSupportedContentMobile">
                                            <Link
                                                to={`/advanced-search/fundraisingCompanies/q=${ADVANCED_SEARCH_DEFAULT_ENCODED.FUND_RAISING_COMPANY_PROFILES}`}
                                                className="nav-link nav_link_custom ">
                                                <div className="chat-menuItem">
                                                    <i className="fas fa-building font_18 font_gray m-r5"/></div>
                                                <span className="menu-item-name-mobile">Fundraising Companies</span>
                                            </Link>
                                        </li>
                                    }

                                    <li className={`nav-item m-l5 m-r5 ${this.state.currentPage === NAVIGATION_PAGE.MARKETPLACE_PUBLIC ? 'active' : ''}`}
                                        data-toggle="collapse" data-target="#navbarSupportedContentMobile">
                                        {
                                            <Link
                                                to={marketplaceRedirection(this.props.auth.user && this.props.auth.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT && this.props.auth.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT > 0)}
                                                className="nav-link nav_link_custom ">
                                                <div className="chat-menuItem" onClick={() => {
                                                    this.props.auth.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT > 0 && this.props.resetTopNavNotificationCounters(NOTIFICATION_TYPES.PRODUCTS_MSG)
                                                }}>
                                                    <i className="fas fa-store font_18 font_gray m-r5"/>
                                                    <span className="menu-item-name-mobile">Marketplace</span>
                                                    {
                                                        this.props.auth.user && this.props.auth.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT &&
                                                        this.props.auth.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT > 0 ?
                                                            <span
                                                                className="chat-active notification-number">{this.props.auth.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT > 99 ? '99+' : this.props.auth.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT}</span> : ''
                                                    }
                                                </div>
                                            </Link>
                                        }
                                    </li>

                                    <li className={`nav-item m-l5 m-r5 ${this.state.currentPage === NAVIGATION_PAGE.FILTER_CONNECTIONS ? 'active' : ''}`}
                                        data-toggle="collapse" data-target="#navbarSupportedContentMobile">
                                        {
                                            <Link
                                                to={connectionRedirection(this.props.auth.user && this.props.auth.user.TOP_NAV_PENDING_CONNECTION_COUNT && this.props.auth.user.TOP_NAV_PENDING_CONNECTION_COUNT > 0)}
                                                className="nav-link nav_link_custom position-relative">
                                                <div className="chat-menuItem">
                                                    <i className="fas fa-user-friends font_18 font_gray m-r5"/>
                                                    <span className="menu-item-name-mobile">Connect</span>
                                                    {
                                                        this.props.auth.user && this.props.auth.user.TOP_NAV_PENDING_CONNECTION_COUNT &&
                                                        this.props.auth.user.TOP_NAV_PENDING_CONNECTION_COUNT > 0 ?
                                                            <span
                                                                className="chat-active notification-number">{this.props.auth.user.TOP_NAV_PENDING_CONNECTION_COUNT > 99 ? '99+' : this.props.auth.user.TOP_NAV_PENDING_CONNECTION_COUNT}</span> : ''
                                                    }
                                                </div>
                                            </Link>
                                        }
                                    </li>

                                    <li className={`nav-item m-l5 m-r5  position-relative ${this.state.currentPage === NAVIGATION_PAGE.MESSAGES ? 'active' : ''}`}
                                        data-toggle="collapse" data-target="#navbarSupportedContentMobile">
                    <span style={{'cursor': 'pointer'}}
                          onClick={() => this.props.history.push(`/message/${MESSAGE_SECTION_TYPES.All.id}/${MESSAGE_SECTION_TYPES.All.id}`)}
                          className="nav-link nav_link_custom">
                      <div className="chat-menuItem" onClick={() => {
                          this.props.auth.user.TOP_NAV_CONVERSATION_COUNT > 0 && this.props.resetTopNavNotificationCounters(NOTIFICATION_TYPES.PERSONAL_MSG)
                      }}>
                        <i className="fas fa-comment-alt font_18 font_gray m-r5"/>
                        <span className="menu-item-name-mobile">Messages</span>
                          {
                              this.props.auth.user && this.props.auth.user.TOP_NAV_CONVERSATION_COUNT &&
                              this.props.auth.user.TOP_NAV_CONVERSATION_COUNT > 0 ?
                                  <span
                                      className="chat-active notification-number">{this.props.auth.user.TOP_NAV_CONVERSATION_COUNT > 99 ? '99+' : this.props.auth.user.TOP_NAV_CONVERSATION_COUNT}</span> : ''
                          }
                      </div>
                    </span>
                                    </li>
                                    {
                                        <li className={`nav-item m-l5 m-r5 ${this.state.currentPage === NAVIGATION_PAGE.EVENTS ? 'active' : ''}`}
                                            data-toggle="collapse" data-target="#navbarSupportedContentMobile">
                                            <Link to="/events/all-events" onClick={this.removeLocalStorageElements}
                                                  className="nav-link nav_link_custom ">
                                                <div className="chat-menuItem">
                                                    <i
                                                        className="fas fa-calendar-alt font_18 font_gray m-r5" />
                                                    <span className="menu-item-name-mobile">Events</span>
                                                </div>
                                            </Link>
                                        </li>
                                    }

                                    {
                                        <li className={`nav-item m-l5 m-r5 ${this.state.currentPage === NAVIGATION_PAGE.TRAINING ? 'active' : ''}`}
                                            data-toggle="collapse" data-target="#navbarSupportedContentMobile">
                                            <Link to="/training" onClick={this.removeLocalStorageElements}
                                                  className="nav-link nav_link_custom ">
                                                <div className="chat-menuItem">
                                                    <i
                                                        className="far fa-file-certificate font_18 font_gray m-r5" />
                                                    <span className="menu-item-name-mobile">Training</span>
                                                </div>
                                            </Link>
                                        </li>
                                    }

                                    {(this.props && this.props.groupCount > 0) &&
                                    <li className={`nav-item m-l5 m-r5 ${this.state.currentPage === NAVIGATION_PAGE.GROUPS ? 'active' : ''}`}
                                        data-toggle="collapse" data-target="#navbarSupportedContentMobile">
                                        <div onClick={() => {
                                            this.toggleMobileGroupPopup(true)
                                        }} className="nav-link nav_link_custom ">
                                            <div className="chat-menuItem">
                                                <i
                                                    className="fas fa-users font_18 font_gray m-r5" />
                                                <span className="menu-item-name-mobile">Groups</span>
                                            </div>
                                        </div>
                                    </li>
                                    }
                                    {
                                        /* <li className="nav-item m-l5 m-r5">
                                          <Link to="" className="nav-link nav_link_custom" onClick={this._logOut.bind(this)}><i className="fas fa-sign-out-alt font_18 font_gray p-all3" />Log Out</Link>
                                        </li> */
                                    }

                                    <li className={`nav-item dropdown ${this.state.actionDropDownVisible ? ' show' : ''}`}>
                                        <Link to="?" id="me" className="nav-link active nav_link_custom"
                                              onClick={() => {
                                                  this.toggleActionDropDown(2, true);
                                              }}>{/*id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"*/}
                                            <span className="cp_img_menu"><img
                                                onError={this.handleImageError.bind(this)}
                                                src={this.props.auth.profileThumbnailUrl || this.state.profileImage}
                                                alt='profile' className="img-fluid"/></span>
                                            Me <i className="fas fa-angle-down font_14 font_white p-all3"/>
                                        </Link>
                                        <div
                                            className={`dropdown-menu droupdownMenuBox ${this.state.actionDropDownVisible ? ' show' : ''}`}
                                            aria-labelledby="navbarDropdown" id="mobile-me">
                                            {
                                                this.state.LeafwireAdminLoggedIn &&
                                                <React.Fragment>
                                                    <Link to="/admin" className="dropdown-item"
                                                          onClick={() => this.toggleActionDropDown(0)}>
                                                        Admin Panel
                                                    </Link>
                                                    <div className="dropdown-divider"/>
                                                </React.Fragment>
                                            }

                                            <Link to={'/user-profile-edit/' + localStorage.getItem('userId')}
                                                  onClick={() => {
                                                      this._setEventTrackingPayload(TOP_NAV.EDIT_PROF);
                                                      this.setState({actionDropDownVisible: false})
                                                  }} className="dropdown-item nav-link nav_link_custom close-on-click">
                                                Edit Profile
                                            </Link>
                                            <div className="dropdown-divider"/>

                                            <Link onClick={() => {
                                                this._setEventTrackingPayload(TOP_NAV.MP);
                                                this.setState({actionDropDownVisible: false})
                                            }} to="/marketplace/public"
                                                  className="dropdown-item nav-link nav_link_custom close-on-click">
                                                Marketplace
                                            </Link>
                                            <span
                                                onClick={() => {
                                                    if (marketplaceDefaultAdd.length === 0) {
                                                        if (this.props.openCreateCompanyModel) {
                                                            this.props.openCreateCompanyModel()
                                                        }
                                                    } else if (marketplaceDefaultAdd.length === 1) {
                                                        this.props.history.push(`/marketplace/product-create/${marketplaceDefaultEdit[0]}`);
                                                    } else {
                                                        this.props.openSelectCompanyModel(COMPANY_LIST_POPUP.ADD_LISTING, this.state.pageName, 'ME_SECTION');
                                                    }
                                                    this._setEventTrackingPayload(TOP_NAV.NEW_LIST);
                                                    this.setState({actionDropDownVisible: false})
                                                }}
                                                className="dropdown-item nav-link nav_link_custom"
                                                style={{color: '#007bff'}}>
                        <i className="fas fa-plus-circle font_18 font_gray p-all3"/>
                        Create a new listing
                      </span>
                                            {marketplaceDefaultEdit.length > 0 &&
                                            <React.Fragment>
                          <span
                              onClick={() => {
                                  if (marketplaceDefaultEdit.length === 1) {
                                      this.props.history.push(`/marketplace/company/${marketplaceDefaultEdit[0]}`);
                                  } else {
                                      this.props.openSelectCompanyModel(COMPANY_LIST_POPUP.EDIT_LISTING, this.state.pageName, 'ME_SECTION');
                                  }
                                  this._setEventTrackingPayload(TOP_NAV.EDIT_LIST);
                                  this.setState({actionDropDownVisible: false})
                              }}
                              className="dropdown-item nav-link nav_link_custom" style={{color: '#007bff'}}>
                            <i className="fas fa-edit font_18 font_gray p-all3"/>
                            Edit an existing listing
                          </span>
                                                {this.props.auth && this.props.auth.showReportingDashboard && this.props.auth.showReportingDashboard.length > 0 &&
                                                <span
                                                    onClick={() => {
                                                        this.props.history.push(`/marketplace/reportingDashboard`);
                                                        this.setState({actionDropDownVisible: false});
                                                        this._setEventTrackingPayload(TOP_NAV.REPORTING);
                                                    }}
                                                    className="dropdown-item createListingLink"
                                                    style={{color: '#007bff', cursor: 'pointer', padding: '0 1.5rem'}}>
                          <i className="fas fa-file-alt font_14 font_gray p-all3"
                             style={{color: 'rgb(0, 123, 255)', marginRight: '3px'}}/>
                          Reporting Dashboard
                          </span>}
                                            </React.Fragment>}
                                            <div className="dropdown-divider"/>
                                            <Link
                                                onClick={() => {
                                                    this.setState({actionDropDownVisible: false})
                                                    this.redirectToJBHQ();
                                                }}
                                                to=""
                                                className="dropdown-item nav-link nav_link_custom"
                                            >
                                                Job Board
                                            </Link>
                                            <span
                                                onClick={() => {
                                                    this._setEventTrackingPayload(TOP_NAV.POST_JOB);
                                                    this.setState({actionDropDownVisible: false});
                                                    let companyList = (this.props.auth && this.props.auth.myCompanies) ? this.props.auth.myCompanies : [];
                                                    postAJob(companyList, this.props.history.push, this.props.openCreateCompanyModel, this.props.openSelectCompanyModel);

                                                }}
                                                className="dropdown-item nav-link nav_link_custom"
                                                style={{color: '#007bff'}}
                                            >
                        <i className="fas fa-plus-circle font_14 p-all3" style={{color: 'rgb(0, 123, 255)'}}/>
                        Post a Job
                      </span>
                                            {
                                                this.props.auth && this.props.auth.user && this.props.auth.user.IS_SUBSCRIBED_TO_ANY_MODULES && (
                                                    <div>
                                                        <div className="dropdown-divider"/>
                                                        <Link onClick={() => {
                                                            this._setEventTrackingPayload(TOP_NAV.MANAGE_SUB);
                                                            this.setState({actionDropDownVisible: false})
                                                        }} to="/manage-subscriptions"
                                                              className="dropdown-item nav-link nav_link_custom close-on-click">
                                                            Manage Subscriptions
                                                        </Link>
                                                        <div className="dropdown-divider"/>
                                                    </div>
                                                )
                                            }
                                            <Link onClick={() => {
                                                this._setEventTrackingPayload(TOP_NAV.CONNECTIONS);
                                                this.setState({actionDropDownVisible: false})
                                            }}
                                                  to={`/filter-connections/q=${ENCODED_DEFAULT_FILTER[CONNECTIONS_SECTION.SUGGESTED]}`}
                                                  className="dropdown-item nav-link nav_link_custom close-on-click">
                                                Connections
                                            </Link>
                                            {/* <Link onClick={() => this.setState({ actionDropDownVisible: false })} to="" className="dropdown-item nav-link nav_link_custom">
                        Calendar
                      </Link> */}
                                            {this.props.auth.user && this.props.auth.user.MEMBER_TYPE === MEMBER_TYPE.INVESTOR &&
                                            <Link onClick={() => {
                                                this._setEventTrackingPayload(TOP_NAV.FIND_COMPANY);
                                                this.setState({actionDropDownVisible: false})
                                            }}
                                                  to={`/advanced-search/fundraisingCompanies/q=${ADVANCED_SEARCH_DEFAULT_ENCODED.FUND_RAISING_COMPANY_PROFILES}`}
                                                  className="dropdown-item nav-link nav_link_custom close-on-click">
                                                Find Fundraising Companies
                                            </Link>}
                                            {/* <Link onClick={() => this.setState({ actionDropDownVisible: false })} to="" className="dropdown-item nav-link nav_link_custom">
                        Blogs/Videos
                      </Link> */}

                                            <div className="dropdown-divider"/>

                                            {
                                                this.props.auth.myCompanies.length > 0 &&
                                                <p className="dropdown-title">My Companies</p>
                                            }

                                            <AdminCompanyList
                                                switchCompany={this.switchCompany}
                                                companies={this.props.auth.myCompanies}
                                                toggleActionDropDown={this.toggleActionDropDown}
                                            />

                                            <Link id="createNewCompany" onClick={() => {
                                                this._setEventTrackingPayload(TOP_NAV.CREATE_COMPANY);
                                                this.toggleActionDropDown(0)
                                            }} to="/create-company" className="dropdown-item" data-toggle="collapse"
                                                  data-target="#navbarSupportedContentMobile"><i
                                                className="fas fa-plus-circle font_14  p-all3"/> Create New
                                                Company</Link>

                                            <AccountSettings {...this.props}
                                                             userHasAuthenticated={this.props.userHasAuthenticated}
                                                             page={this.state.pageName}/>
                                        </div>
                                    </li>
                                    <button
                                        className="dropdown-item nav-link nav_link_custom"
                                        data-toggle="collapse"
                                        data-target="#navbarSupportedContentMobile"
                                        aria-controls="navbarSupportedContent"
                                        onClick={() => {
                                            this._logOut();

                                        }}>
                                        <i className="fas fa-sign-out-alt font_18 font_gray p-all3"/> Log Out
                                    </button>
                                    <li id="hideNavBar" className="nav-item dropdown show" data-toggle="collapse"
                                        data-target="#navbarSupportedContentMobile"/>
                                    <div className="form-inline my-2 my-lg-0">
                                        <div className="nav_input_cont" id="search">
                                            <div className="icon_cont">
                                                <i className="fas fa-search font_20 font_white"/>
                                            </div>
                                            <SearchDropdown
                                                {...this.props}
                                                data={Data.COMPANY_STAGE}
                                                inputname="cStage"
                                                onchange={this.props.onSearch}
                                                profiles={this.props.search.profiles}
                                                searching={this.props.search.fetchingTopSearch}
                                                topSearch={true}
                                                action={this.props.search.action}
                                                count={this.props.search.topSearchCount}
                                                hideNavbar={hideNavBar}
                                                clearTopSearch={this.clearTopSearch}
                                                togglezIndex={this.togglezIndex}
                                                setProfileViewLocation={this._setProfileViewLocation}
                                                openLogin={() => this.props.showLoginPopup(PUBLIC_PENDING_ACTIONS.TN_REDIRECT, null)}
                                                onFocus={() => {
                                                    let elementToScroll = document.getElementById("mobile-search-scroll-div");
                                                    if (elementToScroll) {
                                                        setTimeout(() => {
                                                            elementToScroll.scrollTop = elementToScroll.scrollHeight;
                                                        }, 500)
                                                    }
                                                }}
                                            />
                                            {this.props.auth.toolTips && this.props.auth.toolTips.length > 0 && this.props.auth.toolTips[TT.PAGES.NEWS_FEED][TT.NF_SECTION.SEARCH] && this.props.auth.toolTips[TT.PAGES.NEWS_FEED][TT.NF_SECTION.SEARCH].VISIBLE &&
                                            <ToolTip
                                                tooltip={this.props.auth.toolTips[TT.PAGES.NEWS_FEED][TT.NF_SECTION.SEARCH]}/>}
                                        </div>
                                    </div>
                                </ul>

                            </div>
                        </div>
                    </div>

                    {this.state.showMobileGroupPopup && <MobileGroupPopup modelClose={() => {
                        this.toggleMobileGroupPopup(false)
                    }} joinGroup={this.props.joinGroup}/>}
                    {showMobileNav && <div className="fixed-footer-nav">
                        <ul className="navbar-nav d-flex align-items-center" data-toggle="collapse">
                            {
                                <li className={`nav-item m-l4 m-r4 ${this.state.currentPage === NAVIGATION_PAGE.HOME ? 'active' : ''}`}>
                                    {
                                        <Link
                                            to="/"
                                            onClick={() => {
                                                this.props.dispatch(clearNewsfeedSinglePost())
                                                if (isAuthenticated) {
                                                    this.removeLocalStorageElements();
                                                    if (window.location.pathname === '/') {
                                                        let feed = document.getElementById('newsfeeds');
                                                        if (feed) {
                                                            feed.scrollIntoView({behavior: "smooth"});
                                                            this.props.forceNewsfeedRefresh()
                                                        }
                                                    }
                                                } else {
                                                    //not logged action
                                                    this.props.showLoginPopup(PUBLIC_PENDING_ACTIONS.TN_REDIRECT, null)
                                                }
                                            }}
                                            className="nav-link nav_link_custom "
                                        >
                                            <div className="home-menuItem">
                                                <div className="menu-item d-flex flex-column align-items-center">
                                                    <i className="fas fa-home font_18 font_gray p-all3"/>
                                                    <div className="menu-item-name-mobile">Home</div>
                                                </div>
                                            </div>
                                        </Link>
                                    }
                                </li>
                            }

                            <li className={`nav-item m-l4 m-r4`}>
                <span
                    style={{'cursor': 'pointer'}}
                    onClick={() => {
                        if (isAuthenticated) {
                            this.redirectToJBHQ()
                        } else {
                            //not logged action
                            window.location.href = 'https://jobs.leafwire.com/';
                        }
                    }}
                    className="nav-link nav_link_custom"
                >
                  <div className="chat-menuItem">
                    <div className="menu-item d-flex flex-column align-items-center">
                      <i onClick={this.removeLocalStorageElements}
                         className="fas fa-briefcase font_18 font_gray p-all3"/>
                      <div className="menu-item-name-mobile">Jobs</div>
                    </div>
                  </div>
                </span>
                            </li>

                            <li className={`nav-item m-l4 m-r4 ${this.state.currentPage === NAVIGATION_PAGE.MARKETPLACE_PUBLIC ? 'active' : ''}`}>
                                {
                                    <Link
                                        to={isAuthenticated ? marketplaceRedirection(this.props.auth.user && this.props.auth.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT && this.props.auth.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT > 0) : '/'}
                                        className="nav-link nav_link_custom "
                                        onClick={() => {
                                            if (!isAuthenticated) {
                                                //not logged action
                                                this.props.showLoginPopup(PUBLIC_PENDING_ACTIONS.TN_REDIRECT, '/marketplace/public')
                                            }
                                        }}
                                    >
                                        <div className="marketplace-menuItem" onClick={() => {
                                            this.props.auth.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT > 0 && this.props.resetTopNavNotificationCounters(NOTIFICATION_TYPES.PRODUCTS_MSG)
                                        }}>
                                            <div className="menu-item d-flex flex-column align-items-center">
                                                {/* <i className="fas fa-store font_18 font_gray p-all3" /> */}
                                                <i onClick={this.removeLocalStorageElements}
                                                   className="fas fa-store font_18 font_gray p-all3">
                                                    {
                                                        this.props.auth.user && this.props.auth.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT &&
                                                        this.props.auth.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT > 0 ?
                                                            <span
                                                                className="chat-active notification-number">{this.props.auth.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT > 99 ? '99+' : this.props.auth.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT}</span> : ''
                                                    }
                                                </i>
                                                <div className="menu-item-name-mobile">Marketplace</div>
                                            </div>
                                        </div>
                                    </Link>
                                }
                            </li>

                            <li className={`nav-item m-l4 m-r4 ${this.state.currentPage === NAVIGATION_PAGE.FILTER_CONNECTIONS ? 'active' : ''}`}>
                                {
                                    <Link
                                        to={isAuthenticated ? connectionRedirection(this.props.auth.user && this.props.auth.user.TOP_NAV_PENDING_CONNECTION_COUNT && this.props.auth.user.TOP_NAV_PENDING_CONNECTION_COUNT > 0) : '/'}
                                        className="nav-link nav_link_custom "
                                        onClick={() => {
                                            if (!isAuthenticated) {
                                                //not logged action
                                                this.props.showLoginPopup(PUBLIC_PENDING_ACTIONS.TN_REDIRECT, `/filter-connections/q=${ENCODED_DEFAULT_FILTER[CONNECTIONS_SECTION.SUGGESTED]}`)
                                            }
                                        }}
                                    >
                                        <div className="chat-menuItem">
                                            <div className="menu-item d-flex flex-column align-items-center">
                                                {/* <i className="fas fa-user font_18 font_gray p-all3" /> */}
                                                <i onClick={this.removeLocalStorageElements}
                                                   className="fas fa-user-friends font_18 font_gray p-all3">
                                                    {
                                                        this.props.auth.user && this.props.auth.user.TOP_NAV_PENDING_CONNECTION_COUNT
                                                        && this.props.auth.user.TOP_NAV_PENDING_CONNECTION_COUNT > 0 ?
                                                            <span
                                                                className="chat-active notification-number">{this.props.auth.user.TOP_NAV_PENDING_CONNECTION_COUNT > 99 ? '99+' : this.props.auth.user.TOP_NAV_PENDING_CONNECTION_COUNT}</span> : ''
                                                    }
                                                </i>
                                                <div className="menu-item-name-mobile">Connect</div>
                                            </div>
                                        </div>
                                    </Link>
                                }
                            </li>

                            <li className={`nav-item m-l4 m-r4 ${this.state.currentPage === NAVIGATION_PAGE.MESSAGES ? 'active' : ''}`}>
                <span
                    style={{'cursor': 'pointer'}}
                    onClick={() => {
                        if (isAuthenticated) {
                            this.props.auth.user.TOP_NAV_CONVERSATION_COUNT > 0 && this.props.resetTopNavNotificationCounters(NOTIFICATION_TYPES.PERSONAL_MSG);
                            this.props.history.push(`/message/${MESSAGE_SECTION_TYPES.All.id}/${MESSAGE_SECTION_TYPES.All.id}`)
                        } else {
                            //not logged action
                            this.props.showLoginPopup(PUBLIC_PENDING_ACTIONS.TN_REDIRECT, `/message/${MESSAGE_SECTION_TYPES.All.id}/${MESSAGE_SECTION_TYPES.All.id}`)
                        }
                    }}
                    className="nav-link nav_link_custom">
                  <div onClick={this.removeLocalStorageElements} className="chat-menuItem">
                    <div className="menu-item d-flex flex-column align-items-center">
                      <i className="fas fa-comment-alt font_18 font_gray p-all3">
                        {
                            this.props.auth.user && this.props.auth.user.TOP_NAV_CONVERSATION_COUNT &&
                            this.props.auth.user.TOP_NAV_CONVERSATION_COUNT > 0 ?
                                <span
                                    className="chat-active notification-number">{this.props.auth.user.TOP_NAV_CONVERSATION_COUNT > 99 ? '99+' : this.props.auth.user.TOP_NAV_CONVERSATION_COUNT}</span> : ''
                        }
                      </i>
                      <div className="menu-item-name-mobile">Messages</div>
                    </div>
                  </div>
                </span>
                            </li>

                            <li className={`nav-item m-l4 m-r4 ${this.state.currentPage === NAVIGATION_PAGE.EVENTS ? 'active' : ''}`}>
                                {
                                    <Link
                                        to={isAuthenticated ? '/events/all-events' : '/'}
                                        className="nav-link nav_link_custom "
                                        onClick={() => {
                                            if (!isAuthenticated) {
                                                //not logged action
                                                this.props.showLoginPopup(PUBLIC_PENDING_ACTIONS.TN_REDIRECT, '/events/all-events')
                                            }
                                        }}
                                    >
                                        <div className="chat-menuItem">
                                            <div className="menu-item d-flex flex-column align-items-center">
                                                {/* <i className="fas fa-user font_18 font_gray p-all3" /> */}
                                                <i onClick={this.removeLocalStorageElements}
                                                   className="fas fa-calendar-alt font_18 font_gray p-all3"/>
                                                <div className="menu-item-name-mobile">Events</div>
                                            </div>
                                        </div>
                                    </Link>
                                }
                            </li>
                        </ul>
                    </div>}
                </nav>
            </div>
        )
    }
}

export default IsAuth;
