module.exports = {

    CREATING_PAYMENT_FIELDS_TOKEN: "CREATING_PAYMENT_FIELDS_TOKEN",
    CREATE_PAYMENT_FIELDS_TOKEN_SUCCESS: "CREATE_PAYMENT_FIELDS_TOKEN_SUCCESS",
    CREATE_PAYMENT_FIELDS_TOKEN_FAIL: "CREATE_PAYMENT_FIELDS_TOKEN_FAIL",

    CREATING_CUSTOMER: "CREATING_CUSTOMER",
    CREATE_CUSTOMER_SUCCESS: "CREATE_CUSTOMER_SUCCESS",
    CREATE_CUSTOMER_FAIL: "CREATE_CUSTOMER_FAIL",

    CREATING_SUBSCRIPTION: "CREATING_SUBSCRIPTION",
    CREATE_SUBSCRIPTION_SUCCESS: "CREATE_SUBSCRIPTION_SUCCESS",
    CREATE_SUBSCRIPTION_FAIL: "CREATE_SUBSCRIPTION_FAIL",

    FETCHING_CARDS: "FETCHING_CARDS",
    FETCH_CARDS_SUCCESS: "FETCH_CARDS_SUCCESS",
    FETCH_CARDS_FAIL: "FETCH_CARDS_FAIL",
    CLEAR_CARDS: "CLEAR_CARDS",

    RENEWING_SUBSCRIPTION: "RENEWING_SUBSCRIPTION",
    RENEW_SUBSCRIPTION_SUCCESS: "RENEW_SUBSCRIPTION_SUCCESS",
    RENEW_SUBSCRIPTION_FAIL: "RENEW_SUBSCRIPTION_FAIL",

    SETTING_DEFAULT_CARD: "SETTING_DEFAULT_CARD",
    SETTING_DEFAULT_CARD_SUCCESS: "SETTING_DEFAULT_CARD_SUCCESS",
    SETTING_DEFAULT_CARD_FAIL: "SETTING_DEFAULT_CARD_FAIL",

    ADDING_NEW_CARD: "ADDING_NEW_CARD",
    ADDING_NEW_CARD_SUCCESS: "ADDING_NEW_CARD_SUCCESS",
    ADDING_NEW_CARD_FAIL: "ADDING_NEW_CARD_FAIL",

    DELETING_CARD: "DELETING_CARD",
    DELETE_CARD_SUCCESS: "DELETE_CARD_SUCCESS",
    DELETE_CARD_FAIL: "DELETE_CARD_FAIL",

    FETCHING_PAYMENT_HISTORY: "FETCHING_PAYMENT_HISTORY",
    FETCH_PAYMENT_HISTORY_SUCCESS: "FETCH_PAYMENT_HISTORY_SUCCESS",
    FETCH_PAYMENT_HISTORY_FAIL: "FETCH_PAYMENT_HISTORY_FAIL",
    CLEAR_PAYMENT_HISTORY: "CLEAR_PAYMENT_HISTORY",

    FETCHING_SUBSCRIPTIONS: "FETCHING_SUBSCRIPTIONS",
    FETCH_SUBSCRIPTIONS_SUCCESS: "FETCH_SUBSCRIPTIONS_SUCCESS",
    FETCH_SUBSCRIPTIONS_FAIL: "FETCH_SUBSCRIPTIONS_FAIL",
    CLEAR_SUBSCRIPTIONS: "CLEAR_SUBSCRIPTIONS",

    CANCELING_SUBSCRIPTION: "CANCELING_SUBSCRIPTION",
    CANCEL_SUBSCRIPTION_SUCCESS: "CANCEL_SUBSCRIPTION_SUCCESS",
    CANCEL_SUBSCRIPTION_FAIL: "CANCEL_SUBSCRIPTION_FAIL",

    RE_ACTIVATING_SUBSCRIPTION: "RE_ACTIVATING_SUBSCRIPTION",
    RE_ACTIVATE_SUBSCRIPTION_SUCCESS: "RE_ACTIVATE_SUBSCRIPTION_SUCCESS",
    RE_ACTIVATE_SUBSCRIPTION_FAIL: "RE_ACTIVATE_SUBSCRIPTION_FAIL",

    FETCHING_COMPANY_PAYMENT_GATEWAYS: "FETCHING_COMPANY_PAYMENT_GATEWAYS",
    FETCH_COMPANY_PAYMENT_GATEWAYS_SUCCESS: "FETCH_COMPANY_PAYMENT_GATEWAYS_SUCCESS",
    FETCH_COMPANY_PAYMENT_GATEWAYS_FAIL: "FETCH_COMPANY_PAYMENT_GATEWAYS_FAIL",

    ATTACHING_NEW_CARD_TO_SUBSCRIPTION: 'ATTACHING_NEW_CARD_TO_SUBSCRIPTION',
    ATTACHING_NEW_CARD_TO_SUBSCRIPTION_SUCCESS: 'ATTACHING_NEW_CARD_TO_SUBSCRIPTION_SUCCESS',
    ATTACHING_NEW_CARD_TO_SUBSCRIPTION_FAIL: 'ATTACHING_NEW_CARD_TO_SUBSCRIPTION_FAIL',

    FETCHING_CARDS_BY_COMPANY_ID: 'FETCHING_CARDS_BY_COMPANY_ID',
    FETCHING_CARDS_BY_COMPANY_ID_SUCCESS: 'FETCHING_CARDS_BY_COMPANY_ID_SUCCESS',
    FETCHING_CARDS_BY_COMPANY_ID_FAIL: 'FETCHING_CARDS_BY_COMPANY_ID_FAIL',

    FETCHING_USER_PAYMENT_GATEWAYS: "FETCHING_USER_PAYMENT_GATEWAYS",
    FETCH_USER_PAYMENT_GATEWAYS_SUCCESS: "FETCH_USER_PAYMENT_GATEWAYS_SUCCESS",
    FETCH_USER_PAYMENT_GATEWAYS_FAIL: "FETCH_USER_PAYMENT_GATEWAYS_FAIL",

    FETCHING_SHOPPER: "FETCHING_SHOPPER",
    FETCH_SHOPPER_SUCCESS: "FETCH_SHOPPER_SUCCESS",
    FETCH_SHOPPER_FAIL: "FETCH_SHOPPER_FAIL",
    CLEAR_SHOPPER: "CLEAR_SHOPPER",

    CANCEL_JOB_BOARD_SUBSCRIPTION: 'CANCEL_JOB_BOARD_SUBSCRIPTION',
    CANCEL_JOB_BOARD_SUBSCRIPTION_SUCCESS: 'CANCEL_JOB_BOARD_SUBSCRIPTION_SUCCESS',
    CANCEL_JOB_BOARD_SUBSCRIPTION_FAIL: 'CANCEL_JOB_BOARD_SUBSCRIPTION_FAIL',

    REACTIVATE_JOB_BOARD_SUBSCRIPTION: 'REACTIVATE_JOB_BOARD_SUBSCRIPTION',
    REACTIVATE_JOB_BOARD_SUBSCRIPTION_SUCCESS: 'REACTIVATE_JOB_BOARD_SUBSCRIPTION_SUCCESS',
    REACTIVATE_JOB_BOARD_SUBSCRIPTION_FAIL: 'REACTIVATE_JOB_BOARD_SUBSCRIPTION_FAIL',

    CLEAR_ACTION: "CLEAR_ACTION"

};