import React from 'react'

const TooltipWrapper = ({ children }) =>{

    return(
        <div className="tooltip_wripper">
          {children}
        </div>
    )

}
export { TooltipWrapper }