import AuthTypes from '../types/authentication';
import ProfileTypes from '../types/profile';
import {
    STATUS,
    SHOW_MSG_NOTIFICATION_COUNT,
    SHOW_MARKETPLACE_MSG_NOTIFICATION_COUNT,
    RECEIVED_MARKETPLACE_MSG_NOTIFICATION,
    DECREMENT_MARKETPLACE_MSG_NOTIFICATION,
    RECEIVED_PERSONAL_MSG_NOTIFICATION,
    RECEIVED_CONNECTION_MSG_NOTIFICATION,
    DECREMENT_PERSONAL_MSG_NOTIFICATION,
    DECREMENT_CONNECTION_MSG_NOTIFICATION,
    MARK_ALL_MESSAGES_READ,
    GET_LEFT_PANEL_CONVERSATION_COUNT
} from '../types/message-v2';
import {RESET_PRO_MESSAGE_LIMIT} from '../types/chat';
import { DECREMENT_PENDING_CONNECTION, NEW_CONNECTION_REQUEST_NOTIFICATION, CANCEL_CONNECTION_REQUEST_NOTIFICATION, CLEAR_PREVIOUS_REQUEST } from '../types/connections';
import { NOTIFICATION_TYPES, MESSAGE_SECTION_TYPES, PUBLIC_AUTH_TYPE } from '../types/common';

export const initialState = {
    action: 0,
    fetching: false,
    activationSuccess: false,//ed
    activationFail: false,//ed
    signInSuccess: false,
    signInFail: false,
    activationStep: 0,
    initloginFetching: false,
    showWelcomeMessage: false,
    welcomeMessage: null,
    profileImageUrl: false,
    profilePublicImageUrl: false,
    profileThumbnailUrl: false,
    user: {
        _id: '',
        FIRST_NAME: '',
        LAST_NAME: '',
        TOKEN: '',
        MEMBER_TYPE: '',
        REGISTRATION_STEP: '',
        EMAIL: '',
        INVESTOR: [],
        IS_FIRST_TIME: false,
        UNSEEN_CONVERSATION_COUNT: 0,
        UNSEEN_PENDING_CONNECTION_COUNT: 0,
        UNSEEN_MARKETPLACE_CONVERSATION_COUNT: 0,
        UNSEEN_PENDING_CONNECTION_CONVERSATION_COUNT: 0,
        TOP_NAV_CONVERSATION_COUNT: 0,
        TOP_NAV_MARKETPLACE_CONVERSATION_COUNT: 0,
        TOP_NAV_PENDING_CONNECTION_COUNT: 0,
        HEADLINE: '',
        IS_PRO_MEMBER: false,
        VANITY_URL:''
    },
    employmentHistory: [],
    usersEmploymentHistory: [],
    response: {
        code: 0,
        data: [],
        message: '',
        success: ''
    },
    reset_password: {
        status: null,
        message: ''
    },
    reset_password_token: {
        status: 0,
        message: ''
    },
    publicProfile: null,
    publicProfileImageUrl: false,
    passwordResetToken: null,
    unReadMessageCount: 0,
    noneUpdatedCompanies: [],
    noneUpdatedCompaniesWarning: true,
    changePassword: {
        status: null,
        message: ''
    },
    isLoggedInUserCompanyAdmin: false,
    adminCompanies: [],
    deleteUserMessage: '',
    myCompanies: [],
    myCompaniesFetched: false,
    fetchingEmpHistory: false,
    unseenPendingConnectionRequestersIdSet: [],
    toolTips: [],
    personalNewsfeed: false,

    publicPendingAction: {
        authType: PUBLIC_AUTH_TYPE.LOGIN,
        type: null,
        data: null
    }
};

export const auth = (state = initialState, action) => {
    switch (action.type) {
        case DECREMENT_PENDING_CONNECTION:
            const decrementingIndex = state.unseenPendingConnectionRequestersIdSet.indexOf(action.userId);
            return {
                ...state,
                user: {
                    ...state.user,
                    TOP_NAV_PENDING_CONNECTION_COUNT: (state.unseenPendingConnectionRequestersIdSet.includes(action.userId) && state.user.TOP_NAV_PENDING_CONNECTION_COUNT > 0) ? state.user.TOP_NAV_PENDING_CONNECTION_COUNT - 1 : state.user.TOP_NAV_PENDING_CONNECTION_COUNT,
                    UNSEEN_PENDING_CONNECTION_COUNT: state.user.UNSEEN_PENDING_CONNECTION_COUNT > 0 ? state.user.UNSEEN_PENDING_CONNECTION_COUNT - 1 : state.user.UNSEEN_PENDING_CONNECTION_COUNT
                },
                unseenPendingConnectionRequestersIdSet: decrementingIndex >= 0 ? [...state.unseenPendingConnectionRequestersIdSet.slice(0, decrementingIndex), ...state.unseenPendingConnectionRequestersIdSet.slice(decrementingIndex + 1)] : state.unseenPendingConnectionRequestersIdSet,
            };
        case NEW_CONNECTION_REQUEST_NOTIFICATION:
            return {
                ...state,
                user: {
                    ...state.user,
                    TOP_NAV_PENDING_CONNECTION_COUNT: !state.unseenPendingConnectionRequestersIdSet.includes(action.userId) ? state.user.TOP_NAV_PENDING_CONNECTION_COUNT + 1 : state.user.TOP_NAV_PENDING_CONNECTION_COUNT,
                    UNSEEN_PENDING_CONNECTION_COUNT: state.user.UNSEEN_PENDING_CONNECTION_COUNT + 1
                },
                unseenPendingConnectionRequestersIdSet: [action.userId, ...state.unseenPendingConnectionRequestersIdSet],
                connectionRequestSentBy: action.userId
            };
        case CLEAR_PREVIOUS_REQUEST:
            return {
                ...state,
                connectionRequestSentBy: null,
            }
        case CANCEL_CONNECTION_REQUEST_NOTIFICATION:
            const cancelingIndex = state.unseenPendingConnectionRequestersIdSet.indexOf(action.userId);
            return {
                ...state,
                user: {
                    ...state.user,
                    TOP_NAV_PENDING_CONNECTION_COUNT: (state.unseenPendingConnectionRequestersIdSet.includes(action.userId) && state.user.TOP_NAV_PENDING_CONNECTION_COUNT > 0) ? state.user.TOP_NAV_PENDING_CONNECTION_COUNT - 1 : state.user.TOP_NAV_PENDING_CONNECTION_COUNT,
                    UNSEEN_PENDING_CONNECTION_COUNT: state.user.UNSEEN_PENDING_CONNECTION_COUNT > 0 ? state.user.UNSEEN_PENDING_CONNECTION_COUNT - 1 : state.user.UNSEEN_PENDING_CONNECTION_COUNT
                },
                unseenPendingConnectionRequestersIdSet: cancelingIndex >= 0 ? [...state.unseenPendingConnectionRequestersIdSet.slice(0, cancelingIndex), ...state.unseenPendingConnectionRequestersIdSet.slice(cancelingIndex + 1)] : state.unseenPendingConnectionRequestersIdSet,
            };

        case SHOW_MSG_NOTIFICATION_COUNT:
            return {
                ...state,
                user: {
                    ...state.user,
                    UNSEEN_CONVERSATION_COUNT: 0
                }
            };
        case SHOW_MARKETPLACE_MSG_NOTIFICATION_COUNT:
            return {
                ...state,
                user: {
                    ...state.user,
                    UNSEEN_MARKETPLACE_CONVERSATION_COUNT: 0
                }
            };

        case RECEIVED_MARKETPLACE_MSG_NOTIFICATION:
            return {
                ...state,
                user: {
                    ...state.user,
                    TOP_NAV_MARKETPLACE_CONVERSATION_COUNT: (action.activeConversationId !== action.data._id && !action.soFarTopNavUnSeenConvIdSet.includes(action.data._id)) ? state.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT + 1 : state.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT,
                    TOP_NAV_CONVERSATION_COUNT: (action.activeConversationId !== action.data._id && !action.soFarTopNavUnSeenConvIdSet.includes(action.data._id)) ? state.user.TOP_NAV_CONVERSATION_COUNT + 1 : state.user.TOP_NAV_CONVERSATION_COUNT,
                    UNSEEN_MARKETPLACE_CONVERSATION_COUNT: (action.activeConversationId !== action.data._id && !action.soFarNotifiedConversationIdSet.includes(action.data._id)) ? state.user.UNSEEN_MARKETPLACE_CONVERSATION_COUNT + 1 : state.user.UNSEEN_MARKETPLACE_CONVERSATION_COUNT
                }
            };
        case DECREMENT_MARKETPLACE_MSG_NOTIFICATION:
            return {
                ...state,
                user: {
                    ...state.user,
                    TOP_NAV_MARKETPLACE_CONVERSATION_COUNT: (action.soFarTopNavUnSeenConvIdSet.includes(action.conversationId) && state.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT > 0) ? state.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT - 1 : state.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT,
                    TOP_NAV_CONVERSATION_COUNT: (action.soFarTopNavUnSeenConvIdSet.includes(action.conversationId) && state.user.TOP_NAV_CONVERSATION_COUNT > 0) ? state.user.TOP_NAV_CONVERSATION_COUNT - 1 : state.user.TOP_NAV_CONVERSATION_COUNT,
                    UNSEEN_MARKETPLACE_CONVERSATION_COUNT: state.user.UNSEEN_MARKETPLACE_CONVERSATION_COUNT !== 0 ? state.user.UNSEEN_MARKETPLACE_CONVERSATION_COUNT - 1 : state.user.UNSEEN_MARKETPLACE_CONVERSATION_COUNT
                }
            };

        case RECEIVED_PERSONAL_MSG_NOTIFICATION:
            return {
                ...state,
                user: {
                    ...state.user,
                    TOP_NAV_CONVERSATION_COUNT: (action.activeConversationId !== action.data._id && !action.soFarTopNavUnSeenConvIdSet.includes(action.data._id)) ? state.user.TOP_NAV_CONVERSATION_COUNT + 1 : state.user.TOP_NAV_CONVERSATION_COUNT,
                    UNSEEN_CONVERSATION_COUNT: (action.activeConversationId !== action.data._id && !action.soFarNotifiedConversationIdSet.includes(action.data._id)) ? state.user.UNSEEN_CONVERSATION_COUNT + 1 : state.user.UNSEEN_CONVERSATION_COUNT
                }
            };
        case DECREMENT_PERSONAL_MSG_NOTIFICATION:
            return {
                ...state,
                user: {
                    ...state.user,
                    TOP_NAV_CONVERSATION_COUNT: (action.soFarTopNavUnSeenConvIdSet.includes(action.conversationId) && state.user.TOP_NAV_CONVERSATION_COUNT > 0) ? state.user.TOP_NAV_CONVERSATION_COUNT - 1 : state.user.TOP_NAV_CONVERSATION_COUNT,
                    UNSEEN_CONVERSATION_COUNT: state.user.UNSEEN_CONVERSATION_COUNT !== 0 ? state.user.UNSEEN_CONVERSATION_COUNT - 1 : state.user.UNSEEN_CONVERSATION_COUNT
                }
            };

        case RECEIVED_CONNECTION_MSG_NOTIFICATION:
            return {
                ...state,
                user: {
                    ...state.user,
                    TOP_NAV_CONVERSATION_COUNT: (action.activeConversationId !== action.data._id && !action.soFarTopNavUnSeenConvIdSet.includes(action.data._id)) ? state.user.TOP_NAV_CONVERSATION_COUNT + 1 : state.user.TOP_NAV_CONVERSATION_COUNT,
                    UNSEEN_PENDING_CONNECTION_CONVERSATION_COUNT: (action.activeConversationId !== action.data._id && !action.soFarNotifiedConversationIdSet.includes(action.data._id)) ? state.user.UNSEEN_PENDING_CONNECTION_CONVERSATION_COUNT + 1 : state.user.UNSEEN_PENDING_CONNECTION_CONVERSATION_COUNT
                }
            };
        case DECREMENT_CONNECTION_MSG_NOTIFICATION:
            return {
                ...state,
                user: {
                    ...state.user,
                    TOP_NAV_CONVERSATION_COUNT: (action.soFarTopNavUnSeenConvIdSet.includes(action.conversationId) && state.user.TOP_NAV_CONVERSATION_COUNT > 0) ? state.user.TOP_NAV_CONVERSATION_COUNT - 1 : state.user.TOP_NAV_CONVERSATION_COUNT,
                    UNSEEN_PENDING_CONNECTION_CONVERSATION_COUNT: state.user.UNSEEN_PENDING_CONNECTION_CONVERSATION_COUNT !== 0 ? state.user.UNSEEN_PENDING_CONNECTION_CONVERSATION_COUNT - 1 : state.user.UNSEEN_PENDING_CONNECTION_CONVERSATION_COUNT
                }
            };

        case AuthTypes.LOGOUT:
            return {
                ...state,
                action: AuthTypes.LOGOUT
            };
        case AuthTypes.INSERTING_USER:
            return {
                ...state,
                action: AuthTypes.INSERTING_USER
            };
        case AuthTypes.FETCHING:
            return {
                ...state,
                fetching: true,
                action: AuthTypes.FETCHING
            };
        case AuthTypes.ACTIVATION_SUCCESS:
            return {
                ...state,
                action: AuthTypes.ACTIVATION_SUCCESS,
                fetching: false,
                user: action.payload.data,
            };
        case AuthTypes.ACTIVATION_REQUIRED:
            return {
                ...state,
                action: AuthTypes.ACTIVATION_REQUIRED,
                fetching: false,
                user: action.payload.data,
                response: action.payload.response,
            };
        case AuthTypes.ACTIVATION_FAIL:
            return {
                ...state,
                fetching: false,
                activationFail: true,
                action: AuthTypes.ACTIVATION_FAIL,
                response: action.payload,
            };
        case AuthTypes.TOKEN_EXPIRED:
            return {
                ...state,
                action: AuthTypes.TOKEN_EXPIRED,
                fetching: false,
            };
        case AuthTypes.INVALID_TOKEN:
            return {
                ...state,
                action: AuthTypes.INVALID_TOKEN,
                fetching: false,
            };
        case AuthTypes.SIGNUP_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: AuthTypes.SIGNUP_SUCCESS,
                response: action.payload,
                user: { ...action.payload.data, events: (action.payload && action.payload.data && action.payload.data.events) ? action.payload.data.events.map(ev => { return { _id: ev._id, status: ev.INTERESTED_USERS[0].STATUS } }) : [] },
                initloginFetching: false,
                myCompaniesFetched: true
            };
        case AuthTypes.SIGNUP_FAIL:
            return {
                ...state,
                fetching: false,
                action: AuthTypes.SIGNUP_FAIL,
                response: action.payload,
                initloginFetching: false
            };
        case AuthTypes.SIGNUP_STEP_TWO_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: AuthTypes.SIGNUP_STEP_TWO_SUCCESS,
                response: action.payload,
                user: { ...action.payload.data, MEMBER_TYPE: action.memberType, events: (action.payload && action.payload.data && action.payload.data.events) ? action.payload.data.events.map(ev => { return { _id: ev._id, status: ev.INTERESTED_USERS[0].STATUS } }) : [] },
            };
        case AuthTypes.SIGNUP_STEP_TWO_FAIL:
            return {
                ...state,
                fetching: false,
                action: AuthTypes.SIGNUP_STEP_TWO_FAIL,
                response: action.payload
            };
        case AuthTypes.SIGNIN_SUCCESS:
            return {
                ...state,
                fetching: false,
                signInSuccess: true,
                action: AuthTypes.SIGNIN_SUCCESS,
                response: action.payload,
                user: { ...action.payload.data, events: (action.payload && action.payload.data && action.payload.data.events) ? action.payload.data.events.map(ev => { return { _id: ev._id, status: ev.INTERESTED_USERS[0].STATUS } }) : [] }
            };
        case AuthTypes.SIGNIN_FAIL:
            return {
                ...state,
                fetching: false,
                signInFail: true,
                action: AuthTypes.SIGNIN_FAIL,
                response: action.payload
            };
        case AuthTypes.INSERTING_USER_DATA_SUCCESS:
            return {
                ...state,
                action: AuthTypes.INSERTING_USER_DATA_SUCCESS,
                activationStep: action.payload,
                user: { ...action.response.data, events: (action.payload && action.payload.data && action.payload.data.events) ? action.payload.data.events.map(ev => { return { _id: ev._id, status: ev.INTERESTED_USERS[0].STATUS } }) : [] },
                response: action.response
            };
        case AuthTypes.INSERTING_USER_DATA_FAIL:
            return {
                ...state,
                action: AuthTypes.INSERTING_USER_DATA_FAIL,
                activationStep: action.payload,
                response: action.response
            };
        case AuthTypes.CLEAR_ACTION:
            return {
                ...state,
                action: 0
            };
        case AuthTypes.WELCOME_MESSAGE:
            return {
                ...state,
                action: AuthTypes.WELCOME_MESSAGE,
                showWelcomeMessage: true
            };
        case AuthTypes.CLEAR_WELCOME_MESSAGE:
            return {
                ...state,
                showWelcomeMessage: null
            };
        case AuthTypes.INITAL_FETCHING:
            return {
                ...state,
                action: AuthTypes.INITAL_FETCHING,
                initloginFetching: true
            };
        case AuthTypes.GETTING_WELCOME_MESSAGE_SUCCESS:
            return {
                ...state,
                action: AuthTypes.GETTING_WELCOME_MESSAGE_SUCCESS,
                response: action.payload,
                welcomeMessage: action.payload
            };
        case AuthTypes.GETTING_WELCOME_MESSAGE_FAIL:
            return {
                ...state,
                action: AuthTypes.GETTING_WELCOME_MESSAGE_FAIL,
            };
        case AuthTypes.ADDING_EMPLOYMENT_HISTORY:
            return {
                ...state,
                fetching: true,
                action: AuthTypes.ADDING_EMPLOYMENT_HISTORY
            };
        case AuthTypes.ADDING_EMPLOYMENT_HISTORY_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: AuthTypes.ADDING_EMPLOYMENT_HISTORY_SUCCESS,
            };
        case AuthTypes.ADDING_EMPLOYMENT_HISTORY_FAIL:
            return {
                ...state,
                fetching: false,
                action: AuthTypes.ADDING_EMPLOYMENT_HISTORY_FAIL
            };
        case AuthTypes.FETCHING_EMPLOYMENT_HISTORY:
            return {
                ...state,
                fetching: true,
                action: AuthTypes.FETCHING_EMPLOYMENT_HISTORY
            };
        case AuthTypes.FETCHING_EMPLOYMENT_HISTORY_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: AuthTypes.FETCHING_EMPLOYMENT_HISTORY_SUCCESS,
                employmentHistory: action.payload.COMPANY_DETAILS.length > 0 ? action.payload.COMPANY_DETAILS : []
            };
        case AuthTypes.FETCHING_EMPLOYMENT_HISTORY_FAIL:
            return {
                ...state,
                fetching: false,
                action: AuthTypes.FETCHING_EMPLOYMENT_HISTORY_FAIL
            };

        // Added Due to the employement history edit
        case AuthTypes.FETCHING_EMPLOYMENT_HISTORY_FOREDIT:
            return {
                ...state,
                fetching: true,
                fetchingEmpHistory: true,
                action: AuthTypes.FETCHING_EMPLOYMENT_HISTORY_FOREDIT
            };
        case AuthTypes.FETCHING_EMPLOYMENT_HISTORY_SUCCESS_FOREDIT:
            return {
                ...state,
                fetching: false,
                fetchingEmpHistory: false,
                action: AuthTypes.FETCHING_EMPLOYMENT_HISTORY_SUCCESS_FOREDIT,
                usersEmploymentHistory: action.payload.COMPANY_DETAILS.length > 0 ? action.payload.COMPANY_DETAILS : []
            };
        case AuthTypes.FETCHING_EMPLOYMENT_HISTORY_FAIL_FOREDIT:
            return {
                ...state,
                fetching: false,
                fetchingEmpHistory: false,
                action: AuthTypes.FETCHING_EMPLOYMENT_HISTORY_FAIL_FOREDIT
            };
        case AuthTypes.DELETING_EMPLOYMENT_HISTORY:
            return {
                ...state,
                fetching: true,
                action: AuthTypes.DELETING_EMPLOYMENT_HISTORY
            };
        case AuthTypes.DELETING_EMPLOYMENT_HISTORY_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: AuthTypes.DELETING_EMPLOYMENT_HISTORY_SUCCESS,
            };
        case AuthTypes.DELETING_EMPLOYMENT_HISTORY_FAIL:
            return {
                ...state,
                fetching: false,
                action: AuthTypes.DELETING_EMPLOYMENT_HISTORY_FAIL
            };
        case AuthTypes.GETTING_PROFILE_IMAGE:
            return {
                ...state,
                fetching: true,
                action: AuthTypes.GETTING_PROFILE_IMAGE
            };
        case AuthTypes.GETTING_PROFILE_IMAGE_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: AuthTypes.GETTING_PROFILE_IMAGE_SUCCESS,
                profileImageUrl: action.payload
            };
        case AuthTypes.GETTING_PROFILE_IMAGE_FAIL:
            return {
                ...state,
                fetching: false,
                action: AuthTypes.GETTING_PROFILE_IMAGE_FAIL
            };
        case AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_SUCCESS,
                profilePublicImageUrl: action.payload
            };
        case AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_FAIL:
            return {
                ...state,
                fetching: false,
                action: AuthTypes.GETTING_PUBLIC_PROFILE_IMAGE_FAIL
            };
        case AuthTypes.CLEAR_PROFILE_IMAGE:
            return {
                ...state,
                fetching: false,
                action: AuthTypes.CLEAR_PROFILE_IMAGE,
                profileImageUrl: /*localStorage.getItem('profilePicture') ? localStorage.getItem('profilePicture') :*/ false
            };
        case AuthTypes.GETTING_PROFILE_THUMBNAIL_IMAGE_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: AuthTypes.GETTING_PROFILE_THUMBNAIL_IMAGE_SUCCESS,
                profileThumbnailUrl: action.payload
            };
        case AuthTypes.GETTING_PROFILE_THUMBNAIL_IMAGE_FAIL:
            return {
                ...state,
                fetching: false,
                action: AuthTypes.GETTING_PROFILE_THUMBNAIL_IMAGE_FAIL
            };
        case AuthTypes.CLEAR_PROFILE_THUMBNAIL_IMAGE:
            return {
                ...state,
                fetching: false,
                action: AuthTypes.CLEAR_PROFILE_THUMBNAIL_IMAGE,
                profileThumbnailUrl: /*localStorage.getItem('profileThumbnail') ? localStorage.getItem('profileThumbnail') :*/ false
            };
        case AuthTypes.FETCHING_EMPLOYMENT_HISTORY_COMPANY_IMAGES_SUCCESS: {
            return {
                ...state,
                action: AuthTypes.FETCHING_EMPLOYMENT_HISTORY_COMPANY_IMAGES_SUCCESS,
                employmentHistory: { ...action.payload }
            }
        }
        case AuthTypes.RESEND_ACTIVATION_EMAIL:
            return {
                ...state,
                action: AuthTypes.RESEND_ACTIVATION_EMAIL
            };
        case AuthTypes.RESEND_ACTIVATION_EMAIL_SUCCESS:
            return {
                ...state,
                action: AuthTypes.RESEND_ACTIVATION_EMAIL_SUCCESS
            };
        case AuthTypes.RESEND_ACTIVATION_EMAIL_FAIL:
            return {
                ...state,
                action: AuthTypes.RESEND_ACTIVATION_EMAIL_FAIL
            };

        case AuthTypes.RESETTING_PASSWORD:
            return {
                ...state,
                // reset_password_token: action.payload,
                action: AuthTypes.RESETTING_PASSWORD
            };
        case AuthTypes.RESETTING_PASSWORD_SUCCESS:
            return {
                ...state,
                reset_password_token: action.payload,
                action: AuthTypes.RESETTING_PASSWORD_SUCCESS
            };
        case AuthTypes.RESETTING_PASSWORD_FAIL:
            return {
                ...state,
                reset_password_token: action.payload,
                action: AuthTypes.RESETTING_PASSWORD_FAIL
            };

        case AuthTypes.VALIDATE_RESET_TOKEN:
            return {
                ...state,
                action: AuthTypes.VALIDATE_RESET_TOKEN,
                reset_password: action.payload
            };
        case AuthTypes.VALIDATE_RESET_TOKEN_SUCCESS:
            return {
                ...state,
                passwordResetToken: action.token,
                reset_password: action.payload,
                action: AuthTypes.VALIDATE_RESET_TOKEN_SUCCESS
            };
        case AuthTypes.VALIDATE_RESET_TOKEN_FAIL:
            return {
                ...state,
                reset_password: action.payload,
                action: AuthTypes.VALIDATE_RESET_TOKEN_FAIL
            };

        case AuthTypes.UPDATE_PASSWORD:
            return {
                ...state,
                action: AuthTypes.UPDATE_PASSWORD
            };
        case AuthTypes.UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                passwordResetMessage: action.payload,
                action: AuthTypes.UPDATE_PASSWORD_SUCCESS
            };
        case AuthTypes.UPDATE_PASSWORD_FAIL:
            return {
                ...state,
                reset_password: action.payload,
                action: AuthTypes.UPDATE_PASSWORD_FAIL
            };
        case AuthTypes.UPDATING_PERSONAL_OVERVIEW:
            return {
                ...state,
                action: AuthTypes.UPDATING_PERSONAL_OVERVIEW
            };
        case AuthTypes.UPDATING_PERSONAL_OVERVIEW_SUCCESS:
            return {
                ...state,
                action: AuthTypes.UPDATING_PERSONAL_OVERVIEW_SUCCESS,
                user: {
                    ...state.user,
                    PERSONAL_BACKGROUND: action.payload
                }
            };
        case AuthTypes.UPDATING_PERSONAL_OVERVIEW_FAIL:
            return {
                ...state,
                action: AuthTypes.UPDATING_PERSONAL_OVERVIEW_FAIL
            };
        case AuthTypes.UPDATE_INVESTOR_OVERVIEW_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    INVESTOR: {
                        ...state.user.INVESTOR,
                        ...action.payload
                    }
                }
            };
        case AuthTypes.UPDATE_INVESTOR_THESIS_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    INVESTOR: {
                        ...state.user.INVESTOR,
                        ...action.payload
                    }
                }
            };
        case AuthTypes.UPDATING_EMPLOYMENT_HISTORY:
            return {
                ...state,
                fetching: true,
                action: AuthTypes.UPDATING_EMPLOYMENT_HISTORY
            };
        case AuthTypes.UPDATING_EMPLOYMENT_HISTORY_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: AuthTypes.UPDATING_EMPLOYMENT_HISTORY_SUCCESS,
            };
        case AuthTypes.UPDATING_EMPLOYMENT_HISTORY_FAIL:
            return {
                ...state,
                fetching: false,
                action: AuthTypes.UPDATING_EMPLOYMENT_HISTORY_FAIL
            };
        case AuthTypes.DELETE_USER:
            return {
                ...state,
                fetching: true,
                action: AuthTypes.DELETE_USER
            };
        case AuthTypes.DELETE_USER_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: AuthTypes.DELETE_USER_SUCCESS
            };
        case AuthTypes.DELETE_USER_FAIL:
            return {
                ...state,
                fetching: false,
                action: AuthTypes.DELETE_USER_FAIL,
                deleteUserMessage: action.message
            };
        case AuthTypes.CLEAR_DELETE_USER_FAIL_MESSAGE:
            return {
                ...state,
                deleteUserMessage: '',
                action: AuthTypes.CLEAR_DELETE_USER_FAIL_MESSAGE
            };
        case AuthTypes.GET_NONUPDATED_COMPANIES:
            return {
                ...state,
                fetching: true,
                noneUpdatedCompanies: []
            };
        case AuthTypes.GET_NONUPDATED_COMPANIES_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: AuthTypes.GET_NONUPDATED_COMPANIES_SUCCESS,
                noneUpdatedCompanies: action.payload
            };
        case AuthTypes.GET_NONUPDATED_COMPANIES_FAIL:
            return {
                ...state,
                fetching: false,
                action: AuthTypes.GET_NONUPDATED_COMPANIES_FAIL,
                noneUpdatedCompanies: []
            };
        case AuthTypes.HIDE_NONUPDATED_COMPANIES_WARNING:
            return {
                ...state,
                noneUpdatedCompaniesWarning: false
            };
        case AuthTypes.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                action: AuthTypes.CHANGE_PASSWORD_SUCCESS,
                changePassword: {
                    status: action.status,
                    message: action.message
                }
            };
        case AuthTypes.CHANGE_PASSWORD_FAIL:
            return {
                ...state,
                action: AuthTypes.CHANGE_PASSWORD_FAIL,
                changePassword: {
                    status: action.status,
                    message: action.message
                }
            };
        case AuthTypes.CHANGE_EMAIL_SUCCESS:
            return {
                ...state,
                action: AuthTypes.CHANGE_EMAIL_SUCCESS,
            };
        case AuthTypes.CHANGE_EMAIL_FAIL:
            return {
                ...state,
                action: AuthTypes.CHANGE_EMAIL_FAIL
            };

        case AuthTypes.FETCHING_IS_LOGGED_IN_USER_COMPANY_ADMIN:
            return {
                ...state,
                action: AuthTypes.FETCHING_IS_LOGGED_IN_USER_COMPANY_ADMIN,
            };
        case AuthTypes.FETCH_IS_LOGGED_IN_USER_COMPANY_ADMIN_SUCCESS:
            return {
                ...state,
                action: AuthTypes.FETCH_IS_LOGGED_IN_USER_COMPANY_ADMIN_SUCCESS,
                isLoggedInUserCompanyAdmin: action.payload.length > 0 ? true : false
            };
        case AuthTypes.FETCH_IS_LOGGED_IN_USER_COMPANY_ADMIN_FAIL:
            return {
                ...state,
                action: AuthTypes.FETCH_IS_LOGGED_IN_USER_COMPANY_ADMIN_FAIL,
                isLoggedInUserCompanyAdmin: false
            };
        case AuthTypes.NOT_DELETABLE_COMPANY_ADMIN:
            return {
                ...state,
                action: AuthTypes.NOT_DELETABLE_COMPANY_ADMIN,
                adminCompanies: action.payload
            };
        case AuthTypes.PROFILE_IMAGE_REMOVED:
            return {
                ...state,
                action: AuthTypes.PROFILE_IMAGE_REMOVED
            };
        case AuthTypes.FETCHING_ADMIN_COMPANY_LIST_SUCCESS:
            return {
                ...state,
                myCompanies: action.payload,
                showReportingDashboard: action.payload.filter(company => company.SHOW_REPORTING_DASHBOARD),
                action: AuthTypes.FETCHING_ADMIN_COMPANY_LIST_SUCCESS,
                myCompaniesFetched: true
            };
        case AuthTypes.FETCHING_ADMIN_COMPANY_LIST_FAIL:
            return {
                ...state,
                myCompanies: [],
                myCompaniesFetched: true
            };
        case AuthTypes.ADD_TO_ADMIN_COMPANY_LIST:
            return {
                ...state,
                myCompanies: [...state.myCompanies, action.payload]
            };
        case AuthTypes.REMOVE_FROM_ADMIN_COMPANY_LIST:
            return {
                ...state,
                myCompanies: state.myCompanies.filter(company => { return company._id !== action.companyId })
            };
        case AuthTypes.UPDATE_USER:
            return {
                ...state,
                user: {
                    ...action.payload,
                    events: action.payload.events.map(ev => { return { _id: ev._id, status: ev.INTERESTED_USERS[0].STATUS } })
                }
            };
        case AuthTypes.CLEAR_IS_FIRST_TIME:
            return {
                ...state,
                user: { ...state.user, IS_FIRST_TIME: false }
            };
        case AuthTypes.SET_INDUSTRY_ROLES_SUCCESS:
            return {
                ...state,
                action: AuthTypes.SET_INDUSTRY_ROLES_SUCCESS,
                user: {
                    ...state.user,
                    IS_INDUSTRY_ROLE_SET: action.isFilled,
                }
            };
        case AuthTypes.SET_INDUSTRY_ROLES_FAIL:
            return {
                ...state,
                action: AuthTypes.SET_INDUSTRY_ROLES_FAIL
            };

        case AuthTypes.RESETTING_TOP_NAV_COUNTS:
            return {
                ...state,
                action: AuthTypes.RESETTING_TOP_NAV_COUNTS
            };
        case AuthTypes.RESET_TOP_NAV_COUNTS_SUCCESS:
            if (action.moduleNo === NOTIFICATION_TYPES.PERSONAL_MSG) {
                return {
                    ...state,
                    action: AuthTypes.RESET_TOP_NAV_COUNTS_SUCCESS,
                    user: {
                        ...state.user,
                        TOP_NAV_CONVERSATION_COUNT: 0
                    }
                };
            } else if (action.moduleNo === NOTIFICATION_TYPES.PRODUCTS_MSG) {
                return {
                    ...state,
                    action: AuthTypes.RESET_TOP_NAV_COUNTS_SUCCESS,
                    user: {
                        ...state.user,
                        TOP_NAV_MARKETPLACE_CONVERSATION_COUNT: 0
                    }
                };
            } else if (action.moduleNo === NOTIFICATION_TYPES.CONNECTIONS_MSG) {
                return {
                    ...state,
                    action: AuthTypes.RESET_TOP_NAV_COUNTS_SUCCESS,
                    user: {
                        ...state.user,
                        TOP_NAV_PENDING_CONNECTION_COUNT: 0
                    },
                    unseenPendingConnectionRequestersIdSet: []
                };
            } else {
                return {
                    ...state
                }
            }
        case AuthTypes.RESET_TOP_NAV_COUNTS_FAIL:
            return {
                ...state,
                action: AuthTypes.RESET_TOP_NAV_COUNTS_FAIL
            }

        case MARK_ALL_MESSAGES_READ:
            return {
                ...state,
                user: {
                    ...state.user,
                    UNSEEN_CONVERSATION_COUNT: action.module === MESSAGE_SECTION_TYPES.PERSONAL.id ? 0 : state.user.UNSEEN_CONVERSATION_COUNT,
                    UNSEEN_PENDING_CONNECTION_CONVERSATION_COUNT: action.module === MESSAGE_SECTION_TYPES.CONNECTIONS.id ? 0 : state.user.UNSEEN_PENDING_CONNECTION_CONVERSATION_COUNT,
                    UNSEEN_MARKETPLACE_CONVERSATION_COUNT: action.module === MESSAGE_SECTION_TYPES.PRODUCTS.id ? 0 : state.user.UNSEEN_MARKETPLACE_CONVERSATION_COUNT,
                    TOP_NAV_CONVERSATION_COUNT: state.user.TOP_NAV_CONVERSATION_COUNT - action.count,
                    TOP_NAV_MARKETPLACE_CONVERSATION_COUNT: (action.module === MESSAGE_SECTION_TYPES.PRODUCTS.id && state.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT > 0) ? state.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT - action.count : state.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT
                }
            };
        case AuthTypes.UPDATE_NOTIFICATION_COUNTERS_AFTER_DELETE:
            return {
                ...state,
                user: {
                    ...state.user,
                    UNSEEN_CONVERSATION_COUNT: (action.soFarNotifiedConversationIdSet.includes(action.conversationId) && action.module === NOTIFICATION_TYPES.PERSONAL_MSG) ? state.user.UNSEEN_CONVERSATION_COUNT - 1 : state.user.UNSEEN_CONVERSATION_COUNT,
                    UNSEEN_MARKETPLACE_CONVERSATION_COUNT: (action.soFarNotifiedConversationIdSet.includes(action.conversationId) && action.module === NOTIFICATION_TYPES.PRODUCTS_MSG) ? state.user.UNSEEN_MARKETPLACE_CONVERSATION_COUNT - 1 : state.user.UNSEEN_MARKETPLACE_CONVERSATION_COUNT,
                    UNSEEN_PENDING_CONNECTION_CONVERSATION_COUNT: (action.soFarNotifiedConversationIdSet.includes(action.conversationId) && action.module === NOTIFICATION_TYPES.CONNECTIONS_MSG) ? state.user.UNSEEN_PENDING_CONNECTION_CONVERSATION_COUNT - 1 : state.user.UNSEEN_PENDING_CONNECTION_CONVERSATION_COUNT,
                    TOP_NAV_CONVERSATION_COUNT: (action.soFarTopNavUnSeenConvIdSet.includes(action.conversationId) && (action.module === NOTIFICATION_TYPES.PERSONAL_MSG || action.module === NOTIFICATION_TYPES.PRODUCTS_MSG || action.module === NOTIFICATION_TYPES.CONNECTIONS_MSG)) ? state.user.TOP_NAV_CONVERSATION_COUNT - 1 : state.user.TOP_NAV_CONVERSATION_COUNT,
                    TOP_NAV_MARKETPLACE_CONVERSATION_COUNT: (action.soFarTopNavUnSeenConvIdSet.includes(action.conversationId) && action.module === NOTIFICATION_TYPES.PRODUCTS_MSG) ? state.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT - 1 : state.user.TOP_NAV_MARKETPLACE_CONVERSATION_COUNT
                }
            };

        case AuthTypes.GETTING_UNSEEN_CONNECTION_REQUESTERS_ID_SET:
            return {
                ...state,
                action: AuthTypes.GETTING_UNSEEN_CONNECTION_REQUESTERS_ID_SET
            };
        case AuthTypes.GETTING_UNSEEN_CONNECTION_REQUESTERS_ID_SET_SUCCESS:
            return {
                ...state,
                action: AuthTypes.GETTING_UNSEEN_CONNECTION_REQUESTERS_ID_SET_SUCCESS,
                user: {
                    ...state.user
                },
                unseenPendingConnectionRequestersIdSet: action.payload
            };
        case AuthTypes.GETTING_UNSEEN_CONNECTION_REQUESTERS_ID_SET_FAIL:
            return {
                ...state,
                action: AuthTypes.GETTING_UNSEEN_CONNECTION_REQUESTERS_ID_SET_FAIL
            };

        case AuthTypes.RELOAD_TOP_NAV_COUNTS_SUCCESS:
            return {
                ...state,
                user: { ...state.user, ...action.payload }
            };
        case AuthTypes.FETCH_TOOLTIP_SUCCESS:
            const newsfeedTT = []
            const marketplaceTT = []
            const companyPageTT = []
            const popupTT = []
            const eventTT = []
            const connectionMessageTT = []
            const proMessageTT = []

            const newsfeedTTH = []
            const marketplaceTTH = []
            const companyPageTTH = []
            const popupTTH = []
            const eventTTH = []
            const connectionMessageTTH = []
            const proMessageTTH = []

            action.payload.forEach(tooltip => {
                if (tooltip.PAGE === 1) {
                    newsfeedTT.push(tooltip)
                    if(tooltip.ICON===3)
                    newsfeedTTH.push(tooltip)
                } else if (tooltip.PAGE === 2) {
                    marketplaceTT.push(tooltip)
                    if(tooltip.ICON===3)
                    marketplaceTTH.push(tooltip)
                } else if (tooltip.PAGE === 3) {
                    companyPageTT.push(tooltip)
                    if(tooltip.ICON===3)
                    companyPageTTH.push(tooltip)
                } else if (tooltip.PAGE === 4) {
                    popupTT.push(tooltip)
                    if(tooltip.ICON===3)
                    popupTTH.push(tooltip)
                } else if (tooltip.PAGE === 5) {
                    eventTT.push(tooltip)
                    if(tooltip.ICON===3)
                    eventTTH.push(tooltip)
                } else if (tooltip.PAGE === 6) {
                    connectionMessageTT.push(tooltip)
                    if(tooltip.ICON===3)
                    connectionMessageTTH.push(tooltip)
                } else if (tooltip.PAGE === 7) {
                    proMessageTT.push(tooltip)
                    if(tooltip.ICON===3)
                    proMessageTTH.push(tooltip)
                }
            })
                return {
                    ...state,

                    toolTips: (window.innerWidth >= 992) ? 
                    [newsfeedTT, marketplaceTT, companyPageTT, popupTT, eventTT, connectionMessageTT, proMessageTT] : 
                    [newsfeedTTH, marketplaceTTH, companyPageTTH, popupTTH, eventTTH, connectionMessageTTH, proMessageTTH]
                };
        case AuthTypes.ADD_INTERESTED_EVENT:
            return {
                ...state,
                user: {
                    ...state.user,
                    events: [
                        ...state.user.events,
                        { _id: action.eventId, status: 1 }
                    ]
                }
            };
        case AuthTypes.REMOVE_INTERESTED_EVENT:
            return {
                ...state,
                user: {
                    ...state.user,
                    events: state.user.events.filter(ev => { return ev._id !== action.eventId })
                }
            };
        case AuthTypes.CHANGE_EVENT_NOTIFICATION:
            return {
                ...state,
                user: {
                    ...state.user,
                    events: action.status
                        ? state.user.events.map(ev => { return (ev._id !== action.eventId ? ev : { ...ev, status: 1 }) })
                        : state.user.events.map(ev => { return (ev._id !== action.eventId ? ev : { ...ev, status: 2 }) })
                }
            }

        case AuthTypes.SET_PUBLIC_ACTION:
            return {
                ...state,
                publicPendingAction: {
                    authType: PUBLIC_AUTH_TYPE.LOGIN,
                    type: action.actionType,
                    data: action.actionData
                }
            };
        case AuthTypes.REMOVE_PUBLIC_ACTION:
            return {
                ...state,
                publicPendingAction: {
                    authType: PUBLIC_AUTH_TYPE.LOGIN,
                    type: null,
                    data: null
                }
            };
        case AuthTypes.SET_PUBLIC_ACTION_AUTH:
            return {
                ...state,
                publicPendingAction: {
                    ...state.publicPendingAction,
                    authType: action.authType
                }
            };
        case AuthTypes.CLOSE_CANDIDATE_SURVEY_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    SHOW_CANDIDATE_SURVEY_POPUP: false
                },
                action: AuthTypes.CLOSE_CANDIDATE_SURVEY_SUCCESS
            };
        case AuthTypes.CLOSE_CANDIDATE_SURVEY_FAIL:
            return {
                ...state,
                user: {
                    ...state.user,
                    SHOW_CANDIDATE_SURVEY_POPUP: false
                },
                action: AuthTypes.CLOSE_CANDIDATE_SURVEY_FAIL
            };
        case AuthTypes.SIGNUP_AS_PROFESSIONAL:
            return {
                ...state,
                action: AuthTypes.SIGNUP_AS_PROFESSIONAL
            };
        case AuthTypes.UPDATE_USER_PERSONAL_SUMMARY_SUCCESS:
            return {
                ...state,
                action: AuthTypes.UPDATE_USER_PERSONAL_SUMMARY_SUCCESS
            };
        case AuthTypes.UPDATE_USER_PERSONAL_SUMMARY_FAIL:
            return {
                ...state,
                action: AuthTypes.UPDATE_USER_PERSONAL_SUMMARY_FAIL
            };

        case GET_LEFT_PANEL_CONVERSATION_COUNT + STATUS.FETCHING:
            return {
                ...state,
                action: GET_LEFT_PANEL_CONVERSATION_COUNT + STATUS.FETCHING
            };
        case GET_LEFT_PANEL_CONVERSATION_COUNT + STATUS.SUCCESS:
            return {
                ...state,
                action: GET_LEFT_PANEL_CONVERSATION_COUNT + STATUS.SUCCESS,
                user: {
                    ...state.user,
                    UNSEEN_CONVERSATION_COUNT: action.payload.UNSEEN_CONVERSATION_COUNT,
                    UNSEEN_PENDING_CONNECTION_CONVERSATION_COUNT: action.payload.UNSEEN_PENDING_CONNECTION_CONVERSATION_COUNT,
                    UNSEEN_MARKETPLACE_CONVERSATION_COUNT: action.payload.UNSEEN_MARKETPLACE_CONVERSATION_COUNT
                }
            };
        case GET_LEFT_PANEL_CONVERSATION_COUNT + STATUS.FAIL:
            return {
                ...state,
                action: GET_LEFT_PANEL_CONVERSATION_COUNT + STATUS.FAIL
            };
        case ProfileTypes.CREATE_VANITY_URL_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    VANITY_URL: action.payload.term
                }
            };
        case RESET_PRO_MESSAGE_LIMIT:
            return {
                ...state,
                user: {
                    ...state.user,
                    PENDING_PRO_MESSAGES_COUNT: action.count
                }
            }
        case AuthTypes.UPDATE_USER_NEWSFEED_STATUS:
            return {
                ...state,
                personalNewsfeed: action.payload
            }

        default:
            return state;
    }
};
