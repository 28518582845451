import profileTypes from '../types/profile';
import { SUB_MENU_TYPES, CHANGE_SUB_MENU } from '../types/common';

export const initialState = {
    fetching: false,
    error: null,
    items: [],
    connectionRequest: false,
    user: {
        _id: '',
        MEMBER_TYPE: null,
        FIRST_NAME: '',
        LAST_NAME: '',
        PROFILE_IMAGE: '',
        JOB_TITLE: '',
        COMPANY_NAME: '',
        CITY: '',
        STATE: '',
        COUNTRY: '',
    },
    selectedSubMenu: SUB_MENU_TYPES.SUMMARY,
    connectionAction: {
        add: {
            id: null,
            status: false
        },
        accept: {
            id: null,
            status: false
        },
        reject: {
            id: null,
            status: false
        }
    },
    publicProfileImageUrl: null,
    publicCompanyImageUrl: null,
    companyProfile: {},
    individualProfile: {
        _id: null,
        FIRST_NAME: null,
        COMPANY_NAME: null,
        PERSONAL_BACKGROUND: null,
        LAST_NAME: null,
        SOCIAL_MEDIA: null,
        INVESTOR: null,
        CITY: null,
        JOB_TITLE: null,
        PHOTO: null,
        EDUCATION: null,
        DEGREE: null,
        CONNECTIONS: null,
        MEMBER_TYPE: null,
        COMPANY_ID: null,
        EMPLOYEMENT_HISTORY: [],
        VANITY_URL:''
    },
    recentPosts: [],
    keyEmpFundrasingDetails: {},
    action: 0,
    requestedConnections: [],
    profileViewLocation: 0
};

export const profile = (state = initialState, action) => {
    switch (action.type) {
        case profileTypes.SET_PROFILE_VIEW_LOCATION:
            return {
                ...state,
                action: profileTypes.SET_PROFILE_VIEW_LOCATION,
                profileViewLocation: action.payload
            };
        case profileTypes.FETCH_PUBLIC_PROFILE_BEGIN:
            return {
                ...state,
                profileFetching: true,
                fetching: true,
                action: profileTypes.FETCH_PUBLIC_PROFILE_BEGIN,
                individualProfile: {
                    _id: null,
                    FIRST_NAME: null,
                    COMPANY_NAME: null,
                    PERSONAL_BACKGROUND: null,
                    LAST_NAME: null,
                    SOCIAL_MEDIA: null,
                    INVESTOR: null,
                    CITY: null,
                    JOB_TITLE: null,
                    PHOTO: null,
                    EDUCATION: null,
                    DEGREE: null,
                    CONNECTIONS: null,
                    MEMBER_TYPE: null,
                    COMPANY_ID: null,
                    EMPLOYEMENT_HISTORY: []
                }
            };
        case profileTypes.FETCH_PUBLIC_PROFILE_SUCCESS:
            return {
                ...state,
                profileFetching: false,
                fetching: false,
                individualProfile: action.payload,
                action: profileTypes.FETCH_PUBLIC_PROFILE_SUCCESS
            };
        case profileTypes.FETCH_PUBLIC_PROFILE_FAILURE:
            return {
                ...state,
                profileFetching: false,
                fetching: false,
                error: action.payload,
                action: profileTypes.FETCH_PUBLIC_PROFILE_FAILURE
            };
        case profileTypes.FETCH_PROFILE_BEGIN:
            return {
                ...state,
                profileFetching: true,
                fetching: true,
                action: profileTypes.FETCH_PROFILE_BEGIN,
                publicCompanyImageUrl: null,
                publicProfileImageUrl: null
            };
        case profileTypes.FETCH_PROFILE_SUCCESS:
            return {
                ...state,
                profileFetching: false,
                fetching: false,
                user: action.payload,
                companyProfile: action.payload,
                action: profileTypes.FETCH_PROFILE_SUCCESS
            };
        case profileTypes.FETCH_PROFILE_FAILURE:
            return {
                ...state,
                profileFetching: false,
                fetching: false,
                error: action.payload,
                action: profileTypes.FETCH_PROFILE_FAILURE
            };
        case profileTypes.FETCH_PROFILE_NOT_FOUND:
            return {
                ...state,
                profileFetching: false,
                fetching: false,
                error: action.payload,
                action: profileTypes.FETCH_PROFILE_NOT_FOUND
            };

        case profileTypes.GETTING_PUBLIC_PROFILE_IMAGE:
            return {
                ...state,
                fetching: true,
                action: profileTypes.GETTING_PUBLIC_PROFILE_IMAGE
            };
        case profileTypes.GETTING_PUBLIC_PROFILE_IMAGE_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: profileTypes.GETTING_PUBLIC_PROFILE_IMAGE_SUCCESS,
                publicProfileImageUrl: action.payload
            };
        case profileTypes.GETTING_PUBLIC_COMPANY_IMAGE_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: profileTypes.GETTING_PUBLIC_PROFILE_IMAGE_SUCCESS,
                publicCompanyImageUrl: action.payload
            };
        case profileTypes.REMOVE_PUBLIC_COMPANY_IMAGE_SUCCESS:
            return {
                ...state,
                publicCompanyImageUrl: null,
                action: profileTypes.GETTING_PUBLIC_PROFILE_IMAGE_SUCCESS,
            };
        case profileTypes.GETTING_PUBLIC_PROFILE_IMAGE_FAIL:
            return {
                ...state,
                fetching: false,
                action: profileTypes.GETTING_PUBLIC_PROFILE_IMAGE_FAIL
            };

        case profileTypes.GETTING_EMPLOYEE_PROFILE_IMAGE_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: profileTypes.GETTING_EMPLOYEE_PROFILE_IMAGE_SUCCESS,
                user: {
                    ...state.user,
                    EMPLOYEES: action.payload
                }
            };

        case profileTypes.GETTING_CURRENT_INVESTORS_PROFILE_IMAGE_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: profileTypes.GETTING_CURRENT_INVESTORS_PROFILE_IMAGE_SUCCESS,
                user: {
                    ...state.user,
                    CURRENT_INVESTORS: action.payload
                }
            };

        case profileTypes.GETTING_INVESTED_COMPANIES_PROFILE_IMAGE_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: profileTypes.GETTING_INVESTED_COMPANIES_PROFILE_IMAGE_SUCCESS,
                user: {
                    ...state.user,
                    CURRENT_INVESTED_COMPANIES: action.payload
                }
            };

        case profileTypes.GETTING_PRODUCT_SHOWCASE_IMAGE_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: profileTypes.GETTING_PRODUCT_SHOWCASE_IMAGE_SUCCESS,
                user: {
                    ...state.user,
                    PRODUCT_SCREENSHOTS: action.payload
                }
            };

        case profileTypes.ADD_CONNECTION:
            return {
                ...state,
                action: profileTypes.ADD_CONNECTION
            };
        case profileTypes.ADD_CONNECTION_SUCCESS:
            return {
                ...state,
                connectionRequest: true,
                action: profileTypes.ADD_CONNECTION_SUCCESS,
                connectionAction: {
                    ...state.connectionAction,
                    add: {
                        id: action.payload.id,
                        status: action.payload.status
                    }
                },
                requestedConnections: state.requestedConnections.concat(action.requestId)
            };
        case profileTypes.NEWSFEED_ADD_CONNECTION_SUCCESS:
            return{
                ...state,
                requestedConnections: state.requestedConnections.concat(action.requestId)
            };
        case profileTypes.ADD_CONNECTION_FAIL:
            return {
                ...state,
                action: profileTypes.ADD_CONNECTION_FAIL,
                connectionAction: {
                    ...state.connectionAction,
                    add: {
                        id: action.payload.id,
                        status: action.payload.status
                    }
                }
            };

        case profileTypes.ACCEPT_CONNECTION:
            return {
                ...state,
                action: profileTypes.ACCEPT_CONNECTION
            };
        case profileTypes.ACCEPT_CONNECTION_SUCCESS:
            return {
                ...state,
                connectionRequest: true,
                action: profileTypes.ACCEPT_CONNECTION_SUCCESS,
                connectionAction: {
                    ...state.connectionAction,
                    accept: {
                        id: action.payload.id,
                        status: action.payload.status
                    }
                }
            };
        case profileTypes.ACCEPT_CONNECTION_FAIL:
            return {
                ...state,
                action: profileTypes.ACCEPT_CONNECTION_FAIL,
                connectionAction: {
                    ...state.connectionAction,
                    accept: {
                        id: action.payload.id,
                        status: action.payload.status
                    }
                }
            };

        case profileTypes.REJECT_CONNECTION:
            return {
                ...state,
                action: profileTypes.REJECT_CONNECTION
            };
        case profileTypes.REJECT_CONNECTION_SUCCESS:
            return {
                ...state,
                connectionRequest: true,
                action: profileTypes.REJECT_CONNECTION_SUCCESS,
                connectionAction: {
                    ...state.connectionAction,
                    reject: {
                        id: action.payload.id,
                        status: action.payload.status
                    }
                }
            };
        case profileTypes.REJECT_CONNECTION_FAIL:
            return {
                ...state,
                action: profileTypes.REJECT_CONNECTION_FAIL,
                connectionAction: {
                    ...state.connectionAction,
                    reject: {
                        id: action.payload.id,
                        status: action.payload.status
                    }
                }
            };


        case profileTypes.CLEAR_ACTION:
            return {
                ...state,
                connectionAction: {
                    add: {
                        id: null,
                        status: false
                    },
                    accept: {
                        id: null,
                        status: false
                    },
                    reject: {
                        id: null,
                        status: false
                    }
                },
                action: 0
            };
        case profileTypes.CLEAR_PROFILE:
            return {
                ...state,
                individualProfile: {
                    _id: null,
                    FIRST_NAME: null,
                    COMPANY_NAME: null,
                    PERSONAL_BACKGROUND: null,
                    LAST_NAME: null,
                    SOCIAL_MEDIA: null,
                    INVESTOR: null,
                    CITY: null,
                    JOB_TITLE: null,
                    PHOTO: null,
                    EDUCATION: null,
                    DEGREE: null,
                    CONNECTIONS: null,
                    MEMBER_TYPE: null,
                    COMPANY_ID: null,
                    EMPLOYEMENT_HISTORY: []
                }
            }
        case profileTypes.CLEAR_CONNECTION_ACTION:
            return {
                ...state,
                connectionAction: {
                    add: {
                        id: null,
                        status: false
                    },
                    accept: {
                        id: null,
                        status: false
                    },
                    reject: {
                        id: null,
                        status: false
                    }
                }
            };
        case profileTypes.FETCH_LAST_POSTS:
            return {
                ...state,
                fetching: true,
                action: profileTypes.FETCH_LAST_POSTS,
                // recentPosts: []
            };

        case profileTypes.FETCH_LAST_POSTS_SUCCESS:
            return {
                ...state,
                fetching: true,
                action: profileTypes.FETCH_LAST_POSTS_SUCCESS,
                recentPosts: [...state.recentPosts, ...action.payload]
            };

        case profileTypes.FETCH_LAST_POSTS_FAIL:
            return {
                ...state,
                fetching: true,
                action: profileTypes.FETCH_LAST_POSTS_FAIL,
                //recentPosts: []
            };
        case profileTypes.CLEAR_LAST_POSTS:
            return {
                ...state,
                recentPosts: []
            };

        case profileTypes.FETCH_KEY_EMP_FUNDRAISING:
            return {
                ...state,
                fetching: true,
                action: profileTypes.FETCH_KEY_EMP_FUNDRAISING,
            };
        case profileTypes.FETCH_KEY_EMP_FUNDRAISING_SUCCESS:
            return {
                ...state,
                fetching: false,
                action: profileTypes.FETCH_KEY_EMP_FUNDRAISING_SUCCESS,
                keyEmpFundrasingDetails: action.payload
            };
        case profileTypes.FETCH_KEY_EMP_FUNDRAISING_FAILURE:
            return {
                ...state,
                fetching: false,
                action: profileTypes.FETCH_KEY_EMP_FUNDRAISING_FAILURE
            };
        case profileTypes.CLEAR_FUNDRAISING_DETAILS: {
            return {
                ...state,
                action: profileTypes.CLEAR_FUNDRAISING_DETAILS,
                keyEmpFundrasingDetails: {}
            }
        }
        case CHANGE_SUB_MENU:
            return {
                ...state,
                selectedSubMenu: action.payload
            }
        case profileTypes.CLEAR_REQUESTED_CONNECTIONS:
            return {
                ...state,
                requestedConnections:[]
            };
        case profileTypes.CREATE_VANITY_URL_SUCCESS:
            return {
                ...state,
                individualProfile: {
                    ...state.individualProfile,
                    VANITY_URL: action.payload.term
                }
            };
        case profileTypes.UPDATE_CONNECTION_COUNT_IN_USER_PROFILE:                
                let UpdatedFriendStatus =[];
                if(action.change ===1){
                    UpdatedFriendStatus.push({
                        'CONNECTED_AT': state.individualProfile.FRIEND_STATUS[0].CONNECTED_AT,
                        'IS_SEEN': state.individualProfile.FRIEND_STATUS[0].IS_SEEN,
                        'RECIPIENT': state.individualProfile.FRIEND_STATUS[0].RECIPIENT,
                        'REQUESTER': state.individualProfile.FRIEND_STATUS[0].REQUESTER,
                        'STATUS':2,
                        'UPDATED_AT': state.individualProfile.FRIEND_STATUS[0].UPDATED_AT
                    })
                }
                return {                    
                    ...state,
                    individualProfile: {
                        ...state.individualProfile,
                        CONNECTIONS: action.count+ action.change,
                        FRIEND_STATUS: UpdatedFriendStatus

                    }
            }     
        default:
            return state
    }
}