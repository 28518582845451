import axios from 'axios';
import ConnectionTypes from '../types/connections';
import {ROUTES} from '../config';
import {SET_IMAGES} from './../types/images';
import * as ApiConnector from '../libs/apiConnector';
import { 
    CONNECTIONS_SECTION, 
    NOTIFICATION_TYPES, 
    CONNECTION_REJECT_TYPES, 
    POP_ALERT, 
    BLOCKUSER_ACCEPT_ERROR_MSG,
    BLOCKUSER_REJECT_ERROR_MSG,
    BLOCKUSER_POPUP_CLASSNAME
} from "./../types/common";
import { notifyReceiver } from "./../libs/socket";
import { reloadUserNotificationCounters } from './../actions/auth';
import { refeshChat } from '../actions/chat';
import { notifyUserAccountDeletion } from './../actions/profile';
import { errorHandler } from '../libs/commonFunctions';
import { getLeftPanelCoversationCounts } from '../actions/message-v2'
import { ADD_LAST_ACCEPTED_USER_MESSAGE } from './../types/message-v2'
import {get, post} from "../libs/apiConnector";
import { showPopupAlert } from './alert';

export const getMutualFriends = async (friendId) => {

    try {
        const {data, err} = await ApiConnector.post(ROUTES.GET_MUTUAL_CONNECTIONS, {friendId});
        return {
            data: data.data,
            success: !err
        };
    } catch (e) {
        return {
            success: false
        };
    }
};

export function fetchConnections(filter, cancelToken) {
    return function (dispatch) {
        dispatch({type: ConnectionTypes.FETCHING_MY_CONNECTIONS});
        post(`${ROUTES.GET_CONNECTIONS}`, filter, {cancelToken})
            .then(response => {
                if (response.data.code === 200) {
                    let isNeedToClear = filter.skip === 0;
                    dispatch({
                        type: ConnectionTypes.FETCHING_MY_CONNECTIONS_SUCCESS,
                        payload: response.data.data,
                        reset: isNeedToClear
                    });
                } else {
                    dispatch({type: ConnectionTypes.FETCHING_MY_CONNECTIONS_FAIL});
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: ConnectionTypes.FETCHING_MY_CONNECTIONS_FAIL});
                }
            })
    }
}

export function fetchConnectionsCount(filter, cancelToken) {
    return function (dispatch) {
        dispatch({type: ConnectionTypes.FETCHING_MY_CONNECTIONS_COUNT});
        post(`${ROUTES.GET_MY_CONNECTIONS_COUNT}`, filter, {cancelToken})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({
                        type: ConnectionTypes.FETCHING_MY_CONNECTIONS_COUNT_SUCCESS,
                        payload: response.data.data.COUNT
                    });
                } else {
                    dispatch({type: ConnectionTypes.FETCHING_MY_CONNECTIONS_COUNT_FAIL});
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: ConnectionTypes.FETCHING_MY_CONNECTIONS_FAIL});
                }
            })
    }
}

export function fetchRecommondedConnections(filter, isNeedToClear = false, cancelToken) {
    return function (dispatch, getState) {
        dispatch({type: ConnectionTypes.FETCHING_RECOMMONDED_CONNECTIONS});
        post(`${ROUTES.GET_SUGGESTIONS}`, filter, {cancelToken})
            .then(response => {
                // const { skip, limit } = response.config.headers.filter;
                if (response.data.code === 200) {
                    const {skip, limit} = filter
                    dispatch(showRecommondedConnectionNextButton(skip, limit, cancelToken));
                    dispatch({
                        type: ConnectionTypes.FETCHING_RECOMMONDED_CONNECTIONS_SUCCESS,
                        payload: response.data.data,
                        // reset: false /* Removed due to the LEAF-5924 */
                    });
                    if (response.data.data && response.data.data.length > 0) {
                        let count = response.data.data.length;
                        let images = {};
                        let cacheImages = {};
                        response.data.data.forEach(item => {
                            if (!item.PROFILE_PHOTO || item.PROFILE_PHOTO === '') {
                                images[item._id] = '';
                                if (Object.keys(images).length === count) {
                                    dispatch({
                                        type: ConnectionTypes.SET_RECOMMENDED_CONNECTIONS_IMAGES,
                                        payload: images
                                    });
                                }
                            } else {
                                const {images_L} = (getState().images);
                                if (images_L[item.PROFILE_PHOTO]) {
                                    images[item._id] = images_L[item.PROFILE_PHOTO];
                                    if (Object.keys(images).length === count) {
                                        dispatch({
                                            type: ConnectionTypes.SET_RECOMMENDED_CONNECTIONS_IMAGES,
                                            payload: images
                                        });
                                    }
                                } else {
                                    get(`${ROUTES.IMAGE_PATH}/${item.PROFILE_PHOTO}/1`)
                                        .then(imgResponse => {
                                            if (imgResponse.status === 200) {
                                                images[item._id] = imgResponse.data;
                                                cacheImages[item.PROFILE_PHOTO] = imgResponse.data;
                                                if (Object.keys(images).length === count) {
                                                    dispatch({
                                                        type: ConnectionTypes.SET_RECOMMENDED_CONNECTIONS_IMAGES,
                                                        payload: images
                                                    });
                                                    dispatch({type: SET_IMAGES, payload: cacheImages})
                                                }
                                            } else {
                                                images[item._id] = '';
                                                if (Object.keys(images).length === count) {
                                                    dispatch({
                                                        type: ConnectionTypes.SET_RECOMMENDED_CONNECTIONS_IMAGES,
                                                        payload: images
                                                    });
                                                    dispatch({type: SET_IMAGES, payload: cacheImages})
                                                }
                                            }
                                        })
                                        .catch(err => {
                                            console.log(err)
                                        })
                                }
                            }
                        })
                    }
                } else {
                    dispatch({type: ConnectionTypes.FETCHING_RECOMMONDED_CONNECTIONS_FAIL});
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    errorHandler(dispatch, error);
                    dispatch({type: ConnectionTypes.FETCHING_RECOMMONDED_CONNECTIONS_FAIL});
                }
            })
    }
}

export function requestToFetchMore() {
    return function (dispatch) {
        dispatch({type: ConnectionTypes.FETCHING_MORE_CONNECTIONS});
    }
}

export function loadMoreRecommondedConnections() {
    return function (dispatch) {
        dispatch({type: ConnectionTypes.FETCHING_MORE_RECOMMONDED_CONNECTIONS});
    }
}

export function loadMoreSearchConnections() {
    return function (dispatch) {
        dispatch({type: ConnectionTypes.FETCHING_MORE_SEARCH_CONNECTIONS});
    }
}

export function removeConnection() {
    return function (dispatch) {

    }
}

export function removeRecommondedConnections(skip) {
    return function (dispatch) {
        dispatch({type: ConnectionTypes.CLEAR_RECOMMONDED_CONNECTIONS, payload: skip});
    }
}

export function removeMyConnections() {
    return function (dispatch) {
        dispatch({type: ConnectionTypes.CLEAR_MY_CONNECTIONS});
    }
}

export function toggleSearchMode(mode) {
    return function (dispatch) {
        dispatch({type: ConnectionTypes.TOGGLE_SEARCH_MODE, payload: mode});
    }
}

export function setToFirst() {
    return function (dispatch) {
        dispatch({type: ConnectionTypes.SET_TO_FIRST});
    }
}

export function clearAction() {
    return function (dispatch) {
        dispatch({type: ConnectionTypes.CLEAR_ACTION})
    }
}

export function getRecommondedConnectionCount(cancelToken) {
    return function (dispatch) {
        dispatch({type: ConnectionTypes.FETCHING_RECOMMONDED_CONNECTIONS_COUNT});
        get(`${ROUTES.GET_SUGGESTIONS_COUNT}`, {cancelToken})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({
                        type: ConnectionTypes.FETCHING_RECOMMONDED_CONNECTIONS_COUNT_SUCCESS,
                        payload: response.data.data
                    });
                } else {
                    dispatch({type: ConnectionTypes.FETCHING_RECOMMONDED_CONNECTIONS_COUNT_FAIL});
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: ConnectionTypes.FETCHING_RECOMMONDED_CONNECTIONS_COUNT_FAIL});
                }
            })
    }
}

export function showRecommondedConnectionNextButton(skip, limit, cancelToken) {
    return function (dispatch) {
        dispatch({type: ConnectionTypes.FETCH_RECOMMENDED_NEXT_BUTTON});
        post(`${ROUTES.GET_SUGGESTIONS_COUNT}`, {skip, limit}, {cancelToken})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({
                        type: ConnectionTypes.FETCH_RECOMMENDED_NEXT_BUTTON_SUCCESS,
                        payload: response.data.data.isNextPageAvailable
                    });
                } else {
                    dispatch({type: ConnectionTypes.FETCH_RECOMMENDED_NEXT_BUTTON_FAIL});
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: ConnectionTypes.FETCHING_RECOMMONDED_CONNECTIONS_COUNT_FAIL});
                }
            })
    }
}

export function fetchPendingConnections(skip, limit, cancelToken, data = null) {
    return function (dispatch) {
        dispatch({type: ConnectionTypes.FETCHING_PENDING_CONNECTIONS});
        post(`${ROUTES.GET_PENDING_CONNECTIONS}`, {skip, limit, data}, {cancelToken})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({
                        type: ConnectionTypes.FETCHING_PENDING_CONNECTIONS_SUCCESS,
                        payload: response.data.data,
                        reset: skip === 0
                    });
                } else {
                    dispatch({type: ConnectionTypes.FETCHING_PENDING_CONNECTIONS_FAIL});
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: ConnectionTypes.FETCHING_PENDING_CONNECTIONS_EXCEPTION});
                }
            })
    }
}

export function getPendingConnectionCount(cancelToken, data = null) {
    return function (dispatch) {
        dispatch({type: ConnectionTypes.FETCHING_PENDING_CONNECTIONS_COUNT});
        post(`${ROUTES.GET_PENDING_CONNECTION_COUNT}`, {data}, {cancelToken})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({
                        type: ConnectionTypes.FETCHING_PENDING_CONNECTIONS_COUNT_SUCCESS,
                        payload: response.data.data
                    });
                } else {
                    dispatch({type: ConnectionTypes.FETCHING_PENDING_CONNECTIONS_COUNT_FAIL});
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: ConnectionTypes.FETCHING_PENDING_CONNECTIONS_COUNT_EXCEPTION});
                }
            })
    }
}

export function clearPendingConnections() {
    return function (dispatch) {
        dispatch({type: ConnectionTypes.CLEAR_PENDING_CONNECTIONS});
    }
}

export function acceptConnection(requesterUserId, successCallback = null, errorCallback = null, socket = null, message=null) {
    return function (dispatch) {
        dispatch({type: ConnectionTypes.ACCEPTING_PENDING_CONNECTION});
        post(`${ROUTES.ACCEPT_CONNECTION}`, {requesterUserId})
            .then(async response => {
                if (response.data.code === 200) {
                    dispatch({type: ConnectionTypes.ACCEPT_PENDING_CONNECTION_SUCCESS, userId: requesterUserId});
                    dispatch({type: ConnectionTypes.CLEAR_ACTION});

                    dispatch({type: ConnectionTypes.DECREMENT_PENDING_CONNECTION, userId: requesterUserId});
                    dispatch(getConnectionsCount())
                    // send new conversation id - LEAF 3481
                    if (successCallback) {
                        if (response.data && response.data.data && response.data.data.conversationId) {
                            successCallback(response.data.data.conversationId)
                        } else if (errorCallback) {
                            errorCallback()
                        }
                    }

                    // update conversation
                    refeshChat(dispatch, requesterUserId);
                    setTimeout(() => {
                        dispatch(getLeftPanelCoversationCounts())
                    }, 4000)
                    if(response.data && response.data.data && response.data.data.conversationId){
                        dispatch({ type: ADD_LAST_ACCEPTED_USER_MESSAGE, conversationId: response.data.data.conversationId})
                    }

                    // Notify Reaction to requester
                    if(socket){
                        let socketData = {
                            type: 'ACCEPT',
                            requester: requesterUserId,
                            recipient: localStorage.getItem('userId'),
                            newConversationId: (response.data && response.data.data && response.data.data.conversationId) ? response.data.data.conversationId : null
                        };
                        notifyReceiver(socket, requesterUserId, NOTIFICATION_TYPES.REACT_CONNECTION_REQUEST, socketData)
                    } else {
                        console.error('socket error')
                    }

                }  else if (response.data.code === 217) {
                    dispatch({ type: ConnectionTypes.ACCEPT_PENDING_CONNECTION_FAIL });
                    dispatch({ type: ConnectionTypes.CLEAR_ACTION });
                    showPopupAlert(dispatch, POP_ALERT.OK, '', BLOCKUSER_ACCEPT_ERROR_MSG, '', BLOCKUSER_POPUP_CLASSNAME);
                    if(errorCallback) {errorCallback(false, true)}
                } else {
                    dispatch({ type: ConnectionTypes.ACCEPT_PENDING_CONNECTION_FAIL });
                    dispatch({ type: ConnectionTypes.CLEAR_ACTION });
                    let userStatus = await notifyUserAccountDeletion(requesterUserId)
                    if(errorCallback) {errorCallback(userStatus, false)}
                }
            })
            .catch((error) => {
                dispatch({type: ConnectionTypes.ACCEPT_PENDING_CONNECTION_EXCEPTION});
                dispatch({type: ConnectionTypes.CLEAR_ACTION});
                if (errorCallback) {
                    errorCallback()
                }
            })
    }
}

export function rejectConnection(rejectUserId, successCallback=null, errorCallback= null, socket=null, rejectType=CONNECTION_REJECT_TYPES.REJECT_REQUEST, message=null) {
    return function (dispatch) {
        dispatch({type: ConnectionTypes.REJECTING_PENDING_CONNECTION});
        post(`${ROUTES.REJECT_CONNECTION}`, {rejectUserId, rejectType})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: ConnectionTypes.REJECT_PENDING_CONNECTION_SUCCESS, userId: rejectUserId});
                    dispatch({type: ConnectionTypes.CLEAR_ACTION});
                    dispatch({type: ConnectionTypes.DECREMENT_PENDING_CONNECTION, userId: rejectUserId});
                    dispatch(getConnectionsCount());
                    dispatch(reloadUserNotificationCounters());
                    // redirect to all messages when rejected - LEAF 3481
                    if(successCallback){successCallback()}

                    // Notify Reaction to requester
                    if (socket) {
                        let socketData = {
                            type: 'REJECT',
                            requester: rejectUserId,
                            recipient: localStorage.getItem('userId'),
                            newConversationId: null,
                            deletedConversation: ( response.data.data && response.data.data.deletedConversationId ) ? response.data.data.deletedConversationId : null
                        };
                        notifyReceiver(socket, rejectUserId, NOTIFICATION_TYPES.REACT_CONNECTION_REQUEST, socketData)
                    }

                }  else if (response.data.code === 217) {
                    dispatch({type: ConnectionTypes.REJECT_PENDING_CONNECTION_FAIL});
                    dispatch({type: ConnectionTypes.CLEAR_ACTION});
                    showPopupAlert(dispatch, POP_ALERT.OK, '', BLOCKUSER_REJECT_ERROR_MSG, '', BLOCKUSER_POPUP_CLASSNAME);
                    if (errorCallback) {
                        errorCallback(true)
                    }
                } else {
                    dispatch({type: ConnectionTypes.REJECT_PENDING_CONNECTION_FAIL});
                    dispatch({type: ConnectionTypes.CLEAR_ACTION});
                    if (errorCallback) {
                        errorCallback()
                    }
                }
            })
            .catch((error) => {
                dispatch({type: ConnectionTypes.REJECT_PENDING_CONNECTION_EXCEPTION});
                dispatch({type: ConnectionTypes.CLEAR_ACTION});
                if (errorCallback) {
                    errorCallback()
                }
            })
    }
}

export function getConnectionsCount(cancelToken) {
    return function (dispatch) {
        dispatch({type: ConnectionTypes.GET_CONNECTIONS_COUNT});
        get(`${ROUTES.GET_CONNECTIONS_COUNT}`, {cancelToken})
            .then(response => {
                if (response.data.code === 200) {
                    dispatch({type: ConnectionTypes.GET_CONNECTIONS_COUNT_SUCCESS, payload: response.data.data});
                } else {
                    dispatch({type: ConnectionTypes.GET_CONNECTIONS_COUNT_FAIL});
                }
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return;
                } else {
                    dispatch({type: ConnectionTypes.GET_CONNECTIONS_COUNT_FAIL});
                }
            })
    }
}

export function updateConnectionRequestNotification(module, data) {
    return function (dispatch, getState) {
        dispatch({type: ConnectionTypes.CLEAR_PREVIOUS_REQUEST});
        if (Number(module) === NOTIFICATION_TYPES.CONNECTION_REQUESTS) {
            dispatch({type: ConnectionTypes.NEW_CONNECTION_REQUEST_NOTIFICATION, userId: data._id});
            if (getState().connection.activeFilterConnectionSection === CONNECTIONS_SECTION.PENDING) {
                dispatch({type: ConnectionTypes.PUSH_NEW_CONNECTION_REQUEST, data});
            }
        } else if (Number(module) === NOTIFICATION_TYPES.CANCEL_CONNECTION_REQUEST) {
            dispatch({type: ConnectionTypes.CANCEL_CONNECTION_REQUEST_NOTIFICATION, userId: data.requesterUserId});
            if (getState().connection.activeFilterConnectionSection === CONNECTIONS_SECTION.PENDING) {
                dispatch({type: ConnectionTypes.POP_EXISTING_CONNECTION_REQUEST, data});
            }
        }
    }
}

export const connectionAcceptedNotification = (acceptedBy) => async dispatch => dispatch({
    type: ConnectionTypes.CONNECTION_REQ_ACCEPTED_BY_USER,
    acceptedBy
})

export const connectionRejectededNotification = (rejectedBy) => async dispatch => dispatch({
    type: ConnectionTypes.CONNECTION_REQ_REJECTED_BY_USER,
    rejectedBy
})

export const clearPreviousRequest = () => async dispatch => dispatch({ type: ConnectionTypes.CLEAR_PREVIOUS_REQUEST })

export const setRequestedUser = (userId) => dispatch => dispatch({type: ConnectionTypes.SET_REQUESTED_USER_NOTIFICATION, userId: userId})

export const clearRequestedUser = () => dispatch => dispatch({type: ConnectionTypes.CLEAR_REQUESTED_USER_NOTIFICATION})

export const getMyFriendsConnections = async (friendId, skip, limit) => {
    try {
        const {data, err} = await ApiConnector.post(ROUTES.GET_MY_FRIEND_CONNECTIONS,{friendId, skip, limit});
        return {
            data: data.data,
            success: !err
        };
    } catch (e) {
        return {
            success: false
        };
    }
};
