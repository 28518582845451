module.exports = {
    // common
    CLEAR_ACTION: 'CLEAR_ACTION_MV2',
    STATUS: {
        FETCHING: '_FETCHING',
        SUCCESS: '_SUCCESS',
        FAIL: '_FAIL',
        DELETING: '_DELETING',
        BLOCKED: '_BLOCKED'
    },

    // actions
    CHANGE_ACTIVE_SECTION: "CHANGE_ACTIVE_SECTION",
    CHANGE_ACTIVE_CONVERSATION: "CHANGE_ACTIVE_CONVERSATION",
    GET_CONVERSATIONS: 'GET_CONVERSATIONS_V2',
    GET_CONVERSATIONS_COUNT: 'GET_CONVERSATIONS_COUNT_V2',
    EMPTY_MESSAGES: 'EMPTY_MESSAGES_V2',
    EMPTY_MESSAGES_COUNT: 'EMPTY_MESSAGES_COUNT_V2',
    EMPTY_MESSAGES_PARTICIPANTS: 'EMPTY_MESSAGES_PARTICIPANTS_V2',
    GET_MESSAGES: 'GET_MESSAGES_V2',
    GET_MESSAGES_COUNT: 'GET_MESSAGES_COUNT_V2',
    GET_CONVERSATION_PARTICIPANTS: 'GET_CONVERSATION_PARTICIPANTS_V2',
    GOT_EXISTING_CONVERSATION_ID: 'GOT_EXISTING_CONVERSATION_ID_V2',
    SET_RECIPIENT_DETAILS: 'SET_RECIPIENT_DETAILS_V2',
    CLEAR_RECIPIENT_DETAILS: 'CLEAR_RECIPIENT_DETAILS_V2',
    GET_CONVERSATION_PARTICIPANT_IMG: 'GET_CONVERSATION_PARTICIPANT_IMG_V2',
    GET_RECIPIENT_CONNECTION_STATUS: 'GET_RECIPIENT_CONNECTION_STATUS_V2',
    SEND_MESSAGE: 'SEND_MESSAGE_V2',
    START_NEW_CONVERSATION: 'START_NEW_CONVERSATION_V2',
    DELETE_CONVERSATION_THREAD: 'DELETE_CONVERSATION_THREAD',
    SHOW_MSG_NOTIFICATION_COUNT: 'SHOW_MSG_NOTIFICATION_COUNT',
    SHOW_MARKETPLACE_MSG_NOTIFICATION_COUNT: 'SHOW_MARKETPLACE_MSG_NOTIFICATION_COUNT',
    
    NEW_MESSAGE_NOTIFICATION: 'NEW_MESSAGE_NOTIFICATION',
    UPDATE_LAST_SEEN_MSG: 'UPDATE_LAST_SEEN_MSG',

    RECEIVED_MARKETPLACE_MSG_NOTIFICATION: 'RECEIVED_MARKETPLACE_MSG_NOTIFICATION',
    DECREMENT_MARKETPLACE_MSG_NOTIFICATION: 'DECREMENT_MARKETPLACE_MSG_NOTIFICATION',

    RECEIVED_PERSONAL_MSG_NOTIFICATION: 'RECEIVED_PERSONAL_MSG_NOTIFICATION',
    DECREMENT_PERSONAL_MSG_NOTIFICATION: 'DECREMENT_PERSONAL_MSG_NOTIFICATION',

    RECEIVED_CONNECTION_MSG_NOTIFICATION: 'RECEIVED_CONNECTION_MSG_NOTIFICATION',
    DECREMENT_CONNECTION_MSG_NOTIFICATION: 'DECREMENT_CONNECTION_MSG_NOTIFICATION',
    
    UPDATE_ACTIVE_CONVERSATION_MSG: 'UPDATE_ACTIVE_CONVERSATION_MSG',
    UPDATE_USER_TYPING_STATUS: 'UPDATE_USER_TYPING_STATUS',

    GETTING_NOTIFIED_CONV_ID_SET: "GETTING_NOTIFIED_CONV_ID_SET",
    GETTING_NOTIFIED_CONV_ID_SET_SUCCESS: "GETTING_NOTIFIED_CONV_ID_SET_SUCCESS",
    GETTING_NOTIFIED_CONV_ID_SET_FAIL: "GETTING_NOTIFIED_CONV_ID_SET_FAIL",

    GETTING_NOTIFIED_CHAT_ID_SET: "GETTING_NOTIFIED_CHAT_ID_SET",
    GETTING_NOTIFIED_CHAT_ID_SET_SUCCESS: "GETTING_NOTIFIED_CHAT_ID_SET_SUCCESS",
    GETTING_NOTIFIED_CHAT_ID_SET_FAIL: "GETTING_NOTIFIED_CHAT_ID_SET_FAIL",

    GETTING_UNSEEN_TOP_NAV_CONV_ID_SET: "GETTING_UNSEEN_TOP_NAV_CONV_ID_SET",
    GETTING_UNSEEN_TOP_NAV_CONV_ID_SET_SUCCESS: "GETTING_UNSEEN_TOP_NAV_CONV_ID_SET_SUCCESS",
    GETTING_UNSEEN_TOP_NAV_CONV_ID_SET_FAIL: "GETTING_UNSEEN_TOP_NAV_CONV_ID_SET_FAIL",

    MARK_ALL_MESSAGES_READ: 'MARK_ALL_MESSAGES_READ',
    MARK_ALL_CONVERSATIONS_READ: 'MARK_ALL_CONVERSATIONS_READ',

    REMOVE_CONNECTION_CONVERSATION: 'REMOVE_CONNECTION_CONVERSATION',
    FILTER_DELETED_CONVERSATION: 'FILTER_DELETED_CONVERSATION',

    OPEN_MESSAGE: 'OPEN_MESSAGE',
    RESET_MESSAGE: 'RESET_MESSAGE',

    GET_LEFT_PANEL_CONVERSATION_COUNT: 'GET_LEFT_PANEL_CONVERSATION_COUNT',
    ADD_LAST_ACCEPTED_USER_MESSAGE: 'ADD_LAST_ACCEPTED_USER_MESSAGE',

    GET_WARNING_DISCLAIMER_TERMS: 'GET_WARNING_DISCLAIMER_TERMS',
    GET_WARNING_DISCLAIMER_TEXT: 'GET_WARNING_DISCLAIMER_TEXT'


};