import React, { Component } from "react";
import "./index.css";
import { isURL } from './../../../../libs/validation';
import { ROUTES } from '../../../../config';

import defaultAvatorCompany from "./../../../../assets/img/default-corporate-image.jpg";
import defaultAvatorPeople from "./../../../../assets/img/default-male.jpg";

import classNames from "classnames";
import { Link } from 'react-router-dom';
import { STATUS } from '../../../../types/common'
import {get} from "../../../../libs/apiConnector";


class ItemBoxSm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      avatar: this.props.isCompany ? defaultAvatorCompany : defaultAvatorPeople,
      imageName: this.props.imgSrc
    }
  }

  componentWillMount() {
    if (!isURL(String.toString(this.props.imgSrc)) && this.props.imgSrc !== '') {
      this._getImageUrl(this.props.imgSrc);
    }
  }

  _getImageUrl(imageName) {
    get(`${ROUTES.IMAGE_PATH}/${imageName}/1`)
      .then(response => {
        if (response.status === 200) {
          if (response.data) {
            this.setState({
              avatar: response.data
            });
          }
        } else {
          this.setState({
            avatar: this.props.isCompany ? defaultAvatorCompany : defaultAvatorPeople
          });
        }
      })
  }

  render() {

    let avatarStyle = {
      backgroundImage: "url(" + this.state.avatar + ")",
      height: this.props.height + "px",
      width: this.props.width + "px"
    };

    /*let loaderBoxStyle = {
      height: this.props.height + "px",
      width: this.props.width + "px"
    };*/

    let display = null;
    let name = null;
    let subTitle = null;
    let duration = null;
    let media = classNames("media_item_box", { media_item_box_shdow: this.props.boxShadow });

    if (this.state.avatar) {
      display = (
        <div className={media} style={avatarStyle}>
          <div className="media_item_box_action">
            {this.props.deleteOption === true ? (
              <button className="media_item_box_action_button" >
                <i className="far fa-trash-alt media_item_box_action_icon" />
              </button>
            ) : null}
          </div>
        </div>
      );
    } else {
      display = <div className="media_item_box" />;
    }

    if (this.props.title) {
      name = (
        this.props.status === STATUS.NOT_DELETED 
        ? <Link style={{ textDecoration: 'none' }} to={this.props.redirectLink}>
          <h3 className="text-center font_16 font_d_blue font_regular m-t15 ">
            {this.props.title}
          </h3>
        </Link>
        : <h3 className="text-center font_16 font_d_blue font_regular m-t15 ">
            {this.props.title}
          </h3>
      );
    } else {
      name = null;
    }

    if (this.props.subText) {
      subTitle = (
        <p className="text-center font_14 font_dark_gray font_light m-t5 ">
          {this.props.subText}
        </p>
      );
    } else {
      subTitle = null;
    }

    if (this.props.years) {
      duration = (
        <p className="text-center font_14 font_dark_gray font_light m-t3 ">
          {this.props.years}
        </p>
      );
    } else {
      duration = null;
    }

    return (
      <div className="m-t10 m-b10 media_item_box_conten">
        {display}
        {name}
        {subTitle}
        {duration}
      </div>
    );
  }

}

ItemBoxSm.defaulProps = {
  backgroundImage: null,
  height: "auto",
  width: "100%",
  title: null,
  profile: true,
  imgSrc: null,
  boxShadow: false,
  deleteOption: true,
  subText: null,
  years: null
};

export { ItemBoxSm };